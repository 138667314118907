import {
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import {
    DesignProposal,
    DesignProposalRequestParam,
    DesignProposalEnum,
    DesignProposalService,
    Project,
    ProjectStorageService,
    FurbanUtil,
    CommentsPopupComponent,
    ThreeStateEnum,
    PathObject,
    PathObjectsService,
    MapService,
    SortingObjectsArray,
    routingConstants,
    AuthService,
    DpSortObject,
    DesignProposalIsAdminFilterEnum,
    DpCitizenViewEnum,
    ReactionsNameEnum,
    DesignProposalReaction,
    ReactionsTypeEnum,
    ReactionsViewEnum,
    CustomToasterComponent,
    Report,
    Comment,
    ReportService,
    CustomToasterService,
    ModalManager,
    QuickViewEnum,
    CommentsService,
    TutorialsService,
    MediaService
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription, switchMap, of } from 'rxjs';
import { PublicThreeJsEditorComponent } from '../../../shared/public-three-js-editor/public-three-js-editor.component';
import { DpQuickViewComponent } from '../dp-quick-view/dp-quick-view.component';
import { DesignViewerCarouselService } from '../../../project-shared/_services/design-viewer-carousel.service';
import { ProjectDetailsService } from '../../../project-shared/project-details/project-details.service';
@Component({
    selector: 'furban-design-viewer-carousel',
    templateUrl: './design-viewer-carousel.component.html',
    styleUrls: ['./design-viewer-carousel.component.scss'],
})
export class DesignViewerCarouselComponent implements OnInit, OnDestroy {
    @ViewChild('threeJsEditorComponent')
    threeJsEditorComponent: PublicThreeJsEditorComponent;
    @ViewChild('dpQuickView') dpQuickView: DpQuickViewComponent;

    public designProposals: DesignProposal[];
    public currentPage = 0;
    public filterType: string = DesignProposalEnum.allDesignProposals;
    public sorting: DpSortObject;
    public project: Project;
    public currentDisplayedDp: DesignProposal;
    public defaultObjects: PathObject[];
    public currentObjects?: PathObject[];

    public sortingObjects: DpSortObject[];
    public swipeAction = { left: 'swipeleft', right: 'swiperight' };
    public threeState = ThreeStateEnum.view;

    public getColorBasedOnProposalType = FurbanUtil.getColorBasedOnProposalType;
    public getDesignProposalTypeText = FurbanUtil.getDesignProposalTypeText;
    public isVotingDateExpired = false;
    public threeIsReady: boolean;
    public displayedDPIndex: number;
    public designProposalsCount: number;

    public isCollaborativeProject: boolean;
    public reactions: ReactionsTypeEnum[] = [
        ReactionsTypeEnum.angry,
        ReactionsTypeEnum.dissatisfied,
        ReactionsTypeEnum.neutral,
        ReactionsTypeEnum.satisfied,
        ReactionsTypeEnum.happy,
    ];
    public currentDesignProposalReaction: DesignProposalReaction;
    public currentView: ReactionsViewEnum;
    public quickView: QuickViewEnum;
    public quickViewBtns: string[] = [
        QuickViewEnum.designProposal,
        QuickViewEnum.comments,
    ];
    public isQuickViewToggled = false;
    private isMobile = FurbanUtil.isMobile();
    private userProfileId: string;
    private projectId: string;
    private projectStorageSubscription: Subscription;
    private pinCommentSubscription: Subscription;
    private tutorialsSubscription: Subscription;
    private highlightedCommentSubscription: Subscription;
    private routeSubscription: Subscription;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private designProposalService: DesignProposalService,
        private projectStorageService: ProjectStorageService,
        private pathObjectsService: PathObjectsService,
        private router: Router,
        private mapService: MapService,
        private dialog: MatDialog,
        private authService: AuthService,
        private designViewerCarouselService: DesignViewerCarouselService,
        private translateService: TranslateService,
        private reportService: ReportService,
        private customToasterService: CustomToasterService,
        private modal: ModalManager,
        private tutorialsService: TutorialsService,
        private mediaService: MediaService,
        private commentsService: CommentsService,
        private projectDetailsService: ProjectDetailsService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {

        this.project = this.projectStorageService.getCurentProject();

        if (this.project) {
            this.setupProjectInformation();
            return;
        }

        this.subscribeToRoute();

    }

    ngOnDestroy(): void {
        this.designProposalService.citizenView = null;
        this.unsubscribeEvents();
    }

    private subscribeToEvents() {
        this.subscribeToProjectChange();
        this.subscribeTo3dPinComment();
        this.subscribeToHighlightedComment();
        this.subscribeToTutorials();
    }

    private unsubscribeEvents() {
        this.projectStorageSubscription?.unsubscribe();
        this.pinCommentSubscription?.unsubscribe();
        this.highlightedCommentSubscription?.unsubscribe();
        this.tutorialsSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    @HostListener('scroll', ['$event'])
    public onScroll(event: any): void {
        if (event.target.offsetWidth + event.target.scrollLeft >= event.target.scrollWidth) {
            this.getNextPage();
        }
    }

    public isSelected(reactionId: number): boolean {
        return (
            this.currentDesignProposalReaction &&
            this.currentDesignProposalReaction.reactionId === reactionId
        );
    }

    public scrollEmitted(event: Event) {
        if (!event) {
            return;
        }

        this.getNextPage();
    }

    public get reactionsNameEnum(): typeof ReactionsNameEnum {
        return ReactionsNameEnum;
    }

    public getImageURL(reactionId: ReactionsTypeEnum): string {
        return `../../../assets/icons/reactions/${this.getReactionName(
            reactionId
        )}.png`;
    }

    public closeQuickView(): void {
        this.disable3DPinIfActive();
        this.isQuickViewToggled = false;
    }

    public toggleQuickView(view: QuickViewEnum): void {
        this.disable3DPinIfActive();
        if (this.isQuickViewToggled && this.quickView !== view) {
            this.quickView = view;
            return;
        }
        this.quickView = view;
        this.tutorialsService.closeTutorialMenu();
        this.isQuickViewToggled = !this.isQuickViewToggled;
    }

    public reactToDesignProposal(reactionId: ReactionsTypeEnum, currentDisplayedDp: DesignProposal): void {
        const isTheSameReactionClicked =
            reactionId === this.currentDesignProposalReaction?.reactionId;
        const designProposalReaction = new DesignProposalReaction(
            reactionId,
            this.userProfileId,
            this.project.projectId,
            currentDisplayedDp.designProposalId,
            this.currentDesignProposalReaction?.designProposalsReactionId
        );

        if (isTheSameReactionClicked) {
            this.removeDesigmProposalReaction(designProposalReaction);
        } else {
            this.addDesignProposalReaction(designProposalReaction);
        }
    }

    public onThreeJSCommentSave(comment: Comment) {
        const parentComment = comment;
        parentComment.childComments = [];
        this.currentDisplayedDp.comments.push(parentComment);

        if (!this.dpQuickView.commentsRef) {
            return;
        }

        this.dpQuickView.commentsRef.sortedComments.push(parentComment);
    }

    private subscribeToRoute(): void {
        this.routeSubscription = this.route.params
            .pipe(
                switchMap(params => {
                    this.projectId = params['id'];
                    return this.projectId ? this.projectDetailsService.getProject(this.projectId) : of(null);
                })
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }

                this.project = data as Project;
                this.projectStorageService.changeProject(this.project);
                this.setupProjectInformation();
            });
    }

    private setupProjectInformation(): void {
        this.setIsVotingDateExpired();
        this.isCollaborativeProject = this.project?.isCitizenDesigns;
        this.sortingObjects = SortingObjectsArray.getSortingObjects(
            this.isCollaborativeProject
        );
        this.sorting = this.sortingObjects?.[0];
        this.userProfileId = this.authService.userProfile.userProfileId;
        this.initializeData();
        this.subscribeToEvents();
    }

    private disable3DPinIfActive(): void {
        if (
            !(
                this.quickView === QuickViewEnum.comments &&
                this.threeJsEditorComponent.addingCommentsEnabled
            )
        ) {
            return;
        }
        this.threeJsEditorComponent.toggleAddingCommentsOnClick();
    }

    private removeDesigmProposalReaction(
        designProposalReaction: DesignProposalReaction
    ): void {
        this.designViewerCarouselService
            .deleteDesignProposalReaction(designProposalReaction)
            .subscribe({
                complete: () => {
                    this.currentDesignProposalReaction = null;
                    this.getDesignProposals(this.sorting.tag);
                }
            }
            );
    }

    private addDesignProposalReaction(
        designProposalReaction: DesignProposalReaction
    ): void {
        this.designViewerCarouselService
            .reactToDesignProposal(designProposalReaction)
            .subscribe((data) => {
                this.currentDesignProposalReaction = data;
                this.currentView = ReactionsViewEnum.thankYouView;
                this.getDesignProposals(this.sorting.tag);
            });
    }

    private getDesignProposalReaction(): void {
        this.designViewerCarouselService
            .getReactionToDesignProposal(
                this.userProfileId,
                this.currentDisplayedDp.designProposalId
            )
            .subscribe((data) => {
                if (!data) {
                    this.currentDesignProposalReaction = null;
                    this.currentView = ReactionsViewEnum.reactionsView;
                    return;
                }

                this.currentDesignProposalReaction = data;
                this.currentView = ReactionsViewEnum.thankYouView;
            });
    }

    public getReactionName(reactionId: ReactionsTypeEnum): string {
        if (reactionId === ReactionsTypeEnum.angry) {
            return ReactionsNameEnum.angry;
        } else if (reactionId === ReactionsTypeEnum.dissatisfied) {
            return ReactionsNameEnum.dissatisfied;
        } else if (reactionId === ReactionsTypeEnum.neutral) {
            return ReactionsNameEnum.neutral;
        } else if (reactionId === ReactionsTypeEnum.satisfied) {
            return ReactionsNameEnum.satisfied;
        } else if (reactionId === ReactionsTypeEnum.happy) {
            return ReactionsNameEnum.happy;
        }
    }

    public isQuickViewBtnActive(btn: string): boolean {
        return this.isQuickViewToggled && this.quickView === btn;
    }

    public setSorting(sortObject: DpSortObject) {
        this.currentPage = 0;
        this.sorting = sortObject;
        this.countDesignProposals();
        this.getDesignProposals(this.sorting.tag);
    }

    public seeProposalDetails() {
        this.router.navigate([
            routingConstants.designProposals,
            this.currentDisplayedDp.designProposalId,
        ]);
    }

    public getStatusTooltipText(btn: string): string {
        return this.isQuickViewComments(btn)
            ? this.translateService.instant(
                'designProposals.toggleQuickViewComments'
            )
            : this.translateService.instant('designProposals.toggleQuickView');
    }

    public isQuickViewComments(btn: string): boolean {
        return btn === QuickViewEnum.comments;
    }

    public isQuickViewDp(btn: string): boolean {
        return btn === QuickViewEnum.designProposal;
    }

    public goToDashboard() {
        this.router.navigate([routingConstants.dashboard]);
    }

    public isReactionsView(): boolean {
        return this.currentView === ReactionsViewEnum.reactionsView;
    }

    public isThankYouView(): boolean {
        return this.currentView === ReactionsViewEnum.thankYouView;
    }

    public revote(): void {
        this.currentView = ReactionsViewEnum.reactionsView;
    }

    public selectDP(dp: DesignProposal, index?: number) {
        this.currentDisplayedDp = dp;
        this.displayedDPIndex = index
            ? index
            : this.designProposals.findIndex(
                (dProp) => dProp.designProposalId === dp.designProposalId
            );

        this.getDesignProposalReaction();
        this.getDesignProposalObjects(dp);
        if (index > -1) {
            this.scrollToElement(index);
        }

        if (index === undefined && this.isMobile) {
            this.closeQuickView();
        }
    }

    public openCommentDialog(curentDp: DesignProposal) {
        const userProfileDialogRef = this.dialog.open(CommentsPopupComponent, {
            width: '40%',
            data: {
                designProposal: curentDp,
                project: this.project,
            },
        });
        userProfileDialogRef.disableClose = true;
        userProfileDialogRef.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
        userProfileDialogRef.afterClosed().subscribe((data) => {
            this.getDesignProposals(this.sorting.tag);
        });
    }

    public swipe(action = this.swipeAction.right) {
        // out of range
        if (
            this.displayedDPIndex > this.designProposals.length ||
            this.displayedDPIndex < 0
        ) {
            return;
        }
        let nextIndex = 0;

        // swipe right, next avatar
        if (action === this.swipeAction.right) {
            const isLast =
                this.displayedDPIndex === this.designProposals.length - 1;
            nextIndex = isLast ? 0 : this.displayedDPIndex + 1;
        }

        // swipe left, previous avatar
        if (action === this.swipeAction.left) {
            const isFirst = this.displayedDPIndex === 0;
            nextIndex = isFirst
                ? this.designProposals.length - 1
                : this.displayedDPIndex - 1;
        }

        this.selectDP(this.designProposals[nextIndex], nextIndex);
    }

    public checkIfHavingDps() {
        return this.designProposals && this.designProposals.length > 0;
    }

    public getDesignProposalMedia(dp: DesignProposal) {
        return dp.media
            ? this.mediaService.getUserMedia(dp.media)
            : '../../../assets/images/default-project.png';
    }

    public getCitizenView(): DpCitizenViewEnum {
        return this.isCollaborativeProject
            ? DpCitizenViewEnum.top
            : DpCitizenViewEnum.vote;
    }

    public isVotingView(): boolean {
        return (
            this.designProposalService.citizenView === DpCitizenViewEnum.vote
        );
    }

    public isTopView(): boolean {
        return this.designProposalService.citizenView === DpCitizenViewEnum.top;
    }

    public getDropOrientation(): string {
        if (!this.isMobile || (this.isMobile && window.innerWidth >= 820)) {
            return 'vertical';
        } else {
            return 'horizontal';
        }
    }

    public showTop(): void {
        this.designProposalService.citizenView = DpCitizenViewEnum.top;
    }

    public isVotingExpiredOrWinnerSelected(): boolean {
        return this.project.hasWinner || this.isVotingDateExpired;
    }

    public report(comment: Comment): void {
        this.modal
            .showModal(
                this.viewContainerRef,
                ModalManager.createConfiguration(
                    'errors.warning',
                    'report.warningCommentMessage',
                    'generic.yesBtn',
                    'generic.noBtn'
                )
            )
            .subscribe((res) => {
                if (!res) {
                    return;
                }

                this.saveReport(comment);
            });
    }

    public get computeInfoTitle(): string {
        if (this.isVotingExpiredOrWinnerSelected()) {
            return this.translateService.instant(
                'designProposals.reactionEnded'
            );
        }

        if (this.currentDisplayedDp?.userProfile) {
            return this.translateService.instant('designProposals.question', {
                screenName: this.currentDisplayedDp?.userProfile?.screenName,
            });
        }

        return this.translateService.instant(
            'designProposals.collaborativeQuestion'
        );
    }

    private saveReport(comment: Comment): void {
        const report = new Report();
        report.reportType = 1;
        report.reportCommentId = comment.commentId;
        report.reportDesignProposalId = comment.designProposalId;
        report.reportingUserProfile =
            this.authService.userProfile.userProfileId;
        report.isActive = true;

        this.reportService.saveReport(report).subscribe((data) => {
            this.customToasterService.openCustomToaster(
                CustomToasterComponent,
                'check_circle',
                'success',
                'report.reportCommentSuccesfully',
                1000
            );
        });
    }

    private getAllDesignProposals(sortingCriteria: string) {
        const dpRequestParam = new DesignProposalRequestParam(
            this.project.projectId,
            this.currentPage,
            sortingCriteria,
            this.filterType
        );
        dpRequestParam.isAdminDesignFilter =
            DesignProposalIsAdminFilterEnum.allDesigns;

        const requests = [
            this.designProposalService.getDesignProposals(dpRequestParam),
            this.mapService.getPolygon(this.project.projectId),
            this.pathObjectsService.getPathDefaultObjects(
                this.project.projectId
            ),
        ];

        forkJoin(requests).subscribe((data) => {
            this.defaultObjects = data[2] as PathObject[];
            this.currentObjects = [...this.defaultObjects];
            this.processDesignProposals(data[0]);
            this.threeJsEditorComponent?.initializeThreeJS();
        });
    }

    private scrollToElement(id: number) {
        const element = document.getElementById('id-' + id);
        if (element) {
            element.scrollIntoView();
        }
    }

    private getDesignProposalObjects(dp: DesignProposal): void {
        this.currentObjects = [...this.defaultObjects];
        let request;
        if (dp.isAdminDesign) {
            request = this.pathObjectsService.getPathObjectsForDefaultDP(
                dp.projectId,
                dp.designProposalId
            );
        } else if (dp.groupId) {
            request = this.pathObjectsService.getLivePathObjects(
                dp.designProposalId
            );
        } else {
            const userProfileId = dp.userProfile
                ? dp.userProfile.userProfileId
                : this.userProfileId;
            request = this.pathObjectsService.getPathObjects(
                dp.projectId,
                userProfileId
            );
        }

        request.subscribe((objects) => {
            this.addDPObjects(objects as PathObject[]);
        });
    }

    private addDPObjects(objects: PathObject[]) {
        this.threeIsReady = true;
        this.currentObjects = this.currentObjects.concat(
            objects as PathObject[]
        );

        if (!this.threeJsEditorComponent) {
            return;
        }

        this.threeJsEditorComponent.redrawObjects(this.currentObjects);
    }

    private getDesignProposals(sortingCriteria: string) {
        const dpRequestParam = new DesignProposalRequestParam(
            this.project.projectId,
            this.currentPage,
            sortingCriteria,
            this.filterType
        );
        dpRequestParam.isAdminDesignFilter =
            DesignProposalIsAdminFilterEnum.allDesigns;
        this.designProposalService
            .getDesignProposals(dpRequestParam)
            .subscribe((data) => {
                this.processDesignProposals(data);
            });
    }

    private getIndexForDesignProposals(dps: DesignProposal[]): number {
        const index = dps.findIndex(
            (dp) =>
                dp.designProposalId ===
                this.currentDisplayedDp?.designProposalId
        );
        return index > 0 ? index : 0;
    }

    private processDesignProposals(data) {
        const index = this.getIndexForDesignProposals(data);

        this.designProposals = data;
        if (!this.checkIfHavingDps()) {
            this.threeIsReady = false;
            return;
        }

        this.selectDP(this.designProposals[index], index);
    }

    private countDesignProposals() {
        this.designProposalService
            .getDesignProposalCount(this.project.projectId)
            .subscribe((data) => {
                this.designProposalsCount = data;
            });
    }

    private getNextPage() {
        if (
            !this.designProposals ||
            this.designProposals.length === 0 ||
            this.designProposals.length >= this.designProposalsCount
        ) {
            return;
        }

        this.currentPage = this.currentPage + 1;
        const dpRequestParam = new DesignProposalRequestParam(
            this.project.projectId,
            this.currentPage,
            this.sorting.tag,
            this.filterType
        );
        dpRequestParam.isAdminDesignFilter =
            DesignProposalIsAdminFilterEnum.allDesigns;
        this.designProposalService
            .getDesignProposals(dpRequestParam)
            .subscribe((data) => {
                this.designProposals = this.designProposals.concat(data);
            });
    }

    private subscribeToProjectChange() {
        this.projectStorageSubscription =
            this.projectStorageService.projectChanged.subscribe(
                (item: Project) => {
                    if (item.projectId !== this.project.projectId) {
                        this.designProposalService.citizenView = null;
                        this.designProposalService.resetDPFiltersForProject();
                        this.currentPage = 0;
                        this.project = item;
                        this.setIsVotingDateExpired();
                        this.isCollaborativeProject =
                            this.project?.isCitizenDesigns;
                        this.threeJsEditorComponent?.removePathObjects();
                        this.initializeData();
                    }
                }
            );
    }

    private subscribeTo3dPinComment(): void {
        this.pinCommentSubscription =
            this.commentsService.addPinCommentEmitter.subscribe((data) => {
                this.threeJsEditorComponent.toggleAddingCommentsOnClick();
                this.isQuickViewToggled = !this.isMobile;
                this.customToasterService.openCustomToaster(
                    CustomToasterComponent,
                    'info',
                    'info',
                    'designProposals.addPinInfoMobile',
                    1500
                );
            });
    }

    private subscribeToHighlightedComment(): void {
        if (!this.isMobile) {
            return;
        }
        this.highlightedCommentSubscription =
            this.commentsService.selectCommentEmitter.subscribe((data) => {
                this.isQuickViewToggled = false;
            });
    }

    private subscribeToTutorials(): void {
        this.tutorialsSubscription =
            this.tutorialsService.tutorialMenuToggled.subscribe((data) => {
                if (!data) {
                    return;
                }
                this.closeQuickView();
            });
    }

    private initializeData() {
        this.countDesignProposals();
        this.getAllDesignProposals(this.sorting.tag);
        this.designProposalService.citizenView = this.getCitizenView();
    }

    private setIsVotingDateExpired(): void {
        this.isVotingDateExpired = FurbanUtil.isDateExpiredForIOS(
            this.project?.votingExpiringDate
        );
    }
}
