import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox.js';
import { ThreeSelectionHelper } from './three-selection-helper';
import { ThreeUtils } from './three.utils';
import { ThreeGroupBuilder } from './three-group-builder';
import { ThreeInstance } from './three-instance';
import { Mesh, Scene, Vector3, Quaternion, Group } from 'three';
import { Subject, Observable, of } from 'rxjs';
import { ThreeGroupEnum } from '../_enum/three-group.enum';
import { TextureColorEnum } from '../_enum/texture-color.enum';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { FurbanUtil } from '../helpers/furbanUtil';
import { FreezeAction } from '../_models/freeze-action';
import { FreezeActionEnum } from '../_enum/freeze-action.enum';
import { HouseSceneConfig } from '../_models/house-config';
import { PermitThreeService } from '../_services/permit-three.service';
import { PermitAssetTypeValues } from '../_constants/permit-asset-type-values';
import { MenuService } from '../_services/menu.service';

export class ThreeMultiselect {
    private static modifiedSubject = new Subject<boolean>();

    public instance: ThreeInstance | HouseSceneConfig;
    public skipDefaultObjects: boolean;
    private permitThreeService: PermitThreeService
    private menuService: MenuService;

    constructor(
        instance: ThreeInstance | HouseSceneConfig,
        skipDefaultObjects = false,
        menuService: MenuService,
        permitThreeService?: PermitThreeService
    ) {
        this.instance = instance;
        this.skipDefaultObjects = skipDefaultObjects;
        this.permitThreeService = permitThreeService;
        this.menuService = menuService;
    }

    public static getModifiedSubject(): Observable<boolean> {
        return ThreeMultiselect.modifiedSubject.asObservable();
    }

    public enableSelectionBox(): void {
        this.instance.controls.enabled = false;
        this.instance.selectionBox = new SelectionBox(this.instance.camera, this.instance.scene);
        this.instance.selectionHelper = new ThreeSelectionHelper(this.instance.selectionBox, this.instance.renderer, 'selectBox');
        this.addBoxSelectionEvents();
    }

    public disableSelectionBox(): void {
        this.removeEvents();
        if (this.instance.selectionHelper) {
            this.instance.selectionHelper.removeEvents();
        }
        this.instance.controls.enabled = true;
        this.instance.selectionBox = null;
        this.instance.selectionHelper = null;
        this.menuService.emitOnActionFinished();

    }

    public removeEvents(): void {
        if (FurbanUtil.isMobile()) {
            this.instance.renderer.domElement.removeEventListener('touchstart', this.boxSelectionClick);
            this.instance.renderer.domElement.removeEventListener('touchmove', this.boxSelectionMove);
            this.instance.renderer.domElement.removeEventListener('touchend', this.boxSelectionEnd);
        } else {
            this.instance.renderer.domElement.removeEventListener('pointerdown', this.boxSelectionClick);
            this.instance.renderer.domElement.removeEventListener('pointermove', this.boxSelectionMove);
            this.instance.renderer.domElement.removeEventListener('pointerup', this.boxSelectionEnd);
        }
    }

    public onGroupingStart(selectedMeshes: Mesh[]): void {
        ThreeUtils.setMoveMode(this.instance);

        if (this.instance instanceof ThreeInstance) {
            this.instance.intersectedObject = null;
            this.instance.intersectedType = ObjectTypeEnum.regular;
        }

        const center = ThreeUtils.getCenterFromMeshes(selectedMeshes);
        this.instance.multiselectGroup = ThreeGroupBuilder.createGroup(ThreeGroupEnum.multiselect, 4);

        this.instance.multiselectGroup.userData.selected = [];
        this.instance.multiselectGroup.userData.prevParent = [];
        this.instance.multiselectGroup.position.set(center.x, center.y, center.z);
        this.instance.multiselectGroup.userData.previousPosition = new Vector3(center.x, center.y, center.z);
        this.instance.multiselectGroup.userData.previousQuaternion = new Quaternion();

        this.instance.scene.add(this.instance.multiselectGroup);

        let isLocked = true;

        for (const mesh of selectedMeshes) {
            this.instance.multiselectGroup.userData.selected.push(mesh);
            this.instance.multiselectGroup.userData.prevParent.push(mesh.parent);
            this.instance.multiselectGroup.attach(mesh);
            ThreeUtils.setHexColorOnMaterial(mesh, TextureColorEnum.mildBlue);
            if (!mesh.userData['isLocked']) {
                isLocked = false;
            }
        }
        this.instance.multiselectGroup.userData.isLocked = isLocked;
        if (!isLocked) {
            this.instance.transformControls.attach(this.instance.multiselectGroup);
        }
        this.instance.multiselectGroup.add(this.instance.controlBtns);

        const ids = ThreeUtils.getPathObjectIds(this.instance.multiselectGroup.children);
        const freezeAction = new FreezeAction(FreezeActionEnum.freeze, ids);
        ThreeInstance.freezeSubject.next(freezeAction)
    }

    public onGroupingEnd(): void {
        if (!this.instance.multiselectGroup || this.instance.multiselectGroup.userData.selected.length === 0) {
            return;
        }

        let intersectedObject: Group;
        const ids = ThreeUtils.getPathObjectIds(this.instance.multiselectGroup.children);

        for (let i = 0; i < this.instance.multiselectGroup.userData.selected.length; i++) {
            intersectedObject = this.instance.multiselectGroup.userData.selected[i];
            if (intersectedObject.userData['name'] === ObjectTypeEnum.freeshape) {
                intersectedObject.userData['rotated'] = true;
            }
            this.instance.multiselectGroup.userData.prevParent[i].attach(intersectedObject);
            intersectedObject = this.setNewPositionOnObject(intersectedObject);
            ThreeUtils.setHexColorOnMaterial(intersectedObject, TextureColorEnum.neutral0);
        }
        this.instance.multiselectGroup.userData.selected = [];
        this.instance.multiselectGroup.userData.prevParent = [];
        this.instance.scene.remove(this.instance.multiselectGroup);
        if (this.instance.transformControls) {
            this.instance.transformControls.detach();
        }
        this.instance.scene.remove(this.instance.multiselectGroup);

        const freezeAction = new FreezeAction(FreezeActionEnum.unfreeze, ids);
        ThreeInstance.freezeSubject.next(freezeAction)

    }

    private needToSkipObject(object: Mesh): boolean {
        return this.instance.isPublished
            || this.needToSkipDefaultObject(object)
            || this.needToSkipGroundAndRegularObject(object)
            || this.needToSkipFreezeObject(object);
    }

    private needToSkipObjectForPermit(object: Mesh): boolean {
        return object.userData['houseAsset']?.asset?.assetType?.permitAssetTypeId !== PermitAssetTypeValues.FOR_GROUND.id;
    }

    private needToSkipFreezeObject(object: Mesh): boolean {
        return object.userData['freeze'];
    }

    needToSkipDefaultObject(object: Mesh) {
        return object.userData['default'] && this.skipDefaultObjects;
    }

    needToSkipGroundAndRegularObject(object: Mesh) {
        return (
            object.parent.name !== ThreeGroupEnum.objectsGround &&
            object.parent.name !== ThreeGroupEnum.objectsRegular
        );
    }

    private boxSelectionClick = (event: MouseEvent | TouchEvent) => {
        this.onGroupingEnd();
        const container =
            this.instance.renderer.domElement.getBoundingClientRect();
        this.setBoxStartAndEndPoint(event, container, true);
    };

    private boxSelectionMove = (event: MouseEvent | TouchEvent) => {
        const container =
            this.instance.renderer.domElement.getBoundingClientRect();
        if (this.instance.selectionHelper.isDown) {
            this.setBoxStartAndEndPoint(event, container, false);
        }
    };

    private boxSelectionEnd = (event: MouseEvent | TouchEvent) => {
        const container =
            this.instance.renderer.domElement.getBoundingClientRect();
        this.setBoxStartAndEndPoint(event, container, false);
        const allSelected = this.instance.selectionBox.select();
        this.groupingUniqueMeshes(allSelected);
        if (this.instance instanceof ThreeInstance) {
            return
        }

        const selectedPermitMeshes = this.getUniqueMeshesFromIntersectionForPermit(allSelected);
        if (selectedPermitMeshes.length > 0) {
            this.permitThreeService.multiselectPerformedEvents.next(true);
        }
    };

    setBoxStartAndEndPoint(
        event: MouseEvent | any,
        container: DOMRect,
        isStart: boolean
    ) {
        if (event.changedTouches) {
            event = event.changedTouches[0];
        }

        const positionVector3 = new Vector3();
        positionVector3.x =
            ((event.clientX - container.left) / container.width) * 2 - 1;
        positionVector3.y =
            -((event.clientY - container.top) / container.height) * 2 + 1;
        positionVector3.z = 0.5;

        if (isStart) {
            this.instance.selectionBox.startPoint.set(
                positionVector3.x,
                positionVector3.y,
                positionVector3.z
            );
        } else {
            this.instance.selectionBox.endPoint.set(
                positionVector3.x,
                positionVector3.y,
                positionVector3.z
            );
        }
    }

    private addBoxSelectionEvents(): void {
        if (FurbanUtil.isMobile()) {
            this.instance.renderer.domElement.addEventListener(
                'touchstart',
                this.boxSelectionClick,
                { passive: false }
            );
            this.instance.renderer.domElement.addEventListener(
                'touchmove',
                this.boxSelectionMove,
                { passive: false }
            );
            this.instance.renderer.domElement.addEventListener(
                'touchend',
                this.boxSelectionEnd,
                { passive: false }
            );
        } else {
            this.instance.renderer.domElement.addEventListener(
                'pointerdown',
                this.boxSelectionClick,
                { passive: false }
            );
            this.instance.renderer.domElement.addEventListener(
                'pointermove',
                this.boxSelectionMove,
                { passive: false }
            );
            this.instance.renderer.domElement.addEventListener(
                'pointerup',
                this.boxSelectionEnd,
                { passive: false }
            );
        }
    }

    private getUniqueMeshesFromIntersection(allSelected: Mesh[]): Mesh[] {
        const selectedMeshes: Mesh[] = [];

        for (const mesh of allSelected) {
            const object = ThreeUtils.getObjectBeforeParent(mesh);

            if (this.needToSkipObject(object)) {
                continue;
            }

            if (selectedMeshes.indexOf(object) === -1) {
                selectedMeshes.push(object);
            }
        }
        return selectedMeshes;
    }

    private getUniqueMeshesFromIntersectionForPermit(allSelected: Mesh[]): Mesh[] {
        const selectedMeshes: Mesh[] = [];

        for (const mesh of allSelected) {
            const object = ThreeUtils.getObjectBeforeParentForPermit(mesh);

            if (this.needToSkipObjectForPermit(object)) {
                continue;
            }

            if (selectedMeshes.indexOf(object) === -1) {
                selectedMeshes.push(object);
            }
        }
        return selectedMeshes;
    }

    private groupingUniqueMeshes(allSelected: Mesh[]): void {
        const selectedMeshes: Mesh[] = this.instance instanceof ThreeInstance ?
            this.getUniqueMeshesFromIntersection(allSelected) :
            this.getUniqueMeshesFromIntersectionForPermit(allSelected);
        if (selectedMeshes.length > 0) {
            this.onGroupingStart(selectedMeshes);
            this.disableSelectionBox();
        }
    }

    private setNewPositionOnObject(intersectedObject: Group): Group {
        intersectedObject.updateMatrix();
        const threeInstance = this.instance as ThreeInstance;
        const objUpdatedPosition = ThreeUtils.getPositionConsideringCustomDesign(intersectedObject.position, threeInstance.customDesign);
        intersectedObject.position.y = objUpdatedPosition.y;
        return intersectedObject;
    }
}
