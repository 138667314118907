import { Vector3 } from 'three';

export class AssetSkeletonProperties {
    public distance: number;
    public position: Vector3;
    public rotation: number;

    constructor(distance: number, position: Vector3, rotation: number) {
        this.distance = distance;
        this.position = position;
        this.rotation = rotation;
    }
}
