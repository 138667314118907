import {
  CylinderGeometry,
  Mesh,
  MeshBasicMaterial,
  Shape,
  ShapeGeometry,
  Vector3,
} from 'three';

import { TextureColorEnum } from '../_enum/texture-color.enum';

export class FreeshapeHelper {
  drawShapeFromPoints(points: Vector3[]): Shape {
    const shape = new Shape();
    shape.moveTo(points[0].x, points[0].z);

    points.forEach((coordinate) => {
      shape.lineTo(coordinate['x'], coordinate['z']);
    });
    return shape;
  }

  createMeshFromShape(shape: Shape, yPosition: number): Mesh {
    const geometry = new ShapeGeometry(shape);
    const material = new MeshBasicMaterial({
      color: TextureColorEnum.lightBlue,
    });
    material.transparent = true;
    material.opacity = 0.4;
    const mesh = new Mesh(geometry, material);
    this.addPropertiesToMesh(mesh, yPosition);
    return mesh;
  }

  addPropertiesToMesh(mesh: Mesh, yPosition: number) {
    mesh.rotation.x = -Math.PI / 2;
    mesh.updateMatrix();
    mesh.geometry.applyMatrix4(mesh.matrix);
    mesh.rotation.set(0, 0, 0);
    mesh.scale.z = -1;
    mesh.position.setY(yPosition);
    mesh.renderOrder = 100;
  }

  generateHelper(points: Vector3[]): Mesh {
    const shape = this.drawShapeFromPoints(points);
    const mesh = this.createMeshFromShape(shape, points[0].y);
    return mesh;
  }

  generateCylinderHelper(): Mesh {
    const geometry = new CylinderGeometry(0.1, 0.1, 10, 32);
    const material = new MeshBasicMaterial({
      color: TextureColorEnum.lightBlue,
    });
    return new Mesh(geometry, material);
  }
}
