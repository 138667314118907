import { FurbanUtil } from '../helpers/furbanUtil';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { ACESFilmicToneMapping, PCFSoftShadowMap, WebGLRenderer } from 'three';


export class ThreeRendererBuilder {
    private static options = {
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: true,
    };

    public static createRenderer(canvas?: any) {
        const options = this.options;

        if (canvas) {
            options['canvas'] = canvas;
        }

        if (FurbanUtil.isIOS()) {
            options.logarithmicDepthBuffer = false;
        }

        const renderer = new WebGLRenderer(options);
        this.setRendererProperties(renderer);
        return renderer;
    }

    public static createHTMLRenderer(domElement: HTMLCanvasElement) {
        const htmlRenderer = new CSS2DRenderer();
        htmlRenderer.setSize(domElement.width, domElement.height);
        htmlRenderer.domElement.style.position = 'absolute';
        document
            .getElementById('htmlRenderer')
            .appendChild(htmlRenderer.domElement);
        return htmlRenderer;
    }

    private static setRendererProperties(renderer: WebGLRenderer): void {
        renderer.shadowMap.enabled = true;
        renderer['shadowMapSoft'] = true;
        renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1.8;
        renderer.shadowMap.type = PCFSoftShadowMap;
        renderer.autoClear = true;
    }
}
