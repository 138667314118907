import {
    Directive,
    Input,
    OnDestroy,
    EventEmitter,
    Output,
    OnInit,
    Inject,
} from '@angular/core';
import { Vector3, Group, Mesh, Euler, Quaternion, Vector2, Intersection } from 'three';
import { Subscription } from 'rxjs';

import {
    AssetColorAndMaterial,
    deleteAssetRequest, deleteMultipleAssetsRequest, HouseAsset, HouseSceneConfig, KeyCode, MenuService, PermitAssetNamesEnum, PermitAssetTypeValues, PermitKeyboardActionEnum, PermitMenuControlsEnum, PermitStore,
    PermitThreeService, PermitUtils, TextureColorEnum, ThreeGroupEnum, ThreeMultiselect, ThreeObjectControlsEnum, ThreeUtils, updateAssetRequest, updateMultipleAssetObjectsRequest
} from '@furban/utilities';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[furbanAssetSelectEvents]',
})
export class AssetSelectEventsDirective implements OnDestroy, OnInit {
    @Input() houseSceneConfig: HouseSceneConfig;
    @Output() objectMoved: EventEmitter<boolean> = new EventEmitter();
    @Output() updateColorAndMaterial: EventEmitter<AssetColorAndMaterial> =
        new EventEmitter();

    private selection: any;
    private houseAsset: HouseAsset;
    private dragging = false;
    private bindedMouseDown = this.mouseDownEvent.bind(this);
    private bindedMouseMove = this.mouseMoveEvent.bind(this);
    private bindedMouseUp = this.mouseUpEvent.bind(this);
    private bindedDoubleClick = this.doubleClickEvent.bind(this);
    private bindedKeyDownPressing = this.keyDownPressing.bind(this);
    private bindedShiftRelease = this.shiftRelease.bind(this);
    private bindedTransformStart = this.transformStart.bind(this);
    private bindedTransformEnd = this.transformEnd.bind(this);
    private bindedObjectChange = this.objectChange.bind(this);
    private selectEventsAddedSubscription: Subscription;
    private selectControlsMenuSubscription: Subscription;
    private activeControl: PermitMenuControlsEnum = PermitMenuControlsEnum.move;

    private multiselectSubscription: Subscription;
    private threeGrouping: ThreeMultiselect;
    private isMultiselectEnabled = false;
    private assetColorAndMaterialChangeSubscription: Subscription;

    constructor(@Inject(DOCUMENT) private document: Document,
        private permitThreeService: PermitThreeService,
        private menuService: MenuService,
        protected store: Store<{ store: PermitStore }>
    ) { }

    private get isForExtension(): boolean {
        return (
            this.houseAsset.asset.assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_EXTENSION.id
        );
    }

    private get isForDormers(): boolean {
        return (
            this.houseAsset.asset.assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_DORMERS.id
        );
    }

    private get isForRoof(): boolean {
        return (
            this.houseAsset.asset.assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_ROOF.id
        );
    }

    private get isForWall(): boolean {
        return (
            this.houseAsset.asset.assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_WALL.id
        );
    }

    private get isForSolarPanels(): boolean {
        return (
            this.houseAsset.asset.assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_PANELS.id
        );
    }

    private get permitAssetTypeId(): number {
        return this.houseAsset.asset.assetType.permitAssetTypeId;
    }

    private get wallGroup(): any {
        return this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.wallGroup
        );
    }

    private get roofGroup(): any {
        return this.houseSceneConfig.scene.getObjectByName(
            PermitAssetNamesEnum.roofGroup
        );
    }

    private get roofAssetGroup(): Group {
        return this.permitThreeService.roofAssetGroup;
    }

    private get assetGroup(): Group {
        return this.permitThreeService.assetObjects;
    }

    private get extensionGroup(): Group {
        return this.permitThreeService.extensionGroup;
    }

    private get isTransformActive(): boolean {
        return this.activeControl === PermitMenuControlsEnum.transform;
    }

    private get isRotationActive(): boolean {
        return this.activeControl === PermitMenuControlsEnum.rotate;
    }

    private get groupNamesForSelection(): string[] {
        return [
            PermitAssetNamesEnum.assetGroup,
            PermitAssetNamesEnum.roofAssetGroup,
            PermitAssetNamesEnum.extensionGroup,
            PermitAssetNamesEnum.solarGroup,
            PermitAssetNamesEnum.dormersGroup,
        ];
    }

    private get dormersGroup(): Group {
        return this.permitThreeService.dormersGroup;
    }

    private get solarPanelGroup(): Group {
        return this.permitThreeService.solarPanels;
    }

    private get groupsForSelection(): Group[] {
        return [
            this.assetGroup,
            this.roofGroup,
            this.wallGroup,
            this.roofAssetGroup,
            this.extensionGroup,
            this.dormersGroup,
            this.solarPanelGroup,
        ];
    }

    ngOnDestroy(): void {
        this.removeMouseEventsFromRenderer();
        this.selectEventsAddedSubscription.unsubscribe();
        this.selectControlsMenuSubscription.unsubscribe();
        this.multiselectSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscribeToSelectEventsAdded();
        this.subscribeToSelectControls();
        this.subscribeToMultiselect();
        this.initializeColorAndTexturePopup();
    }


    public subscribeToSelectControls(): void {
        this.selectControlsMenuSubscription = this.permitThreeService.selectControlsObservable.subscribe(
            (data) => {
                this.activeControl = data;

                this.removeColorAndTextureConfigurePopup();
                switch (data) {
                    case PermitMenuControlsEnum.discard:
                        this.removeSelection();
                        break;
                    case PermitMenuControlsEnum.delete:
                        this.deleteSelected();
                        break;
                    case PermitMenuControlsEnum.transform:
                        this.transformSelected();
                        break;
                    case PermitMenuControlsEnum.move:
                        this.moveSelected();
                        break;
                    case PermitMenuControlsEnum.rotate:
                        this.rotateSelected();
                        break;
                    case PermitMenuControlsEnum.configure:
                        this.configureSelected();
                        break;
                    default:
                        console.error('Unable to find controls');
                        break;
                }
            }
        )
    }

    public unsubscribeToSelectControls(): void {
        this.selectControlsMenuSubscription.unsubscribe();
    }

    public addingMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindedMouseDown,
            { passive: true }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerup',
            this.bindedMouseUp,
            { passive: true }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'dblclick',
            this.bindedDoubleClick,
            { passive: true }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'keyup',
            this.bindedShiftRelease,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'keydown',
            this.bindedKeyDownPressing,
            { passive: false }
        );
        this.houseSceneConfig.transformControls.addEventListener(
            'mouseDown',
            this.bindedTransformStart
        );
        this.houseSceneConfig.transformControls.addEventListener(
            'mouseUp',
            this.bindedTransformEnd
        );
        this.document.addEventListener('keydown', this.keyDownFunction, {
            passive: false,
        });
    }

    public removeMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindedMouseDown
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerup',
            this.bindedMouseUp
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'dblclick',
            this.bindedDoubleClick
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'keyup',
            this.bindedShiftRelease
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'keydown',
            this.bindedKeyDownPressing
        );
        this.houseSceneConfig.transformControls.removeEventListener(
            'mouseDown',
            this.bindedTransformStart
        );
        this.houseSceneConfig.transformControls.removeEventListener(
            'mouseUp',
            this.bindedTransformEnd
        );
        this.document.removeEventListener('keydown', this.keyDownFunction);
    }

    public deleteSelected(): void {
        if (this.selection && !this.isMultiselectEnabled) {
            const houseAsset = PermitUtils.getHouseAssetFromGroupUserData(this.selection);
            this.permitThreeService.selectControls.next(PermitMenuControlsEnum.discard);
            this.dispatchDeleteAssetRequest(houseAsset);
            return;
        }

        if (this.isMultiselectEnabled) {
            this.deleteMultiselected();
        }
    }

    public transformSelected(): void {
        this.houseSceneConfig.transformControls.setMode(
            ThreeObjectControlsEnum.scale
        );
        this.houseSceneConfig.transformControls.attach(this.selection);
    }

    public moveSelected(): void {
        this.houseSceneConfig.transformControls.detach();
        this.dragging = true;
    }

    public rotateSelected(): void {
        this.houseSceneConfig.transformControls.setMode(
            ThreeObjectControlsEnum.rotate
        );
        if (this.houseSceneConfig.assetType === PermitAssetTypeValues.FOR_GROUND.id) {
            this.houseSceneConfig.transformControls.showX = false;
            this.houseSceneConfig.transformControls.showZ = false;
            this.houseSceneConfig.transformControls.showY = true;

        } else {
            this.houseSceneConfig.transformControls.showX = true;
            this.houseSceneConfig.transformControls.showZ = true;
            this.houseSceneConfig.transformControls.showY = true;
        }

        this.houseSceneConfig.transformControls.attach(this.selection);
    }

    public configureSelected(): void {
        this.houseSceneConfig.transformControls.setMode(
            ThreeObjectControlsEnum.move
        );
        this.selection.add(this.houseSceneConfig.colorAndTexturePopup);
        const initialColorAndMaterial = new AssetColorAndMaterial();
        initialColorAndMaterial.color = this.selection.userData['houseAsset'].color;
        initialColorAndMaterial.material = this.selection.userData['houseAsset'].material;

        this.updateColorAndMaterial.emit(initialColorAndMaterial);
        this.subscribeToRoofAssetColorOrMaterialUpdate();
    }

    public removeSelection(): void {
        if (this.selection && this.houseSceneConfig.controlBtns) {
            if (this.houseSceneConfig.transformControls) {
                this.houseSceneConfig.transformControls.detach();
            }
            this.selection.remove(this.houseSceneConfig.controlBtns);
            ThreeUtils.setHexColorOnMaterial(
                this.selection,
                TextureColorEnum.neutral0
            );
            this.selection = null;
            return;
        }
        this.threeGrouping?.onGroupingEnd();
        this.houseSceneConfig.multiselectGroup?.add(this.houseSceneConfig.controlBtns);
        this.permitThreeService.disableToolEvents.next(true);
        this.permitThreeService.multiselectPerformedEvents.next(false);
    }


    public keyDownFunction = (e: KeyboardEvent): void => {
        const asset = this.selection?.userData?.houseAsset?.asset;

        switch (e.key) {
            case KeyCode.cKey: {
                if (this.isMultiselectEnabled) {
                    return;
                }
                if (e.ctrlKey || e.metaKey) {
                    this.permitThreeService.keyboardPressEvent.next({ type: PermitKeyboardActionEnum.copy, object: asset });
                    e.preventDefault();
                }
                break;
            }

            case KeyCode.zKey: {
                if (e.ctrlKey || e.metaKey) {
                    this.permitThreeService.keyboardPressEvent.next({ type: PermitKeyboardActionEnum.undo });
                    e.preventDefault();
                }
                break;
            }
            case KeyCode.yKey: {
                if (e.ctrlKey || e.metaKey) {
                    this.permitThreeService.keyboardPressEvent.next({ type: PermitKeyboardActionEnum.redo });
                    e.preventDefault();
                }
                break;
            }
            case KeyCode.deleteKey: {
                this.deleteSelected();
                e.preventDefault();
                break;
            }
            default:
                break;
        }
    };

    private initializeColorAndTexturePopup(): void {
        this.houseSceneConfig.colorAndTexturePopup =
            ThreeUtils.getColorAndTexturePopupHTMLElement();
    }

    private subscribeToSelectEventsAdded(): void {
        this.selectEventsAddedSubscription = this.permitThreeService.selectEventsAddedObservable.subscribe(
            (data) => {
                if (data) {
                    this.addingMouseEventsFromRenderer();
                } else {
                    this.removeMouseEventsFromRenderer();
                }
            });
    }

    private subscribeToMultiselect(): void {
        this.multiselectSubscription = this.permitThreeService.multiselectEventsAddedObservable.subscribe(
            (data) => {
                if (!data) {
                    this.disableMultiselect();
                    return;
                }
                this.enableMultiSelect();
            }
        )

        this.permitThreeService.multiselectPerformedObservable.subscribe(
            (data) => {
                if (!data) {
                    this.isMultiselectEnabled = false;
                    return;
                }
                this.addingMouseEventsFromRenderer();
                this.threeGrouping?.removeEvents();
                this.isMultiselectEnabled = true;
            }
        );
    }

    private disableMultiselect(): void {
        if (this.threeGrouping) {
            this.threeGrouping.onGroupingEnd();
            this.threeGrouping.disableSelectionBox();
            this.threeGrouping = null;
            this.removeControlsFromObject();
        }
    }

    private removeControlsFromObject(): void {
        this.discardTransformControls();
        this.removeIntersectedObject();
        this.removeMultiselectGroup();
    }

    private discardTransformControls(): void {
        if (this.houseSceneConfig.transformControls) {
            this.houseSceneConfig.transformControls.detach();
        }
    }

    private removeColorAndTextureConfigurePopup(): void {
        this.selection?.remove(this.houseSceneConfig.colorAndTexturePopup);
        this.assetColorAndMaterialChangeSubscription?.unsubscribe();
    }

    private removeIntersectedObject(): void {
        if (this.selection) {
            this.selection.remove(this.houseSceneConfig.controlBtns);
            this.discardTransformControls();
            this.selection = null;
        }
    }

    private enableMultiSelect(): void {
        this.removeControlsFromObject();
        this.threeGrouping = new ThreeMultiselect(this.houseSceneConfig, false, this.menuService, this.permitThreeService);
        this.threeGrouping.enableSelectionBox();
    }


    private removeMultiselectGroup(): void {
        if (this.houseSceneConfig.multiselectGroup && this.houseSceneConfig.multiselectGroup.userData['selected']) {
            this.houseSceneConfig.multiselectGroup.remove(this.houseSceneConfig.controlBtns);
            //this.houseSceneConfig.multiselectGroup.selectionObjects = [];
        }
        this.disableMultiselect();
    }

    private addControlsToNewSelection(newSelection: Group): void {
        this.selection = newSelection;
        this.houseSceneConfig.assetType = this.selection.userData.assetType;
        this.selection.add(this.houseSceneConfig.controlBtns);
        ThreeUtils.setHexColorOnMaterial(
            this.selection,
            TextureColorEnum.mildBlue
        );
        this.houseAsset = PermitUtils.getHouseAssetFromGroupUserData(
            this.selection
        );
        this.dragging = true;
    }

    private noIntersectionInRaycast(intersects: Intersection[]): boolean {
        return (
            intersects.length === 0 ||
            PermitUtils.intersectsGroups(intersects, [
                PermitAssetNamesEnum.roofGroup,
                PermitAssetNamesEnum.wallGroup,
            ])
        );
    }

    private discardOldSelection(oldSelection?: Group): void {
        if (!oldSelection) {
            return;
        }

        ThreeUtils.setHexColorOnMaterial(
            oldSelection,
            TextureColorEnum.neutral0
        );
        this.houseSceneConfig.transformControls.detach();
        this.permitThreeService.selectControls.next(
            PermitMenuControlsEnum.discard
        );
    }

    private blockMovementByAssetType(
        lastPosition: Vector3,
        lastRotation: Euler
    ): void {
        let childrenNodesCollision, collidedNode;
        if (this.isForWall) {
            const wallObject = this.houseSceneConfig.scene.getObjectByName(
                PermitAssetNamesEnum.wallGroup
            );
            childrenNodesCollision =
                this.houseSceneConfig.intersectionHelpers.children.concat(
                    this.permitThreeService.assetObjects.children
                );
            collidedNode = PermitUtils.getCollindingNode(
                this.selection,
                childrenNodesCollision
            );
            PermitUtils.blockMovementOnAxis(
                this.selection,
                collidedNode,
                lastPosition,
                lastRotation
            );
            PermitUtils.checkIfExceedingLimits(wallObject, this.selection);
        } else if (this.isForExtension) {
            PermitUtils.blockMovementOnAxis(
                this.permitThreeService.loadedAsset,
                collidedNode,
                lastPosition,
                lastRotation
            );
        } else {
            childrenNodesCollision =
                this.houseSceneConfig.intersectionHelpers.children.concat(
                    this.permitThreeService.assetObjects.children
                );
            collidedNode = PermitUtils.getCollindingNode(
                this.selection,
                childrenNodesCollision
            );
            PermitUtils.blockMovementOnAxis(
                this.selection,
                collidedNode,
                lastPosition,
                lastRotation
            );
        }
    }

    private getIntersectionMeshByAssetType(event: MouseEvent): Intersection[] {
        let intersects: Intersection[] = [];
        const planeObject = this.houseSceneConfig.scene.getObjectByName(ThreeGroupEnum.ground);
        const wallGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.wallGroup);
        const extensionGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.extensionGroup);
        const roofGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.roofGroup);
        const wallObject = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.wallGroup);
        const roofAssetGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const rfaGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const rfGroup = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.roofGroup);

        switch (this.permitAssetTypeId) {
            case PermitAssetTypeValues.FOR_GROUND.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [planeObject],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_WALL.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [wallGroup, extensionGroup],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_ROOF.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [roofGroup],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_EXTENSION.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [wallObject],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_DORMERS.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [roofAssetGroup],
                    this.houseSceneConfig
                );
                break;

            case PermitAssetTypeValues.FOR_PANELS.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [rfaGroup, rfGroup],
                    this.houseSceneConfig
                );
                break;
            default:
                console.error('Unable to find asset');
                break;
        }
        return intersects;
    }

    private mouseDownEvent(event: MouseEvent): void {
        if (this.checkIfRightClickAndRemoveSelection(event)) {
            this.permitThreeService.selectControls.next(
                PermitMenuControlsEnum.discard
            );
            return;
        }


        if (this.isMultiselectEnabled) {
            return;
        }

        this.houseSceneConfig.controls.enabled = false;

        const intersects = ThreeUtils.getIntersectionFromEvent(
            event,
            this.groupsForSelection,
            this.houseSceneConfig
        );
        if (this.noIntersectionInRaycast(intersects)) {
            this.houseSceneConfig.controls.enabled = true;
            return;
        }

        const oldSelection = this.selection;
        const newSelection = PermitUtils.getParentObject(
            intersects[0].object,
            this.groupNamesForSelection
        );

        if (oldSelection === newSelection) {
            this.houseSceneConfig.renderer.domElement.addEventListener(
                'pointermove',
                this.bindedMouseMove,
                { passive: true }
            );
            return;
        }

        this.discardOldSelection(oldSelection);
        this.addControlsToNewSelection(newSelection);
    }

    private mouseUpEvent(event: MouseEvent): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointermove',
            this.bindedMouseMove
        );
        this.houseSceneConfig.controls.enabled = true;

        if (this.isMultiselectEnabled && this.houseSceneConfig.multiselectGroup?.userData['selected']?.length > 0) {
            this.updateMultipleObjects();
            return;
        }

        if (!this.isSelectionDragged() && !this.isTransformActive && !this.isRotationActive) {
            return;
        }

        if (this.activeControl === PermitMenuControlsEnum.configure) {
            return;
        }

        this.objectMoved.emit();

        const houseAsset = PermitUtils.getHouseAssetFromGroupUserData(
            this.selection
        );


        this.dispatchUpdateAssetRequest(houseAsset);
    }

    private updateMultipleObjects(): void {
        const pathObjectsToUpdate = [];
        this.houseSceneConfig.multiselectGroup.userData['selected'].forEach((element) => {

            const worldPosition = new Vector3(0, 0, 0);
            element?.getWorldPosition(worldPosition);

            const objToSave = PermitUtils.getHouseAssetFromGroupUserData(element);

            objToSave.coordinates = JSON.stringify(worldPosition);

            const worldQuaternion = new Quaternion();
            element?.getWorldQuaternion(worldQuaternion);
            const objectsEuler = new Euler().setFromQuaternion(worldQuaternion, 'XYZ');
            objToSave.rotation = JSON.stringify(objectsEuler);

            pathObjectsToUpdate.push(objToSave);
        });
        this.houseSceneConfig.multiselectGroup.userData['previousPosition'] = new Vector3(this.houseSceneConfig.multiselectGroup.position.x,
            this.houseSceneConfig.multiselectGroup.position.y,
            this.houseSceneConfig.multiselectGroup.position.z);

        this.houseSceneConfig.multiselectGroup.userData['previousQuaternion'] = new Quaternion(this.houseSceneConfig.multiselectGroup.quaternion.x,
            this.houseSceneConfig.multiselectGroup.quaternion.y,
            this.houseSceneConfig.multiselectGroup.quaternion.z,
            this.houseSceneConfig.multiselectGroup.quaternion.w);
        this.dispatchUpdateMultipleObjectsRequest(pathObjectsToUpdate);
    }

    private deleteMultiselected(): void {
        const pathObjectsToDelete = [];
        this.houseSceneConfig.multiselectGroup.userData['selected'].forEach((element) => {
            const objToDelete = PermitUtils.getHouseAssetFromGroupUserData(element);
            pathObjectsToDelete.push(objToDelete);
        });
        this.dispatchDeleteMultipleAssetRequest(pathObjectsToDelete);
        this.removeMultiselectGroup();
        this.permitThreeService.disableToolEvents.next(true);
    }

    private mouseMoveEvent(event: MouseEvent): void {
        if (
            !this.isSelectionDragged() ||
            this.isTransformActive ||
            this.isRotationActive
        ) {
            return;
        }

        const intersects = this.getIntersectionMeshByAssetType(event);

        if (!intersects || intersects.length === 0) {
            return;
        }

        const lastPosition = this.selection.position.clone();
        const lastRotation = this.selection.rotation.clone();

        const yPos = this.getYPos(intersects);
        const updateLookAt = this.isForExtension || this.isForWall || this.isForDormers || this.isForSolarPanels;
        ThreeUtils.snapAssetToObjectFace(this.selection, intersects, yPos, updateLookAt, this.isForDormers);

        if (!this.isForRoof) {
            this.blockMovementByAssetType(lastPosition, lastRotation);
        }
    }

    private getYPos(intersects: Intersection[]): number {
        return this.isForExtension
            ? this.getPositionOfExtension(this.selection)
            : ThreeUtils.getCustomPositionOnYAxis(
                intersects,
                this.houseSceneConfig,
                this.permitThreeService
            );
    }

    private isSelectionDragged(): boolean {
        return this.dragging && this.selection ? true : false;
    }

    private doubleClickEvent(event: MouseEvent): void {
        if (this.isMultiselectEnabled && this.houseSceneConfig.multiselectGroup?.userData['selected']?.length > 0) {
            return;
        }
        this.permitThreeService.selectControls.next(PermitMenuControlsEnum.discard);
    }

    private checkIfRightClickAndRemoveSelection(event: MouseEvent): boolean {
        return event?.button === 2;
    }

    private subscribeToRoofAssetColorOrMaterialUpdate(): void {
        this.assetColorAndMaterialChangeSubscription = this.permitThreeService.assetColorAndMaterialChangeObservable.subscribe(
            (data) => {
                this.changeAssetColorAndMaterial(data);
            }
        )
    }

    private changeAssetColorAndMaterial(colorAndMaterial: AssetColorAndMaterial): void {
        const houseAsset = PermitUtils.getHouseAssetFromGroupUserData(
            this.selection
        );

        houseAsset.color = colorAndMaterial.color ? colorAndMaterial.color : houseAsset.color;
        houseAsset.material = colorAndMaterial.material ? colorAndMaterial.material : houseAsset.material;

        if (!!colorAndMaterial.material) {
            PermitUtils.changeMaterialTextureForGroup(
                this.selection,
                PermitAssetNamesEnum.roofMaterial,
                colorAndMaterial.material.backgroundImage,
                new Vector2(8, 8)
            );
        }

        if (!!colorAndMaterial.color) {
            this.changeColorToSelection(colorAndMaterial);
        }

        this.selection.userData['houseAsset'].color = houseAsset.color;
        this.selection.userData['houseAsset'].material = houseAsset.material;

        this.dispatchUpdateAssetRequest(houseAsset);
    }

    private changeColorToSelection(colorAndMaterial: AssetColorAndMaterial): void {

        PermitUtils.changeMaterialColor(
            this.selection,
            PermitAssetNamesEnum.roofMaterial,
            colorAndMaterial.color
        );

        ThreeUtils.changeMaterialColor(this.selection, colorAndMaterial.color);
    }

    private keyDownPressing(event: KeyboardEvent): void {
        if (!this.selection) {
            return;
        }
        if (event.key === KeyCode.shift) {
            this.houseSceneConfig.lockedValueY = this.selection.position.y;
        } else if (event.key === KeyCode.deleteKey) {
            this.deleteSelected();
        }
    }

    private shiftRelease(event: KeyboardEvent): void {
        if (event.key === KeyCode.shift) {
            this.houseSceneConfig.lockedValueY = NaN;
        }
    }

    private transformStart(event: MouseEvent): void {
        this.houseSceneConfig.transformControls.addEventListener(
            'objectChange',
            this.bindedObjectChange
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindedMouseDown
        );
        this.houseSceneConfig.controls.enabled = false;
        this.dragging = false;
    }

    private transformEnd(event: MouseEvent): void {
        this.houseSceneConfig.transformControls.removeEventListener(
            'objectChange',
            this.bindedObjectChange
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindedMouseDown,
            { passive: true }
        );
        this.houseSceneConfig.controls.enabled = true;
    }

    private objectChange(): void {
        const transformMode = this.houseSceneConfig.transformControls.getMode();
        const object = this.houseSceneConfig.transformControls.object as Mesh;

        if (
            transformMode === ThreeObjectControlsEnum.scale &&
            object.userData['asset'].assetType.permitAssetTypeId ===
            PermitAssetTypeValues.FOR_EXTENSION.id
        ) {
            const positionY = this.getPositionOfExtension(object);
            object.position.y = positionY;
        }
    }

    private getPositionOfExtension(object: Mesh): number {
        const geometry = object.geometry as any;
        const height = geometry.parameters.height;
        const scale = Math.abs(object.scale.y);
        const positionYAxis = (height * scale) / 2;

        return positionYAxis > 0 ? positionYAxis : 0;
    }

    protected dispatchDeleteAssetRequest(asset: HouseAsset): void {
        this.store.dispatch(deleteAssetRequest({ object: asset }));
    }

    protected dispatchUpdateAssetRequest(asset: HouseAsset): void {
        this.store.dispatch(updateAssetRequest({ object: asset }));
    }

    protected dispatchDeleteMultipleAssetRequest(assets: HouseAsset[]): void {
        this.store.dispatch(deleteMultipleAssetsRequest({ objects: assets }));
    }
    protected dispatchUpdateMultipleObjectsRequest(houseAssetsToUpdate: HouseAsset[]): void {
        this.store.dispatch(updateMultipleAssetObjectsRequest({ objects: houseAssetsToUpdate }));
    }
}
