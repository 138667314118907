import {
    Component,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermitProjectService } from '../permit-project/permit-project.service';
import { forkJoin, Subscription } from 'rxjs';

import {
    StepperService,
    House,
    HouseService,
    PermitProject,
    routingConstants,
    ToolingButtonsEnum,
    PermitThreeService,
    ProjectTypeNameEnum,
    SvgImageEnum,
    PermitStore,
    ModalManager,
    ToolingActionsEnum,
    MenuService,
    ToolNameEnum,
    Tool
} from '@furban/utilities';
import { Modifiable } from '../../shared/modifyStatus/modifiable.interface';
import { Store } from '@ngrx/store';

@Component({
    selector: 'furban-permit-container',
    templateUrl: './permit-container.component.html',
    styleUrls: ['./permit-container.component.scss'],
})
export class PermitContainerComponent implements OnInit, OnDestroy, Modifiable {

    public activeButton: ToolingActionsEnum;
    public homeRoute: string;
    public isLoaded = false;
    public isClonedHouse: boolean;
    public modified = false;
    public isRedoEnabled = false;
    public isUndoEnabled = false;
    public enabledTool: string;

    private projectChangeSubscription: Subscription;

    private storeSubscription: Subscription;
    private actionFinishedSubscription: Subscription;
    private disableActiveButtonsSubscription: Subscription;

    public get toolingActions(): typeof ToolingActionsEnum {
        return ToolingActionsEnum;
    }

    constructor(
        public containerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private router: Router,
        private stepperService: StepperService,
        private permitProjectService: PermitProjectService,
        private houseService: HouseService,
        private store: Store<{ store: PermitStore }>,
        private permitThreeService: PermitThreeService,
        private modal: ModalManager,
        private viewContainerRef: ViewContainerRef,
        private menuService: MenuService
    ) {

    }

    public get svgImageType(): typeof SvgImageEnum {
        return SvgImageEnum;
    }

    ngOnInit(): void {
        this.menuService.shouldShowMenu = false;
        this.subscribeToRouteObservableAndGetProjectInformation();
        this.subscribeToOnProjectChangeObservable();
        this.subscribeToModifiedObservable();
        this.subscribeToStore();
        this.subscribeToActionsFinished();
        this.subscribeToDisableActive();
    }

    ngOnDestroy(): void {
        this.stepperService.resetProject();
        this.projectChangeSubscription.unsubscribe();
        this.storeSubscription.unsubscribe();
        this.actionFinishedSubscription.unsubscribe();
        this.disableActiveButtonsSubscription.unsubscribe();
    }

    public onButtonClick(buttonType: ToolingActionsEnum): void {
        this.resetActions();
        if (this.activeButton === buttonType) {
            const tool = new Tool(buttonType, false);
            this.checkButtonType(tool);
            this.activeButton = null;
            this.menuService.shouldShowMenu = false;
            return;
        }

        const tool = new Tool(buttonType, true);
        this.checkButtonType(tool);
        this.activeButton = buttonType;
    }

    public checkButtonType(tool: Tool): void {
        switch (tool.toolName) {
            case this.toolingActions.addObjects: {
                this.menuService.shouldShowMenu = true;
                break;
            }
            case this.toolingActions.objectsInLine: {
                if (tool.enabled) {
                    this.drawLine();
                    return;
                }
                this.disableDrawLine();
                break;
            }
            case this.toolingActions.multiselect: {
                if (tool.enabled) {
                    this.activateMultiselect();
                    return;
                }
                this.disableMultiselect();
                break;
            }
            default: {
                break;
            }
        }
    }

    public drawLine(): void {
        if (this.enabledTool === ToolNameEnum.rowOfObjects) {
            this.disableDrawLine();
            return;
        }
        this.enabledTool = ToolNameEnum.rowOfObjects;
        this.permitThreeService.drawLine();
    }

    public activateMultiselect(): void {
        if (this.enabledTool === ToolNameEnum.multipleSelection) {
            this.disableMultiselect();
            return;
        }
        this.enabledTool = ToolNameEnum.multipleSelection;
        this.permitThreeService.activateMultiselect();
    }

    public disableDrawLine(): void {
        this.enabledTool = '';
        this.permitThreeService.finishedDrawingLine();
    }

    public isToolEnabled(toolName: string): boolean {
        return this.enabledTool === toolName;
    }

    public checkActiveButton(buttonType: ToolingActionsEnum): boolean {
        return this.activeButton === buttonType;
    }

    public checkPermitRoute(): boolean {
        return this.checkIfRouteIncludes('preview') ||
            this.checkIfRouteIncludes('update');
    }

    public showDetailsTooling(): boolean {
        return this.checkIfRouteIncludes('project:details');
    }

    public saveProjectDetailsPermit(): void {
        this.permitProjectService.saveProjectDetailsSubject.next(true);
    }

    public shouldDisplayStepper(): boolean {
        return (
            !this.checkIfRouteIncludes('overview') &&
            !!this.stepperService.steps &&
            !!this.stepperService.currentStep
        );
    }

    private getAllProjectInformation(projectId: string) {
        const requests = [
            this.permitProjectService.getProject(projectId),
            this.houseService.getAllMaterials(),
        ];

        forkJoin(requests).subscribe((data) => {
            if (!data) {
                return;
            }
            this.setupPermitProject(data[0] as PermitProject);
        });
    }

    private subscribeToDisableActive(): void {
        this.disableActiveButtonsSubscription = this.router.events.subscribe(() => {
            this.activeButton = null;
            this.menuService.shouldShowMenu = false;
        });
    }

    private resetActions(): void {
        this.disableDrawLine();
        this.disableMultiselect();
        this.menuService.shouldShowMenu = false;
    }

    private getHouseData() {
        let housePolygonRequest;
        if (this.isClonedHouse) {
            housePolygonRequest = this.houseService.getModifiedHousePolygon(
                this.stepperService.projectId
            );
        } else {
            housePolygonRequest = this.houseService.getHousePolygon(
                this.stepperService.projectId
            );
        }

        housePolygonRequest.subscribe((data) => {
            if (!data) {
                return;
            }
            this.setupHouse(data as House);
        });
    }

    private setupPermitProject(data: PermitProject) {
        this.stepperService.project = data;
        this.stepperService.generateStepsWithStatus(
            ProjectTypeNameEnum.pioneerPermitProject
        );
        this.redirectToPermitProcess();
    }

    private setupHouse(data: House) {
        this.permitThreeService.house = House.copyHouse(data);
        this.permitThreeService.house.processedCoordinatesForThree.pop();
    }

    isProjectLoaded() {
        return this.stepperService.project;
    }

    redirectToPermitProcess() {
        if (
            this.router.url ===
            `/${routingConstants.permitProject}/${this.stepperService.projectId}`
        ) {
            this.router.navigateByUrl(
                `${routingConstants.permitProject}/${this.stepperService.projectId}/(permit-project:overview)`
            );
        }
    }

    checkIfRouteIncludes(includedString: string) {
        return this.router.url.includes(includedString);
    }

    public openResetModal(): void {
        this.modal.showModal(this.viewContainerRef, ModalManager.createConfiguration(
            'errors.warning',
            'pioneer.permitProcess.resetWarning',
            'generic.yesBtn',
            'generic.noBtn'
        )).subscribe(
            res => {
                if (res) {
                    this.permitThreeService.buttonPressed.next(ToolingButtonsEnum.reset);
                }
            }
        );
    }

    buttonPressed(buttonType: string) {
        this.permitThreeService.buttonPressed.next(
            ToolingButtonsEnum[buttonType]
        );
    }

    private disableMultiselect(): void {
        this.permitThreeService.deactivateMultiselect();
    }

    private subscribeToRouteObservableAndGetProjectInformation() {
        this.route.params.subscribe((params) => {
            if (params) {
                this.stepperService.projectId = params['id'];
                this.homeRoute = `${routingConstants.permitProject}/${this.stepperService.projectId}/(permit-project:overview)`;

                if (this.stepperService.projectId === 'new') {
                    this.stepperService.project = new PermitProject();
                    this.stepperService.generateStepsWithStatus(
                        ProjectTypeNameEnum.pioneerPermitProject
                    );
                    this.redirectToPermitProcess();
                } else {
                    this.getAllProjectInformation(
                        this.stepperService.projectId
                    );
                    this.getHouseData();
                }
            }
        });
    }

    subscribeToOnProjectChangeObservable() {
        this.projectChangeSubscription =
            this.stepperService.projectChanged.subscribe((projectChanged) => {
                if (!projectChanged) {
                    return;
                }
                this.permitProjectService
                    .saveProject(projectChanged.project as PermitProject)
                    .subscribe((data) => {
                        this.stepperService.regeneratePermitSteps(
                            data,
                            projectChanged.redirect
                        );
                    });
            });
    }

    private subscribeToModifiedObservable() {
        this.stepperService.projectModifiedStateObservable.subscribe((data) => {
            this.modified = data;
        });
    }

    private subscribeToStore(): void {
        this.storeSubscription = this.store.pipe().subscribe((data) => {
            if (!data['permit']?.store) {
                return;
            }
            this.isRedoEnabled = data['permit'].store.future?.length > 0;
            this.isUndoEnabled = data['permit'].store.previous?.length > 0;
        });
    }

    private subscribeToActionsFinished(): void {
        this.actionFinishedSubscription = this.menuService.actionFinishedObservable$.subscribe((data) => {
            this.activeButton = null;
            this.menuService.shouldShowMenu = false;
        });
    }
}
