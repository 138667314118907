import { Directive, Input, OnDestroy } from '@angular/core';
import { OBB } from 'three/examples/jsm/math/OBB.js';
import { MAX_MOUSE_MOVE_TIME } from '../_constants/mouse-move-time';
import { Subscription } from 'rxjs';

import {
    TextureColorEnum,
    ThreeGroupEnum,
    KeyCode,
    HouseAsset,
    ThreeUtils,
    HouseSceneConfig,
    PermitAssetNamesEnum,
    PermitThreeService,
    PermitUtils,
    HouseService,
    PermitStore,
    addAssetObjectRequest,
    ThreeRoofBuilder,
    PermitAssetTypeValues,
} from '@furban/utilities';
import { Store } from '@ngrx/store';
import { BufferGeometry, Euler, Group, Intersection, Matrix3, Mesh, Scene, Vector3 } from 'three';

@Directive({
    selector: '[furbanAssetAttachEvents]',
})
export class AssetAttachEventsDirective implements OnDestroy {
    @Input() houseSceneConfig: HouseSceneConfig;
    private assetType: number;
    private bindMouseMove = this.mouseMoveEvent.bind(this);
    private bindMouseClick = this.mouseClickEvent.bind(this);
    private bindMouseDown = this.mouseDownEvent.bind(this);
    private bindedShiftPressing = this.shiftPressing.bind(this);
    private bindedShiftRelease = this.shiftRelease.bind(this);
    private bindedDoubleClick = this.doubleClickEvent.bind(this);
    private mouseDownTime: number;
    private attachEventsAddedSubscription: Subscription;
    private attachEventsRemovedSubscription: Subscription;

    constructor(
        private permitThreeService: PermitThreeService,
        protected store: Store<{ store: PermitStore }>,
        private houseService: HouseService
    ) { }

    private get isForDormers(): boolean {
        return this.assetType === PermitAssetTypeValues.FOR_DORMERS.id;
    }

    private get isForWall(): boolean {
        return this.assetType === PermitAssetTypeValues.FOR_WALL.id;
    }

    private get isForExtension(): boolean {
        return this.assetType === PermitAssetTypeValues.FOR_EXTENSION.id;
    }

    private get isForSolarPanels(): boolean {
        return this.assetType === PermitAssetTypeValues.FOR_PANELS.id;
    }

    ngOnDestroy(): void {
        this.removeMouseEventsFromRenderer();
        this.attachEventsAddedSubscription.unsubscribe();
        this.attachEventsRemovedSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.attachEventsAddedSubscription =
            this.permitThreeService.attachEventsAddedObservable.subscribe(
                (data) => {
                    this.addingMouseEventsFromRenderer(data);
                }
            );

        this.attachEventsRemovedSubscription =
            this.permitThreeService.attachEventsRemovedObservable.subscribe(
                (data) => {
                    this.removeMouseEventsFromRenderer();
                }
            );
    }

    public addingMouseEventsFromRenderer(assetType?: number): void {
        this.assetType = assetType;
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointermove',
            this.bindMouseMove,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindMouseDown,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerup',
            this.bindMouseClick,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'keyup',
            this.bindedShiftRelease,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'keydown',
            this.bindedShiftPressing,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'dblclick',
            this.bindedDoubleClick,
            { passive: true }
        );
    }

    public removeMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointermove',
            this.bindMouseMove
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindMouseDown
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerup',
            this.bindMouseClick
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'keyup',
            this.bindedShiftRelease
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'keydown',
            this.bindedShiftPressing
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'dblclick',
            this.bindedDoubleClick
        );
    }

    private mouseClickEvent(event: MouseEvent): void {
        this.checkIfRightClickAndRemoveSelection(event);
        const timeDifference = new Date().getTime() - this.mouseDownTime;

        if (
            !this.permitThreeService.loadedAsset ||
            timeDifference > MAX_MOUSE_MOVE_TIME ||
            (this.permitThreeService.loadedAsset &&
                !this.permitThreeService.loadedAsset.visible)
        ) {
            return;
        }

        if (
            this.permitThreeService.loadedAsset.userData['assetType'] ===
            PermitAssetTypeValues.FOR_EXTENSION.id
        ) {
            const mesh = this.setupExtensionMesh();
            this.getHouseAssetAndDispatch(mesh);
            return;
        }

        if (
            this.permitThreeService.loadedAsset.userData['assetType'] ===
            PermitAssetTypeValues.FOR_ROOF.id &&
            this.permitThreeService.loadedAsset.userData['asset'].objectLookId.includes(
                'RFA'
            )
        ) {
            const mesh = this.setupRoofnMesh();

            this.getHouseAssetAndDispatch(mesh);
            return;
        }

        const cloned3DObject = this.setupClonedMesh(
            this.permitThreeService.loadedAsset
        );
        ThreeUtils.cloneMaterial(cloned3DObject);
        ThreeUtils.setHexColorOnMaterial(
            cloned3DObject,
            TextureColorEnum.neutral0
        );
        cloned3DObject.userData['houseAsset'] = new HouseAsset();
        cloned3DObject.userData['houseAsset'].asset =
            this.permitThreeService.loadedAsset.userData['asset'];
        cloned3DObject.userData['houseAsset'].houseId =
            this.permitThreeService.loadedAsset.userData['houseId'];

        if (cloned3DObject.userData['asset'].assetType?.permitAssetTypeId === PermitAssetTypeValues.FOR_DORMERS.id) {
            cloned3DObject.userData['houseAsset'].color = this.permitThreeService.house.roof.roofColor;
            cloned3DObject.userData['houseAsset'].material = this.permitThreeService.house.roof.roofMaterial;
        }

        this.getHouseAssetAndDispatch(cloned3DObject);
    }

    private getHouseAssetAndDispatch(mesh: Group): void {
        const houseAsset = PermitUtils.getHouseAssetFromGroupUserData(mesh);
        this.dispatchAddAssetRequest(houseAsset);
    }

    private setupExtensionMesh(): any {
        const extensionMesh = PermitUtils.setupExtensionMesh(
            this.permitThreeService.house,
            this.permitThreeService.loadedAsset.position,
            this.permitThreeService.loadedAsset.rotation
        );
        extensionMesh.userData['assetType'] =
            PermitAssetTypeValues.FOR_EXTENSION.id;
        extensionMesh.userData['houseAsset'] = new HouseAsset();
        extensionMesh.userData['houseAsset'].asset =
            this.permitThreeService.loadedAsset.userData['asset'];
        extensionMesh.userData['houseAsset'].houseId =
            this.permitThreeService.loadedAsset.userData['houseId'];
        return extensionMesh;
    }

    private setupRoofnMesh(): Group {
        const roofBuilder = new ThreeRoofBuilder(
            this.permitThreeService.house.roof,
            this.permitThreeService.house.processedCoordinatesForThree
        );
        const extensionMesh = roofBuilder.createDefaultRoofAsset(
            2,
            this.permitThreeService.house,
            this.permitThreeService.loadedAsset.position
        );
        extensionMesh.userData['assetType'] = PermitAssetTypeValues.FOR_ROOF.id;
        extensionMesh.userData['houseAsset'] = new HouseAsset();
        extensionMesh.userData['houseAsset'].asset =
            this.permitThreeService.loadedAsset.userData['asset'];
        extensionMesh.userData['houseAsset'].houseId =
            this.permitThreeService.loadedAsset.userData['houseId'];
        extensionMesh.userData['houseAsset'].color = this.permitThreeService.house.roof.roofColor;
        extensionMesh.userData['houseAsset'].material = this.permitThreeService.house.roof.roofMaterial;

        return extensionMesh;
    }

    private mouseDownEvent(event: MouseEvent): void {
        this.checkIfRightClickAndRemoveSelection(event);

        if (
            !this.permitThreeService.loadedAsset ||
            (this.permitThreeService.loadedAsset &&
                !this.permitThreeService.loadedAsset.visible)
        ) {
            return;
        }
        this.mouseDownTime = new Date().getTime();
    }

    private mouseMoveEvent(event: MouseEvent): void {

        if (!this.permitThreeService.loadedAsset) {
            return;
        }

        const intersects = this.getIntersectionMeshByAssetType(
            event,
            this.assetType,
            this.houseSceneConfig.scene
        );

        if (intersects.length === 0) {
            this.permitThreeService.loadedAsset.visible = false;
            return;
        }

        this.permitThreeService.loadedAsset.visible = true;

        const lastPosition =
            this.permitThreeService.loadedAsset.position.clone();
        const lastRotation =
            this.permitThreeService.loadedAsset.rotation.clone();

        const yPos =
            this.assetType === PermitAssetTypeValues.FOR_EXTENSION.id
                ? 1.5
                : ThreeUtils.getCustomPositionOnYAxis(
                    intersects,
                    this.houseSceneConfig,
                    this.permitThreeService
                );
        const updateLookAt =
            this.isForExtension ||
            this.isForWall ||
            this.isForDormers ||
            this.isForSolarPanels;

        ThreeUtils.snapAssetToObjectFace(
            this.permitThreeService.loadedAsset,
            intersects,
            yPos,
            updateLookAt,
            this.isForDormers
        );

        this.blockMovementByAssetType(lastPosition, lastRotation);
    }

    private blockMovementByAssetType(
        lastPosition: Vector3,
        lastRotation: Euler
    ): void {
        let childrenNodesCollision, collidedNode;

        const wallObject = this.houseSceneConfig.scene.getObjectByName(PermitAssetNamesEnum.wallGroup);

        switch (this.assetType) {
            case PermitAssetTypeValues.FOR_WALL.id:

                childrenNodesCollision =
                    this.houseSceneConfig.intersectionHelpers.children.concat(
                        this.permitThreeService.assetObjects.children
                    );
                collidedNode = PermitUtils.getCollindingNode(
                    this.permitThreeService.loadedAsset,
                    childrenNodesCollision
                );
                PermitUtils.blockMovementOnAxis(
                    this.permitThreeService.loadedAsset,
                    collidedNode,
                    lastPosition,
                    lastRotation
                );
                PermitUtils.checkIfExceedingLimits(
                    wallObject,
                    this.permitThreeService.loadedAsset
                );
                break;
            case PermitAssetTypeValues.FOR_EXTENSION.id:
                PermitUtils.blockMovementOnAxis(
                    this.permitThreeService.loadedAsset,
                    collidedNode,
                    lastPosition,
                    lastRotation
                );
                break;
            default:
                childrenNodesCollision =
                    this.houseSceneConfig.intersectionHelpers.children.concat(
                        this.permitThreeService.assetObjects.children
                    );
                collidedNode = PermitUtils.getCollindingNode(
                    this.permitThreeService.loadedAsset,
                    childrenNodesCollision
                );
                PermitUtils.blockMovementOnAxis(
                    this.permitThreeService.loadedAsset,
                    collidedNode,
                    lastPosition,
                    lastRotation
                );
                break;
        }
    }

    private getIntersectionMeshByAssetType(
        event: MouseEvent,
        assetType: number,
        scene: Scene
    ): Intersection[] {
        let intersects: Intersection[];
        const planeObject = scene.getObjectByName(ThreeGroupEnum.ground);
        const wallGroup = scene.getObjectByName(PermitAssetNamesEnum.wallGroup);
        const extensionGroup = scene.getObjectByName(PermitAssetNamesEnum.extensionGroup);
        const roofGroup = scene.getObjectByName(PermitAssetNamesEnum.roofGroup);
        const wallObject = scene.getObjectByName(PermitAssetNamesEnum.wallGroup);
        const roofAssetGroup = scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const rfaGroup = scene.getObjectByName(PermitAssetNamesEnum.roofAssetGroup);
        const rfGroup = scene.getObjectByName(PermitAssetNamesEnum.roofGroup);

        switch (assetType) {
            case PermitAssetTypeValues.FOR_GROUND.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [planeObject],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_WALL.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [wallGroup, extensionGroup],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_ROOF.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [roofGroup],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_EXTENSION.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [wallObject],
                    this.houseSceneConfig
                );
                break;
            case PermitAssetTypeValues.FOR_DORMERS.id:
                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [roofAssetGroup],
                    this.houseSceneConfig
                );
                break;

            case PermitAssetTypeValues.FOR_PANELS.id:

                intersects = ThreeUtils.getIntersectionFromEvent(
                    event,
                    [rfaGroup, rfGroup],
                    this.houseSceneConfig
                );
                break;
            default:
                console.error('Unable to find asset');
                break;
        }

        return intersects;
    }

    private setupClonedMesh(loadedAsset: Group): Group {
        const cloned3DObject = loadedAsset.clone();
        const assetChildMesh = loadedAsset.children[0];
        const assetChildGeometry = (<Mesh>assetChildMesh).geometry;
        const clonedMesh = cloned3DObject.children[0];
        const clonedMeshGeometry = (<Mesh>clonedMesh).geometry;
        (<BufferGeometry>clonedMeshGeometry).userData['obb'] = (<BufferGeometry>assetChildGeometry).userData['obb'].clone();
        cloned3DObject.userData['obb'] = new OBB(
            new Vector3(),
            new Vector3(),
            new Matrix3()
        );
        return cloned3DObject;
    }
    private shiftPressing(event: KeyboardEvent) {
        if (event.key === KeyCode.shift) {
            this.houseSceneConfig.lockedValueY =
                this.permitThreeService.loadedAsset.position.y;
        }
    }

    private shiftRelease(event: KeyboardEvent): void {
        if (event.key === KeyCode.shift) {
            this.houseSceneConfig.lockedValueY = NaN;
        }
    }

    private checkIfRightClickAndRemoveSelection(event: MouseEvent): void {
        if (event.button === 2) {
            this.removeSelection();
            return;
        }
    }

    private removeSelection(): void {
        if (this.permitThreeService.loadedAsset) {
            this.houseService.cancelSelection();
            ThreeUtils.setHexColorOnMaterial(
                this.permitThreeService.loadedAsset,
                TextureColorEnum.neutral0
            );
            this.permitThreeService.loadedAsset = null;
        }
    }

    private doubleClickEvent(event: MouseEvent): void {
        this.removeSelection();
    }

    protected dispatchAddAssetRequest(asset: HouseAsset): void {
        this.store.dispatch(addAssetObjectRequest({ object: asset }));
    }
}
