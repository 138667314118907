import { PointXY } from './point-xy';
import { PointLatLon } from './point-lat-lon';
import { HouseMaterial } from './house-material';
import { Roof } from './roof';
import { PermitUtils } from '../helpers/permit.utils';
import { FurbanUtil } from '../helpers/furbanUtil';
import { Vector2 } from 'three';

export class House {
    public houseId?: string;
    public xYCoordinates: Array<PointXY> | string;
    public latLonCoordinates: Array<PointLatLon> | string;
    public processedCoordinatesForThree?: Array<Vector2>;
    public projectId?: string;
    public isFlatRoof?: boolean;
    public houseMaterial?: HouseMaterial;
    public roof?: Roof;
    public numberOfFloors?: number;
    public floorHeight?: number;
    public houseColor?: string;
    public isClone?: boolean;
    public isNeighborHouse?: boolean;
    public houseIdentificationNumber?: string;

    public static copyHouse(houseToCopy?: House): House {
        const newHouse = Object.assign({}, houseToCopy);
        newHouse.latLonCoordinates = FurbanUtil.getJSONfromStringfyValue(
            houseToCopy.latLonCoordinates
        );
        newHouse.xYCoordinates = FurbanUtil.getJSONfromStringfyValue(
            houseToCopy.xYCoordinates
        );
        newHouse.roof = new Roof(houseToCopy.roof);
        newHouse.processedCoordinatesForThree = PermitUtils.parseCoordinates(
            newHouse.xYCoordinates as Array<PointXY>
        );

        return newHouse;
    }
}
