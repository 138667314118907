import { AnimationClip, AnimationMixer, Group } from 'three';

export class ThreeAnimationBuilder {
  public static createAnimationMixer(object: Group, animation: AnimationClip) {
    const mixer = new AnimationMixer(object);
    const action = mixer.clipAction(animation);
    action.play();
    return mixer;
  }
}
