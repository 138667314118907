import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ThreeUtils,
    HouseSceneConfig,
    PermitThreeService,
    urlConstants,
    PermitComment,
    PermitCommentsService,
    TextureColorEnum,
} from '@furban/utilities';
import { Object3D } from 'three';

@Directive({
    selector: '[furbanSelectPinComments]',
})
export class SelectPinCommentsDirective implements OnDestroy, OnInit {
    @Input() houseSceneConfig: HouseSceneConfig;
    @Input() permitProjectId: string;
    @Output() updatePinCommentToDisplay: EventEmitter<PermitComment> =
        new EventEmitter();

    private bindMouseDown = this.mouseDownEvent.bind(this);

    private currentPinComment: PermitComment;
    private detachPopupToPinSubscription: Subscription;
    private saveDataToPinSubscription: Subscription;
    private addPinEventsSubscription: Subscription;
    private lastSelectedPin: Object3D;
    private highlightCommentSubscription: Subscription;

    constructor(
        private permitThreeService: PermitThreeService,
        private permitCommentService: PermitCommentsService
    ) { }

    ngOnDestroy(): void {
        this.removeMouseEventsFromRenderer();
        this.detachPopupToPinSubscription?.unsubscribe();
        this.saveDataToPinSubscription?.unsubscribe();
        this.addPinEventsSubscription?.unsubscribe();
        this.highlightCommentSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadPin();
        this.setupPinPopupOnRenderer();
        this.subscribeToPopupEvents();
        this.subscribeToAddPin();
        this.addingMouseEventsFromRenderer();
        this.subscribeToHighlightComment();
        this.subscribeToDeleteComment();
    }

    public addingMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindMouseDown,
            { passive: false }
        );
    }

    public removeMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindMouseDown
        );
    }

    private loadPin(): void {
        if (this.houseSceneConfig.loadedPin) {
            this.clonePinForEveryPositionedComment();
            return;
        }

        const loadingUrl = urlConstants.pin;
        this.houseSceneConfig.loader.load(loadingUrl, (gltf) => {
            this.houseSceneConfig.loadedPin = gltf.scene;
            this.clonePinForEveryPositionedComment();
        });
    }

    private setupPinPopupOnRenderer(): void {
        this.houseSceneConfig.pinCommentPopup =
            ThreeUtils.getCommentPopupHTMLElement();
    }

    private subscribeToPopupEvents(): void {
        this.detachPopupToPinSubscription =
            this.permitThreeService.detachPopupToPinObservable.subscribe(
                (data) => {
                    if (!data) {
                        return;
                    }
                    this.removePopupFromPin();
                }
            );
    }

    private unsubscribeToPopupEvents(): void {
        this.detachPopupToPinSubscription.unsubscribe();
    }

    private subscribeToAddPin(): void {
        this.addPinEventsSubscription =
            this.permitThreeService.addPinEventsObservable.subscribe((data) => {
                if (!data) {
                    this.addingMouseEventsFromRenderer();
                    this.subscribeToPopupEvents();
                } else {
                    this.removeMouseEventsFromRenderer();
                    this.unsubscribeToPopupEvents();
                }
            });
    }

    private removePopupFromPin(): void {
        this.lastSelectedPin?.remove(this.houseSceneConfig.pinCommentPopup);
        this.houseSceneConfig.pinHelper.children.forEach((element) => {
            ThreeUtils.setHexColorOnMaterial(
                element,
                TextureColorEnum.neutral0
            );
        });
        this.permitCommentService.highlightedPermitCommentEmitter.emit(null);
    }

    private clonePinForEveryPositionedComment(): void {
        this.permitCommentService
            .getAllPermitComments(this.permitProjectId)
            .subscribe((data) => {
                if (!data || data.length === 0) {
                    return;
                }

                data.forEach((pin) => {
                    if (pin.position) {
                        const pinPosition = JSON.parse(pin.position);
                        const pinRotation = JSON.parse(pin.rotation);

                        const newPinToAdd =
                            this.houseSceneConfig.loadedPin.clone();
                        ThreeUtils.cloneMaterial(newPinToAdd);

                        newPinToAdd.position.copy(pinPosition);
                        newPinToAdd.rotation.copy(pinRotation);
                        newPinToAdd.userData = pin;
                        this.houseSceneConfig.pinHelper.add(newPinToAdd);
                    }
                });
            });
    }

    mouseDownEvent(event: MouseEvent): void {
        if (event.button === 2) {
            return;
        }

        const container =
            this.houseSceneConfig.renderer.domElement.getBoundingClientRect();
        const mouseCoordinates = this.houseSceneConfig.isSpiltScreenView
            ? ThreeUtils.computeEventCoordinatesForSplitScreen(
                event,
                this.houseSceneConfig.renderer
            )
            : ThreeUtils.getMouseCoordinatesInContainer(event, container);

        const newIntersectedObject = ThreeUtils.getIntersectedPinComment(
            this.houseSceneConfig,
            mouseCoordinates
        );

        if (!newIntersectedObject) {
            return;
        }

        this.currentPinComment = newIntersectedObject.userData as PermitComment;
        this.updatePinCommentToDisplay.emit(this.currentPinComment);
        this.permitCommentService.highlightedPermitCommentEmitter.emit(
            this.currentPinComment
        );
    }

    private highlightPinComment(pinComment: PermitComment): void {
        if (!pinComment) {
            ThreeUtils.setHexColorOnMaterial(
                this.lastSelectedPin,
                TextureColorEnum.neutral0
            );
            this.lastSelectedPin?.remove(this.houseSceneConfig.pinCommentPopup);
            return;
        }
        this.updatePinCommentToDisplay.emit(pinComment);

        this.houseSceneConfig.pinHelper.children.forEach((element) => {
            if (element.userData?.['commentId'] === pinComment?.commentId) {
                this.lastSelectedPin = element;
                ThreeUtils.setHexColorOnMaterial(
                    element,
                    TextureColorEnum.mildBlue
                );
                this.lastSelectedPin.add(this.houseSceneConfig.pinCommentPopup);
            } else {
                ThreeUtils.setHexColorOnMaterial(
                    element,
                    TextureColorEnum.neutral0
                );
            }
        });
    }

    private subscribeToHighlightComment(): void {
        this.highlightCommentSubscription =
            this.permitCommentService.highlightedPermitCommentEmitter.subscribe(
                (data) => {
                    this.highlightPinComment(data);
                }
            );
    }

    private subscribeToDeleteComment(): void {
        this.highlightCommentSubscription =
            this.permitCommentService.deletedPermitCommentEmitter.subscribe(
                (data) => {
                    this.houseSceneConfig.pinHelper.children.forEach(
                        (element) => {
                            if (element.userData?.['commentId'] === data) {
                                element.remove(
                                    this.houseSceneConfig.pinCommentPopup
                                );
                                this.houseSceneConfig.pinHelper.remove(element);
                                return;
                            }
                        }
                    );
                }
            );
    }
}
