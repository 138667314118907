import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Project,
    ProjectStorageService,
    ObjectCategoryStatistics,
    AuthService,
    StepperService,
} from '@furban/utilities';
import { StatisticsService } from '../../municipality/user/statistics/statistics.service';
import { Subscription, switchMap, of } from 'rxjs';
import { ProjectDetailsService } from '../project-details/project-details.service';

@Component({
    selector: 'furban-statistics-overview',
    templateUrl: './statistics-overview.component.html',
    styleUrls: ['./statistics-overview.component.scss'],
})
export class StatisticsOverviewComponent implements OnInit, OnDestroy {
    public curentProject: Project;
    public curentProjectId: string;
    public objectsPerCategoryCount: ObjectCategoryStatistics[];
    public objectsTotalCount: number;

    public get isCitizenOrExpert(): boolean {
        return this.authService.isCitizenOrExpert;
    }

    public get isAdminOrPionner(): boolean {
        return this.authService.isPioneer() || this.authService.isAdmin();
    }

    private routeSub: Subscription;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private statisticsService: StatisticsService,
        private projectStorageService: ProjectStorageService,
        private authService: AuthService,
        private stepperService: StepperService,
        private location: Location,
        private route: ActivatedRoute
    ) {
        this.subscribeToProjectChangedForUsers(projectStorageService);
    }

    ngOnInit(): void {
        this.setCurrentProject();
        this.loadDataForProject();
    }


    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    public displayCategoryStatistics(): boolean {
        return (this.isAdminOrPionner && this.objectsTotalCount && this.objectsPerCategoryCount?.length > 0);
    }

    public goBack(): void {
        this.location.back();
    }

    private setCurrentProject(): void {
        if (this.isAdminOrPionner) {
            this.curentProject = this.stepperService.project as Project;
        } else {
            this.curentProject = this.projectStorageService.curentProject as Project;
        }

        if (!this.curentProject) {
            this.subscribeToRouteChange();
        }
    }

    private subscribeToRouteChange(): void {
        this.routeSub = this.route.params
            .pipe(
                switchMap(params => {
                    this.curentProjectId = params['id'];
                    return this.curentProjectId ? this.projectDetailsService.getProject(this.curentProjectId) : of(null);
                })
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }

                this.curentProject = data as Project;

                if (this.isCitizenOrExpert) {
                    this.projectStorageService.changeProject(this.curentProject);
                }
            });
    }

    private subscribeToProjectChangedForUsers(projectStorageService: ProjectStorageService): void {
        if (this.isCitizenOrExpert) {
            this.curentProject = this.projectStorageService?.getCurentProject();
            this.curentProjectId = this.curentProject?.projectId;
            projectStorageService.projectChanged.subscribe((item) => {
                if (item.projectId !== this.curentProject.projectId) {
                    this.curentProject = item;
                }
            });
        }
    }

    private getDataForAdminCharts(): void {
        this.statisticsService
            .getDataForAdminCharts(this.curentProjectId)
            .subscribe((responseList) => {
                this.objectsPerCategoryCount = responseList[0];
                this.objectsTotalCount = responseList[1];
            });
    }

    private loadDataForProject(): void {
        if (this.isAdminOrPionner) {
            this.getDataForAdminCharts();
        }
    }
}

