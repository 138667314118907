import {
  Shape,
  ShapeGeometry,
  WebGLRenderer,
  Vector2,
  Vector3,
  Group,
  Mesh,
  Euler,
  Quaternion,
  Raycaster,
  Object3D,
  Intersection,
  BoxGeometry,
  Matrix3,
  Box3,
  Path,
  PerspectiveCamera,
} from 'three';
import { ThreeInstance } from './three-instance';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { HouseSceneConfig } from '../_models/house-config';
import { PathObject } from '../_models/path-object';
import { FurbanUtil } from '../helpers/furbanUtil';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { Opened3DSections } from '../_models/opened-3d-sections';
import { ThreeGroupEnum } from '../_enum/three-group.enum';
import { PointXY } from '../_models/point-xy';
import { FurbanMeshPosition } from '../_models/furban-mesh-position';
import { MathUtil } from '../helpers/math.util';
import { TextureColorEnum } from '../_enum/texture-color.enum';
import { Project3dModel } from '../_models/project-3d-model';
import { ProjectAndPathIds } from '../_models/project-and-path-id';
import { ThreeObjectControlsEnum } from '../_enum/three-object-controls.enum';
import { PermitThreeService } from '../_services/permit-three.service';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { MeshBVH } from 'three-mesh-bvh/build/index.module';
import { Project3DUpload } from '../_models/project-3d-upload';
import { PermitAsset } from '../_models/permit-asset';
import { HouseAsset } from '../_models/house-asset';
// import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory.js';
// import BufferParameters from 'jsts/org/locationtech/jts/operation/buffer/BufferParameters.js';
// import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate.js';
// import BufferBuilder from 'jsts/org/locationtech/jts/operation/buffer/BufferBuilder.js';
import { PermitUtils } from '../helpers/permit.utils';
import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';

// REFACTOR - no more any
export class ThreeUtils {
  public static defaultZFightingOffset = 0.02;
  public static linesZFightingOffset = 0.2;
  public static customHouseDefaultDim = { width: 3, height: 3, depth: 3 };
  public static defaultMaterialOptions = {
    transparent: true,
    depthTest: true,
    depthWrite: false,
    polygonOffset: true,
    polygonOffsetFactor: -4,
  };
  public static sphereHelper = 'sphere_helper';
  public static gridHelperName = 'grid_helper';
  public static toAngle = (x) => (x * 180) / Math.PI;

  public static toReal = (x) => {
    if (!isNaN(parseFloat(x)) && isFinite(parseFloat(x))) {
      return parseFloat(parseFloat(x).toFixed(3));
    } else {
      return x;
    }
  };

  public static toRad = (x) => (x / 180) * Math.PI;
  public static toRadReduced = (x) => ((x % 360) / 180) * Math.PI;

  public static convertFromQuaternionToDegrees(quat: Quaternion) {
    const angle = 2 * Math.acos(quat.w);
    const degrees = this.toReal(this.toAngle(angle));
    if (quat.y > 0) {
      return -degrees;
    }
    return degrees;
  }

  public static convertFromEulerToDegrees(euler: Euler) {
    const quat = new Quaternion();
    quat.setFromEuler(euler);

    const angle = 2 * Math.acos(quat.w);
    const degrees = this.toReal(this.toAngle(angle));

    return degrees;
  }

  public static inflatePolygon(poly: PointXY[], spacing: number): Vector2[] {
    const vectors: Vector2[] = [];
    poly.map((element) => vectors.push(new Vector2(element.X, element.Y)));
    return PermitUtils.offsetContour(spacing, vectors);
  }

//   public static inflatePolygon1(poly: any, spacing: any): any {
//     const geoInput = this.convertVectorCoordinatesToJTS(poly);
//     const geometryFactory = new GeometryFactory();
//     const shell = geometryFactory.createPolygon(geoInput);

//     const polygon = shell.buffer(spacing, BufferParameters.CAP_FLAT);

//     const inflatedCoordinates = [];
//     const oCoordinates = polygon.getCoordinates(); //_shell._points._coordinates;

//     for (let i = 0; i < oCoordinates.length; i++) {
//       const oItem = oCoordinates[i];
//       inflatedCoordinates.push(
//         new Vector2(Math.ceil(oItem.x), Math.ceil(oItem.y))
//       );
//     }
//     return inflatedCoordinates;
//   }

//   public static convertVectorCoordinatesToJTS(polygon: any) {
//     const coordinates = [];
//     for (let i = 0; i < polygon.length; i++) {
//       coordinates.push(new Coordinate(polygon[i].X, polygon[i].Y));
//     }
//     return coordinates;
//   }

  // REFACTOR - too long
  public static convert3DObjectsToPathObjects(
    objects3D: any,
    roleAdmin: boolean,
    ignoreRole: boolean
  ): PathObject[] {
    const savedObjs: PathObject[] = [];
    for (let i = 0; i < objects3D.length; i++) {
      if (
        ignoreRole ||
        (objects3D[i].userData.default && roleAdmin) ||
        (!objects3D[i].userData.default && !roleAdmin)
      ) {
        const obj = this.convert3DObjectToPathObject(objects3D[i]);
        savedObjs.push(obj);
      }
    }
    return savedObjs;
  }

  public static convert3DObjectToPathObject(objects3D: Object3D): PathObject {
    const obj: PathObject = new PathObject();
    this.setPropertiesToPathObject(obj, objects3D);
    this.calculateAngle(obj, objects3D);
    this.computeFreeShapePoints(obj, objects3D);
    return obj;
  }

  public static addPropertiesToObject = (
    pathObj: PathObject,
    object3D: Object3D
  ) => {
    object3D.name = pathObj.objId.toString();

    for (const [key, value] of Object.entries(pathObj)) {
      object3D.userData[key] = value;
    }

    object3D.userData['freeze'] = false;
  };

  // REFACTOR
  public static traverseObjectAndAddProperties = (object: any) => {
    object.traverse((obj: any) => {
      obj.castShadow = true;
      obj.receiveShadow = true;

      if (
        obj.type &&
        obj.type === 'Mesh' &&
        obj.material.name &&
        (obj.material.name.includes('Furban') ||
          obj.material.name.includes('Foliage'))
      ) {
        obj.material.polygonOffsetFactor = -4;
        obj.material.polygonOffset = true;

        if (obj.material.name.includes('Foliage')) {
          obj.material.alphaTest = 0.5;
          obj.material.depthTest = true;
          obj.material.transparent = true;
          obj.material.depthWrite = true;
          obj.material.side = 2;
        }
      }
    });
  };

  public static getAspectRatio(canvas: HTMLCanvasElement): number {
    const height = canvas.height;
    if (height === 0) {
      return 0;
    }
    return canvas.width / canvas.height;
  }

  // REFACTOR - split into multiple methods
  public static setCanvasDimensions(
    canvas: HTMLCanvasElement,
    camera: PerspectiveCamera,
    openedSections: Opened3DSections
  ) {
    const container = document.getElementsByTagName(
      'furban-app-user-project'
    )[0];

    if (container) {
      this.updateCanvasInsideContainer(canvas, openedSections);

      if (openedSections.openedNavigation && !openedSections.fullScreenMode) {
        canvas.width =
          document.getElementsByTagName('body')[0].clientWidth - 78;
      } else {
        canvas.width = document.getElementsByTagName('body')[0].clientWidth;
      }

      canvas.height = openedSections.fullScreenMode
        ? document.getElementsByTagName('body')[0].clientHeight
        : document.getElementsByTagName('furban-app-user-project')[0]
            .clientHeight;
    } else if (
      document.getElementsByTagName('furban-design-proposal-details')[0]
    ) {
      this.updateCanvasOnDesignProposalDetails(canvas);
    } else if (document.getElementsByClassName('card-three-container')[0]) {
      this.updateCanvasOnCardThreeContainer(canvas);
    } else if (document.getElementsByClassName('three-container')[0]) {
      this.updateCanvasOnThreeContainer(canvas);
    } else if (document.getElementsByClassName('three-replacement')[0]) {
      canvas.width =
        document.getElementsByClassName('three-replacement')[0].clientWidth;
      canvas.height =
        document.getElementsByClassName('three-replacement')[0].clientHeight;
    }

    if (camera) {
      camera.aspect = this.getAspectRatio(canvas);
      camera.updateProjectionMatrix();
    }
  }

  public static removeNoScroll() {
    document
      .getElementsByClassName('main-container')[0]
      .classList.remove('no-scroll');
  }

  public static getObjectPath(
    objectId: number,
    mobileObjectsIds: number[]
  ): string {
    if (FurbanUtil.isMobile() && mobileObjectsIds.includes(objectId)) {
      return `assets/images/objects/${objectId}/${objectId}m.glb`;
    } else {
      return `assets/images/objects/${objectId}/${objectId}.glb`;
    }
  }

  public static getMouseCoordinatesInContainer = (
    event: any,
    container: any
  ): Vector2 => {
    if (event.touches) {
      event = event.touches[0];
    }

    const mouseX = ((event.clientX - container.left) / container.width) * 2 - 1;
    const mouseY =
      -((event.clientY - container.top) / container.height) * 2 + 1;
    return new Vector2(mouseX, mouseY);
  };

  public static computeEventCoordinatesForSplitScreen(
    event: MouseEvent | Touch,
    renderer: WebGLRenderer
  ): Vector2 {
    const container = renderer.domElement;
    const viewWidth = 0.5;
    const viewHeight = 1;

    const boundingRect = container.getBoundingClientRect();

    const x =
      (event.clientX - boundingRect.left) *
      (window.innerWidth / boundingRect.width);
    const y =
      (event.clientY - boundingRect.top) *
      (window.innerHeight / boundingRect.height);

    const WIDTH = Math.floor(window.innerWidth * viewWidth);
    const HEIGHT = Math.floor(window.innerHeight * viewHeight);

    const xRatio = x / WIDTH;
    const yRatio = y / HEIGHT;
    const xValue = (xRatio - 1) * 2 - 1;
    const yValue = -yRatio * 2 + 1;

    return new Vector2(xValue, yValue);
  }

  public static getCoordinatesFromCenterOfContainer = (): Vector2 => {
    return new Vector2(0, 0);
  };

  public static getIntersectedCustomUploadedObject = (
    instance: ThreeInstance,
    mouse: Vector2
  ) => {
    const objects = instance.uploadedObjectHelper.children;
    instance.raycaster.setFromCamera(mouse, instance.camera);
    const intersectedObjects = instance.raycaster.intersectObjects(
      objects,
      true
    );
    return intersectedObjects.length > 0
      ? ThreeUtils.getParentObject(intersectedObjects[0].object)
      : null;
  };

  public static getIntersectedObject = (
    instance: ThreeInstance,
    mouse: Vector2,
    isCitizenOrExpert: boolean,
    skipDefault?: boolean
  ) => {
    const regularObjects = instance.objectsRegular.children;
    const groundObjects = instance.objectsGround.children;
    const objectsInsideThreeJs = regularObjects.concat(groundObjects);

    instance.raycaster.setFromCamera(mouse, instance.camera);
    const intersectedObjects = instance.raycaster.intersectObjects(
      objectsInsideThreeJs,
      true
    );
    let currentIndex = 0;
    let intersectedObject;
    while (currentIndex < intersectedObjects.length) {
      const parentObject = ThreeUtils.getParentObject(
        intersectedObjects[currentIndex].object
      );

      if (
        instance.isPublished ||
        (isCitizenOrExpert && skipDefault) ||
        parentObject.userData.freeze
      ) {
        currentIndex++;
        continue;
      }

      intersectedObject = parentObject;
      break;
    }
    return intersectedObject;
  };

  public static getIntersectedPinComment = (
    instance: ThreeInstance | HouseSceneConfig,
    mouse: Vector2
  ) => {
    const pinComments = instance.pinHelper.children;
    instance.raycaster.setFromCamera(mouse, instance.camera);
    const intersectedObjects = instance.raycaster.intersectObjects(
      pinComments,
      true
    );

    return intersectedObjects.length > 0
      ? ThreeUtils.getParentObject(intersectedObjects[0].object)
      : null;
  };

  public static getParentObject(object: any) {
    while (!this.isParentGroup(object)) {
      object = object.parent;
    }
    return object;
  }

  public static isParentGroup(object: any) {
    return (
      object.parent.name === ThreeGroupEnum.objectsRegular ||
      object.parent.name === ThreeGroupEnum.objectsGround ||
      object.parent.name === ThreeGroupEnum.custom_uploaded_object ||
      object.parent.name === ThreeGroupEnum.pin_helpers
    );
  }

  public static getObjectBeforeParent(object: any) {
    const isParentGroup = () =>
      object.parent.name === ThreeGroupEnum.ground ||
      object.parent.name === ThreeGroupEnum.objectsRegular ||
      object.parent.name === ThreeGroupEnum.objectsGround ||
      object.parent.name === ThreeGroupEnum.transform ||
      object.parent.name === ThreeGroupEnum.background ||
      object.parent.name === ThreeGroupEnum.multiselect ||
      object.parent.name === ThreeGroupEnum.helpers;

    while (!isParentGroup()) {
      object = object.parent;
    }
    return object;
  }

  public static getObjectBeforeParentForPermit(object: Mesh | any) {
    const isParentGroup = () =>
      object.parent.name === PermitAssetNamesEnum.assetGroup ||
      object.parent.name === PermitAssetNamesEnum.dormersGroup ||
      object.parent.name === PermitAssetNamesEnum.extensionGroup ||
      object.parent.name === PermitAssetNamesEnum.roofAssetGroup ||
      object.parent.name === PermitAssetNamesEnum.ground ||
      object.parent.name === ThreeGroupEnum.transform ||
      object.parent.name === ThreeGroupEnum.multiselect ||
      object.parent.name === PermitAssetNamesEnum.roofGroup ||
      object.parent.name === PermitAssetNamesEnum.wallGroup ||
      object.parent.name === PermitAssetNamesEnum.neighborWallGroup ||
      object.parent.name === PermitAssetNamesEnum.solarGroup ||
      object.parent.name === PermitAssetNamesEnum.neighborRoofGroup;

    while (!isParentGroup()) {
      object = object.parent;
    }
    return object;
  }
  public static parseCoordinaresForGround(
    instance: ThreeInstance | HouseSceneConfig,
    currentDrawing: Array<PointXY>
  ) {
    instance.currentCoordinates = FurbanUtil.deepCopy(currentDrawing);
    instance.currentCoordinates = FurbanUtil.parseCoordinatesFor2DAnd3D(
      instance.currentCoordinates,
      1
    );

    instance.currentInflatedCoordinates = ThreeUtils.inflatePolygon(
      instance.currentCoordinates,
      15
    );

    instance.maxXPointFromArray = Math.abs(
      FurbanUtil.getMax(instance.currentCoordinates, 'X')
    );
    instance.minYPointFromArray = Math.abs(
      FurbanUtil.getMin(instance.currentCoordinates, 'Y')
    );
    instance.states = [
      {
        x: instance.maxXPointFromArray,
        y: 20,
        z: instance.minYPointFromArray,
      },
      { x: instance.maxXPointFromArray, y: 20, z: 0 },
      { x: 0, y: 20, z: 0 },
      { x: 0, y: 20, z: instance.minYPointFromArray },
    ];
  }

  public static convertFromPointXYTo3D(points: PointXY[]): Vector3[] {
    return points.map((point) => new Vector3(point.X, 0, point.Y));
  }

  public static convertFromPoint2DTo3D(points: Vector2[]): Vector3[] {
    return points.map((point) => new Vector3(point.x, 0, point.y));
  }

  public static setPositionAndRotationOnMesh(
    mesh: any,
    position: FurbanMeshPosition,
    reset: boolean
  ): void {
    mesh.position.set(
      position.position.x,
      position.position.y,
      position.position.z
    );

    if (reset) {
      mesh.rotation.set(0, 0, 0);
    }

    mesh.rotateY(-MathUtil.deg2rad(position.angle));
  }

  public static transformArrayIntoObject(array: number[]) {
    const arrayObject: any = {};
    for (const arrayItem of array) {
      arrayObject[arrayItem] = [];
    }
    return arrayObject;
  }

  public static isPointInsideThePolygon(
    point: Vector3,
    polygonCoordinates: Vector3[]
  ): boolean {
    const x = point.x;
    const y = point.z;
    let xi: number;
    let yi: number;
    let xj: number;
    let yj: number;
    let intersect: boolean;
    let inside = false;
    for (
      let i = 0, j = polygonCoordinates.length - 1;
      i < polygonCoordinates.length;
      j = i++
    ) {
      xi = polygonCoordinates[i].x;
      yi = polygonCoordinates[i].z;
      xj = polygonCoordinates[j].x;
      yj = polygonCoordinates[j].z;

      intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  }

  public static disposeThreeElement(element: any): void {
    for (let i = 0; i < element.children.length; i++) {
      element.children[i].traverse((child: any) => {
        if (child.geometry !== undefined) {
          child.geometry.dispose();
        }
        if (child.material !== undefined && child.material.length > 0) {
          for (let j = 0; j < child.material.length; j++) {
            if (child.material[j].map && child.material[j].map.texture) {
              child.material[j].map.texture.dispose();
            }
            child.material[j].dispose();
          }
        }
      });
      element.children[i] = undefined;
    }
  }

  public static getKeyOfObject(object: any, index: number): string {
    return Object.keys(object)[index];
  }

  public static convertFromEulerToAxisAngle(euler: Euler): string {
    const quat = new Quaternion(0, 0.8181140143466348, 0, -0.5750560490331653);

    quat.setFromEuler(euler);

    const axis = [0, 0, 0];
    const angle = 2 * Math.acos(quat.w);
    if (1 - quat.w * quat.w < 0.000001) {
      axis[0] = quat.x;
      axis[1] = quat.y;
      axis[2] = quat.z;
    } else {
      const s = Math.sqrt(1 - quat.w * quat.w);
      axis[0] = quat.x / s;
      axis[1] = quat.y / s;
      axis[2] = quat.z / s;
    }
    const real =
      '{ [ ' +
      this.toReal(axis[0]) +
      ', ' +
      this.toReal(axis[1]) +
      ', ' +
      this.toReal(axis[2]) +
      ' ], ' +
      this.toReal(this.toAngle(angle)) +
      ' }';
    return real;
  }

  public static getLockedInfoHTMLElement(): CSS2DObject {
    return this.getHTMLElementById('lockedInfo');
  }

  public static getControlsHTMLElement(): CSS2DObject {
    return this.getHTMLElementById('controlsHTML');
  }

  public static getCommentPopupHTMLElement(): CSS2DObject {
    return this.getHTMLElementById('pinPopupHTML');
  }

  public static getColorAndTexturePopupHTMLElement(): CSS2DObject {
    return this.getHTMLElementById('color-texture-popup-html');
  }

  public static createParagraphElement(
    text: string,
    position: Vector3
  ): CSS2DObject {
    const moonDiv = document.createElement('p');
    moonDiv.style.marginTop = '-24px';
    moonDiv.style.zIndex = '-1';
    moonDiv.style.color = TextureColorEnum.neutral1;
    moonDiv.textContent = text;

    const moonLabel = new CSS2DObject(moonDiv);
    moonLabel.position.set(position.x, position.y, position.z);
    return moonLabel;
  }

  public static checkIfExceedingRestrictions(
    object: any,
    instance: ThreeInstance
  ) {
    const maximumScale = {
      width:
        instance.customCubeMaxSize / Math.abs(object.geometry.parameters.width),
      depth:
        instance.customCubeMaxSize / Math.abs(object.geometry.parameters.depth),
      height:
        instance.customCubeMaxSize /
        Math.abs(object.geometry.parameters.height),
    };

    if (Math.abs(object.scale.y) > maximumScale.height) {
      object.scale.y = maximumScale.height;
    }
    if (Math.abs(object.scale.x) > maximumScale.width) {
      object.scale.x = maximumScale.width;
    }
    if (Math.abs(object.scale.z) > maximumScale.depth) {
      object.scale.z = maximumScale.depth;
    }
  }

  public static bringCustomObjectToPlane(
    object: Mesh,
    instance: ThreeInstance
  ) {
    const geometry = object.geometry as BoxGeometry;
    const height = geometry.parameters.height;
    const scale = Math.abs(object.scale.y);

    if (scale > 0) {
      if (instance.transformControls.object != undefined) {
        instance.transformControls.object.position.y = (height * scale) / 2;
      }
    }
  }

  public static checkProportionateScalling(
    object: Mesh,
    previousScaleX: number,
    previousScaleZ: number,
    changingValue: number
  ): number {
    const scaleX = Math.abs(object.scale.x);
    const scaleZ = Math.abs(object.scale.z);

    if (scaleX !== previousScaleX && scaleX !== changingValue) {
      object.scale.z = scaleX;
    } else if (scaleZ !== previousScaleZ) {
      object.scale.x = scaleZ;
    }
    return scaleX;
  }

  public static resetRotationIfExceeding = (
    object: Mesh,
    instance: ThreeInstance
  ) => {
    const angle = ThreeUtils.convertFromQuaternionToDegrees(object.quaternion);
    if (angle > 355) {
      object.rotation.set(0, 0, 0);
      object.updateMatrix();
      instance.transformControls.detach();
    }
  };

  public static convertProject3DModelToPathObject(
    object: Project3dModel,
    position: FurbanMeshPosition,
    idsNeeded: ProjectAndPathIds,
    isAdminOrPioneer: boolean,
    dpId?: string
  ): PathObject {
    const pathObject: PathObject = new PathObject();

    pathObject.angle = position.angle;
    pathObject.default = isAdminOrPioneer && !dpId;
    pathObject.name = object.objectType;
    pathObject.freeshapePoints = object.freeshapePoints;
    pathObject.freeshapeGroundMaterial = object.furban3DModel.objectLookId;
    pathObject.objId = object.furban3DModel.objectLookId;
    pathObject.pathId = idsNeeded.pathId;
    pathObject.placeOutside = object.furban3DModel.placeOutside;
    pathObject.projectId = idsNeeded.projectId;
    pathObject.safetyArea = object.furban3DModel.safetyArea;
    pathObject.verticalCollision = object.furban3DModel.verticalCollision;
    pathObject.position = JSON.stringify(position.position);
    pathObject.designProposalId = dpId;
    pathObject.published = isAdminOrPioneer;
    return pathObject;
  }

  public static convertPermitAssetToHouseAsset(
    object: PermitAsset,
    position: string,
    houseId?: any,
    rotation?: any,
    scale?: any
  ): HouseAsset {
    const houseAsset: HouseAsset = new HouseAsset();

    houseAsset.rotation = rotation;
    houseAsset.coordinates = position;
    houseAsset.scale = scale;
    houseAsset.asset = object;
    houseAsset.houseId = houseId;

    return houseAsset;
  }

  public static isDoubleTap(
    threeInstance: ThreeInstance | HouseSceneConfig
  ): boolean {
    if (threeInstance.mylatesttap) {
      const now = new Date().getTime();
      const timesince = now - threeInstance.mylatesttap;
      if (timesince < 200 && timesince > 0) {
        return true;
      }
    }
    return false;
  }

  public static getCenterFromMeshes(meshes: Mesh[]): Vector3 {
    const center = new Vector3();
    const count = meshes.length;
    for (let i = 0; i < count; i++) {
      center.add(meshes[i].position);
    }
    center.divideScalar(count);
    return center;
  }

  public static cloneMaterial(clonedScene: Group) {
    clonedScene.traverse((node) => {
      if ((<Mesh>node).isMesh) {
        (<any>node).material = (<any>node).material.clone();
      }
    });
  }

  public static setMoveMode(
    instance: ThreeInstance | HouseSceneConfig,
    showY: boolean = false
  ) {
    instance.transformControls.setMode(ThreeObjectControlsEnum.move);
    instance.transformControls.showY = showY;
    instance.transformControls.showX = true;
    instance.transformControls.showZ = true;
    if (instance instanceof HouseSceneConfig) {
      return;
    }
    instance.activeTab = ThreeObjectControlsEnum.move;
  }

  public static setRotateMode(instance: ThreeInstance) {
    instance.transformControls.setMode(ThreeObjectControlsEnum.rotate);
    instance.transformControls.showY = true;
    instance.transformControls.showX = false;
    instance.transformControls.showZ = false;
    instance.activeTab = ThreeObjectControlsEnum.rotate;
  }

  public static setScaleMode(instance: ThreeInstance, isCustom: boolean) {
    instance.transformControls.setMode(ThreeObjectControlsEnum.scale);
    instance.transformControls.showY = isCustom;
    instance.transformControls.showX = true;
    instance.transformControls.showZ = true;
    instance.activeTab = ThreeObjectControlsEnum.scale;
  }

  public static setLockedMode(instance: ThreeInstance) {
    instance.transformControls.showY = false;
    instance.transformControls.showX = false;
    instance.transformControls.showZ = false;
    instance.activeTab = ThreeObjectControlsEnum.lock;
  }

  public static multiplyPointsByFactor(
    points3D: Vector3[],
    factor: number = 1
  ): Vector3[] {
    return points3D.map(
      (point: Vector3) =>
        new Vector3(point.x * factor, point.y, point.z * factor)
    );
  }

  public static convertPoints3DTo2D(
    points3D: Vector3[],
    factor: number = 1
  ): Vector2[] {
    const points2D: Vector2[] = [];
    points3D.forEach((point: Vector3) => {
      points2D.push(new Vector2(point.x * factor, point.z * factor));
    });
    return points2D;
  }

  public static getPositionConsideringCustomDesign(
    positionOnDefaultPlane: Vector3,
    customDesign: Group
  ): Vector3 {
    if (!customDesign) {
      return positionOnDefaultPlane;
    }
    const ray = new Raycaster();
    const origin = new Vector3();
    const direction = new Vector3(0, -1, 0);

    origin.set(positionOnDefaultPlane.x, 50, positionOnDefaultPlane.z);
    ray.set(origin, direction);

    const intersectionWithCustomObject = ray.intersectObjects(
      customDesign.children,
      true
    );
    if (intersectionWithCustomObject.length !== 0) {
      const positionOnCustomObject = new Vector3(
        intersectionWithCustomObject[0].point.x,
        intersectionWithCustomObject[0].point.y,
        intersectionWithCustomObject[0].point.z
      );

      return positionOnCustomObject;
    }
    return positionOnDefaultPlane;
  }

  public static getIntersectionOfEventWithGroundHavingCustomDesign(
    threeInstance: ThreeInstance,
    event: MouseEvent | TouchEvent | Touch
  ): Vector3 {
    const container = threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoordinates = ThreeUtils.getMouseCoordinatesInContainer(
      event,
      container
    );
    return ThreeUtils.getIntersectionOfCoordsWithGroundHavingCustomDesign(
      threeInstance,
      mouseCoordinates
    );
  }

  private static getIntersectionOfCoordsWithGroundHavingCustomDesign(
    threeInstance: ThreeInstance,
    coordinates: Vector2
  ): Vector3 {
    const intersectionWithInvisiblePlane =
      ThreeUtils.getRayIntersectionOfCoordsWithObjects(
        threeInstance,
        coordinates,
        [threeInstance.invisiblePlane]
      );
    return ThreeUtils.getPositionConsideringCustomDesign(
      intersectionWithInvisiblePlane,
      threeInstance.customDesign
    );
  }

  public static getIntersectionOfEventWithGroup(
    threeInstance: ThreeInstance | HouseSceneConfig,
    event: MouseEvent | Touch,
    group: Group = threeInstance.groundGroup
  ): Vector3 {
    const container = threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoordinates =
      'isSpiltScreenView' in threeInstance && threeInstance.isSpiltScreenView
        ? ThreeUtils.computeEventCoordinatesForSplitScreen(
            event,
            threeInstance.renderer
          )
        : ThreeUtils.getMouseCoordinatesInContainer(event, container);
    return ThreeUtils.getRayIntersectionOfCoordsWithObjects(
      threeInstance,
      mouseCoordinates,
      group.children
    );
  }

  public static getIntersectionOfEventWithMeshes(
    threeInstance: ThreeInstance | HouseSceneConfig,
    event: MouseEvent | Touch,
    objects: Mesh[]
  ): any {
    const container = threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoordinates =
      'isSpiltScreenView' in threeInstance && threeInstance.isSpiltScreenView
        ? ThreeUtils.computeEventCoordinatesForSplitScreen(
            event,
            threeInstance.renderer
          )
        : ThreeUtils.getMouseCoordinatesInContainer(event, container);
    return ThreeUtils.getRayIntersectionWithObjects(
      threeInstance,
      mouseCoordinates,
      objects
    );
  }

  public static getIntersectionPointForComment(
    event: MouseEvent | Touch,
    instance: ThreeInstance | HouseSceneConfig,
    groupsToCheck: any[]
  ): Intersection[] {
    let intersections = [];

    for (const group of groupsToCheck) {
      if (!group) {
        continue;
      }

      intersections = ThreeUtils.getIntersectionOfEventWithMeshes(
        instance,
        event,
        group.children
      );
      if (intersections && intersections.length > 0) {
        return intersections;
      }
    }
    return intersections;
  }

  public static getIntersectionArrayForPermitComment(
    event: MouseEvent | Touch,
    instance: ThreeInstance | HouseSceneConfig,
    groupsToCheck: any[]
  ): Intersection[] {
    let objectsArray: Mesh[] = [];
    groupsToCheck.forEach((element) => {
      if (!element?.children) {
        return;
      }
      objectsArray = objectsArray.concat(element.children);
    });

    return ThreeUtils.getIntersectionOfEventWithMeshes(
      instance,
      event,
      objectsArray
    );
  }

  public static isObjectIntersectingTheSelectedArea(
    addedObject: Object3D,
    canvasCoordinates: Vector3[],
    buildingsAreaCoordinates: Vector3[]
  ): boolean {
    const objectPosition = new Vector3(
      addedObject.position.x,
      0,
      -addedObject.position.z
    );
    return (
      (addedObject.userData['placeOutside'] &&
        ThreeUtils.isPointInsideThePolygon(
          objectPosition,
          buildingsAreaCoordinates
        )) ||
      (!addedObject.userData['placeOutside'] &&
        ThreeUtils.isPointInsideThePolygon(objectPosition, canvasCoordinates))
    );
  }

  private static getRayIntersectionOfCoordsWithObjects(
    threeInstance: ThreeInstance | HouseSceneConfig,
    coordinates: Vector2,
    objects: any[]
  ): Vector3 | null {
    if (threeInstance.camera) {
      threeInstance.raycaster.setFromCamera(coordinates, threeInstance.camera);
    }

    const intersectedObjects = threeInstance.raycaster.intersectObjects(
      objects,
      true
    );
    if (intersectedObjects && intersectedObjects.length > 0) {
      return new Vector3(
        intersectedObjects[0].point.x,
        intersectedObjects[0].point.y,
        intersectedObjects[0].point.z
      );
    } else {
      return null;
    }
  }

  private static getRayIntersectionWithObjects(
    threeInstance: ThreeInstance | HouseSceneConfig,
    coordinates: Vector2,
    objects: Mesh[]
  ): Intersection[] {
    if (threeInstance.camera) {
      threeInstance.raycaster.setFromCamera(coordinates, threeInstance.camera);
    }
    return threeInstance.raycaster.intersectObjects(objects, true);
  }

  public static getIntersectionFromCenterOfScreen(
    threeInstance: ThreeInstance
  ): any {
    const containerCenterCoordinates =
      ThreeUtils.getCoordinatesFromCenterOfContainer();
    return ThreeUtils.getIntersectionOfCoordsWithGroundHavingCustomDesign(
      threeInstance,
      containerCenterCoordinates
    );
  }

  public static computeDistanceBetweenTwoPoints(
    startingPoint: Vector3,
    finalPoint: Vector3
  ): number {
    return Math.sqrt(
      (startingPoint.x - finalPoint.x) * (startingPoint.x - finalPoint.x) +
        (startingPoint.z - finalPoint.z) * (startingPoint.z - finalPoint.z)
    );
  }

  public static setHexColorOnMaterial(mesh: Object3D, color: string) {
    if (!mesh) {
      return;
    }
    mesh.traverse((child) => {
      if (child instanceof Mesh) {
        (<any>child).material.emissive.set(color);
      }
    });
  }

  // eslint-disable-next-line max-len
  public static getIntersectionFromEvent(
    event: MouseEvent,
    objects: Object3D[],
    permitInstance: HouseSceneConfig | ThreeInstance
  ): Intersection[] {
    const container =
      permitInstance.renderer.domElement.getBoundingClientRect();
    const mouse = ThreeUtils.getMouseCoordinatesInContainer(event, container);
    permitInstance.raycaster.setFromCamera(mouse, permitInstance.camera);

    return permitInstance.raycaster.intersectObjects(objects, true);
  }

  public static getCustomPositionOnYAxis(
    intersects: Intersection[],
    permitInstance: HouseSceneConfig,
    service: PermitThreeService,
    diff?: number
  ): number {
    let yPos = intersects[0].point.y;
    if (!isNaN(permitInstance.lockedValueY)) {
      yPos = permitInstance.lockedValueY;
    }

    const floorHeight = service.house.floorHeight;
    const noOfFloors = service.house.numberOfFloors;
    const houseHeight = noOfFloors * (floorHeight / 100) + noOfFloors * 0.2;
    const maxHeight = houseHeight - diff;
    const minHeight = diff;

    if (yPos > maxHeight) {
      yPos = maxHeight;
    }

    if (yPos < minHeight) {
      yPos = minHeight;
    }

    return yPos;
  }

  public static snapAssetToObjectFace(
    asset: Group,
    intersects: Intersection[],
    yPos: number,
    shouldUpdateLookAt?: boolean,
    resetRotationNormal?: boolean
  ): void {
    const normalMatrix = new Matrix3();
    const worldNormal = new Vector3();
    const lookAtVector = new Vector3();

    const faceNormal = intersects[0].face.normal;
    const objectMatrixWorld = intersects[0].object.matrixWorld;
    const intersectionPoint = intersects[0].point;

    /** Get Matrix3 from intersected object and copy that into another Matrix3 */
    normalMatrix.getNormalMatrix(objectMatrixWorld);
    if (intersects[0]?.face) {
      worldNormal.copy(faceNormal).applyMatrix3(normalMatrix).normalize();
    }

    if (resetRotationNormal) {
      worldNormal.y = 0;
    }

    /** Set the position of the Asset and rotate to Vector3 by adding world normal*/
    asset.position.copy(intersectionPoint.setY(yPos));
    if (shouldUpdateLookAt) {
      asset.lookAt(lookAtVector.copy(intersectionPoint).add(worldNormal));
    }
  }

  public static snapObjectToFaceAndSetPosition(
    asset: Group,
    intersects: Intersection[]
  ): void {
    const normalMatrix = new Matrix3();
    const worldNormal = new Vector3();
    const lookAtVector = new Vector3();
    const faceNormal = intersects[0].face.normal;
    const objectMatrixWorld = intersects[0].object.matrixWorld;
    const intersectionPoint = intersects[0].point;

    /** Get Matrix3 from intersected object and copy that into another Matrix3 */
    asset.position.copy(intersectionPoint);
    normalMatrix.getNormalMatrix(objectMatrixWorld);
    if (intersects[0]?.face) {
      worldNormal.copy(faceNormal).applyMatrix3(normalMatrix).normalize();
    }

    asset.lookAt(lookAtVector.copy(intersectionPoint).add(worldNormal));
  }

  public static computeMergedGeometries(mesh: MeshBVH) {
    const geometries = [];
    mesh.updateMatrixWorld(true);
    mesh.traverse((c) => {
      if (c.geometry) {
        const cloned = c.geometry.clone();
        cloned.applyMatrix4(c.matrixWorld);
        for (const key in cloned.attributes) {
          if (key !== 'position') {
            cloned.deleteAttribute(key);
          }
        }
        geometries.push(cloned);
      }
    });

    const mergedGeometry = mergeGeometries(geometries, false);
    mergedGeometry['boundsTree'] = new MeshBVH(mergedGeometry, {
      lazyGeneration: false,
    });
    return mergedGeometry;
  }

  public static getVectorsFromShape(mesh: Mesh): Vector3[] {
    const position = mesh.geometry.attributes['position'];
    const vectors: Vector3[] = [];

    for (let i = 0; i < position.count; i++) {
      const vector = new Vector3();
      vector.fromBufferAttribute(position, i);
      vector.applyMatrix4(mesh.matrixWorld);
      vectors.push(vector);
    }

    return vectors;
  }

  public static generateHoleFromBoundingBox(vectors: Vector3[]): Path {
    const hole = new Path()
      .moveTo(vectors[0].x, -vectors[0].z)
      .lineTo(vectors[1].x, -vectors[1].z)
      .lineTo(vectors[2].x, -vectors[2].z)
      .lineTo(vectors[3].x, -vectors[3].z)
      .lineTo(vectors[0].x, -vectors[0].z);

    return hole;
  }

  public static generateHoleForCustomLayer(
    uploadedObject: Mesh | Object3D | Group
  ): Path {
    // 1. Clone object and reset rotation
    const cloneObj = uploadedObject.clone();
    cloneObj.rotation.copy(new Euler());

    // 2. Generate BoundingBox and Generate Shape
    const bbox = this.generateBoundingBox(cloneObj);
    const shape = this.createShapeFromBoundingBox(bbox);

    // 3, Create Shape Mesh
    const buffer = new ShapeGeometry(shape);
    const mesh = new Mesh(buffer);

    // 4. Store rotation and position of uploaded object
    const uploadedObjectRotation = uploadedObject.rotation;
    const uploadedObjectEulerRotation = new Euler(
      uploadedObjectRotation.x,
      uploadedObjectRotation.y,
      uploadedObjectRotation.z,
      uploadedObjectRotation.order
    );

    const uploadedObjectPosition = uploadedObject.position;
    const uploadedObjectVector3Position = new Vector3(
      uploadedObjectPosition.x,
      uploadedObjectPosition.y,
      uploadedObjectPosition.z
    );

    // 5. Rotate to horizontal position and apply rotation and position to mesh
    mesh.rotation.x = -Math.PI / 2;
    this.applyPositionAndRotationToMesh(mesh, true);

    // 6. Copy rotation and position to mesh and apply rotation and position to mesh
    mesh.position.copy(uploadedObjectVector3Position);
    mesh.rotation.copy(uploadedObjectEulerRotation);
    this.applyPositionAndRotationToMesh(mesh, false);

    const vectors = this.getVectorsFromShape(mesh);
    return this.generateHoleFromBoundingBox(vectors);
  }

  public static createShapeFromBoundingBox(bbox: Box3): Shape {
    const shape = new Shape();
    shape.moveTo(bbox.max.x, -bbox.max.z);
    shape.lineTo(bbox.max.x, -bbox.min.z);
    shape.lineTo(bbox.min.x, -bbox.min.z);
    shape.lineTo(bbox.min.x, -bbox.max.z);

    return shape;
  }

  public static applyPositionAndRotationToMesh(
    mesh: Mesh,
    centerGeometry: boolean
  ): void {
    mesh.updateMatrix();
    mesh.geometry.applyMatrix4(mesh.matrix);
    mesh.position.set(0, 0, 0);
    mesh.rotation.set(0, 0, 0);
    mesh.scale.set(1, 1, 1);
    if (centerGeometry) {
      mesh.geometry.center();
    }
    mesh.updateMatrix();
  }

  public static convertCustomUploadedMeshToCustomObject(
    mesh: Mesh,
    objectType: number,
    projectId: string
  ): Project3DUpload {
    const customObject: Project3DUpload = new Project3DUpload();
    customObject.position = JSON.stringify(mesh.position);
    customObject.rotation = JSON.stringify(mesh.rotation);
    customObject.projectId = projectId;
    customObject.objectType = objectType;
    customObject.extension = mesh.userData['extension'];
    return customObject;
  }

  public static generateHoleFromPoints(points: PointXY[]): Path {
    const pointsVector2: Vector2[] = [];

    points.forEach((element) => {
      pointsVector2.push(new Vector2(element.X, element.Y));
    });

    const path = new Path();

    return path.setFromPoints(pointsVector2);
  }
  private static updateCanvasInsideContainer(
    canvas: HTMLCanvasElement,
    openedSections: Opened3DSections
  ) {
    if (openedSections.openedNavigation && !openedSections.fullScreenMode) {
      canvas.width = document.getElementsByTagName('body')[0].clientWidth - 78;
    } else {
      canvas.width = document.getElementsByTagName('body')[0].clientWidth;
    }

    if (openedSections.openedMenu && !openedSections.viewMode) {
      const menuWidth: number = window.innerWidth > 900 ? 320 : 225;
      canvas.width = canvas.width - menuWidth;
    } else if (!openedSections.viewMode) {
      canvas.width = canvas.width - 20;
    }

    canvas.height = openedSections.fullScreenMode
      ? document.getElementsByTagName('body')[0].clientHeight
      : document.getElementsByTagName('furban-app-user-project')[0]
          .clientHeight;
  }

  public static updateCanvasOnDesignProposalDetails(canvas: HTMLCanvasElement) {
    const mainContainer = document.getElementsByClassName('main-container')[0];
    mainContainer.classList.add('no-scroll');
    canvas.width = mainContainer.clientWidth;
    canvas.height = mainContainer.clientHeight;
  }

  private static updateCanvasOnCardThreeContainer(canvas: HTMLCanvasElement) {
    canvas.width =
      document.getElementsByClassName('card-three-container')[0].clientWidth +
      78;
    canvas.height = document.getElementsByClassName(
      'card-three-container'
    )[0].clientHeight;
  }

  private static updateCanvasOnThreeContainer(canvas: HTMLCanvasElement) {
    canvas.width =
      document.getElementsByClassName('three-container')[0].clientWidth;
    canvas.height =
      document.getElementsByClassName('three-container')[0].clientHeight;
  }

  private static calculateAngle(pathObject: PathObject, object3D: Object3D) {
    let quaternion = object3D.quaternion;
    if (
      object3D.userData['name'] &&
      object3D.userData['name'] === ObjectTypeEnum.freeshape &&
      object3D.userData['rotated']
    ) {
      const cloneObj = object3D.clone();
      cloneObj.rotateY(Math.PI);
      quaternion = cloneObj.quaternion;
    }

    pathObject.angle = ThreeUtils.convertFromQuaternionToDegrees(quaternion);
  }

  private static computeFreeShapePoints(pathObject: PathObject, object3D: any) {
    const isGroundMaterial = FurbanUtil.isGroundMaterial(
      object3D.userData.objId
    );

    if (object3D.userData.name === ObjectTypeEnum.custom) {
      const dimensions = {
        width: object3D.geometry.parameters.width * object3D.scale.x,
        height: object3D.geometry.parameters.depth * object3D.scale.z,
        depth: object3D.geometry.parameters.height * object3D.scale.y,
      };
      pathObject.freeshapePoints = JSON.stringify(dimensions);
    } else if (isGroundMaterial) {
      this.computeFreeshapePointsForGroundMaterial(pathObject, object3D);
    }
  }

  private static computeFreeshapePointsForGroundMaterial(
    pathObject: PathObject,
    object3D: any
  ) {
    object3D.freeshapeGroundMaterial = object3D.userData.objId;
    switch (object3D.userData.name) {
      case ObjectTypeEnum.freeshape:
        pathObject.freeshapePoints = object3D.userData.freeshapePoints;
        break;
      case ObjectTypeEnum.square:
        pathObject.freeshapePoints = this.computeSquarePoints(object3D);
        break;
      case ObjectTypeEnum.elipse:
        pathObject.freeshapePoints = this.computeElipsePoints(object3D);
        break;
      default:
        break;
    }
  }

  private static computeSquarePoints(object3D: any): string {
    return JSON.stringify({
      x: Math.abs(object3D.geometry.parameters.width * object3D.scale.x),
      y: Math.abs(object3D.geometry.parameters.height * object3D.scale.z),
    });
  }

  private static computeElipsePoints(object3D: any): string {
    return JSON.stringify({
      x: Math.abs(
        object3D.geometry.parameters.shapes.curves[0].xRadius * object3D.scale.x
      ),
      y: Math.abs(
        object3D.geometry.parameters.shapes.curves[0].yRadius * object3D.scale.z
      ),
    });
  }

  private static setPropertiesToPathObject(
    pathObject: PathObject,
    object3D: Object3D
  ): void {
    const position = new Vector3();
    position.x = object3D.position.x;
    position.y = object3D.position.y;
    position.z = object3D.position.z;

    pathObject.position = JSON.stringify(position);
    pathObject.objId = object3D.userData['objId'];
    pathObject.projectId = object3D.userData['projectId'];
    pathObject.pathId = object3D.userData['pathId'];
    pathObject.pathObjsId = object3D.userData['pathObjsId'];
    pathObject.name = object3D.userData['name'];
    pathObject.safetyArea = object3D.userData['safetyArea'];
    pathObject.verticalCollision = object3D.userData['verticalCollision'];
    pathObject.default = object3D.userData['default'];
    pathObject.published = object3D.userData['published'];
    pathObject.isLocked = object3D.userData['isLocked'];
    pathObject.placeOutside = object3D.userData['placeOutside'];
    pathObject.designProposalId = object3D.userData['designProposalId'];
    pathObject.userId = object3D.userData['userId'];
  }

  private static getHTMLElementById(elementId: string): CSS2DObject {
    const moonDiv = document.getElementById(elementId);
    moonDiv.style.marginTop = '-8em';
    moonDiv.style.zIndex = '-1';
    moonDiv.style.pointerEvents = 'auto';
    const moonLabel = new CSS2DObject(moonDiv);
    moonLabel.position.set(0, 2, 0);
    return moonLabel;
  }

  public static generateBoundingBox(
    uploadedObject: Object3D | Group | Mesh
  ): Box3 {
    // Step 1 - compute all geometries together and generate a Three Mesh
    const uploadedObjectGeometries =
      ThreeUtils.computeMergedGeometries(uploadedObject);
    const uploadedObjectMesh = new Mesh(uploadedObjectGeometries);

    // Step 2 - Apply object matrix and reset position and rotation
    this.applyPositionAndRotationToMesh(uploadedObjectMesh, false);

    // Step 3 - Generate Bounding Box from the Mesh
    return new Box3().setFromObject(uploadedObjectMesh);
  }

  public static getPathObjectIds(objects: any[]): number[] {
    const objIds: number[] = [];
    objects.map((obj) => {
      if (!obj.userData?.['pathObjsId']) {
        return;
      }
      objIds.push(obj.userData['pathObjsId']);
    });
    return objIds;
  }

  public static changeMaterialColor(
    object: Mesh | Group,
    roofColor: string,
    wallColor?: string
  ): void {
    object.traverse((obj: any) => {
      if (obj.type && obj.type === 'Mesh') {
        if (obj.material.name.includes('furban_custom_roof')) {
          obj.material.color.set(roofColor);
        }

        if (!wallColor) {
          return;
        }

        if (obj.material.name.includes('Furban_custom_wall')) {
          obj.material.color.set(wallColor);
        }
      }
    });
  }
}
