import { OBB } from 'three/examples/jsm/math/OBB.js';
import {
  Object3D,
  Euler,
  Matrix4,
  Intersection,
  BoxGeometry,
  ShapeGeometry,
  ExtrudeGeometry,
} from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { AssetSkeletonProperties } from '../_models/asset-skeleton-properties';
import { AssetFirstLetterEnum } from '../_enum/asset-first-letter.enum';
import { AssetLabelEnum } from '../_enum/asset-label.enum';
import { PermitDefaultValues } from '../_enum/permit-default-values.enum';
import { HouseSceneConfig } from '../_models/house-config';
import { PermitThreeService } from '../_services/permit-three.service';
import { ThreeUtils } from '../_three-helpers/three.utils';
import { FurbanUtil } from './furbanUtil';
import { HouseAsset } from '../_models/house-asset';
import { TextureColorEnum } from '../_enum/texture-color.enum';
import { MathUtil } from './math.util';
import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';
import { ThreeGeometryBuilder } from '../_three-helpers/three-geometry-builder';
import { ThreeTextureBuilder } from '../_three-helpers/three-texture-builder';
import { House } from '../_models/house';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { MiddleVector } from '../_models/middle-vectors';
import { ReferenceVectors } from '../_models/reference-vectors';
import { PermitAssetTypeValues } from '../_constants/permit-asset-type-values';
import { PointXY } from '../_models/point-xy';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { HouseMaterial } from '../_models/house-material';
import { Vector2 } from 'three';
import { BufferAttribute } from 'three';
import { Vector3 } from 'three';
import { PerspectiveCamera } from 'three';
import { MeshLambertMaterial } from 'three';
import { Mesh } from 'three';
import { DoubleSide } from 'three';
import { Scene } from 'three';
import { TextureLoader } from 'three';
import { RepeatWrapping } from 'three';
import { Texture } from 'three';
import { Box3 } from 'three';
import { BufferGeometry } from 'three';
import { Group } from 'three';
import { MeshBasicMaterial } from 'three';
import { MeshPhongMaterial } from 'three';
import { Matrix3 } from 'three';
import { ShapeUtils } from 'three';
import { Path } from 'three';
import { Shape } from 'three';

export class PermitUtils {
  public static getPermitLabelColor(statusTranslationLabel: string): string {
    if (statusTranslationLabel == 'pending') {
      return ' var(--warning-2)';
    } else if (statusTranslationLabel == 'approved') {
      return ' var(--primary-10)';
    } else if (statusTranslationLabel == 'rejected') {
      return ' var(--alert-1)';
    } else if (statusTranslationLabel == 'required_changes') {
      return ' var(--accent-1)';
    }
  }

  public static offsetContour(offset, contour): Vector2[] {
    const result = [];

    offset = new BufferAttribute(new Float32Array([offset, 0, 0]), 3);

    for (let i = 0; i < contour.length; i++) {
      const v1 = new Vector2().subVectors(
        contour[i - 1 < 0 ? contour.length - 1 : i - 1],
        contour[i]
      );
      const v2 = new Vector2().subVectors(
        contour[i + 1 === contour.length ? 0 : i + 1],
        contour[i]
      );
      const angle = v2.angle() - v1.angle();
      const halfAngle = angle * 0.5;

      const hA = halfAngle;
      const tA = v2.angle() + Math.PI * 0.5;

      const shift = Math.tan(hA - Math.PI * 0.5);
      const shiftMatrix = new Matrix4().set(
        1,
        0,
        0,
        0,
        -shift,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );

      const tempAngle = tA;
      const rotationMatrix = new Matrix4().set(
        Math.cos(tempAngle),
        -Math.sin(tempAngle),
        0,
        0,
        Math.sin(tempAngle),
        Math.cos(tempAngle),
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );

      const translationMatrix = new Matrix4().set(
        1,
        0,
        0,
        contour[i].x,
        0,
        1,
        0,
        contour[i].y,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );

      const cloneOffset = offset.clone();

      cloneOffset.applyMatrix4(shiftMatrix);
      cloneOffset.applyMatrix4(rotationMatrix);
      cloneOffset.applyMatrix4(translationMatrix);
      result.push(new Vector2(cloneOffset.getX(0), cloneOffset.getY(0)));
    }

    return result;
  }

  public static getTopVertices(
    vertices: any[],
    constructionHeight: number
  ): Vector3[] {
    const verticesOnTop = [];
    const shape = PermitUtils.createBaseFrame(vertices);

    const geometry = new ShapeGeometry(shape);
    const mesh = new Mesh(geometry, new MeshLambertMaterial());
    mesh.rotation.x = -Math.PI / 2;
    mesh.updateMatrix();
    mesh.geometry.applyMatrix4(mesh.matrix);
    mesh.rotation.set(0, 0, 0);

    const positionAttribute = mesh.geometry.getAttribute('position');

    for (
      let vertexIndex = 0;
      vertexIndex < positionAttribute.count;
      vertexIndex++
    ) {
      const vertex = new Vector3();
      vertex.fromBufferAttribute(
        <BufferAttribute>positionAttribute,
        vertexIndex
      );
      vertex.y = constructionHeight;
      verticesOnTop.push(vertex);
    }

    return verticesOnTop;
  }

  public static getCenterOfPolygon(
    vertices: Vector2[],
    constructionHeight: number
  ): Vector3 {
    const accumulator = vertices.reduce(
      (prevVal: Vector2, curVal: Vector2) =>
        new Vector2(
          (Math.abs(prevVal.x) + Math.abs(curVal.x),
          Math.abs(prevVal.y) + Math.abs(curVal.y))
        )
    );

    return new Vector3(
      accumulator.x / vertices.length,
      constructionHeight,
      -(accumulator.y / vertices.length)
    );
  }

  public static getCenterOf3DPolygon(
    vertices: Vector3[],
    constructionHeight: number
  ): Vector3 {
    const accumulator = vertices.reduce(
      (prevVal: Vector3, curVal: Vector3) =>
        new Vector3(
          Math.abs(prevVal.x) + Math.abs(curVal.x),
          Math.abs(prevVal.y) + Math.abs(curVal.y),
          Math.abs(prevVal.z) + Math.abs(curVal.z)
        )
    );

    return new Vector3(
      accumulator.x / vertices.length,
      constructionHeight,
      -(accumulator.z / vertices.length)
    );
  }

  public static getCenter(vertices: PointXY[]): Vector3 {
    const accumulator = vertices.reduce(
      (prevVal: PointXY, curVal: PointXY) => ({
        X: Math.abs(prevVal.X) + Math.abs(curVal.X),
        Y: Math.abs(prevVal.Y) + Math.abs(curVal.Y),
      })
    );

    return new Vector3(
      accumulator.X / vertices.length,
      0,
      accumulator.Y / vertices.length
    );
  }

  public static setCanvasDimensionsTest(
    canvas: HTMLCanvasElement,
    camera: PerspectiveCamera
  ) {
    canvas.width = document.getElementById('three-cont').clientWidth;
    canvas.height = document.getElementById('three-cont').clientHeight;

    if (camera) {
      camera.aspect = ThreeUtils.getAspectRatio(canvas);
      camera.updateProjectionMatrix();
    }
  }

  public static parseCoordinates(coordinates: any[]): Vector2[] {
    const minX = FurbanUtil.getMin(coordinates, 'X');
    const minY = FurbanUtil.getMin(coordinates, 'Y');

    const parsedPoints = [];
    coordinates.forEach((element) => {
      const point = new Vector2();
      point.x = element.X - minX + 1;
      point.y = element.Y - minY + 1;
      parsedPoints.push(point);
    });

    return parsedPoints;
  }

  public static createExtrudeGeometry(
    points: any,
    height: number,
    punchingHole: boolean
  ): ExtrudeGeometry {
    const frame = this.createBaseFrame(points);

    // REFACTOR UVGenerator needs to pe initcap?
    const extrudeSettings = {
      depth: height,
      bevelEnabled: false,
      steps: 2,
    };

    if (punchingHole) {
      const hole = this.createHoleForFrame(points);
      frame.holes.push(hole);
    }

    return new ExtrudeGeometry(frame, extrudeSettings);
  }

  public static createHoleForFrame(points: any): Path {
    const offset = ShapeUtils.isClockWise(points) ? -0.2 : 0.2;

    const offsetPoints = PermitUtils.offsetContour(offset, points);
    const hole = new Path();
    hole.moveTo(offsetPoints[0].x, offsetPoints[0].y);
    for (let i = 1; i < offsetPoints.length; i++) {
      hole.lineTo(offsetPoints[i].x, offsetPoints[i].y);
    }

    return hole;
  }

  public static createBaseFrame(points: any): Shape {
    const frame = new Shape();
    frame.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      frame.lineTo(points[i].x, points[i].y);
    }

    return frame;
  }

  public static createMesh(
    extrudedGeometry: ExtrudeGeometry,
    material: MeshLambertMaterial
  ): Mesh {
    const wallMesh = new Mesh(extrudedGeometry, material);
    wallMesh.rotation.x = -Math.PI / 2;
    wallMesh.updateMatrix();
    wallMesh.geometry.applyMatrix4(wallMesh.matrix);
    wallMesh.rotation.set(0, 0, 0);

    return wallMesh;
  }

  public static createDivision(
    points: any,
    defaultDivision: number,
    color: number
  ): Mesh {
    const extrudedGeometry = PermitUtils.createExtrudeGeometry(
      points,
      defaultDivision,
      false
    );
    const material = new MeshLambertMaterial({
      side: DoubleSide,
      color: color,
    });
    const division = this.createMesh(extrudedGeometry, material);
    ThreeUtils.applyPositionAndRotationToMesh(division, true);
    division.castShadow = true;
    division.receiveShadow = true;
    return division;
  }

  public static getAssetURL(objectLookId: string): string {
    const baseAssetURL = 'assets/images/permit/';
    let customAssetURL: string;

    if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.windowFirstLetter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.windowLabel}/`;
    } else if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.dormerFirstLetter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.dormersLabel}/`;
    } else if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.doorFirstLetter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.doorLabel}/`;
    } else if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.terraceFencesFirstLtter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.terraceFencesLabel}/`;
    } else if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.solarPannelFirstLetter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.solarPanelsLabel}/`;
    } else if (
      this.isAssetFirstLetterEqualToLetter(
        objectLookId,
        AssetFirstLetterEnum.chimneysFirstLetter
      )
    ) {
      customAssetURL = `${AssetLabelEnum.chimneysLabel}/`;
    } else {
      console.error('Asset could not be found!');
    }

    return `${baseAssetURL}${customAssetURL}${objectLookId}/${objectLookId}.glb`;
  }

  public static isAssetFirstLetterEqualToLetter(
    objectLookId: string,
    firstLetter: string
  ): boolean {
    return objectLookId.startsWith(firstLetter);
  }

  public static setCameraState(instance: HouseSceneConfig): void {
    instance.centroid = PermitUtils.getCenter(instance.currentCoordinates);

    instance.cameraStates = [
      new Vector3(
        instance.centroid.x + instance.offsetCentroid,
        20,
        instance.centroid.z + instance.offsetCentroid
      ),
      new Vector3(
        instance.centroid.x + instance.offsetCentroid,
        20,
        instance.centroid.z - instance.offsetCentroid
      ),
      new Vector3(
        instance.centroid.x - instance.offsetCentroid,
        20,
        instance.centroid.z - instance.offsetCentroid
      ),
      new Vector3(
        instance.centroid.x - instance.offsetCentroid,
        20,
        instance.centroid.z + instance.offsetCentroid
      ),
    ];
  }

  public static getValuesFromParsedArray(
    instance: HouseSceneConfig,
    service: PermitThreeService
  ): void {
    instance.minValues = FurbanUtil.getMinXandY(
      service.house.processedCoordinatesForThree
    );
    instance.maxValues = FurbanUtil.getMaxXandY(
      service.house.processedCoordinatesForThree
    );
    instance.centroid = PermitUtils.getCenterOfPolygon(
      service.house.processedCoordinatesForThree as Vector2[],
      0
    );
  }

  public static loadAndCloneAssetsWithTheSameLookId(
    loader: GLTFLoader,
    houseAssets: HouseAsset[],
    objectLookId: string,
    scene: Scene,
    house: House
  ): void {
    const assetURL = PermitUtils.getAssetURL(objectLookId);
    const assetObjectsGroup = scene.getObjectByName(
      PermitAssetNamesEnum.assetGroup
    );
    const roofAssetGroup = scene.getObjectByName(
      PermitAssetNamesEnum.roofAssetGroup
    );
    const dormersGroup = scene.getObjectByName(
      PermitAssetNamesEnum.dormersGroup
    );
    const solarPanelsGroup = scene.getObjectByName(
      PermitAssetNamesEnum.solarGroup
    );

    loader.load(assetURL, (gltf) => {
      const loadedAsset = gltf.scene;
      for (const houseAsset of houseAssets) {
        const cloned3DObject = loadedAsset.clone();
        ThreeUtils.cloneMaterial(cloned3DObject);
        if (
          houseAsset.asset.assetType.permitAssetTypeId ===
          PermitAssetTypeValues.FOR_DORMERS.id
        ) {
          const roofColor = houseAsset.color;
          ThreeUtils.changeMaterialColor(
            cloned3DObject,
            roofColor,
            house.houseColor
          );
        }

        this.setupDefaultOBBonGeometryLevel(cloned3DObject);
        cloned3DObject.visible = true;
        cloned3DObject.position.copy(houseAsset.coordinates as Vector3);
        cloned3DObject.rotation.copy(houseAsset.rotation as Euler);
        cloned3DObject.userData['houseAsset'] = houseAsset;
        cloned3DObject.userData['assetType'] =
          houseAsset.asset.assetType.permitAssetTypeId;
        ThreeUtils.setHexColorOnMaterial(
          cloned3DObject,
          TextureColorEnum.neutral0
        );

        switch (houseAsset.asset.assetType.permitAssetTypeId) {
          case PermitAssetTypeValues.FOR_DORMERS.id:
            dormersGroup.add(cloned3DObject);
            break;
          case PermitAssetTypeValues.FOR_PANELS.id:
            solarPanelsGroup.add(cloned3DObject);
            break;
          case PermitAssetTypeValues.FOR_ROOF.id:
            roofAssetGroup.add(cloned3DObject);
            break;
          default:
            assetObjectsGroup.add(cloned3DObject);
            break;
        }
      }
    });
  }

  public static loadPermitAssetFromURL(
    instance: HouseSceneConfig,
    service: PermitThreeService
  ): void {
    const assetURL = PermitUtils.getAssetURL(service.assetToAdd.objectLookId);
    instance.loader.load(assetURL, (gltf) => {
      service.loadedAsset = gltf.scene;
      this.setupDefaultOBBonGeometryLevel(service.loadedAsset);
      service.loadedAsset.visible = false;
      service.loadedAsset.userData['asset'] = service.assetToAdd;
      service.loadedAsset.userData['houseId'] = service.house.houseId;
      ThreeUtils.setHexColorOnMaterial(
        service.loadedAsset,
        TextureColorEnum.mildBlue
      );
      instance.scene.add(service.loadedAsset);
    });
  }

  public static setupDefaultOBBonGeometryLevel(mesh: Object3D): void {
    mesh.userData['obb'] = new OBB(new Vector3(), new Vector3(), new Matrix3());
    let selectMesh;
    if (mesh.children.length > 0) {
      selectMesh = mesh.children[0];
    } else {
      selectMesh = mesh;
    }
    const geometry = (<Mesh>selectMesh).geometry;
    (<BufferGeometry>geometry).userData['obb'] = new OBB(
      new Vector3(),
      new Vector3(),
      new Matrix3()
    );
    const box = new Box3().setFromObject(mesh);
    const size = new Vector3();
    (<BufferGeometry>geometry).userData['obb'].halfSize
      .copy(box.getSize(size))
      .multiplyScalar(0.5);
  }

  public static changeMaterialColor(
    object: Object3D,
    materialName: string,
    color: string
  ): void {
    /** We need the type any since typescript doesn't recognise name (altough it exists) */
    object.traverse((node: any) => {
      if ((<Mesh>node).isMesh) {
        if (node.material.name === materialName) {
          (<any>node).material.color.set(color);
        }
      }
    });
  }

  public static loadTexture(
    textureURL: string,
    repeat = new Vector2(1, 1)
  ): Texture {
    const texture = new TextureLoader().load(textureURL);
    //texture.encoding = sRGBEncoding;
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.repeat.set(repeat.x, repeat.y);
    return texture;
  }

  public static changeMaterialTexture(
    scene: Scene,
    groupName: string,
    materialName: string,
    textureURL: string,
    repeat?: Vector2
  ): void {
    const group = scene.getObjectByName(groupName);
    this.changeMaterialTextureForGroup(group, materialName, textureURL, repeat);
  }

  public static changeMaterialTextureForGroup(
    group: Object3D,
    materialName: string,
    textureURL: string,
    repeat?: Vector2
  ): void {
    const texture = this.loadTexture(textureURL, repeat);

    const setRepeatOnMap = (node: Mesh): void => {
      const material = node.material as MeshPhongMaterial;
      material.map.repeat.x = material.map.repeat.x * node.scale.x;
      material.map.repeat.y = material.map.repeat.y * node.scale.y;
    };

    /** We need the type any since typescript doesn't recognise name (altough it exists) */
    group.traverse((node: any) => {
      if ((<Mesh>node).isMesh) {
        if (node.material.name === materialName) {
          node.material.map.dispose();
          node.material.map = texture;
          setRepeatOnMap(node);
          node.material.needsUpdate = true;
        }
      }
    });
  }

  public static removeGroups(scene: Scene, groupNames: string[]): void {
    for (const groupName of groupNames) {
      const group = scene.getObjectByName(groupName);
      scene.remove(group);
    }
  }

  public static changeMaterialColorOnGroup(
    scene: Scene,
    groupName: string,
    materialName: string,
    color: string
  ): void {
    const group = scene.getObjectByName(groupName);
    this.changeMaterialColor(group, materialName, color);
  }

  public static getParentObject(object, groupNameArray: string[]) {
    while (!this.stringInArray(object.parent.name, groupNameArray)) {
      object = object.parent;
    }
    return object;
  }

  public static intersectsGroups(
    intersectionList: Intersection[],
    arrayOfGroupNames: string[]
  ): boolean {
    const meshObject = PermitUtils.getParentObject(
      intersectionList[0].object,
      this.getGroupNamesForIntersectionCheck()
    );

    return PermitUtils.stringInArray(meshObject.parent.name, arrayOfGroupNames);
  }

  private static getGroupNamesForIntersectionCheck(): string[] {
    return [
      PermitAssetNamesEnum.wallGroup,
      PermitAssetNamesEnum.roofGroup,
      PermitAssetNamesEnum.assetGroup,
      PermitAssetNamesEnum.ground,
      PermitAssetNamesEnum.roofAssetGroup,
      PermitAssetNamesEnum.extensionGroup,
      PermitAssetNamesEnum.dormersGroup,
      PermitAssetNamesEnum.solarGroup,
    ];
  }

  public static stringInArray(
    stringName: string,
    stringArray: string[]
  ): boolean {
    return stringArray.indexOf(stringName) > -1;
  }

  public static getControlsHTMLElement(): CSS2DObject {
    const controlsDiv = document.getElementById('controlsHTML');
    controlsDiv.style.marginTop = '-8em';
    controlsDiv.style.zIndex = '-1';
    controlsDiv.style.pointerEvents = 'auto';
    const controlsLabel = new CSS2DObject(controlsDiv);
    controlsLabel.position.set(0, 1, 0);
    return controlsLabel;
  }

  public static generateVerticalSkeleton(
    permitInstance: HouseSceneConfig,
    topVertices: any[],
    constructionHeight: number
  ): void {
    const geometry = new BoxGeometry(
      PermitDefaultValues.skeletonWidth,
      constructionHeight,
      PermitDefaultValues.skeletonWidth
    );

    const material = new MeshBasicMaterial();
    const cylinder = new Mesh(geometry, material);

    topVertices.forEach((element) => {
      const cloneMesh = cylinder.clone();
      cloneMesh.position.x = element.x;
      cloneMesh.position.y = constructionHeight / 2;
      cloneMesh.position.z = element.z;
      cloneMesh.userData['vertical'] = true;
      cloneMesh.visible = false;
      this.setupDefaultOBBonGeometryLevel(cloneMesh);
      cloneMesh.userData['obb'] = new OBB(
        new Vector3(),
        new Vector3(),
        new Matrix3()
      );
      permitInstance.intersectionHelpers.add(cloneMesh);
    });
  }

  public static getPropertiesForBaseSkeleton(
    vector1: Vector3,
    vector2: Vector3
  ): AssetSkeletonProperties {
    const distanceBetween = vector1.distanceTo(vector2);
    const middlePoint = new Vector3(0, 0, 0);
    middlePoint.x = (vector1.x + vector2.x) / 2;
    middlePoint.z = (vector1.z + vector2.z) / 2;

    const thirdPoint = new Vector3(0, 0, 0);
    thirdPoint.x = middlePoint.x;
    thirdPoint.z = middlePoint.z - distanceBetween / 2;

    const radians = MathUtil.getRadiansFromPoints(
      vector2.x,
      Math.abs(vector2.z),
      middlePoint.x,
      Math.abs(middlePoint.z),
      thirdPoint.x,
      Math.abs(thirdPoint.z)
    );

    return new AssetSkeletonProperties(distanceBetween, middlePoint, radians);
  }

  public static setHorizontalSkeleton(
    permitInstance: HouseSceneConfig,
    vertices: any[],
    constructionHeight: number
  ): void {
    const firstPoint = vertices[0];
    const lastPoint = vertices[vertices.length - 1];
    this.addSegmentSkeleton(
      permitInstance,
      firstPoint,
      lastPoint,
      constructionHeight
    );

    for (let i = 0; i < vertices.length - 1; i++) {
      const firstIteration = vertices[i];
      const secondIteration = vertices[i + 1];
      this.addSegmentSkeleton(
        permitInstance,
        firstIteration,
        secondIteration,
        constructionHeight
      );
    }
  }

  public static addSegmentSkeleton(
    permitInstance: HouseSceneConfig,
    firstPoint: Vector3,
    secondPoint: Vector3,
    constructionHeight: number
  ): void {
    const properties: AssetSkeletonProperties =
      this.getPropertiesForBaseSkeleton(firstPoint, secondPoint);
    const geometry = new BoxGeometry(
      PermitDefaultValues.skeletonWidth,
      PermitDefaultValues.skeletonWidth,
      properties.distance
    );
    const material1 = new MeshBasicMaterial();
    const wall = new Mesh(geometry, material1);
    wall.position.x = properties.position.x;
    wall.position.z = properties.position.z;
    wall.rotateY(properties.rotation);
    wall.visible = false;
    wall.userData['vertical'] = false;
    permitInstance.intersectionHelpers.add(wall);

    const cloned = wall.clone();
    cloned.position.y = constructionHeight;
    cloned.visible = false;
    cloned.userData['vertical'] = false;
    permitInstance.intersectionHelpers.add(cloned);
  }

  public static getCollindingNode(
    selection: Group,
    intersectionHelpers: Object3D[]
  ): Object3D {
    let collidedNode = null;
    for (const node of intersectionHelpers) {
      if (selection.uuid === node.uuid) {
        continue;
      }
      const collision = this.detectCollisionOBB(node, selection);
      if (collision) {
        collidedNode = node;
        break;
      }
    }

    return collidedNode;
  }

  public static blockMovementOnAxis(
    selection: Object3D,
    collidedNode: Object3D,
    lastPosition: Vector3,
    lastRotation: Euler
  ): void {
    if (!collidedNode) {
      return;
    }
    this.blockToLastPosition(
      selection,
      collidedNode,
      lastPosition,
      lastRotation
    );
  }

  public static blockMovementOnAxisIfIntersectsOtherObject(
    selection: Object3D,
    collidedNode: Object3D,
    lastPosition: Vector3,
    lastRotation: Euler
  ): void {
    if (!collidedNode) {
      return;
    }
    this.blockToLastPosition(
      selection,
      collidedNode,
      lastPosition,
      lastRotation
    );
  }

  public static checkIfExceedingLimits(
    wallObject: Object3D,
    selection: Group
  ): void {
    const bbox = new Box3().setFromObject(selection);
    const diff = (bbox.max.y - bbox.min.y) / 2;
    const constructionHeight = wallObject.userData['constructionHeight'];
    if (selection.position.y > constructionHeight - diff) {
      selection.position.y = constructionHeight - diff;
    } else if (selection.position.y < diff) {
      selection.position.y = diff;
    }
  }

  public static detectCollisionOBB(
    object1: Object3D,
    object2: Object3D
  ): boolean {
    object1.updateMatrix();
    object1.updateMatrixWorld();

    object2.updateMatrix();
    object2.updateMatrixWorld();

    const subMesh1 = this.getSubMesh(object1);
    const subMesh2 = this.getSubMesh(object2);

    const objectGeometry1 = subMesh1.geometry as BufferGeometry;
    const objectGeometry2 = subMesh2.geometry as BufferGeometry;

    object1.userData['obb'].copy(objectGeometry1.userData['obb']);
    object2.userData['obb'].copy(objectGeometry2.userData['obb']);

    object1.userData['obb'].applyMatrix4(object1.matrixWorld);
    object2.userData['obb'].applyMatrix4(object2.matrixWorld);

    objectGeometry1.computeBoundingBox();
    objectGeometry2.computeBoundingBox();

    return object1.userData['obb'].intersectsOBB(object2.userData['obb']);
  }

  public static getSubMesh(object: Object3D): Mesh {
    let subMesh;
    if (object.children.length > 0) {
      subMesh = object.children[0];
    } else {
      subMesh = object;
    }
    return subMesh;
  }

  public static detectCollision(object1: Object3D, object2: Object3D): boolean {
    const bbox = new Box3().setFromObject(object1);
    const bbox2 = new Box3().setFromObject(object2);
    return bbox.intersectsBox(bbox2);
  }

  public static getHouseAssetFromGroupUserData(object: Object3D): HouseAsset {
    const houseAsset = new HouseAsset();
    houseAsset.asset = object.userData['houseAsset'].asset;
    houseAsset.houseAssetsId = object.userData['houseAsset'].houseAssetsId;
    houseAsset.houseId = object.userData['houseAsset'].houseId;
    houseAsset.coordinates = JSON.stringify(object.position);
    houseAsset.rotation = JSON.stringify(object.rotation);
    houseAsset.scale = JSON.stringify(object.scale);
    houseAsset.color = object.userData['houseAsset'].color;
    houseAsset.material = object.userData['houseAsset'].material;

    return houseAsset;
  }

  public static deserializeHouseAssets(
    houseAssets: HouseAsset[]
  ): HouseAsset[] {
    houseAssets.forEach((element) => {
      element.coordinates = JSON.parse(element.coordinates as string);
      element.rotation = JSON.parse(element.rotation as string);
      element.scale = JSON.parse(element.scale as string);
    });
    return houseAssets;
  }

  public static getURLForMaterials(houseMaterial: HouseMaterial): string {
    return `url('${houseMaterial.materialIcon}') center / contain no-repeat`;
  }

  public static getPointInBetweenByPerc(
    pointA: Vector3,
    pointB: Vector3,
    percentage: number
  ): Vector3 {
    let dir = pointB.clone().sub(pointA);
    const len = dir.length();
    dir = dir.normalize().multiplyScalar(len * percentage);
    return pointA.clone().add(dir);
  }

  public static getMidPointBetweenTwoVectors(
    pointA: Vector3,
    pointB: Vector3
  ): Vector3 {
    const middlePoint = new Vector3();
    middlePoint.x = (pointA.x + pointB.x) / 2;
    middlePoint.y = (pointA.y + pointB.y) / 2;
    middlePoint.z = (pointA.z + pointB.z) / 2;

    return middlePoint;
  }

  public static getSegmentsWithMiddleVectors(
    vertices: Vector3[],
    position: number
  ): MiddleVector[] {
    const segments: MiddleVector[] = [];

    vertices.forEach((element, index) => {
      let nextElement = vertices[index + 1];
      if (!nextElement) {
        nextElement = vertices[0];
      }

      const distance = element.distanceTo(nextElement);
      const middlePoint = PermitUtils.getMidPointBetweenTwoVectors(
        element,
        nextElement
      );
      middlePoint.y = position + 2;
      const vectors = [element, nextElement];
      segments.push(new MiddleVector(index, vectors, distance, middlePoint));
    });

    return segments.sort((a, b) => a.distance - b.distance);
  }

  public static generateFirstPart(
    segment: MiddleVector,
    point1: Vector3,
    point2: Vector3,
    roofPositions: number[],
    roofUvs: number[]
  ): void {
    const referencePoints = this.getNearestAndFarestPointFromVector(
      point1,
      segment.vectors[0],
      segment.vectors[1]
    );

    // We will leave this for tesing purpose - will de deleted if everything is allright

    // // First triangle
    // roofPositions.push(point2.x, point2.y, point2.z);
    // roofUvs.push(0, 0);
    // roofPositions.push(point1.x, point1.y, point1.z);
    // roofUvs.push(1, 0);
    // roofPositions.push(referencePoints.first.x, referencePoints.first.y, referencePoints.first.z);
    // roofUvs.push(1, 1);

    // // Second triangle
    // roofPositions.push(point2.x, point2.y, point2.z);
    // roofUvs.push(0, 0);
    // roofPositions.push(referencePoints.first.x, referencePoints.first.y, referencePoints.first.z);
    // roofUvs.push(1, 1);
    // roofPositions.push(referencePoints.second.x, referencePoints.second.y, referencePoints.second.z);
    // roofUvs.push(0, 1);

    // First triangle
    roofPositions.push(
      referencePoints.first.x,
      referencePoints.first.y,
      referencePoints.first.z
    );
    roofUvs.push(0, 0);
    roofPositions.push(point1.x, point1.y, point1.z);
    roofUvs.push(1, 0);
    roofPositions.push(point2.x, point2.y, point2.z);
    roofUvs.push(1, 1);

    // Second triangle
    roofPositions.push(
      referencePoints.first.x,
      referencePoints.first.y,
      referencePoints.first.z
    );
    roofUvs.push(0, 0);
    roofPositions.push(point2.x, point2.y, point2.z);
    roofUvs.push(1, 1);
    roofPositions.push(
      referencePoints.second.x,
      referencePoints.second.y,
      referencePoints.second.z
    );
    roofUvs.push(0, 1);
  }

  public static generateSecondPart(
    segment: MiddleVector,
    point1: Vector3,
    point2: Vector3,
    roofPositions: number[],
    roofUvs: number[]
  ): void {
    const referencePoints = this.getNearestAndFarestPointFromVector(
      point1,
      segment.vectors[0],
      segment.vectors[1]
    );

    // First triangle
    roofPositions.push(point2.x, point2.y, point2.z);
    roofUvs.push(0, 0);
    roofPositions.push(point1.x, point1.y, point1.z);
    roofUvs.push(1, 0);
    roofPositions.push(
      referencePoints.first.x,
      referencePoints.first.y,
      referencePoints.first.z
    );
    roofUvs.push(1, 1);

    // Second triangle
    roofPositions.push(point2.x, point2.y, point2.z);
    roofUvs.push(0, 0);
    roofPositions.push(
      referencePoints.first.x,
      referencePoints.first.y,
      referencePoints.first.z
    );
    roofUvs.push(1, 1);
    roofPositions.push(
      referencePoints.second.x,
      referencePoints.second.y,
      referencePoints.second.z
    );
    roofUvs.push(0, 1);
  }

  public static generateFirstPart1(
    points: Vector3[],
    roofPoints: Vector3[],
    roofPositions: number[],
    roofUvs: number[]
  ): void {
    // First triangle
    roofPositions.push(points[0].x, points[0].y, points[0].z);
    roofUvs.push(0, 0);
    roofPositions.push(points[1].x, points[1].y, points[1].z);
    roofUvs.push(1, 0);
    roofPositions.push(roofPoints[0].x, roofPoints[0].y, roofPoints[0].z);
    roofUvs.push(1, 1);

    // Second triangle
    roofPositions.push(points[0].x, points[0].y, points[0].z);
    roofUvs.push(0, 0);
    roofPositions.push(roofPoints[0].x, roofPoints[0].y, roofPoints[0].z);
    roofUvs.push(1, 1);
    roofPositions.push(roofPoints[1].x, roofPoints[1].y, roofPoints[1].z);
    roofUvs.push(0, 1);
  }

  public static generateSecondPart2(
    points: Vector3[],
    roofPoints: Vector3[],
    roofPositions: number[],
    roofUvs: number[]
  ): void {
    // First triangle
    roofPositions.push(points[2].x, points[2].y, points[2].z);
    roofUvs.push(1, 1);
    roofPositions.push(points[3].x, points[3].y, points[3].z);
    roofUvs.push(0, 1);
    roofPositions.push(roofPoints[1].x, roofPoints[1].y, roofPoints[1].z);
    roofUvs.push(0, 0);

    // Second triangle
    roofPositions.push(roofPoints[1].x, roofPoints[1].y, roofPoints[1].z);
    roofUvs.push(0, 0);
    roofPositions.push(roofPoints[0].x, roofPoints[0].y, roofPoints[0].z);
    roofUvs.push(1, 0);
    roofPositions.push(points[2].x, points[2].y, points[2].z);
    roofUvs.push(1, 1);
  }

  public static getNearestAndFarestPointFromIndex(
    segment1: MiddleVector,
    segment2: MiddleVector
  ): ReferenceVectors {
    const leftPoint =
      segment1.index < segment2.index ? segment1.middle : segment2.middle;
    const rightPoint =
      segment1.index < segment2.index ? segment2.middle : segment1.middle;

    return new ReferenceVectors(leftPoint, rightPoint);
  }

  public static setupExtensionMesh(
    house: House,
    position?: string | Vector3,
    rotation?: string | Euler,
    scale: Vector3 = new Vector3(1, 1, 1)
  ): any {
    // Here it is will dynamic when transform will be added
    const parsedDimensions = { width: 3, height: 3, depth: 3 };

    const geometry =
      ThreeGeometryBuilder.createCustomShapeGeometry(parsedDimensions);
    const material = ThreeTextureBuilder.createExtensionTexture(
      house.houseMaterial.backgroundImage,
      house.houseColor,
      parsedDimensions,
      scale
    );
    const customShapeMesh = new Mesh(geometry, material);
    customShapeMesh.name = PermitAssetTypeValues.FOR_EXTENSION.name;
    if (position && rotation) {
      customShapeMesh.position.copy(position as Vector3);
      customShapeMesh.rotation.copy(rotation as Euler);
    }

    if (scale) {
      customShapeMesh.scale.copy(scale);
    }
    customShapeMesh.geometry.translate(0, 0, 1.5);
    this.setupDefaultOBBonGeometryLevel(customShapeMesh);
    return customShapeMesh;
  }

  private static getNearestAndFarestPointFromVector(
    point: Vector3,
    referencePointA: Vector3,
    referencePointB: Vector3
  ): ReferenceVectors {
    const distance1 = point.distanceTo(referencePointA);
    const distance2 = point.distanceTo(referencePointB);
    const nearestPoint =
      distance1 < distance2 ? referencePointA : referencePointB;
    const farestPoint =
      distance1 < distance2 ? referencePointB : referencePointA;

    return new ReferenceVectors(nearestPoint, farestPoint);
  }

  private static blockToLastPosition(
    selection: Object3D,
    collidedNode: Object3D,
    lastPosition: Vector3,
    lastRotation: Euler
  ): void {
    if (!collidedNode.userData['vertical']) {
      selection.position.y = lastPosition.y;
    }
    selection.position.x = lastPosition.x;
    selection.position.z = lastPosition.z;
    selection.rotation.copy(lastRotation);
  }

  public static addTransformControls(
    houseSceneInstance: HouseSceneConfig
  ): void {
    houseSceneInstance.transformControls = new TransformControls(
      houseSceneInstance.camera,
      houseSceneInstance.renderer.domElement
    );
    houseSceneInstance.transformControls.name = 'transform';
    houseSceneInstance.transformControls.setMode('scale');
    houseSceneInstance.transformGroup.add(houseSceneInstance.transformControls);
  }
}
