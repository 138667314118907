import { Color, DirectionalLight, Group, IcosahedronGeometry, Mesh, MeshBasicMaterial, Object3D, Quaternion, Vector2, Vector3 } from 'three';
import { HouseSceneConfig } from '../_models/house-config';
import { PermitView } from '../_models/permit-view';
import { SolarOrientationCalculator } from './solar-orientation-calculator.utils';
import { ThreeUtils } from './three.utils';

export class ThreeSunBuilder {
  // Latitude and longtitude of the current loTcation on the world
  coordinates = new Vector2();
  // The unit vector that is pointing the north in the scene
  north = new Vector3();
  // The unit vector that is pointing the east in the scene
  east = new Vector3();
  // The unit vector that is pointing the ground in the scene, same as gravity
  nadir = new Vector3();
  //The distance of the directional light from this object and it's target.
  sunDistance: number;
  // The azimuth of the sun. Starts from the north, clockwise. In radians.
  azimuth = 0.0;
  // The elevation of the sun. Starts from the horizon. In radians.
  elevation = 0.0;
  // Local date and time
  localDate = new Date();
  //Light will be child of this object
  hingeObject = new Group();

  directionalLight: DirectionalLight;

  houseInstance: HouseSceneConfig | PermitView;
  sunSphere: Object3D;

  constructor(
    instance: HouseSceneConfig | PermitView,
    coordinates: Vector2,
    north: Vector3,
    east: Vector3,
    nadir: Vector3,
    sunDistance: number = 200
  ) {
    this.directionalLight = instance.directionalLight;
    this.coordinates = coordinates;
    this.north = north;
    this.houseInstance = instance;
    this.east = east;
    this.nadir = nadir;
    this.sunDistance = sunDistance;
    this.azimuth = 0;
    this.elevation = 0;
    this.localDate = new Date();
    this.hingeObject = new Group();
    this.createSunSphere();

    this.houseInstance.scene.add(this.hingeObject);
    this.houseInstance.scene.remove(this.directionalLight);
    this.hingeObject.add(this.directionalLight);
  }

  public updateOrientation(date?: Date): void {
    if (!date) {
      this.localDate = new Date();
    } else {
      this.localDate = date;
    }

    const sunOrientation = SolarOrientationCalculator.getAzEl(
      this.coordinates.x,
      this.coordinates.y,
      this.localDate
    );
    this.azimuth = ThreeUtils.toRadReduced(sunOrientation.azimuth);
    this.elevation = ThreeUtils.toRadReduced(sunOrientation.elevation);
  }

  public updateDirectionalLight(): void {
    // If the elevation is less than zero, there is no sun light.
    // Starting from 2 degrees, start fading the light
    const FADE_OUT_THRESHOLD = 2.0;
    const elevationDegrees = (180.0 * this.elevation) / Math.PI;
    if (elevationDegrees <= 0.0) {
      this.directionalLight.intensity = 0.0;
      this.sunSphere.visible = false;
      return;
    } else if (elevationDegrees <= FADE_OUT_THRESHOLD) {
      this.directionalLight.intensity = elevationDegrees / FADE_OUT_THRESHOLD;
    } else {
      this.directionalLight.intensity = 1.0;
    }
    this.sunSphere.visible = true;
    // Reset the hingeObject's quaternion
    this.hingeObject.quaternion.copy(new Quaternion());

    this.directionalLight.position.copy(this.north);
    this.directionalLight.position.multiplyScalar(this.sunDistance);

    const rotator = new Quaternion();
    rotator.setFromAxisAngle(this.east, this.elevation);
    this.hingeObject.quaternion.premultiply(rotator);
    rotator.setFromAxisAngle(this.nadir, this.azimuth);
    this.hingeObject.quaternion.premultiply(rotator);
    this.updateSunSpherePosition();
  }

  private createSunSphere(): void {
    const color = new Color('#FDB813');
    const geometry = new IcosahedronGeometry(4, 15);
    const material = new MeshBasicMaterial({ color: color });
    this.sunSphere = new Mesh(geometry, material);
    this.hingeObject.add(this.sunSphere);
  }

  private updateSunSpherePosition(): void {
    this.sunSphere.position.x = this.directionalLight.position.x;
    this.sunSphere.position.y = this.directionalLight.position.y;
    this.sunSphere.position.z = this.directionalLight.position.z;
  }
}
