import { Group } from 'three';
import { ThreeGroupEnum } from '../_enum/three-group.enum';

export class ThreeGroupBuilder {
    public static createGroup(groupName: ThreeGroupEnum, renderOrder: number) {
        const threeGroup = new Group();
        threeGroup.renderOrder = renderOrder;
        threeGroup.name = groupName;
        return threeGroup;
    }
}
