import { AnimationClip } from 'three';
import { Group } from 'three';

export class Loaded3DObject {
    public scene: Group;
    public animation: AnimationClip;

    constructor(scene: Group, animation: AnimationClip) {
        this.scene = scene;
        this.animation = animation;
    }
}
