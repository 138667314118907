import { AmbientLight, DirectionalLight, HemisphereLight } from 'three';
import { TextureColorEnum } from '../_enum/texture-color.enum';

export class ThreeLightsBuilder {
  public static createAmbientLight(): AmbientLight {
    return new AmbientLight(TextureColorEnum.warm3, 0.3);
  }

  public static createHemisphereLight(
    positionX: number,
    positionY: number
  ): HemisphereLight {
    const hemiLight = new HemisphereLight(
      TextureColorEnum.neutral1,
      TextureColorEnum.warm1,
      0.3
    );
    hemiLight.position.set(positionX / 2, 60, positionY);
    return hemiLight;
  }

  public static createDirectionalLight(
    maxPoint: number,
    minPoint: number
  ): DirectionalLight {
    const dirLight = new DirectionalLight(TextureColorEnum.neutral1, 0.3);
    let heightOfLight = Math.min(maxPoint, minPoint);
    if (heightOfLight > 70) {
      heightOfLight = 70;
    }
    dirLight.position.set(0, heightOfLight, minPoint);
    dirLight.target.position.set(maxPoint / 2, 0, minPoint / 2);
    dirLight.target.updateMatrixWorld();

    // Adding settings for Directional Light //
    dirLight.castShadow = true;
    dirLight.shadow.mapSize.width = 2048;
    dirLight.shadow.mapSize.height = 2048;

    const d = 50;
    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.far = 500;
    dirLight.shadow.bias = -0.0003;

    return dirLight;
  }
}
