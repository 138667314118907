import {
    Directive,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ThreeUtils,
    HouseSceneConfig,
    PermitThreeService,
    FreeshapeHelper,
    ThreeFreeshapeUtil,
    AuthService,
    CustomToasterService,
    FurbanUtil,
} from '@furban/utilities';
import { NotificationToasterComponent } from '../../shared/notification-toaster/notification-toaster.component';
import { Group, Vector3 } from 'three';

@Directive({
    selector: '[furbanPlaceObjectsInLine]',
})
export class PlaceObjectsInLineDirective implements OnDestroy, OnInit {
    @Input() houseSceneConfig: HouseSceneConfig;
    @Output() addRowOfObjectsEvent: EventEmitter<Vector3[]> =
        new EventEmitter();

    public threeFreeshapeUtil: ThreeFreeshapeUtil;

    private bindMouseMove = this.mouseMoveEvent.bind(this);
    private bindMouseClick = this.mouseClickEvent.bind(this);
    private bindMouseDown = this.mouseDownEvent.bind(this);
    private objectsInLineAddedSubscription: Subscription;

    constructor(
        private permitThreeService: PermitThreeService,
        private authService: AuthService,
        private customToasterService: CustomToasterService
    ) { }

    ngOnDestroy(): void {
        this.removeMouseEventsFromRenderer();
        this.objectsInLineAddedSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.threeFreeshapeUtil = new ThreeFreeshapeUtil(this.houseSceneConfig);
        this.objectsInLineAddedSubscription =
            this.permitThreeService.placeObjectsInLineEventsAddedObservable.subscribe(
                (data) => {
                    if (data) {
                        this.addingMouseEventsFromRenderer();
                        this.showDrawingLineToaster();
                    } else {
                        this.removeMouseEventsFromRenderer();
                        this.clearLastDrawing();
                    }
                }
            );
    }

    public addingMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointermove',
            this.bindMouseMove,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerdown',
            this.bindMouseDown,
            { passive: false }
        );
        this.houseSceneConfig.renderer.domElement.addEventListener(
            'pointerup',
            this.bindMouseClick,
            { passive: false }
        );
    }

    public removeMouseEventsFromRenderer(): void {
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointermove',
            this.bindMouseMove
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerdown',
            this.bindMouseDown
        );
        this.houseSceneConfig.renderer.domElement.removeEventListener(
            'pointerup',
            this.bindMouseClick
        );
    }

    private mouseClickEvent(event: MouseEvent): void {
        if (this.houseSceneConfig.freeShapeLineDetails.points.length === 2) {
            return;
        }

        const point = ThreeUtils.getIntersectionOfEventWithGroup(
            this.houseSceneConfig,
            event,
            this.houseSceneConfig.groundGroup
        );

        const freeshapeHelper = new FreeshapeHelper();
        point.y = point.y + ThreeUtils.defaultZFightingOffset;
        const cylinderHelper = freeshapeHelper.generateCylinderHelper();
        cylinderHelper.position.set(point.x, point.y, point.z);

        if (this.houseSceneConfig.freeShapeLineDetails.pointsLength === 0) {
            this.houseSceneConfig.freeShapeLineDetails.points.push(
                new Vector3(point.x, point.y, point.z)
            );
            this.houseSceneConfig.freeShapeLineDetails.pointsLength = 1;
            this.houseSceneConfig.freeShapeLineDetails.setFreeShapeLineObject();
            this.houseSceneConfig.freeShapeLineDetails.updateGeometry();
            this.houseSceneConfig.helpersGroup.add(
                this.houseSceneConfig.freeShapeLineDetails.line
            );
            this.houseSceneConfig.pillarHelper = new Group();
            this.houseSceneConfig.helpersGroup.add(
                this.houseSceneConfig.pillarHelper
            );
            this.attachDistanceInfoToLine();
        } else {
            this.houseSceneConfig.helpersGroup.remove(
                this.houseSceneConfig.freeshapeHelper
            );
            this.threeFreeshapeUtil.addPointToFreeShape(point);
            this.houseSceneConfig.freeShapeLineDetails.updateGeometry();
            this.houseSceneConfig.freeshapeHelper =
                freeshapeHelper.generateHelper(
                    this.houseSceneConfig.freeShapeLineDetails.points
                );
            this.houseSceneConfig.helpersGroup.add(
                this.houseSceneConfig.freeshapeHelper
            );
        }

        this.houseSceneConfig.pillarHelper.add(cylinderHelper);
        this.showDrawingLineToaster();
    }

    private mouseDownEvent(event: MouseEvent): void {
        event.preventDefault();
        if (ThreeUtils.isDoubleTap(this.houseSceneConfig)) {
            this.onDoubleClickWhenDrawingFreeshape(event);
        } else {
            this.mouseClickEvent(event);
        }
        this.houseSceneConfig.mylatesttap = new Date().getTime();
    }

    private mouseMoveEvent(event: MouseEvent): void {
        if (this.houseSceneConfig.freeShapeLineDetails.points.length >= 1) {
            const currentPoint = ThreeUtils.getIntersectionOfEventWithGroup(
                this.houseSceneConfig,
                event,
                this.houseSceneConfig.groundGroup
            );
            if (!currentPoint) {
                return;
            }
            currentPoint.y = currentPoint.y + ThreeUtils.linesZFightingOffset;
            this.houseSceneConfig.freeShapeLineDetails.points[
                this.houseSceneConfig.freeShapeLineDetails.pointsLength
            ] = currentPoint;
            this.houseSceneConfig.freeShapeLineDetails.updateGeometry();

            this.houseSceneConfig.freeShapeLineDetails.lengthInMeters =
                ThreeUtils.computeDistanceBetweenTwoPoints(
                    this.houseSceneConfig.freeShapeLineDetails.points[0],
                    this.houseSceneConfig.freeShapeLineDetails.points[1]
                );
            this.updateLineDistanceInfo(currentPoint);
        }
    }

    private attachDistanceInfoToLine(): void {
        if (!FurbanUtil.isMobile()) {
            this.houseSceneConfig.infoParagraph =
                ThreeUtils.createParagraphElement(
                    this.houseSceneConfig.freeShapeLineDetails.lengthInMeters.toFixed(
                        3
                    ),
                    this.houseSceneConfig.freeShapeLineDetails.points[0]
                );
            this.houseSceneConfig.freeShapeLineDetails.line.add(
                this.houseSceneConfig.infoParagraph
            );
        }
    }

    private updateLineDistanceInfo(newPosition: Vector3): void {
        this.houseSceneConfig.infoParagraph.element.textContent =
            this.houseSceneConfig.freeShapeLineDetails.lengthInMeters.toString();
        this.houseSceneConfig.infoParagraph.position.set(
            newPosition.x,
            newPosition.y,
            newPosition.z
        );
    }

    private clearLastDrawing(): void {
        this.houseSceneConfig.helpersGroup.remove(
            this.houseSceneConfig.freeshapeHelper
        );
        this.houseSceneConfig.helpersGroup.remove(
            this.houseSceneConfig.freeShapeLineDetails.line
        );
        this.houseSceneConfig.freeShapeLineDetails.line?.remove(
            this.houseSceneConfig.infoParagraph
        );
        this.houseSceneConfig.helpersGroup.remove(
            this.houseSceneConfig.pillarHelper
        );
        this.houseSceneConfig.freeShapeLineDetails.resetPointsDetails();
        this.houseSceneConfig.freeShapeLineDetails.setFreeShapeLineObject();
    }

    private onDoubleClickWhenDrawingFreeshape(event: MouseEvent): void {
        this.mouseClickEvent(event);

        this.houseSceneConfig.freeShapeLineDetails.line.remove(
            this.houseSceneConfig.infoParagraph
        );
        this.addRowOfObjects();
        this.permitThreeService.finishedDrawingLine();
    }

    private addRowOfObjects(): void {
        const freeshapePoints =
            this.houseSceneConfig.freeShapeLineDetails.points;
        this.addRowOfObjectsEvent.emit(freeshapePoints);
    }

    private showDrawingLineToaster(): void {
        if (this.houseSceneConfig.freeShapeLineDetails.pointsLength === 0) {
            this.open3DNotificationSnackbar('user.project.startLine');
        }

        if (this.houseSceneConfig.freeShapeLineDetails.pointsLength === 1) {
            this.open3DNotificationSnackbar('user.project.endLine');
        }
    }

    protected open3DNotificationSnackbar(message: string): void {
        if (this.authService.getUserSettings().show3DNotifications) {
            this.customToasterService.openCustomToaster(
                NotificationToasterComponent,
                '3d_rotation',
                'notification',
                message,
                0
            );
        }
    }
}
