import { PerspectiveCamera, Vector3 } from 'three';

export class ThreeCameraBuilder {
  public static createPerspectiveCamera(
    aspectRatio: any,
    position: Vector3
  ): PerspectiveCamera {
    const cameraProperties = {
      fieldOfView: 45,
      nearClippingPane: 1,
      farClippingPane: 1000,
    };

    const camera = new PerspectiveCamera(
      cameraProperties.fieldOfView,
      aspectRatio,
      cameraProperties.nearClippingPane,
      cameraProperties.farClippingPane
    );

    camera.position.set(position.x, position.y, position.z);
    camera.updateProjectionMatrix();
    return camera;
  }
}
