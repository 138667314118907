import { FurbanUtil } from '../helpers/furbanUtil';
//import { sRGBEncoding } from 'three';
import { ThreeUtils } from './three.utils';
import { PathObject } from '../_models/path-object';
import { PermitAssetNamesEnum } from '../_enum/permit-asset-names.enum';
import { DoubleSide, MeshLambertMaterial, RepeatWrapping, TextureLoader, Vector3 } from 'three';


export class ThreeTextureBuilder {
    public static createTextureForGeometry(
        groundTextureId: number,
        repeat?: any
    ): MeshLambertMaterial {
        // Adding floor material details that will fix z-fighting issue
        const floorMat = new MeshLambertMaterial(
            ThreeUtils.defaultMaterialOptions
        );

        floorMat.side = DoubleSide;
        const textureLoader = new TextureLoader();
        textureLoader.load(
            FurbanUtil.get2DImageAssetForObject(groundTextureId),
            (map) => {
                map.wrapS = RepeatWrapping;
                map.wrapT = RepeatWrapping;
                if (repeat) {
                    map.repeat.set(repeat.x, repeat.y);
                } else {
                    map.repeat.set(1, 1);
                }
                //map.encoding = sRGBEncoding;
                floorMat.map = map;
                floorMat.needsUpdate = true;
            }
        );

        return floorMat;
    }

    public static createMaterialWithDefaultColor(
        color: any
    ): MeshLambertMaterial {
        const materialOptions = ThreeUtils.defaultMaterialOptions;
        materialOptions['color'] = color;

        return new MeshLambertMaterial(materialOptions);
    }

    public static createCustomShapeTexture(
        parsedDimensions: any,
        pathObject: PathObject
    ): MeshLambertMaterial {
        const texture = new TextureLoader().load(
            FurbanUtil.get2DImageAssetForObject(pathObject.objId),
            (map) => {
                map.wrapS = RepeatWrapping;
                map.wrapT = RepeatWrapping;
                //map.encoding = sRGBEncoding;
                map.anisotropy = 4;
                map.repeat.set(parsedDimensions.width, parsedDimensions.depth);
            }
        );

        const material = new MeshLambertMaterial({
            map: texture,
            side: DoubleSide,
        });

        return material;
    }

    public static createExtensionTexture(
        textureName: string,
        color: string,
        repeat: any,
        scale: Vector3
    ): MeshLambertMaterial {
        const texture = new TextureLoader().load(textureName, (map) => {
            map.wrapS = RepeatWrapping;
            map.wrapT = RepeatWrapping;
            //map.encoding = sRGBEncoding;
            map.anisotropy = 4;
            map.repeat.set(repeat.width * scale.x, repeat.depth * scale.y);
        });

        const material = new MeshLambertMaterial({
            map: texture,
            side: DoubleSide,
        });

        material.color.set(color);
        material.needsUpdate = true;
        material.name = PermitAssetNamesEnum.wallMaterial;

        return material;
    }
}
