import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';

import { Vector2, Vector3 } from 'three';
import { MathUtil } from './math.util';
import { DesignProposal } from '../_models/design-proposal';
import { DialogPosition } from '@angular/material/dialog';
import { CustomDialogPosition } from '../_models/custom-dialog-position';
import { TranslateService } from '@ngx-translate/core';
import { routingConstants } from '../_constants/routing-constants';

import { PointXY } from '../_models/point-xy';
import { ProjectStatusEnum } from '../_enum/project-status.enum';
import { Project } from '../_models/project';
import { ProjectTypeEnum } from '../_enum/project-type.enum';
import { DocumentModel } from '../_models/document';
import { CustomToasterService } from '../_services/custom-toaster.service';
import { CustomToasterComponent } from '../custom-toaster/custom-toaster.component';

export class FurbanUtil {
  public static encodeObjectURIComponents(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (Array.isArray(obj[p])) {
        for (let i = 0; i < obj[p].length; i++) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][i]));
        }
      } else if (typeof obj[p] === 'object') {
        str.push(
          encodeURIComponent(p) +
            '=' +
            FurbanUtil.encodeObjectURIComponents(obj[p])
        );
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  public static copyObject(src: any): any {
    return JSON.parse(JSON.stringify(src));
  }

  public static deepCopy(oldObj: any) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj =
        Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      // REFACTOR - don't use for in
      for (const i in oldObj) {
        if (oldObj[i]) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }
    return newObj;
  }

  public static isEmpty(value: any): boolean {
    return (
      value === null ||
      value === undefined ||
      value === '' ||
      (value && value.trim && value.trim() === '') ||
      (value && value.length === 0)
    );
  }

  /*
      Two cordova plugins are needed in order to use method:
        cordova plugin add cordova-plugin-screensize
        cordova plugin add cordova-plugin-screen-orientation
    */
  public static setAndroidPhoneLockPortrait(): Promise<any> {
    return new Promise((resolve) => {
      if (window['cordova'] && window['cordova'].platformId === 'android') {
        window['plugins'].screensize.get(
          function (result: any) {
            if (result.diameter <= 6.9) {
              screen['orientation'].lock('portrait');
            }
            resolve(true);
          },
          function (error: any) {
            console.error('android orientation error: ', error);
            resolve(true);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  public static isPortraitMode(): boolean {
    return screen.availHeight > screen.availWidth;
  }

  /**
   * returns the length of a string that contains multi-byte symbols
   * @param str is a sting parameter;
   */
  public static getStringLength(str) {
    let count = 0;
    const stringLength = str.length;

    if (!str) {
      return count;
    }
    str = String(str || '');
    for (let i = 0; i < stringLength; i++) {
      const partCount = encodeURI(str[i]).split('%').length;
      count += partCount === 1 ? 1 : partCount - 1;
    }
    return count;
  }

  /**
   * returns the hex encoded value of a string
   * @param s is the string value to be encoded
   */
  public static toHex(s: string) {
    if (s == null) {
      return '';
    }

    let hex, i;

    let result = '';
    for (i = 0; i < s.length; i++) {
      hex = s.charCodeAt(i).toString(16);
      result += hex.slice(-4);
    }

    return result;
  }

  public static isMobile(): boolean {
    let check = false;
    ((a) => {
      const platforms =
        '/(android|bbd+|meego).+mobile|avantgo|bada/|blackberry|blazer|compal|elaine|fennec|hiptop|' +
        'iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|' +
        'palm( os)?|phone|p(ixi|re)/|plucker|pocket|psp|series(4|6)0|symbian|treo|up.(browser|link)|' +
        'vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i';
      const others =
        '/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al' +
        '(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )' +
        '|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55/|capi|ccwa|' +
        'cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)' +
        '|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-' +
        'mo|go(.w|od)|r(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|' +
        'ghu(aw|tc)|i-(20|go|ma)|i230|iac( |-|/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|' +
        'jigs|kddi|keji|kgt( |/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|/(k|l|u)|50|54|-[a-w])|libw|lynx' +
        '|m1-w|m3ga|m50/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-' +
        '| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on' +
        '|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|ph' +
        'il|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|' +
        'r600|raks|rim9|ro(ve|zo)|s55/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk/|se(c(-|0|1)|47|mc|nd|r' +
        ' i)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb' +
        ')|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|t' +
        ' x-9|up(.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70' +
        ' |80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i';
      if (
        new RegExp(platforms).test(a) ||
        new RegExp(others).test(a.substr(0, 4))
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor);
    if (!check && window['orientation'] > -1) {
      check = true;
    }

    if (!check && 'ontouchstart' in window) {
      check = true;
    }

    return check;
  }

  public static findIndexWithAttr(
    array: DocumentModel[],
    attr: string,
    value: string
  ): number {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  public static reduceFileNameLength(
    fileName: string,
    maxNumberOfChar: number
  ): string {
    const splitFileName = fileName.split('.', 2);
    if (fileName.length === 0) {
      // generate a random name of max 6 chars
      fileName = 'Image_' + (Math.random() + 1).toString(36).substring(7);
    } else if (splitFileName.length < 2) {
      fileName = fileName.substring(0, maxNumberOfChar);
    } else {
      const leftSide = splitFileName[0].substring(0, maxNumberOfChar - 4);
      const rightSide = '.' + splitFileName[1].substring(0, 4);
      fileName = leftSide.concat(rightSide);
    }

    return fileName;
  }

  public static getPercentageChange(
    oldNumber: number,
    newNumber: number
  ): number {
    const decreaseValue = oldNumber - newNumber;
    return Math.round((decreaseValue / oldNumber) * 100);
  }

  public static getMinXandY(data: any) {
    let lowestX = Number.POSITIVE_INFINITY;
    let lowestY = Number.POSITIVE_INFINITY;

    for (let i = 0; i < data.length; i++) {
      lowestX = Math.min(lowestX, data[i]['x']);
      lowestY = Math.min(lowestY, data[i]['y']);
    }
    return new Vector2(lowestX, lowestY);
  }

  public static getMaxXandY(data: any) {
    let highestX = Number.NEGATIVE_INFINITY;
    let highestY = Number.NEGATIVE_INFINITY;

    for (let i = 0; i < data.length; i++) {
      highestX = Math.max(highestX, data[i]['x']);
      highestY = Math.max(highestY, data[i]['y']);
    }

    return new Vector2(highestX, highestY);
  }

  public static getMin(data: any, property: string): number {
    let lowest = Number.POSITIVE_INFINITY;

    for (let i = data.length - 1; i >= 0; i--) {
      lowest = Math.min(lowest, data[i][property]);
    }
    return lowest;
  }

  public static getMax(data: any, property: string): number {
    let highest = Number.NEGATIVE_INFINITY;

    for (let i = data.length - 1; i >= 0; i--) {
      highest = Math.max(highest, data[i][property]);
    }
    return highest;
  }

  public static computeSlopeOfLineGivenByPoints(
    p1: Vector3,
    p2: Vector3
  ): number | undefined {
    if (p1.x === p2.x) {
      // slope is undefined in this case
      return;
    }
    if (p1.z === p2.z) {
      return 0;
    }
    return (p2.z - p1.z) / (p2.x - p1.x);
  }

  public static getRotationAngleInDegreesRelativeToHorizontalAxis(
    p1: Vector3,
    p2: Vector3
  ): number {
    const slope = FurbanUtil.computeSlopeOfLineGivenByPoints(p1, p2);
    let angle: number;
    if (slope === undefined) {
      angle = 90;
    } else {
      angle = Math.abs(Math.atan(slope));
      angle = MathUtil.rad2deg(angle);
    }
    if (p2.x > p1.x && p2.z > p1.z) {
      return angle;
    } else if (p2.x < p1.x && p2.z > p1.z) {
      return 180 - angle;
    } else if (p2.x < p1.x && p2.z < p1.z) {
      return 180 + angle;
    } else {
      return 360 - angle;
    }
  }

  public static getPolygonCentroid(points: any[]) {
    const centroid = new Vector2();
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      centroid.x += parseFloat(point.X);
      centroid.y += parseFloat(point.Y);
    }
    centroid.x /= points.length;
    centroid.y /= points.length;
    return centroid;
  }

  public static isGroundMaterial(objectId: number): boolean {
    const groundMaterials = [1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008];
    return groundMaterials.indexOf(objectId) > -1;
  }

  public static noWhiteSpacesValidator(control: FormControl) {
    const text = control.value;
    let isValid = true;

    if (text != null && !text.replace(/\s/g, '').length) {
      isValid = false;
    }

    return isValid ? null : { whitespace: true };
  }

  public static passwordConfirmValidator(form: FormGroup) {
    const condition =
      form.get('passwordFormControl').value !==
      form.get('passwordConfirmFormControl').value;

    return condition ? { passwordMatches: true } : null;
  }

  /** This validator should corespond to the one from Java - XSSValidator */
  public static htmlContentValidator(control: FormControl) {
    const text = control.value;
    let isValid = true;

    if (!text) {
      return null;
    }

    if (
      text.indexOf('<') > -1 ||
      text.indexOf('&') > -1 ||
      text.indexOf('>') > -1
    ) {
      isValid = false;
    }

    return isValid ? null : { isHTML: true };
  }

  public static nameValidator(codeUser: string) {
    return (control: FormControl) => {
      const nameUser = control.value;
      const isValid = !nameUser.toUpperCase().includes(codeUser);

      return isValid ? null : { isCodeName: true };
    };
  }

  public static parseCoordinatesFor2DAnd3D(
    xyArray: PointXY[],
    value: number
  ): PointXY[] {
    /* Value will be 1 for 3D and 100 for 2D and map. */
    const maxY = this.getMax(xyArray, 'Y');
    const minX = this.getMin(xyArray, 'X');
    for (let i = xyArray.length - 1; i >= 0; i--) {
      xyArray[i].X = parseFloat((xyArray[i].X - minX).toFixed(3)) * value;
      xyArray[i].Y = parseFloat((xyArray[i].Y - maxY).toFixed(3)) * value;
    }

    /* if (value == 100) {
          xyArray.pop();
        }*/
    return xyArray;
  }

  public static reduceCoordinatesToMaxYAndMinX(
    xyArray: PointXY[],
    maxY: number,
    minX: number
  ): PointXY[] {
    for (let i = xyArray.length - 1; i >= 0; i--) {
      xyArray[i].X = parseFloat((xyArray[i].X - minX).toFixed(3));
      xyArray[i].Y = parseFloat((xyArray[i].Y - maxY).toFixed(3));
    }
    return xyArray;
  }

  public static isAreaToBig(coordinates: any[]) {
    const maxX = this.getMax(coordinates, 'X');
    const maxY = this.getMax(coordinates, 'Y');
    if (Math.max(maxX, maxY) < 100000) {
      return false;
    } else {
      return true;
    }
  }

  public static transforDateForIOS(date: Date): number {
    const stringDate = date.toString();
    const substring = stringDate.substring(0, stringDate.indexOf('T'));
    return new Date(substring).getTime();
  }

  public static isDateExpiredForIOS(date: Date): boolean {
    const computedDate = this.transforDateForIOS(date);
    return computedDate < Date.now();
  }

  public static get2DImageAssetForObject(objectLookId: number): string {
    return (
      '/assets/images/objects/' + objectLookId + '/' + objectLookId + '_2d.png'
    );
  }

  //REMOVE THIS
  public static calculatePercentage = (number: number, total: number) => {
    if (number === 0 && total === 0) {
      return 0;
    }
    return Math.round((number * 100) / total);
  };

  public static isDatePassed = (selectedDate: Date): boolean => {
    const datePipe = new DatePipe('en-US');
    const formattedToday = datePipe.transform(new Date(), 'yyyy-MM-dd');
    const formattedSelectedDate = datePipe.transform(
      selectedDate,
      'yyyy-MM-dd'
    );

    const now = new Date(formattedToday).getTime();
    const date = new Date(formattedSelectedDate).getTime();
    return now >= date;
  };

  public static getRoleAsStringByRoleWeight(roleWeight: number): string {
    switch (roleWeight) {
      case 0:
        return 'SuperAdmin';
      case 1:
        return 'Admin';
      case 2:
        return 'Expert';
      case 3:
        return 'Citizen';
      default:
        break;
    }

    return '';
  }

  public static calculateDays = (endDate: Date): number => {
    const datePipe = new DatePipe('en-US');
    const today = new Date();
    const formattedToday = datePipe.transform(today, 'yyyy-MM-dd');
    const formattedEndDate = datePipe.transform(endDate, 'yyyy-MM-dd');
    const oneDay = 86400000;
    const now = new Date(formattedToday).getTime();
    const end = new Date(formattedEndDate).getTime();
    return Math.round(Math.abs((now - end) / oneDay)) + 1;
  };

  public static getClassForProjectStatus(project: Project): string {
    if (project) {
      if (
        project.ended &&
        project.projectStatus.statusWeight !== ProjectStatusEnum.archived
      ) {
        return 'ended';
      }
      switch (project.projectStatus.statusWeight) {
        case ProjectStatusEnum.published: {
          return 'active';
        }
        case ProjectStatusEnum.unpublished: {
          return 'inactive';
        }
        case ProjectStatusEnum.created: {
          return 'inactive';
        }
        case ProjectStatusEnum.archived: {
          return 'archived';
        }
      }
    }
  }

  public static composeImageString(id: number): string {
    return '/assets/images/objects/' + id + '/' + id + '_menu.png';
  }

  public static generateShapesAssetsURL(
    objectLookId: number,
    typeOfObject: string
  ): string {
    return '/assets/images/objects/' + objectLookId + typeOfObject + '.png';
  }

  public static checkIfIsFirefoxBrowser(): boolean {
    return navigator.userAgent.indexOf('Firefox') !== -1;
  }

  public static getDesignProposalTypeText(designProposal: DesignProposal) {
    return designProposal.isAdminDesign
      ? 'designProposals.adminDesign'
      : designProposal.groupId
      ? 'designProposals.liveDesign'
      : 'designProposals.citizenDesign';
  }

  public static getDesignProposalTypeDescription(
    designProposal: DesignProposal,
    isCitizenDesign: boolean
  ): string {
    return designProposal?.isAdminDesign
      ? 'admin.projectDescription.admin.description'
      : designProposal?.groupId
      ? 'admin.projectDescription.live.description'
      : isCitizenDesign
      ? 'admin.projectDescription.citizen.description'
      : 'admin.projectDescription.permit.description';
  }

  public static getColorBasedOnProposalType(designProposal: DesignProposal) {
    return designProposal.isAdminDesign
      ? 'var(--primary-4)'
      : designProposal.groupId
      ? 'var(--alert-4)'
      : 'var(--primary-4)';
  }

  public static getProjectTypeText(project: Project) {
    return FurbanUtil.isCollaborativeProject(project)
      ? 'admin.dashboard.citizenDesigns'
      : 'admin.dashboard.municipalityDesigns';
  }

  public static getColorBasedOnProjectType(project: Project) {
    return FurbanUtil.isCollaborativeProject(project)
      ? 'var(--primary-5)'
      : 'var(--primary-4)';
  }

  public static isIOS() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      ((navigator.platform === 'MacIntel' ||
        navigator.userAgent.includes('MacIntel')) &&
        navigator.maxTouchPoints > 1)
    );
  }

  public static isCollaborativeProject(project: Project): boolean {
    return (
      project.isCitizenDesigns ||
      project.projectType.projectTypeId ===
        ProjectTypeEnum.pioneerInitiativeProject
    );
  }

  // private static vectorCoordinates2JTS(polygon) {
  //     const coordinates = [];
  //     for (let i = 0; i < polygon.length; i++) {
  //         coordinates.push(
  //             new Coordinate(polygon[i].X, polygon[i].Y)
  //         );
  //     }
  //     return coordinates;
  // }

  // public static inflatePolygon(poly, spacing) {
  //     const geoInput = this.vectorCoordinates2JTS(poly);
  //     const geometryFactory = GeometryFactory();
  //     const shell = geometryFactory.createPolygon(geoInput);
  //     const polygon = shell.buffer(
  //         spacing,
  //         BufferParameters.CAP_FLAT
  //     );

  //     const inflatedCoordinates = [];
  //     const oCoordinates = polygon.getCoordinates(); //_shell._points._coordinates;

  //     for (let i = 0; i < oCoordinates.length; i++) {
  //         const oItem = oCoordinates[i];
  //         inflatedCoordinates.push(
  //             new Vector2(Math.ceil(oItem.x), Math.ceil(oItem.y))
  //         );
  //     }
  //     return inflatedCoordinates;
  // }

  public static getJSONfromStringfyValue(value: any) {
    return typeof value === 'string'
      ? (JSON.parse(value as string) as Array<PointXY>)
      : value;
  }

  public static isInViewport(element: Element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public static isWidthSmallerThan(maxWidth: number): boolean {
    return window.innerWidth < maxWidth;
  }

  public static computePopupPositionBasedOnEventCoordinates(
    x: number,
    y: number
  ): DialogPosition {
    const computedPosition = new CustomDialogPosition();

    const offset = 10;

    if (y < window.document.documentElement.clientHeight / 2) {
      computedPosition.top = y + offset + 'px';
    } else {
      computedPosition.bottom =
        window.document.documentElement.clientHeight - y + offset + 'px';
    }

    if (x < document.documentElement.clientWidth / 2) {
      computedPosition.left = x + offset + 'px';
    } else {
      computedPosition.right =
        window.document.documentElement.clientWidth - x + offset + 'px';
    }

    return computedPosition;
  }

  public static getCommentExpiringDate(): Date {
    const expiringDate: Date = new Date();
    expiringDate.setMonth(expiringDate.getMonth() + 1);
    return expiringDate;
  }

  public static getPasswordErrorMessage(
    formControlErrors: ValidationErrors,
    translateService: TranslateService
  ) {
    if (formControlErrors['required']) {
      return translateService.instant('auth.requiredPassword');
    } else if (formControlErrors['validPassword']) {
      return translateService.instant('auth.validPasswordHint');
    } else {
      return null;
    }
  }

  public static getPoints(dp: DesignProposal): number {
    if (!dp.reactionPoints || dp.reactionPoints < 0) {
      return 0;
    }
    return dp.reactionPoints;
  }

  public static generateIframeString(id: string, isProject: boolean): string {
    const hrefString = window.location.href;
    const baseURL = hrefString.substring(0, hrefString.indexOf('app'));
    const endURL = isProject
      ? `${routingConstants.publicPageProject}/${id}`
      : `${routingConstants.publicPageDesign}/${id}`;
    const src = `${baseURL}${endURL}`;

    /** Add variable for width and height */
    return `<iframe src="${src}"
               width="${400}"
               height="${400}"></iframe>`;
  }

  public static displayNumberWithTwoDigits(
    numberToDisplay: number
  ): string | undefined {
    if (numberToDisplay > 99) {
      return;
    }
    const returnString = '00' + numberToDisplay;
    return returnString.substring(returnString.length - 2);
  }

  public static isApp(): boolean {
    const platform = Capacitor.getPlatform();

    return platform && (platform === 'ios' || platform === 'android');
  }

  public static copyToClipboard(
    clipboadTextToCopy: string,
    translateService: TranslateService,
    customToasterService: CustomToasterService
  ): void {
    this.writeToClipboard(clipboadTextToCopy)
      .then(() => {
        this.showToaster(
          translateService.instant('generic.succesCopyToClipboard'),
          false,
          customToasterService
        );
      })
      .catch(() => {
        this.showToaster(
          translateService.instant('generic.failCopyToClipboard'),
          true,
          customToasterService
        );
      });
  }

  public static writeToClipboard(clipboadTextToCopy: string): Promise<void> {
    // if (this.isApp()) {
    //     return Clipboard.write({string: clipboadTextToCopy});
    // }

    return navigator.clipboard.writeText(clipboadTextToCopy);
  }

  public static showToaster(
    text: string,
    fail: boolean,
    customToasterService: CustomToasterService
  ): void {
    customToasterService.openCustomToaster(
      CustomToasterComponent,
      'check_circle',
      fail ? 'error' : 'success',
      text,
      500
    );
  }

  public static isIOSApp(): boolean {
    const platform = Capacitor.getPlatform();

    return platform && platform === 'ios';
  }

  public static isAppOrMobile(): boolean {
    return FurbanUtil.isApp() || FurbanUtil.isMobile();
  }
}
