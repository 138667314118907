import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminAuthRecoverComponent } from './admin/admin-auth-recover/admin-auth-recover.component';
import { AdminAuthComponent } from './admin/admin-auth/admin-auth.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminNewComponent } from './admin/admin-new/admin-new.component';
import { AdminProfileComponent } from './admin/admin-profile/admin-profile.component';
import { BackgroundMediaComponent } from './admin/background-media/background-media.component';
import { MunicipalityNewComponent } from './admin/municipality-new/municipality-new.component';
import { NotificationPanelComponent } from './admin/notification-panel/notification-panel.component';
import { SuperadminSuppliersComponent } from './admin/superadmin-suppliers/superadmin-suppliers.component';
import { UserAuthComponent } from './user/user-auth/user-auth.component';
import { UserDashboardComponent } from './user/user-dashboard/user-dashboard.component';
import { CodesComponent } from './admin/admin-codes/codes/codes.component';
import { GroupsComponent } from './admin/admin-codes/groups/groups.component';
import { ModifiedGuard } from '../shared/modifyStatus/modified.guard';
import { FaqComponent } from '../shared/faq/faq.component';
import { DesignProposalDetailsComponent } from '../shared/design-proposal-details/design-proposal-details.component';
import { AdminLoginGuard } from './_guards/admin-login.guard';
import { UserLoginGuard } from './_guards/user-login.guard';
import { ProjectDashboardComponent } from '../project-shared/project-dashboard/project-dashboard.component';
import { ProjectDetailsComponent } from '../project-shared/project-details/project-details.component';
import { ProjectMapComponent } from '../project-shared/project-map/project-map.component';
import { ProjectGroupsComponent } from '../project-shared/project-groups/project-groups.component';
import { UserProjectComponent } from '../project-shared/user-project/project/user-project.component';
import { ProjectDesignsComponent } from '../project-shared/project-design-proposals/project-design-proposals.component';
import { SetupDefaultObjectsComponent } from '../project-shared/setup-default-objects/setup-default-objects.component';
import { SuperadminRequestsComponent } from './admin/superadmin-requests/superadmin-requests.component';
import { RegisterComponent } from '../shared/register/register.component';
import { AdminViewPermitComponent } from './admin/admin-view-permit/admin-view-permit.component';
import { CitizenRegisterComponent } from '../shared/citizen-register/citizen-register.component';
import { GdprComponent } from '../shared/gdpr/gdpr.component';
import { CreateDefaultDesignsComponent } from '../project-shared/create-default-designs/create-default-designs.component';
import { ProjectContainerComponent } from '../project-shared/project-container/project-container.component';

import { ProjectTypeNameEnum } from '@furban/utilities';
import { DesignViewerCarouselComponent } from './user/design-viewer-carousel/design-viewer-carousel.component';
import { CollaborativeDesignComponent } from '../project-shared/collaborative-design/collaborative-design.component';
import { CollaborativeSessionsComponent } from '../project-shared/collaborative-sessions/collaborative-sessions.component';
import { PermitHouseParallelComponent } from '../permit/permit-house-parallel/permit-house-parallel.component';
import { StatisticsOverviewComponent } from '../project-shared/statistics-overview/statistics-overview.component';
import { UserStatisticsComponent } from '../project-shared/user-statistics/user-statistics.component';
import { ObjectStatisticsComponent } from '../project-shared/object-statistics/object-statistics.component';
import { PollStatisticsComponent } from '../project-shared/poll-statistics/poll-statistics.component';
import { PermitRequestsComponent } from './permit-requests/permit-requests.component';
import { environment } from '../../environments/environment';

const municipalityRoutes: Routes = [
    {
        path: 'app',
        data: { env: environment },
        children: [
            {
                path: 'partner',
                data: { env: environment },
                children: [
                    {
                        path: '',
                        component: AdminAuthComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'register',
                        component: RegisterComponent,
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'recover/:key',
                        component: AdminAuthRecoverComponent,
                        canActivate: [],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'new',
                        component: AdminNewComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'suppliers',
                        component: SuperadminSuppliersComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'requests',
                        component: SuperadminRequestsComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'profile',
                        component: AdminProfileComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/user-statistics',
                        component: UserStatisticsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/object-statistics',
                        component: ObjectStatisticsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/statistics-overview',
                        component: StatisticsOverviewComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/poll-statistics',
                        component: PollStatisticsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/design-proposals',
                        component: ProjectDesignsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/collaborative-design',
                        component: CollaborativeSessionsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'process/:id/collaborative-design/:dpId',
                        component: CollaborativeDesignComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: {
                            env: environment,
                            role: 'admin',
                            isMunicipalityDesignsProject: true,
                        },
                    },
                    {
                        path: 'process/:id',
                        component: ProjectContainerComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: {
                            env: environment,
                            role: 'admin',
                            type: ProjectTypeNameEnum.adminProject,
                        },

                        children: [
                            {
                                path: 'details',
                                component: ProjectDetailsComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'area-definition',
                                component: ProjectMapComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'setup-default-objects',
                                component: SetupDefaultObjectsComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'objects',
                                component: UserProjectComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'create-default-designs',
                                component: CreateDefaultDesignsComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'manage-users',
                                component: ProjectGroupsComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'overview',
                                component: ProjectDashboardComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'project-designs',
                                component: ProjectDesignsComponent,
                                canActivate: [AdminLoginGuard],
                                data: { env: environment, role: 'admin' },
                                outlet: 'project',
                            },
                            {
                                path: 'create-design/:dpId',
                                component: UserProjectComponent,
                                canActivate: [AdminLoginGuard],
                                data: {
                                    env: environment,
                                    role: 'admin',
                                    isMunicipalityDesignsProject: true,
                                },
                                outlet: 'project',
                            },
                        ],
                    },
                    {
                        path: 'municipality',
                        component: MunicipalityNewComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'dashboard',
                        component: AdminDashboardComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'codes',
                        component: GroupsComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'codes/:groupId',
                        component: CodesComponent,
                        canActivate: [AdminLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'design-proposal/:id',
                        component: DesignProposalDetailsComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'design-proposal/:id/:commentId',
                        component: DesignProposalDetailsComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'faq',
                        component: FaqComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'background',
                        component: BackgroundMediaComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'super-admin' },
                    },
                    {
                        path: 'notifications',
                        component: NotificationPanelComponent,
                        canActivate: [AdminLoginGuard],
                        data: {
                            env: environment,
                            role: ['super-admin', 'admin'],
                        },
                    },
                    {
                        path: 'permit-project/:id',
                        component: AdminViewPermitComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    {
                        path: 'permit-requests',
                        component: PermitRequestsComponent,
                        canActivate: [AdminLoginGuard],
                        data: { env: environment, role: 'admin' },
                    },
                    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: '',
                data: { env: environment },
                children: [
                    {
                        path: '',
                        component: UserAuthComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'dashboard',
                        component: UserDashboardComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'project/:id',
                        component: UserProjectComponent,
                        canActivate: [UserLoginGuard],
                        canDeactivate: [ModifiedGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'permit-dashboard',
                        component: PermitHouseParallelComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'collaborative-design/:dpId',
                        component: CollaborativeDesignComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'design-proposal/:id',
                        component: DesignProposalDetailsComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'statistics/:id',
                        component: StatisticsOverviewComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'register/:id',
                        component: CitizenRegisterComponent,
                        data: { env: environment },
                    },
                    {
                        path: 'gdpr',
                        component: GdprComponent,
                        data: { env: environment },
                    },
                    {
                        path: 'design',
                        component: CollaborativeDesignComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    /*
          TO-DO chat add routes back
{
            path: 'chat',
            component: ChatComponent,
            canActivate: [UserLoginGuard],
            data: { env: environment, role: 'user' }
          },
          {
            path: 'chat/:otherUserProfileId',
            component: ChatComponent,
            canActivate: [UserLoginGuard],
            data: { env: environment, role: 'user' }
          },
          {
            path: 'chat/:otherUserProfileId/:mediaUserId',
            component: ChatComponent,
            canActivate: [UserLoginGuard],
            data: { env: environment, role: 'user' }
          }, */
                    {
                        path: 'faq',
                        component: FaqComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    {
                        path: 'designs/:id',
                        component: DesignViewerCarouselComponent,
                        canActivate: [UserLoginGuard],
                        data: { env: environment, role: 'user' },
                    },
                    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
                ],
            },
        ],
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(municipalityRoutes)],
    exports: [RouterModule],
})
export class MunicipalityRoutingModule { }
