import { Group } from 'three';

export class PermitGroupBuilder {
    public static createGroup(groupName: string, renderOrder: number) {
        const threeGroup = new Group();
        threeGroup.renderOrder = renderOrder;
        threeGroup.name = groupName;
        return threeGroup;
    }
}
