import { Location } from '@angular/common';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
    AuthService,
    Poll,
    Project,
    ProjectStatusEnum,
    StepperService,
} from '@furban/utilities';
import { AdminPollPopupComponent } from '../../municipality/admin/admin-poll-popup/admin-poll-popup.component';
import { AdminPollService } from '../../municipality/admin/admin-poll/admin-poll.service';
import { ProjectDetailsService } from '../project-details/project-details.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'furban-poll-statistics',
    templateUrl: './poll-statistics.component.html',
    styleUrls: ['./poll-statistics.component.scss'],
})
export class PollStatisticsComponent implements OnInit, OnDestroy {
    @Input() currentProjectUser: Project;

    public polls: Poll[];
    public currentProject: Project;
    public curentProjectId: string;

    private routeSub: Subscription;

    constructor(
        private projectDetailsService: ProjectDetailsService,
        private dialog: MatDialog,
        private pollService: AdminPollService,
        private viewContainerRef: ViewContainerRef,
        private stepperService: StepperService,
        private location: Location,
        private route: ActivatedRoute,
        private authService: AuthService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.setCurrentProject();
        this.getAllPolls(this.curentProjectId);
    }

    public get translateServiceInstance(): TranslateService {
        return this.translateService;
    }

    public ngOnDestroy(): void {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }

    public get hasPolls(): boolean {
        return this.polls?.length > 0;
    }

    public openCreatePollModal(poll?: Poll): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.width = '540px';
        dialogConfig.data = {
            poll: poll,
            projectId: this.curentProjectId,
        };

        const groupDialog = this.dialog.open(
            AdminPollPopupComponent,
            dialogConfig
        );
        groupDialog.componentInstance.parentViewContainerRef =
            this.viewContainerRef;
        groupDialog.componentInstance.onModalClose.subscribe((data) => {
            this.getAllPolls(this.curentProjectId);
        });
    }

    public getAllPolls(projectId: string): void {
        this.pollService.getPolls(projectId).subscribe((data) => {
            if (data) {
                this.polls = data;
            }
        });
    }

    public goBack(): void {
        this.location.back();
    }

    public isAdmin(): boolean {
        return this.authService.hasAdministrativeRole();
    }

    public onNoPollEvent(): void {
        this.getAllPolls(this.curentProjectId);
    }

    public showCreatePoll(): boolean {
        return (
            this.currentProject &&
            this.currentProject.projectStatus &&
            this.currentProject.projectStatus.statusWeight !=
            ProjectStatusEnum.archived &&
            !this.currentProject.ended
        );
    }

    private setCurrentProject(): void {
        this.currentProject = this.stepperService.project as Project;

        if (this.currentProjectUser) {
            this.currentProject = this.currentProjectUser;
        }
        if (!this.currentProject) {
            this.routeSub = this.route.params.subscribe((params) => {
                this.curentProjectId = params['id'];
                if (this.curentProjectId) {
                    this.projectDetailsService
                        .getProject(this.curentProjectId)
                        .subscribe((data) => {
                            this.currentProject = data;
                        });
                }
            });
        } else {
            this.curentProjectId = this.currentProject.projectId;
        }
    }
}
