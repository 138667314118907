import { DOCUMENT } from '@angular/common';
import { HostListener } from '@angular/core';
import {
  AfterViewInit,
  OnDestroy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FurbanLoaderService,
  MathUtil,
  TextureColorEnum,
  ThreeRendererBuilder,
  ThreeRotateDirective,
  ThreeUtils,
} from '@furban/utilities';
import { LoadingManager } from 'three';
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  Vector3,
  PlaneGeometry,
  Mesh,
  MeshLambertMaterial,
  DoubleSide,
  TextureLoader,
  RepeatWrapping,
  PointLight,
  HemisphereLight,
  SpotLight,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js';
import TWEEN from '@tweenjs/tween.js';
import { Quaternion } from 'three';

@Component({
  selector: 'furban-app-landing',
  templateUrl: './furban-app-landing.component.html',
  styleUrls: ['./furban-app-landing.component.scss'],
})
export class FurbanAppLandingComponent implements AfterViewInit, OnDestroy {
  @ViewChild('canvas')
  private canvasRef: ElementRef;

  @ViewChild('rotateDir')
  private rotateDir: ThreeRotateDirective;

  public mesh: any;
  private defaultPlane: Mesh;
  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private scene: Scene;
  private gltfLoader: GLTFLoader;
  private mouseDown: boolean;
  private manager: LoadingManager;
  private animationFrameId: number;
  private bulbLight: PointLight;
  private hemiLight: HemisphereLight;
  private light: SpotLight;

  private readonly rotationDefault = Math.PI / 4;
  private readonly rotationStart = 0;

  private startTween = new TWEEN.Tween({ rotY: this.rotationStart })
    .to({ rotY: this.rotationDefault }, 1000)
    .easing(TWEEN.Easing.Quadratic.Out)
    .delay(1900);

  private baseTween = new TWEEN.Tween({ rotY: this.rotationDefault })
    .to({ rotY: -this.rotationDefault }, 1700)
    .easing(TWEEN.Easing.Quadratic.InOut);

  private endTween = new TWEEN.Tween({ rotY: -this.rotationDefault })
    .to({ rotY: this.rotationStart }, 1000)
    .easing(TWEEN.Easing.Quadratic.InOut);

  private readonly baseUrl = 'assets/images/homepage/';
  private readonly cameraProperties = {
    fieldOfView: 45,
    nearClippingPane: 1,
    farClippingPane: 1000,
  };

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  constructor(
    private router: Router,
    private loaderService: FurbanLoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    this.initializeThreeJS();
  }

  ngOnDestroy(): void {
    this.scene.remove(this.bulbLight);
    this.scene.remove(this.hemiLight);
    this.scene.remove(this.light);

    this.bulbLight.dispose();
    this.hemiLight.dispose();
    this.light.dispose();

    cancelAnimationFrame(this.animationFrameId);
    this.renderer.dispose();
    ThreeUtils.disposeThreeElement(this.scene);
    delete this.scene;
    delete this.camera;
    delete this.renderer;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.canvas.width =
      this.document.getElementsByClassName('canvasStyle')[0].clientWidth;
    this.canvas.height =
      this.document.getElementsByClassName('canvasStyle')[0].clientHeight;

    if (this.renderer) {
      this.renderer.setSize(this.canvas.width, this.canvas.height);
    }
  }

  public initializeThreeJS(): void {
    this.createGLTFLoader();
    this.createSceneAndCamera();
    this.setDefaultPlane();
    this.loadSceneObject();
    this.createLight();
    this.startRenderingLoop();
  }

  public onMouseDown(event: boolean) {
    this.mouseDown = event;
    if (this.mouseDown === undefined) {
      this.resetObjectToDefaultState();

      this.startTween.start();
    } else {
      this.startTween.stop();
    }
  }

  private resetObjectToDefaultState(): void {
    const resetQuaternion = new Quaternion();
    new TWEEN.Tween({ quat: this.mesh.quaternion })
      .to({ quat: resetQuaternion }, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(this.resetRotation)
      .start();
  }

  private createSceneAndCamera(): void {
    this.scene = new Scene();
    const aspectRatio = this.getAspectRatio();

    const direction = new Vector3(1.5, 0, 2);
    const position = new Vector3(0, 1, -3);
    this.camera = new PerspectiveCamera(
      this.cameraProperties.fieldOfView,
      aspectRatio,
      this.cameraProperties.nearClippingPane,
      this.cameraProperties.farClippingPane
    );
    this.camera.position.set(position.x, position.y, position.z);
    this.camera.lookAt(direction);
    this.camera.updateProjectionMatrix();
  }

  public createLight(): void {
    this.bulbLight = new PointLight(0xffee88, 0.5, 50, 2);
    this.bulbLight.position.set(3, 3, -3);
    this.scene.add(this.bulbLight);

    this.hemiLight = new HemisphereLight(
      TextureColorEnum.neutral1,
      TextureColorEnum.warm1,
      0.5
    );
    this.scene.add(this.hemiLight);

    this.light = new SpotLight(0xffa95c, 0.5);
    this.light.position.set(5, 5, -5);
    this.light.castShadow = true;
    this.light.shadow.bias = -0.0001;
    this.light.shadow.mapSize.width = 1024 * 4;
    this.light.shadow.mapSize.height = 1024 * 4;
    this.scene.add(this.light);
  }

  public navigate(route: string): void {
    this.router.navigate([route]);
  }

  private getAspectRatio(): number {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  private createGLTFLoader(): void {
    this.manager = new LoadingManager();
    this.manager.addHandler(/\.dds$/i, new DDSLoader());
    this.gltfLoader = new GLTFLoader(this.manager);
    this.onLoadManager();
  }

  private onLoadManager(): void {
    this.manager.onProgress = (item: any, loaded: number, total: number) => {
      this.showLoader();
      if (loaded === total) {
        this.hideLoader();
        this.automaticRotation();
      }
    };
  }

  private startRenderingLoop(): void {
    this.canvas.width =
      this.document.getElementsByClassName('canvasStyle')[0].clientWidth;
    this.canvas.height =
      this.document.getElementsByClassName('canvasStyle')[0].clientHeight;

    this.renderer = ThreeRendererBuilder.createRenderer(this.canvas);
    this.recursiveRender();
  }

  private setDefaultPlane(): void {
    const geometry = new PlaneGeometry(20, 5);
    const material = this.createTextureForGeometry();

    this.defaultPlane = new Mesh(geometry, material);
    this.defaultPlane.receiveShadow = true;
    this.defaultPlane.lookAt(new Vector3(0, 1, 0));
    this.defaultPlane.rotateZ(MathUtil.deg2rad(18));
    this.defaultPlane.updateMatrix();
    this.defaultPlane.updateMatrixWorld();
    this.defaultPlane.visible = false;
    this.scene.add(this.defaultPlane);
  }

  private showLoader(): void {
    this.loaderService.show(false);
    this.loaderService.permanent = true;
  }

  private hideLoader(): void {
    setTimeout(() => {
      this.loaderService.permanent = false;
      this.loaderService.hide();
    }, 300);
  }

  private createTextureForGeometry(): MeshLambertMaterial {
    const floorMat = new MeshLambertMaterial();
    floorMat.side = DoubleSide;
    const textureLoader = new TextureLoader();
    textureLoader.load(`${this.baseUrl}grass-paint-daubs.png`, (map) => {
      map.wrapS = RepeatWrapping;
      map.wrapT = RepeatWrapping;
      map.repeat.set(80, 40);
      floorMat.map = map;
      floorMat.needsUpdate = true;
    });

    return floorMat;
  }

  private resetRotation = (object: { quat: Quaternion }): void => {
    this.mesh.quaternion.copy(object.quat);
  };

  private updateRotation = (object: { rotY: number }): void => {
    this.mesh.rotation.y = object.rotY;
  };

  private automaticRotation(): void {
    if (this.mouseDown === undefined) {
      this.startTween.chain(this.baseTween);
      this.baseTween.chain(this.endTween);
      this.endTween.chain(this.startTween);

      this.startTween.onUpdate(this.updateRotation);
      this.baseTween.onUpdate(this.updateRotation);
      this.endTween.onUpdate(this.updateRotation);
      this.startTween.start();
    }
  }

  private handleRotation(): void {
    if (!this.mesh) {
      return;
    }

    if (!this.mouseDown) {
      const drag = 0.95;
      const minDelta = 0.05;

      if (
        this.rotateDir.deltaX < -minDelta ||
        this.rotateDir.deltaX > minDelta
      ) {
        this.rotateDir.deltaX *= drag;
      } else {
        this.rotateDir.deltaX = 0;
      }

      if (
        this.rotateDir.deltaY < -minDelta ||
        this.rotateDir.deltaY > minDelta
      ) {
        this.rotateDir.deltaY *= drag;
      } else {
        this.rotateDir.deltaY = 0;
      }

      this.rotateDir.handleRotation();
    }
  }

  private recursiveRender = (): void => {
    this.animationFrameId = requestAnimationFrame(this.recursiveRender);

    this.handleRotation();
    TWEEN.update();

    this.renderer.render(this.scene, this.camera);
  };

  private loadSceneObject(): void {
    const loadingUrl = `${this.baseUrl}Landing-Page-4.glb`;
    /** Loading the resorce with GLTF Loader */
    this.gltfLoader.load(loadingUrl, (gltf) => {
      this.mesh = gltf.scene;
      ThreeUtils.traverseObjectAndAddProperties(this.mesh);

      this.mesh.position.set(0, -0.04, 0);
      this.mesh.scale.set(1.5, 1.5, 1.5);
      this.scene.add(this.mesh);
      this.defaultPlane.visible = true;
      this.camera.updateProjectionMatrix();
    });
  }
}
