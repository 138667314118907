import {
  Component,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  ViewContainerRef,
  ViewChild,
  OnDestroy,
  Injector,
} from '@angular/core';

import {
  ModalManager,
  DesignProposal,
  ObjectTypeEnum,
  ThreeObjectControlsEnum,
  ThreeStateEnum,
  PathObject,
  CustomToasterComponent,
  FurbanUtil,
  KeyCode,
  Project,
  TextureColorEnum,
  CanvasUtils,
  Emitted3DObject,
  FurbanMeshPosition,
  Opened3DSections,
  PathObjectsService,
  Project3dModel,
  ProjectOverview,
  RIGHT_MENU_TOUR_ITEMS,
  ObjectUtil,
  ProjectStatusEnum,
  ProjectStatusNameEnum,
  RowOfObjects,
  FreeshapeHelper,
  ThreeFreeshapeUtil,
  ThreeGLTFExportHelper,
  ThreeMultiselect,
  ThreeRendererBuilder,
  ThreeTransformControlsBuilder,
  ThreeUtils,
  StepperService,
  DesignProposalService,
  ProjectTypeEnum,
  ToolingActionsEnum,
  TourEvent,
  ThreeGroupEnum,
  ThreeGroupBuilder,
  UploadedObjectTypeEnum,
  UploadedObjectNameEnum,
  ThreeStore,
  getUserObjects,
  getDefaultObjects,
  getDefaultDesignObjects,
  restoreInitialState,
  selectActualState,
  ShepherdTourService,
  ObjAndTextureFiles,
  FileExtensionEnum,
  Project3DUpload,
  addObjectRequest,
  deleteObjectRequest,
  deleteMultipleObjectsRequest,
  updateObjectRequest,
  addMultipleObjectsRequest,
  updateMultipleObjectsRequest,
  undoRequest,
  redoRequest,
  setInitialAvailableBudget,
  restartDesignRequest,
  getLiveObjects,
  MediaService,
  ThreeObjectsEnum,
  UploadFile,
} from '@furban/utilities';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import TWEEN from '@tweenjs/tween.js';
import { PublicThreeJsEditorComponent } from '../public-three-js-editor/public-three-js-editor.component';
import {
  Vector3,
  Object3D,
  Vector2,
  Quaternion,
  Raycaster,
  Group,
  Mesh,
  Matrix4,
  Intersection,
  Sphere,
  Event,
} from 'three';
import { ImageUploadService } from '../image-upload/image-upload.service';
import * as MyEarcut from 'earcut';
import { Subject, Observable, Subscription, from } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { ProjectOverviewDialogComponent } from '../../project-shared/project-overview-dialog/project-overview-dialog.component';
import { PublishPopupComponent } from '../../project-shared/publish-popup/publish-popup.component';
import { GenerateGreenComponent } from '../../project-shared/user-project/generate-green/generate-green.component';
import { RowOfObjectsDialogComponent } from '../../project-shared/user-project/row-of-objects-dialog/row-of-objects-dialog.component';

import { ToolingService } from '../tooling/tooling.service';
import { Router } from '@angular/router';
import { Store, State } from '@ngrx/store';

import { Point } from '@angular/cdk/drag-drop';

@Component({
  selector: 'furban-ngrx-three-editor',
  templateUrl: './ngrx-three-editor.component.html',
  styleUrls: ['./ngrx-three-editor.component.scss'],
})
export class NgrxThreeEditorComponent
  extends PublicThreeJsEditorComponent
  implements AfterViewInit, OnDestroy
{
  @Input() defaultObjects?: PathObject[];
  @Input() availableBudget?: number;
  @Input() designProposalId?: string;
  @Output() screenshotTaken? = new EventEmitter();
  @Output() isEditMode? = new EventEmitter();
  @Output() finishedDrawing = new EventEmitter();
  @Output() projectPriceChange? = new EventEmitter();
  @Output() disabledTool = new EventEmitter();
  @Output() removeUploadObjectFromMenu? = new EventEmitter();
  @ViewChild('clickMenuTrigger') clickMenuTrigger: MatMenuTrigger;
  public threeGrouping: ThreeMultiselect;
  public addOnClickObject: Project3dModel;
  public deleteObjectsList: PathObject[] = [];
  public threeFreeshapeUtil: ThreeFreeshapeUtil;
  public isSquareOrElipseOrCustom = ObjectUtil.isSquareOrElipseOrCustom;
  public bindedTouchDrag = this.onTouchDragMove.bind(this);
  public bindedMouseDrag = this.onMouseDragMove.bind(this);
  public bindedObjectChange = this.onObjectChange.bind(this);
  public bindedChange = this.onThreeObjectChange.bind(this);
  public bindedDraggingObject = this.onDraggingObject.bind(this);
  public bindedShiftEvent = this.onClickingWithShiftPressed.bind(this);
  public bindedShiftPressing = this.shiftPressing.bind(this);
  public bindedShiftRelease = this.shiftRelease.bind(this);
  public isSafetyEnabled: boolean = this.navigationService.isSafetyEnabled;
  public isFullScreen = false;
  public rightMenuTourItems = RIGHT_MENU_TOUR_ITEMS;
  public override isMobile = FurbanUtil.isMobile();
  public lastDeletedObjects: Object3D[] = [];
  public isManagingCustomObject = false;

  public imageUploadService: ImageUploadService;
  public pathObjectsService: PathObjectsService;
  public stepperService: StepperService;
  public designProposalService: DesignProposalService;
  public override toolingService: ToolingService;
  public router: Router;
  public shepherdTourService: ShepherdTourService;
  public snackBar: MatSnackBar;
  public override viewContainerRef: ViewContainerRef;

  private isTransparent = false;

  private raycaster = new Raycaster();
  private isShiftPressed = new Subject<boolean>();
  private isAltPressed = new Subject<boolean>();
  private isAltPressedValue = false;
  private toolingActionsSubscription: Subscription;
  private previousScaleX = 1;
  private previousScaleZ = 1;
  private changingValue = 0;
  private safetyAreaSubscription: Subscription;
  private transparentSubscription: Subscription;
  private tourSubscription: Subscription;

  private objectsToRemoveStore: PathObject[];
  private objectsToAddStore: PathObject[];
  private disabledEventsOnTour = false;

  private isEditingCustomObject = false;
  private isEditingUnderground = false;

  constructor(
    protected override injector: Injector,
    protected store: Store<{ store: ThreeStore }>,
    protected mediaService: MediaService,
    protected currentAppState: State<ThreeStore>
  ) {
    super(injector);
    this.shepherdTourService =
      injector.get<ShepherdTourService>(ShepherdTourService);
    this.imageUploadService =
      injector.get<ImageUploadService>(ImageUploadService);
    this.pathObjectsService =
      injector.get<PathObjectsService>(PathObjectsService);
    this.stepperService = injector.get<StepperService>(StepperService);
    this.designProposalService = injector.get<DesignProposalService>(
      DesignProposalService
    );
    this.toolingService = injector.get<ToolingService>(ToolingService);
    this.router = injector.get<Router>(Router);
    this.snackBar = injector.get<MatSnackBar>(MatSnackBar);
    this.viewContainerRef = injector.get<ViewContainerRef>(ViewContainerRef);
    this.subscribeToEvents();
  }

  override ngOnDestroy(): void {
    cancelAnimationFrame(this.animationFrameId);
    ThreeUtils.disposeThreeElement(this.threeInstance.scene);
    this.removeEventsFromCanvas();
    this.threeInstance.renderer.dispose();
    delete this.threeInstance.scene;
    delete this.threeInstance.camera;
    delete this.threeInstance.renderer;
    delete this.threeInstance.htmlRenderer;
    this.snackBar.dismiss();
    this.resetVisibility();
    this.unsubscribeFromObservable();
    this.threeInstance.objectsToCopy = [];
    this.store.dispatch(restoreInitialState());
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.subscribeToStore();
    this.checkIfDesignIsPublished();
    this.onLoadManager();
    this.subscribeToToolingActionObservable();
    this.subscribeToObjectAdded();
    this.subscribeToUploadedObjectUpdate();
    this.callForObjects();
    this.stepperService.modifyCurrentStepId(false);
  }

  public get instanceIntersectedObject(): Object3D {
    return this.threeInstance.intersectedObject;
  }

  public set instanceIntersectedObject(object: Object3D) {
    this.threeInstance.intersectedObject = object;
  }

  public get instanceMultiselectGroup(): Group {
    return this.threeInstance.multiselectGroup;
  }

  public set instanceMultiselectGroup(group: Group) {
    this.threeInstance.multiselectGroup = group;
  }

  public getModifiedShift(): Observable<boolean> {
    return this.isShiftPressed.asObservable();
  }

  public getModifiedAlt(): Observable<boolean> {
    return this.isAltPressed.asObservable();
  }

  public addEventsForClickSelection(): void {
    this.threeInstance.renderer.domElement.removeEventListener(
      'pointerdown',
      this.onMouseClick
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'pointerdown',
      this.bindedShiftEvent,
      { passive: false }
    );
  }

  public removeEventsForClickSelection(): void {
    if (this.threeInstance.renderer) {
      this.threeInstance.renderer.domElement.removeEventListener(
        'pointerdown',
        this.bindedShiftEvent
      );
      this.threeInstance.renderer.domElement.addEventListener(
        'pointerdown',
        this.onMouseClick,
        { passive: false }
      );
    }
  }

  public override onLoadManager(): void {
    this.manager.onProgress = (item: any, loaded: number, total: number) => {
      this.showLoader();
      if (loaded === total) {
        this.hideLoader();
        if (this.isSafetyEnabled) {
          this.enableSafetyArea();
        }
      }
    };
  }

  public checkIfDesignIsPublished(): void {
    if (this.isAdminOnDefaultDesign) {
      this.threeInstance.isPublished =
        this.project.projectStatus.statusWeight === ProjectStatusEnum.published;
      return;
    }

    if (this.authService.isCitizen()) {
      this.publishService
        .getPublishedDesignByProject(this.project.projectId)
        .subscribe((data) => {
          this.toolingService.toolingVisibility.isPublished = !!data;
          this.threeInstance.isPublished = !!data;
        });
    }
  }

  public changeControlsOnObject(
    controlType: string,
    objectType: ObjectTypeEnum,
    event?: any
  ): void {
    if (event) {
      event.eventType = this.threeInstance.changeControl;
    }
    const isSquareOrElipse = ObjectUtil.isSquareOrElipse(objectType);
    const isCustom = ObjectUtil.isCustom(objectType);

    if (controlType === ThreeObjectControlsEnum.move) {
      ThreeUtils.setMoveMode(this.threeInstance);
    }

    if (controlType === ThreeObjectControlsEnum.rotate) {
      ThreeUtils.setRotateMode(this.threeInstance);
    }

    if (
      controlType === ThreeObjectControlsEnum.scale &&
      (isCustom || isSquareOrElipse)
    ) {
      ThreeUtils.setScaleMode(this.threeInstance, isCustom);
      this.getModifiedAlt()
        .pipe(distinctUntilChanged())
        .subscribe((response) => {
          this.isAltPressedValue = response;
        });

      if (!this.isMobile) {
        this.open3DNotificationSnackbar('userSettings.altScaling');
      }
    }
  }

  discardTransformControls(): void {
    if (this.threeInstance.transformControls) {
      this.threeInstance.transformControls.detach();
    }
  }

  getIntersectedObject = (event) => {
    if (!this.threeInstance.camera || !this.threeInstance.renderer) {
      return;
    }

    const container =
      this.threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoordinates = ThreeUtils.getMouseCoordinatesInContainer(
      event,
      container
    );

    if (!this.isManagingCustomObject) {
      this.manageIntersectedObject(mouseCoordinates);
      return;
    }
    this.manageCustomUploadedObject(mouseCoordinates);
  };

  public manageIntersectedObject(mouseCoordinates: Vector2) {
    const newIntersectedObject = ThreeUtils.getIntersectedObject(
      this.threeInstance,
      mouseCoordinates,
      this.authService.isCitizenOrExpert,
      this.designProposalId != null
    );

    if (
      newIntersectedObject &&
      newIntersectedObject !== this.instanceIntersectedObject
    ) {
      this.instanceIntersectedObject = newIntersectedObject;
      this.removeMultiselectGroup();
      this.setControlsMode(newIntersectedObject);
      if (!this.isIOS) {
        this.open3DNotificationSnackbar('userSettings.deselectMessage');
      }
    }

    if (!this.instanceIntersectedObject && !this.instanceMultiselectGroup) {
      this.discardTransformControls();
    } else if (this.instanceIntersectedObject) {
      this.removeMultiselectGroup();
      this.getIntersectedObjectType();
      this.updateButtonsOnIntersectedObject();
    }
  }

  closeButtons(event): void {
    if (
      this.fileUploadService.isUndergroundUploaded ||
      this.fileUploadService.isCustomObjectUploaded
    ) {
      this.showToaster('warning', 'info', 'errors.closeControls', 20000);
    } else {
      event.stopPropagation();
      if (document.getElementsByTagName('mat-tooltip-component').length > 0) {
        document.getElementsByTagName('mat-tooltip-component')[0].remove();
      }
      this.removeControlsFromObject();
    }
  }

  removeIntersectedObject(): void {
    if (this.threeInstance.intersectedObject) {
      this.threeInstance.intersectedObject.remove(
        this.threeInstance.controlBtns
      );
      this.threeInstance.intersectedObject.remove(
        this.threeInstance.lockedInfo
      );
      this.discardTransformControls();
      this.threeInstance.intersectedObject = null;
      this.threeInstance.currentFocusedObject = null;
    }
  }

  removeMultiselectGroup(): void {
    if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData['selected']
    ) {
      this.instanceMultiselectGroup.remove(this.threeInstance.controlBtns);
      this.threeInstance.selectionObjects = [];
    }
    this.disableMultiselect();
  }

  public removeControlsFromObject(disableTool: boolean = false) {
    this.snackBar.dismiss();
    this.discardTransformControls();
    this.removeIntersectedObject();
    this.removeMultiselectGroup();
    if (disableTool) {
      this.disabledTool.emit();
    }
  }

  public onDoubleClick = (): void => {
    if (this.disabledEventsOnTour) {
      return;
    }
    this.removeControlsFromObject(true);
    this.threeInstance.mylatesttap = new Date().getTime();
  };

  public isChangeControlEvent(event: any): boolean {
    return (
      event.eventType && event.eventType === this.threeInstance.changeControl
    );
  }

  public checkIfRightClickAndRemoveSelection(event: MouseEvent): void {
    if (event.button === 2) {
      this.menuService.objectToAddOnClick = null;
      this.addOnClickObject = null;
      this.removeControlsFromObject();
    }
  }

  public override onMouseClick = (event): void => {
    if (
      !this.threeInstance.controls.enabled ||
      this.isChangeControlEvent(event)
    ) {
      return;
    }
    this.checkIfRightClickAndRemoveSelection(event);
    this.checkIfNeedToAddOnClick(event);
  };

  public isBudgetExceded(addedObjectPrice: number): boolean {
    return this.project.price && this.availableBudget < addedObjectPrice;
  }

  public addObjectDependingOnPriceRestriction(pathObject: PathObject): void {
    if (!this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.dispatchAddObjectRequest(pathObject, false);
    } else {
      this.checkIfWithinBudgetAndAdd(pathObject);
    }
  }

  public checkIfWithinBudgetAndAdd(pathObject: PathObject): void {
    if (
      this.isBudgetExceded(this.addOnClickObject.price) &&
      this.project.isPriceRestrictionEnabled
    ) {
      this.showBudgetExcededToaster();
    } else {
      this.availableBudget = this.availableBudget - this.addOnClickObject.price;
      this.projectPriceChange.emit(this.availableBudget);
      this.dispatchAddObjectRequest(pathObject, false, this.availableBudget);
    }
  }

  public override getUploadObjectsOvrr() {
    this.getUploadedObjects([this.designProposalId, this.project.projectId]);
  }

  public checkIfNeedToAddOnClick(event: MouseEvent): void {
    if (this.addOnClickObject) {
      const meshPosition = new FurbanMeshPosition(
        ThreeUtils.getIntersectionOfEventWithGroup(this.threeInstance, event),
        0
      );

      if (!this.checkIfShouldAdd(this.addOnClickObject, meshPosition)) {
        this.showToaster(
          'warning',
          'info',
          'errors.objectNotAddedOutside',
          20000
        );
        return;
      }

      const pathObject = ThreeUtils.convertProject3DModelToPathObject(
        this.addOnClickObject,
        meshPosition,
        this.getProjectAndPathId(),
        this.authService.hasAdministrativeRole(),
        this.designProposalId
      );
      this.addObjectDependingOnPriceRestriction(pathObject);
    } else {
      this.getIntersectedObject(event);
    }
  }

  public override startRendering(): void {
    const openedSections = new Opened3DSections(
      this.menuService.menuOpened,
      this.navigationService.leftNavOpened,
      !this.editMode,
      this.navigationService.isFullScreen
    );
    ThreeUtils.setCanvasDimensions(
      this.canvas,
      this.threeInstance.camera,
      openedSections
    );

    this.threeInstance.renderer = ThreeRendererBuilder.createRenderer(
      this.canvas
    );

    if (this.state === ThreeStateEnum.default) {
      this.setupControlsOnRenderer();
    }

    this.recursiveRender();
  }

  public override recursiveRender = (): void => {
    this.animationFrameId = requestAnimationFrame(this.recursiveRender);
    TWEEN.update(TWEEN.now());
    this.render();
  };

  public disableSafetyArea(): void {
    this.threeInstance.objectsRegular.children.forEach((element: any) => {
      ThreeUtils.setHexColorOnMaterial(element, TextureColorEnum.neutral0);
      this.traverseSafetyHelper(element, false);
    });
  }

  public traverseSafetyHelper(element: Object3D, visible: boolean): void {
    element.traverse((child) => {
      if (child.name === ThreeUtils.sphereHelper) {
        child.visible = visible;
      }
    });
  }

  public setSafetyHelperVisibility(
    objects: Object3D[],
    visible: boolean
  ): void {
    objects.forEach((element) => {
      this.traverseSafetyHelper(element, visible);
    });
  }

  public createSphereBounding(radius: number, pos: Vector3) {
    const sphere = new Sphere(pos, radius);
    return sphere;
  }

  public enableSafetyArea(): void {
    this.disableSafetyArea();
    const objects = this.getObjectsWithSafetyArea();
    this.setSafetyHelperVisibility(objects, true);
    const allObjects = this.threeInstance.objectsRegular.children;

    for (let i = 0; i < objects.length; i++) {
      const firstObject: any = objects[i];
      const safetySphere1 = this.createSphereBounding(
        firstObject.userData['obb'],
        firstObject.position
      );

      for (let j = 0; j < allObjects.length; j++) {
        const secondObject: any = allObjects[j];
        if (secondObject === firstObject) {
          continue;
        }
        const safetySphere2 = this.createSphereBounding(
          secondObject.userData['obb'],
          secondObject.position
        );

        if (safetySphere1.intersectsSphere(safetySphere2)) {
          ThreeUtils.setHexColorOnMaterial(
            firstObject,
            TextureColorEnum.alert3
          );
          ThreeUtils.setHexColorOnMaterial(
            secondObject,
            TextureColorEnum.alert3
          );
        }
      }
    }
  }

  public checkSafetyAreaCollision(): boolean {
    const objects = this.getObjectsWithSafetyArea();
    const allObjects = this.threeInstance.objectsRegular.children;

    for (let i = 0; i < objects.length; i++) {
      const firstObject = objects[i];
      const safetySphere1 = this.createSphereBounding(
        firstObject.userData['obb'],
        firstObject.position
      );

      for (let j = 0; j < allObjects.length; j++) {
        const secondObject = allObjects[j];
        if (
          secondObject === firstObject ||
          (this.authService.isCitizen() &&
            (firstObject.userData['default'] ===
              secondObject.userData['default']) ===
              true)
        ) {
          continue;
        }
        const safetySphere2 = this.createSphereBounding(
          secondObject.userData['obb'],
          secondObject.position
        );

        if (safetySphere1.intersectsSphere(safetySphere2)) {
          return true;
        }
      }
    }
    return false;
  }

  public getObjectsWithSafetyArea(): Object3D<any>[] {
    const regularObjects = this.threeInstance.objectsRegular.children;
    const filteredObjects = regularObjects.filter(
      (obj) => obj.userData['safetyArea'] > 0
    );
    return filteredObjects;
  }

  public switchFullScreenMode(): void {
    this.isFullScreen = !this.isFullScreen;
    this.navigationService.toggleFullScreen(this.isFullScreen);
    this.toolingService.toolingVisibility.isFullScreen = this.isFullScreen;
  }

  public override render(): void {
    this.updateAnimationMixers(!this.editMode);

    if (this.threeInstance.scene && this.threeInstance.camera) {
      this.threeInstance.renderer.render(
        this.threeInstance.scene,
        this.threeInstance.camera
      );
      if (this.threeInstance.htmlRenderer) {
        this.threeInstance.htmlRenderer.render(
          this.threeInstance.scene,
          this.threeInstance.camera
        );
      }
    }
  }

  public setupControlsOnRenderer(): void {
    this.threeInstance.htmlRenderer = ThreeRendererBuilder.createHTMLRenderer(
      this.threeInstance.renderer.domElement
    );
    ThreeTransformControlsBuilder.addTransformControls(this.threeInstance);
    this.threeInstance.controlBtns = ThreeUtils.getControlsHTMLElement();
    this.threeInstance.lockedInfo = ThreeUtils.getLockedInfoHTMLElement();
    this.addEventsOnCanvas();
  }

  public takeScreenshot(): void {
    this.toggleGridView(false);
    this.removeControlsFromObject();
    const img = new Image();
    // Without 'preserveDrawingBuffer' set to true, we must render now
    this.threeInstance.renderer.render(
      this.threeInstance.scene,
      this.threeInstance.camera
    );
    img.src = this.threeInstance.renderer.domElement.toDataURL();
    this.openImageUploadDialog(img);
  }

  public takeScreenshotForDefaultDesignProposal(): void {
    this.toggleGridView(false);
    this.removeControlsFromObject();
    this.threeInstance.renderer.render(
      this.threeInstance.scene,
      this.threeInstance.camera
    );
    const fullQualityImage = this.threeInstance.renderer.domElement.toDataURL();

    this.takeScreenshotWithCallback(fullQualityImage, (data: string) => {
      this.publishService.designProposal.media = data;
      this.designProposalService
        .saveAdminDesignProposal(this.publishService.designProposal)
        .subscribe(() => {
          this.showToaster(
            'check_circle',
            'success',
            'user.publish.screenShotTaken',
            20000
          );
        });
    });
  }

  public changeScreenshot(): void {
    this.state = ThreeStateEnum.screenshot;
    this.toolingService.toolingVisibility.state = this.state;
  }

  public takeScreenshotWithCallback(
    fullQualityImage: string,
    callbackMethod: any
  ): void {
    const parts = fullQualityImage.split(',');
    this.imageUploadService
      .uploadPhoto(
        fullQualityImage.length,
        this.getFileName(),
        parts[1],
        this.imageUploadService.getContentType(parts[0]),
        '/project'
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        callbackMethod(data);
      });
  }

  public takeDefaultScreenshot(): void {
    this.toggleGridView(false);

    this.navigationService.toggleSafetyArea(false);
    this.navigationService.toggleTransparency(false);
    const objectsInsideThreeJs = this.getObjectsInsideCanvas();

    this.userObjects = ThreeUtils.convert3DObjectsToPathObjects(
      objectsInsideThreeJs,
      this.authService.hasAdministrativeRole(),
      this.designProposalId != null
    );
    this.removeControlsFromObject();
    // Without 'preserveDrawingBuffer' set to true, we must render now
    setTimeout(() => {
      this.threeInstance.renderer.render(
        this.threeInstance.scene,
        this.threeInstance.camera
      );
      const fullQualityImage =
        this.threeInstance.renderer.domElement.toDataURL();
      this.takeScreenshotWithCallback(fullQualityImage, (data: string) => {
        this.publishService.designProposal.mediaId = data;
        this.state = ThreeStateEnum.default;
        this.toolingService.toolingVisibility.state = this.state;
        this.screenshotTaken.emit();
        this.publishAfterScreenshotTaken();
      });
    }, 200);
  }

  public takeDefaultScreenshotLiveCollaboration(): void {
    this.threeInstance.renderer.render(
      this.threeInstance.scene,
      this.threeInstance.camera
    );
    const fullQualityImage = this.threeInstance.renderer.domElement.toDataURL();
    this.takeScreenshotWithCallback(fullQualityImage, (data: string) => {
      this.designProposal.media = data;
      this.state = ThreeStateEnum.default;
      this.toolingService.toolingVisibility.state = this.state;
      this.designProposalService
        .saveAdminDesignProposal(this.designProposal)
        .subscribe((data) => {
          this.designProposal = data as DesignProposal;
        });
    });
  }

  openImageUploadDialog(img: HTMLImageElement): void {
    const imageDialogRef = this.dialog.open(ImageUploadComponent);
    imageDialogRef.disableClose = true;
    imageDialogRef.componentInstance.parentRef = this.viewContainerRef;
    imageDialogRef.componentInstance.imageWidth = 1800;
    imageDialogRef.componentInstance.imageHeight = 1000;
    imageDialogRef.componentInstance.imageUploadPath = '/project';
    imageDialogRef.componentInstance.imageToCrop = img;

    const closeProfileSub =
      imageDialogRef.componentInstance.onImageUploadClose.subscribe((data) => {
        if (data) {
          this.publishService.designProposal.mediaId = data;
        }
        this.state = ThreeStateEnum.default;
        this.toolingService.toolingVisibility.state = this.state;
        this.screenshotTaken.emit();
        this.openPublishDialog();
        imageDialogRef.close();
      });

    imageDialogRef.afterClosed().subscribe((result) => {
      closeProfileSub.unsubscribe();
    });
  }

  public toggleGridView(showGrid?: boolean): void {
    const grid = this.threeInstance.groundGroup.children.find(
      (obj) => obj.name === ThreeUtils.gridHelperName
    );
    if (!grid) {
      return;
    }
    grid.visible = showGrid === undefined ? !grid.visible : showGrid;
  }

  onToggleEditChange(event: boolean): void {
    this.editMode = event ? event : !this.editMode;
    this.isEditMode.emit(this.editMode);
    this.toolingService.toolingVisibility.editMode = this.editMode;

    if (this.editMode) {
      this.state = ThreeStateEnum.default;
      this.toolingService.toolingVisibility.state = this.state;
      this.addEventsOnCanvas();
    } else {
      this.toggleGridView(false);
      this.state = ThreeStateEnum.view;
      this.toolingService.toolingVisibility.state = this.state;
      this.removeControlsFromObject();
      this.removeEventsFromCanvas();
      this.addOnClickObject = null;
      this.menuService.objectToAddOnClick = null;
      this.navigationService.safetyAreaToggled.emit(false);
    }
    this.onResize();
  }

  isPioneerOrAdminLoggedIn(): boolean {
    return this.authService.hasAdministrativeRole();
  }

  onMouseDragMove(event: any): void {
    this.threeInstance.lastEvent = event;
  }

  onTouchDragMove(event: any): void {
    this.threeInstance.lastEvent = event.changedTouches[0];
    const canvasElemRef = document.getElementById('canvasThreeJS');
    if (event.target === canvasElemRef) {
      event.preventDefault();
    }
  }

  onDraggingObject(event: any): void {
    this.threeInstance.controls.enabled = !event.value;

    const transformMode = this.threeInstance.transformControls.getMode();
    if (
      transformMode !== ThreeObjectControlsEnum.move ||
      this.instanceIntersectedObject?.userData['placeOutside']
    ) {
      return;
    }
    this.changeCanvasColor(event.value);
  }

  applyVerticalCollision(
    ray: Raycaster,
    originMesh: Mesh,
    collidingObject: Group
  ): Intersection[] {
    const direction = new Vector3(0, -1, 0);
    const origin = new Vector3();
    // Setting the origin of the ray using the object from TransformControl
    origin.set(originMesh.position.x, 50, originMesh.position.z);
    ray.set(origin, direction);

    return ray.intersectObjects(collidingObject.children, true);
  }

  onObjectChange(): void {
    const transformMode = this.threeInstance.transformControls.getMode();
    const object = this.threeInstance.transformControls.object as Mesh;
    object.remove(this.threeInstance.controlBtns);

    let customObject;
    if (!this.isManagingCustomObject) {
      customObject = this.threeInstance.uploadedObjectHelper?.getObjectByName(
        UploadedObjectNameEnum.customObject
      );
    }

    switch (transformMode) {
      case ThreeObjectControlsEnum.rotate:
        ThreeUtils.resetRotationIfExceeding(object, this.threeInstance);
        break;
      case ThreeObjectControlsEnum.scale:
        this.onObjectScale(object);
        break;
      default:
        break;
    }

    if (customObject) {
      const res = this.applyVerticalCollision(
        this.raycaster,
        object,
        customObject
      );
      this.setPositionBasedOnIntersectionWithCustomDesign(object, res);
    }

    if (this.isSafetyEnabled) {
      this.enableSafetyArea();
    }
  }

  private computeGeometriesInsideMesh(mesh: Group): Mesh {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedGeometries = ThreeUtils.computeMergedGeometries(mesh as any);
    return new Mesh(mergedGeometries);
  }

  private generatePipesCollider(mesh: Group): Mesh {
    const cloneMesh = mesh.clone();
    cloneMesh.position.y = 0;

    return this.computeGeometriesInsideMesh(cloneMesh);
  }

  private isInCollision(customPipes: Group, objectToTest: Group): boolean {
    const pipesCollider = this.generatePipesCollider(customPipes);
    const objectCollider = this.computeGeometriesInsideMesh(objectToTest);

    const transformMatrix = new Matrix4()
      .copy(pipesCollider.matrixWorld)
      .invert()
      .multiply(objectCollider.matrixWorld);

    return pipesCollider.geometry['boundsTree'].intersectsGeometry(
      objectCollider.geometry,
      transformMatrix
    );
  }

  private isVerticalCollision = (object: Object3D | Mesh | Group): boolean => {
    return object.userData['verticalCollision'] === true;
  };

  private isCollisionOnPipes(verticalObjectsCollision: Group[]): boolean {
    if (!this.threeInstance.uploadedObjectHelper) {
      return;
    }
    const customPipes: any =
      this.threeInstance.uploadedObjectHelper.getObjectByName(
        UploadedObjectNameEnum.underground
      );

    if (!customPipes) {
      return;
    }

    const source = from(verticalObjectsCollision);

    source
      .pipe(
        filter(
          (object) =>
            this.isVerticalCollision(object) &&
            this.isInCollision(customPipes, object)
        ),
        take(1)
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }

        this.showToaster(
          'warning',
          'info',
          'user.publish.verticalAreaWarning',
          20000
        );
      });
  }

  onThreeObjectChange(): void {
    if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData['selected'].length > 0
    ) {
      this.updateMultipleSelectedObjects();
      this.instanceMultiselectGroup.add(this.threeInstance.controlBtns);
    }

    if (this.instanceIntersectedObject && !this.isManagingCustomObject) {
      this.updateIntersectedObject();
      this.threeInstance.intersectedObject.add(this.threeInstance.controlBtns);
    }

    if (this.instanceIntersectedObject && this.isManagingCustomObject) {
      this.threeInstance.intersectedObject.add(this.threeInstance.controlBtns);
    }
  }

  setPositionBasedOnIntersectionWithCustomDesign(
    originMesh: Mesh,
    intersects: any[]
  ): void {
    if (intersects.length > 0) {
      originMesh.position.y =
        intersects[0].point.y + ThreeUtils.defaultZFightingOffset;
    } else {
      originMesh.position.y = 0;
    }
  }

  addOrRemoveSelectionOnIntersectedObj(newIntersectedObject: any): void {
    const index =
      this.threeInstance.selectionObjects.indexOf(newIntersectedObject);
    if (index > -1) {
      ThreeUtils.setHexColorOnMaterial(
        newIntersectedObject,
        TextureColorEnum.neutral0
      );
      this.threeInstance.selectionObjects.splice(index, 1);
    } else {
      ThreeUtils.setHexColorOnMaterial(
        newIntersectedObject,
        TextureColorEnum.mildBlue
      );
      this.threeInstance.selectionObjects.push(newIntersectedObject);
    }
  }

  onClickingWithShiftPressed(event: MouseEvent | TouchEvent): void {
    const container =
      this.threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoords = ThreeUtils.getMouseCoordinatesInContainer(
      event,
      container
    );

    const newIntersectedObject = ThreeUtils.getIntersectedObject(
      this.threeInstance,
      mouseCoords,
      this.authService.isCitizenOrExpert,
      this.designProposalId != null
    );

    if (
      newIntersectedObject &&
      !this.skipIfDefaultTrueOnObject(newIntersectedObject)
    ) {
      this.addOrRemoveSelectionOnIntersectedObj(newIntersectedObject);
    }
  }

  private skipIfDefaultTrueOnObject(intersectedObject: Object3D): boolean {
    if (this.isCollaborativeDesignRoute) {
      return false;
    }

    return (
      intersectedObject.userData['default'] &&
      (!this.authService.hasAdministrativeRole() || this.isAdminDesigning())
    );
  }

  shiftPressing(event: KeyboardEvent): void {
    if (event.key === KeyCode.shift) {
      this.isShiftPressed.next(true);
    }
  }

  shiftRelease(event: KeyboardEvent): void {
    if (event.key === KeyCode.shift) {
      this.isShiftPressed.next(false);
    }
  }

  altRelease(e: KeyboardEvent): void {
    if (e.altKey) {
      this.isAltPressed.next(false);
    }
  }

  addEventsOnCanvas(): void {
    document.addEventListener('pointermove', this.bindedMouseDrag, {
      passive: false,
    });
    document.addEventListener('touchmove', this.bindedTouchDrag, {
      passive: false,
    });
    document.addEventListener('keyup', this.keyUpFunction, {
      passive: false,
    });
    document.addEventListener('keydown', this.keyDownFunction, {
      passive: false,
    });

    this.threeInstance.renderer.domElement.addEventListener(
      'touchstart',
      this.onTouchEvent,
      { passive: false }
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'pointerdown',
      this.onMouseClick,
      { passive: false }
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'dblclick',
      this.onDoubleClick,
      { passive: false }
    );

    this.threeInstance.transformControls.addEventListener(
      'objectChange',
      this.bindedObjectChange
    );
    this.threeInstance.transformControls.addEventListener(
      'mouseUp',
      this.bindedChange
    );
    this.threeInstance.transformControls.addEventListener(
      'dragging-changed',
      this.bindedDraggingObject
    );
  }

  removeEventsFromCanvas(): void {
    document.removeEventListener('pointermove', this.bindedMouseDrag);
    document.removeEventListener('touchmove', this.bindedTouchDrag);
    this.threeInstance.renderer.domElement.removeEventListener(
      'touchstart',
      this.onTouchEvent
    );
    this.threeInstance.renderer.domElement.removeEventListener(
      'pointerdown',
      this.onMouseClick
    );
    this.threeInstance.renderer.domElement.removeEventListener(
      'dblclick',
      this.onDoubleClick
    );
    document.removeEventListener('keydown', this.keyDownFunction);
    document.removeEventListener('keyup', this.keyUpFunction);

    if (this.threeInstance.transformControls) {
      this.threeInstance.transformControls.removeEventListener(
        'objectChange',
        this.bindedObjectChange
      );
      this.threeInstance.transformControls.removeEventListener(
        'mouseUp',
        this.bindedChange
      );
      this.threeInstance.transformControls.removeEventListener(
        'dragging-changed',
        this.bindedDraggingObject
      );
    }
  }

  // this is opening the citizen publish dialog
  publish(): void {
    this.takeDefaultScreenshot();
  }

  toggleOpacity(): void {
    const areaPlane: any = this.threeInstance.groundGroup.getObjectByName(
      ThreeObjectsEnum.areaPlane
    );
    areaPlane.material['opacity'] = this.isTransparent ? 0.6 : 1;
    areaPlane.material.needsUpdate = true;
  }

  publishAfterScreenshotTaken(): void {
    const dialogRef = this.dialog.open(PublishPopupComponent, {
      width: '40%',
      minWidth: '500px',
      data: {
        project: this.project,
        availableBudget: this.availableBudget,
      },
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.parentViewContainerRef = this.viewContainerRef;

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 0) {
        this.changeScreenshot();
      } else {
        this.publishService.designProposal = new DesignProposal();
      }
    });
    this.checkIfShouldDisplayCollisionMessage();
  }

  getMeshPositionOnDragOrClick(isDrag: boolean): FurbanMeshPosition {
    const meshPosition = new FurbanMeshPosition();
    meshPosition.angle = 0;
    if (isDrag) {
      meshPosition.position =
        ThreeUtils.getIntersectionOfEventWithGroundHavingCustomDesign(
          this.threeInstance,
          this.threeInstance.lastEvent
        );
    } else {
      meshPosition.position = ThreeUtils.getIntersectionFromCenterOfScreen(
        this.threeInstance
      );
    }
    meshPosition.position.y =
      meshPosition.position.y + ThreeUtils.defaultZFightingOffset;
    return meshPosition;
  }

  public changeCanvasColor(shouldHighlight): void {
    const objectToHighlight = this.threeInstance.areaPlane;
    if (shouldHighlight) {
      ThreeUtils.setHexColorOnMaterial(
        objectToHighlight,
        TextureColorEnum.sceneHighlight
      );
    } else {
      ThreeUtils.setHexColorOnMaterial(
        objectToHighlight,
        TextureColorEnum.neutral0
      );
    }
  }

  add3DObject(emittedObject: Emitted3DObject): void {
    ThreeUtils.setMoveMode(this.threeInstance);

    const meshPosition = this.getMeshPositionOnDragOrClick(
      emittedObject.isDragged
    );
    if (!this.checkIfShouldAdd(emittedObject.project3DModel, meshPosition)) {
      this.showToaster(
        'warning',
        'info',
        'errors.objectNotAddedOutside',
        20000
      );
      return;
    }

    const pathObjectTest = ThreeUtils.convertProject3DModelToPathObject(
      emittedObject.project3DModel,
      meshPosition,
      this.getProjectAndPathId(),
      this.authService.hasAdministrativeRole(),
      this.designProposalId
    );
    this.lastDeletedObjects = [];

    if (this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.availableBudget =
        this.availableBudget - emittedObject.project3DModel.price;
      this.dispatchAddObjectRequest(pathObjectTest, true, this.availableBudget);
      return;
    }

    this.dispatchAddObjectRequest(pathObjectTest, true);
  }

  showToaster(
    icon: string,
    type: string,
    message: string,
    duration: number
  ): void {
    this.customToasterService.openCustomToaster(
      CustomToasterComponent,
      icon,
      type,
      message,
      duration
    );
  }

  showBudgetExcededToaster(): void {
    const message = this.project.isPriceRestrictionEnabled
      ? 'price.excededWhenEnabledRestriction'
      : 'price.exceded';
    this.showToaster('info', 'info', message, 2000);
  }

  public drawFreeshape(
    freeshapeType: string,
    project3DModel?: Project3dModel
  ): void {
    if (
      freeshapeType === ObjectTypeEnum.freeshape &&
      project3DModel !== undefined
    ) {
      this.threeInstance.freeShapeLineDetails.freeshapeProject3DModel =
        project3DModel;
      this.threeInstance.freeShapeLineDetails.freeshapeType =
        ObjectTypeEnum.freeshape;
    } else if (freeshapeType === ObjectTypeEnum.multiple) {
      this.threeInstance.freeShapeLineDetails.freeshapeType =
        ObjectTypeEnum.multiple;
    } else {
      this.threeInstance.freeShapeLineDetails.freeshapeType =
        ObjectTypeEnum.row;
    }
    this.clearLastDrawing();
    this.removeControlsFromObject();
    this.removeEventsFromCanvas();

    this.addMouseEventsForFreeshapeDrawing();
    this.open3DNotificationSnackbar('user.project.startFreeShape');
  }

  finishUploadCustomObject(): void {
    if (
      (this.fileUploadService.currentCustomObjectFile ||
        this.fileUploadService.currentCustomObjectTextureFile) &&
      !this.fileUploadService.customObjectProject3DUpload?.project3DUploadId
    ) {
      this.finalizeUpload(UploadedObjectTypeEnum.customObject);
      this.isEditingCustomObject = false;
    }

    if (
      (this.fileUploadService.currentFixedObjectFile ||
        this.fileUploadService.currentFixedObjectFile) &&
      !this.fileUploadService.fixedObjectProject3DUpload?.project3DUploadId
    ) {
      this.finalizeUpload(UploadedObjectTypeEnum.fixedDesign);
    }

    if (
      (this.fileUploadService.currentUndergroundFile ||
        this.fileUploadService.currentUndergroundTextureFile) &&
      !this.fileUploadService.undergroundProject3DUpload?.project3DUploadId
    ) {
      this.finalizeUpload(UploadedObjectTypeEnum.underground);
      this.isEditingUnderground = false;
    }

    this.updateAlreadySavedCustomObj();

    this.instanceIntersectedObject = null;
  }

  private updateAlreadySavedCustomObj(): void {
    if (this.isEditingCustomObject) {
      this.updateSavedProject3D(
        this.fileUploadService.customObjectProject3DUpload,
        UploadedObjectNameEnum.customObject
      );
      this.isEditingCustomObject = false;
      const editedCustomObject = this.threeInstance?.scene.getObjectByName(
        UploadedObjectNameEnum.customObject
      );
      const hole = ThreeUtils.generateHoleForCustomLayer(editedCustomObject);
      this.loaderSceneGround(hole);
    }

    if (this.isEditingUnderground) {
      this.updateSavedProject3D(
        this.fileUploadService.undergroundProject3DUpload,
        UploadedObjectNameEnum.underground
      );
      this.isEditingUnderground = false;
    }
    this.isManagingCustomObject = false;
  }

  private updateSavedProject3D(
    object: Project3DUpload,
    type: UploadedObjectNameEnum
  ): void {
    const editedCustomObject = this.threeInstance?.scene.getObjectByName(type);
    object.position = JSON.stringify(editedCustomObject.position);
    object.rotation = JSON.stringify(editedCustomObject.rotation);
    this.fileUploadService.updateProject3DUpload(object).subscribe();
    editedCustomObject.remove(this.threeInstance.controlBtns);
    this.discardTransformControls();
  }

  private checkIfShouldAdd(
    project3DModel: Project3dModel,
    meshPosition: FurbanMeshPosition
  ): boolean {
    if (project3DModel.furban3DModel?.placeOutside) {
      return true;
    }

    const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
      this.threeInstance.currentCoordinates
    );
    const pointToCheck = new Vector3(
      meshPosition.position.x,
      0,
      -meshPosition.position.z
    );
    return ThreeUtils.isPointInsideThePolygon(pointToCheck, canvasCoordinates);
  }

  private getInstance(type: UploadedObjectTypeEnum): Mesh {
    let instance: Mesh;

    switch (type) {
      case UploadedObjectTypeEnum.customObject:
        instance = this.instanceUploadedCustom;
        break;

      case UploadedObjectTypeEnum.fixedDesign:
        instance = this.instanceFixedDesign;
        break;

      case UploadedObjectTypeEnum.underground:
        instance = this.instanceUploadedUnderground;
        break;
      default:
        break;
    }

    return instance;
  }

  private finalizeUpload(type: UploadedObjectTypeEnum): void {
    const instance = this.getInstance(type);
    const id = this.designProposalId
      ? this.designProposalId
      : this.project?.projectId;

    const objectToUpload = ThreeUtils.convertCustomUploadedMeshToCustomObject(
      instance,
      type,
      id
    );

    objectToUpload.objectS3Key = id + '_' + type;
    if (objectToUpload.extension === FileExtensionEnum.glb) {
      this.uploadGLBObject(objectToUpload, type);
    } else if (objectToUpload.extension === FileExtensionEnum.obj) {
      objectToUpload.textureS3Key =
        objectToUpload.objectS3Key + '_' + FileExtensionEnum.mtl;
      this.uploadOBJObject(objectToUpload, type);
    }
  }

  private uploadGLBObject(
    objectUpload: Project3DUpload,
    type: UploadedObjectTypeEnum
  ): void {
    const file: File = this.getGLBFile(type);

    this.fileUploadService
      .uploadProject3DUpload(file, objectUpload)
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.onObjectSaved(data, type);
      });
  }

  private getGLBFile(type: UploadedObjectTypeEnum): File {
    let file: File;
    if (type === UploadedObjectTypeEnum.customObject) {
      file = this.fileUploadService.currentCustomObjectFile;
    } else if (type === UploadedObjectTypeEnum.fixedDesign) {
      file = this.fileUploadService.currentFixedObjectFile;
    } else {
      file = this.fileUploadService.currentUndergroundFile;
    }

    return file;
  }

  private getOBJFile(type: UploadedObjectTypeEnum): ObjAndTextureFiles {
    let file: ObjAndTextureFiles;
    if (type === UploadedObjectTypeEnum.customObject) {
      file = this.fileUploadService.currentCustomObjectTextureFile;
    } else if (type === UploadedObjectTypeEnum.fixedDesign) {
      file = this.fileUploadService.currentFixedObjectTextureFile;
    } else {
      file = this.fileUploadService.currentUndergroundTextureFile;
    }

    return file;
  }

  private uploadOBJObject(
    objectToUpload: Project3DUpload,
    type: UploadedObjectTypeEnum
  ): void {
    const objFileTexture = this.getOBJFile(type);

    this.fileUploadService
      .uploadProject3DUpload(
        objFileTexture.objFile,
        objectToUpload,
        objFileTexture.textureFile
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.onObjectSaved(data, type);
      });
  }

  private onObjectSaved(
    data: Project3DUpload,
    type: UploadedObjectTypeEnum
  ): void {
    this.fileUploadService.changeProject3DUpload(data, type);
    this.isManagingCustomObject = false;

    switch (type) {
      case UploadedObjectTypeEnum.customObject:
        this.instanceUploadedCustom.userData['loadedOnServer'] = true;
        this.instanceUploadedCustom?.remove(this.threeInstance.controlBtns);
        break;
      case UploadedObjectTypeEnum.fixedDesign:
        this.instanceFixedDesign.userData['loadedOnServer'] = true;
        this.instanceFixedDesign?.remove(this.threeInstance.controlBtns);
        break;
      case UploadedObjectTypeEnum.underground:
        this.instanceUploadedUnderground.userData['loadedOnServer'] = true;
        this.instanceUploadedUnderground?.remove(
          this.threeInstance.controlBtns
        );
        break;
      default:
        break;
    }

    this.discardTransformControls();

    if (type === UploadedObjectTypeEnum.customObject) {
      const hole = ThreeUtils.generateHoleForCustomLayer(this.instanceUploadedCustom);
      this.loaderSceneGround(hole);
    } else {
      this.loaderSceneGround();
    }
  }

  addMouseEventsForFreeshapeDrawing(): void {
    this.threeInstance.renderer.domElement.addEventListener(
      'click',
      this.onClickWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'dblclick',
      this.onDoubleClickWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'pointermove',
      this.onMoveWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.addEventListener(
      'touchstart',
      this.onTouchWhenDrawingFreeshape,
      false
    );
  }

  removeMouseEventsForFreeshapeDrawing(): void {
    this.threeInstance.renderer.domElement.removeEventListener(
      'click',
      this.onClickWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.removeEventListener(
      'dblclick',
      this.onDoubleClickWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.removeEventListener(
      'pointermove',
      this.onMoveWhenDrawingFreeshape,
      false
    );
    this.threeInstance.renderer.domElement.removeEventListener(
      'touchstart',
      this.onTouchWhenDrawingFreeshape,
      false
    );
  }

  clearLastDrawing(): void {
    this.threeInstance.helpersGroup.remove(this.threeInstance.freeshapeHelper);
    this.threeInstance.helpersGroup.remove(
      this.threeInstance.freeShapeLineDetails.line
    );
    this.threeInstance.freeShapeLineDetails.line?.remove(
      this.threeInstance.infoParagraph
    );
    this.threeInstance.helpersGroup.remove(this.threeInstance.pillarHelper);
    this.threeInstance.freeShapeLineDetails.resetPointsDetails();
    this.threeInstance.freeShapeLineDetails.setFreeShapeLineObject();
    this.removeMouseEventsForFreeshapeDrawing();
    this.finishedDrawing.emit();
  }

  onTouchEvent = (event) => {
    event.preventDefault();
    if (
      !this.threeInstance.controls.enabled ||
      this.isChangeControlEvent(event)
    ) {
      return;
    }

    if (ThreeUtils.isDoubleTap(this.threeInstance)) {
      this.onDoubleClick();
    }

    this.threeInstance.mylatesttap = new Date().getTime();
    event = event.changedTouches[0];
  };

  onTouchWhenDrawingFreeshape = (event) => {
    event.preventDefault();
    if (ThreeUtils.isDoubleTap(this.threeInstance)) {
      this.onDoubleClickWhenDrawingFreeshape(event, true);
    } else {
      this.onClickWhenDrawingFreeshape(event, true);
    }
    this.threeInstance.mylatesttap = new Date().getTime();
  };

  onMoveWhenDrawingFreeshape = (event) => {
    if (this.threeInstance.freeShapeLineDetails.points.length >= 1) {
      const currentPoint = ThreeUtils.getIntersectionOfEventWithGroup(
        this.threeInstance,
        event
      );
      currentPoint.y = currentPoint.y + ThreeUtils.linesZFightingOffset;
      this.threeInstance.freeShapeLineDetails.points[
        this.threeInstance.freeShapeLineDetails.pointsLength
      ] = currentPoint;
      this.threeInstance.freeShapeLineDetails.updateGeometry();
      if (
        this.threeInstance.freeShapeLineDetails.freeshapeType ===
        ObjectTypeEnum.row
      ) {
        this.threeInstance.freeShapeLineDetails.lengthInMeters =
          ThreeUtils.computeDistanceBetweenTwoPoints(
            this.threeInstance.freeShapeLineDetails.points[0],
            this.threeInstance.freeShapeLineDetails.points[1]
          );
        this.updateLineDistanceInfo(currentPoint);
      }
    }
  };

  onClickWhenDrawingFreeshape = (event, isTapEvent: boolean = false) => {
    if (
      this.threeInstance.freeShapeLineDetails.points.length === 2 &&
      this.threeInstance.freeShapeLineDetails.freeshapeType ===
        ObjectTypeEnum.row
    ) {
      return;
    }

    const point = ThreeUtils.getIntersectionOfEventWithGroundHavingCustomDesign(
      this.threeInstance,
      event
    );
    const freeshapeHelper = new FreeshapeHelper();
    point.y = point.y + ThreeUtils.defaultZFightingOffset;
    const cylinderHelper = freeshapeHelper.generateCylinderHelper();
    cylinderHelper.position.set(point.x, point.y, point.z);

    if (this.threeInstance.freeShapeLineDetails.pointsLength === 0) {
      this.threeInstance.freeShapeLineDetails.points.push(
        new Vector3(point.x, point.y, point.z)
      );
      this.threeInstance.freeShapeLineDetails.pointsLength = 1;
      this.threeInstance.freeShapeLineDetails.setFreeShapeLineObject();
      this.threeInstance.freeShapeLineDetails.updateGeometry();
      this.threeInstance.helpersGroup.add(
        this.threeInstance.freeShapeLineDetails.line
      );
      this.threeInstance.pillarHelper = new Group();
      this.threeInstance.helpersGroup.add(this.threeInstance.pillarHelper);
      this.attachDistanceInfoToLine();
    } else {
      this.threeInstance.helpersGroup.remove(
        this.threeInstance.freeshapeHelper
      );
      this.threeFreeshapeUtil.addPointToFreeShape(point, isTapEvent);
      this.threeInstance.freeShapeLineDetails.updateGeometry();
      this.threeInstance.freeshapeHelper = freeshapeHelper.generateHelper(
        this.threeInstance.freeShapeLineDetails.points
      );
      this.threeInstance.helpersGroup.add(this.threeInstance.freeshapeHelper);
    }

    this.threeInstance.pillarHelper.add(cylinderHelper);
    this.showDrawingFreeShapeToaster();
  };

  showDrawingFreeShapeToaster(): void {
    if (this.threeInstance.freeShapeLineDetails.pointsLength === 1) {
      this.open3DNotificationSnackbar('user.project.continueFreeShape');
    }

    if (this.threeInstance.freeShapeLineDetails.pointsLength === 2) {
      this.open3DNotificationSnackbar('user.project.finishFreeShape');
    }
  }

  createObjectOnRandomPosition(triangles: any, k: Project3dModel): PathObject {
    const triangle = CanvasUtils.selectRandomTriangle(triangles);
    const point = CanvasUtils.calcRandomPoint(triangle);
    const pointAsVector3 = new Vector3(point[1], 0, point[0]);

    const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
      this.threeInstance.currentCoordinates
    );
    const pointToCheck = new Vector3(pointAsVector3.x, 0, -pointAsVector3.z);
    if (
      !ThreeUtils.isPointInsideThePolygon(pointToCheck, canvasCoordinates) &&
      !k.furban3DModel.placeOutside
    ) {
      return;
    }

    const position = ThreeUtils.getPositionConsideringCustomDesign(
      pointAsVector3,
      this.threeInstance.customDesign
    );
    const furbanMeshPosition = new FurbanMeshPosition(position, 0);

    const pathObject = ThreeUtils.convertProject3DModelToPathObject(
      k,
      furbanMeshPosition,
      this.getProjectAndPathId(),
      this.authService.hasAdministrativeRole(),
      this.designProposalId
    );
    return pathObject;
  }

  onDoubleClickWhenDrawingFreeshape = (event, isTapEvent: boolean = false) => {
    this.onClickWhenDrawingFreeshape(event, isTapEvent);
    if (
      this.threeInstance.freeShapeLineDetails.freeshapeType ===
      ObjectTypeEnum.row
    ) {
      this.threeInstance.freeShapeLineDetails.line.remove(
        this.threeInstance.infoParagraph
      );
      this.removeIntersectedObject();
      this.addRowOfObjects();
    } else {
      if (
        this.threeInstance.freeShapeLineDetails.freeshapeType ===
        ObjectTypeEnum.freeshape
      ) {
        this.makeFreeshape();
      } else if (
        this.threeInstance.freeShapeLineDetails.freeshapeType ===
        ObjectTypeEnum.multiple
      ) {
        this.triggerModalWithObjects();
      }
    }
    this.clearLastDrawing();
    this.addEventsOnCanvas();
    this.disabledTool.emit();
    this.snackBar.dismiss();
  };

  triggerRowOfObjectsModal(coordinates: Vector3[]) {
    const dialogRef = this.dialog.open(RowOfObjectsDialogComponent, {
      width: '600px',
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.parentRef = this.viewContainerRef;
    dialogRef.componentInstance.projectId = this.project.projectId;

    dialogRef.componentInstance.onModalClose.subscribe((selectedObject) => {
      if (selectedObject) {
        selectedObject.freeshapePoints = JSON.stringify(coordinates);
        this.placeRowOfObjectsConsideringTotalPrice(selectedObject);
        dialogRef.close();
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.menuService.emitOnActionFinished();
    });
  }

  getObjectsInsideCanvas(): Object3D[] {
    const regularObjects = this.threeInstance.objectsRegular.children;
    const groundObjects = this.threeInstance.objectsGround.children;
    const allObjects = regularObjects.concat(groundObjects);

    const objectsInsideTheSelectedArea: Object3D[] = [];

    const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
      this.threeInstance.currentCoordinates
    );
    const buildingsAreaCoordinates = ThreeUtils.convertFromPoint2DTo3D(
      this.threeInstance.currentInflatedCoordinates
    );

    allObjects.forEach((addedObject: Object3D) => {
      if (
        ThreeUtils.isObjectIntersectingTheSelectedArea(
          addedObject,
          canvasCoordinates,
          buildingsAreaCoordinates
        )
      ) {
        objectsInsideTheSelectedArea.push(addedObject);
      } else {
        this.deleteSavedObject(addedObject);
      }
    });
    return objectsInsideTheSelectedArea;
  }

  deleteObjects(): void {
    const objectsToDelete: PathObject[] =
      ThreeUtils.convert3DObjectsToPathObjects(
        this.deleteObjectsList,
        this.authService.hasAdministrativeRole(),
        this.designProposalId != null
      );
    this.pathObjectsService
      .deletePathObjects(objectsToDelete)
      .subscribe((data) => {
        this.deleteObjectsList = [];
        this.lastDeletedObjects = [];
        this.store.dispatch(
          restartDesignRequest({ budget: this.availableBudget })
        );
      });
  }

  removeObjectFromScene(element: any): void {
    this.deleteSavedObject(element);
    this.removeEntity(element);
  }

  deleteSavedObject(addedObject: any): void {
    if (addedObject.userData.pathObjsId) {
      this.deleteObjectsList.push(addedObject);
    }
    this.onObjectDelete(addedObject.userData.objId);
  }

  deleteMultipleSelectedObject(): void {
    this.lastDeletedObjects = [];
    const pathObjectsToDelete = [];
    this.instanceMultiselectGroup.userData['selected'].forEach((element) => {
      this.setMultipleSelectObjectPreviousPositionAndColor(element);
      this.lastDeletedObjects.push(element);
      this.removeObjectFromScene(element);

      const elementToDelete = element.userData;
      elementToDelete.position = JSON.stringify(elementToDelete.position);

      pathObjectsToDelete.push(elementToDelete);
    });

    if (this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.dispatchMultipleDeleteObjectsRequest(
        pathObjectsToDelete,
        this.availableBudget
      );
    } else {
      this.dispatchMultipleDeleteObjectsRequest(pathObjectsToDelete);
    }

    this.instanceMultiselectGroup.userData['selected'] = [];
    this.instanceMultiselectGroup.userData['prevParent'] = [];
    this.removeControlsFromObject(true);
    this.threeInstance.scene.remove(this.instanceMultiselectGroup);
    this.instanceMultiselectGroup = null;
  }

  deleteIntersectedObject(): void {
    this.lastDeletedObjects = [this.threeInstance.intersectedObject];

    if (this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.onObjectDelete(this.instanceIntersectedObject.userData['objId']);
      this.dispatchDeleteObjectsRequest(this.availableBudget);
    } else {
      this.dispatchDeleteObjectsRequest();
    }
    this.removeControlsFromObject();
  }

  deleteObject(): void {
    if (
      this.checkIfIsLocked() ||
      !this.hasAccessToIntersectedObjOrMultiSelectionGroup()
    ) {
      return;
    }

    if (document.getElementsByTagName('mat-tooltip-component').length > 0) {
      document.getElementsByTagName('mat-tooltip-component')[0].remove();
    }

    if (
      this.instanceIntersectedObject &&
      this.instanceIntersectedObject === this.instanceUploadedUnderground
    ) {
      this.removeUploadObjectFromMenu.emit(UploadedObjectTypeEnum.underground);
      return;
    }

    if (
      this.instanceIntersectedObject &&
      this.instanceIntersectedObject === this.instanceUploadedCustom
    ) {
      this.removeUploadObjectFromMenu.emit(UploadedObjectTypeEnum.customObject);
      return;
    }

    if (
      this.instanceIntersectedObject &&
      this.instanceIntersectedObject === this.instanceFixedDesign
    ) {
      this.removeUploadObjectFromMenu.emit(UploadedObjectTypeEnum.fixedDesign);
      return;
    }
    if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData['selected'].length > 0
    ) {
      this.deleteMultipleSelectedObject();
    }

    if (this.instanceIntersectedObject) {
      this.deleteIntersectedObject();
    }
    if (this.isSafetyEnabled) {
      this.enableSafetyArea();
    }
  }

  lock(): void {
    if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData['selected'].length > 0
    ) {
      this.lockMultipleSelectedObjects();
    } else if (this.instanceIntersectedObject) {
      this.lockIntersectedObject();
    }
  }

  unlock(): void {
    if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData['selected'].length > 0
    ) {
      this.unlockMultipleSelectedObjects();
    }

    if (this.instanceIntersectedObject) {
      this.unlockIntersectedObject();
    }
  }

  lockIntersectedObject(): void {
    this.instanceIntersectedObject.userData['isLocked'] = true;
    ThreeUtils.setLockedMode(this.threeInstance);
    this.updateIntersectedObject();
  }

  lockMultipleSelectedObjects(): void {
    this.changeMultipleSelectedObjectsLockStatus(true);
    ThreeUtils.setLockedMode(this.threeInstance);
  }

  unlockIntersectedObject(): void {
    ThreeUtils.setMoveMode(this.threeInstance);
    this.instanceIntersectedObject.userData['isLocked'] = false;
    this.updateIntersectedObject();
  }

  unlockMultipleSelectedObjects(): void {
    this.changeMultipleSelectedObjectsLockStatus(false);
  }

  checkIfIsLocked(): boolean {
    return (
      this.instanceIntersectedObject?.userData['isLocked'] ||
      (this.instanceMultiselectGroup?.userData['isLocked'] &&
        this.instanceMultiselectGroup.userData['selected'].length > 0)
    );
  }

  removeEntity(object): void {
    object.parent.remove(object);
  }

  // this is opening the citizen publish dialog
  openPublishDialog(): void {
    const dialogRef = this.dialog.open(PublishPopupComponent, {
      width: '40%',
      minWidth: '500px',
      data: {
        project: this.project,
        availableBudget: this.availableBudget,
      },
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.parentViewContainerRef = this.viewContainerRef;
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 0) {
        this.changeScreenshot();
      } else {
        this.publishService.designProposal = new DesignProposal();
      }
    });
  }

  disableMultiselect(): void {
    if (this.threeGrouping) {
      this.threeGrouping.disableSelectionBox();
      this.threeGrouping.onGroupingEnd();
      this.threeGrouping = null;
      this.disabledTool.emit();
      if (this.isSafetyEnabled) {
        this.enableSafetyArea();
      }
    }
  }

  enableMultiSelect(): void {
    this.removeControlsFromObject();
    this.threeGrouping = new ThreeMultiselect(
      this.threeInstance,
      this.skipDefaultObjects,
      this.menuService
    );
    this.threeGrouping.enableSelectionBox();
  }

  onObjectDelete(objectId: number): void {
    const clientObject = this.menuService.getClientObject(objectId);
    if (
      this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
      this.project.price &&
      !ObjectUtil.isGroundObject(clientObject.furban3DModel.objectLookId)
    ) {
      this.availableBudget = this.availableBudget + clientObject.price;
    }
  }

  isCreatedOrUnpublishedProjectUpdatedByAdminOrPioneer(): boolean {
    return (
      this.isReadyForPublishing() &&
      (this.hasPublishOptionWhenAdmin() || this.hasPublishOptionWhenPioneer())
    );
  }

  triggerProjectOverviewModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '600px';
    dialogConfig.width = '50%';
    dialogConfig.data = new ProjectOverview(
      this.project.name,
      this.authService.client.clientName,
      this.project.startDate,
      this.project.endDate,
      this.project.description,
      this.mediaService.getMedia(this.project.media),
      this.project.price
    );

    const dialogRef = this.dialog.open(
      ProjectOverviewDialogComponent,
      dialogConfig
    );
    dialogRef.disableClose = true;
    dialogRef.componentInstance.parentRef = this.viewContainerRef;

    dialogRef.componentInstance.onModalClose.subscribe((wantToPublish) => {
      if (wantToPublish) {
        this.publishProject();
        dialogRef.close();
      }
    });
  }

  restorePreviousState(): void {
    this.pathObjectsService
      .getPathObjects(
        this.project.projectId,
        this.authService.userProfile.userProfileId
      )
      .subscribe((data) => {
        if (data) {
          this.userObjects = [...this.defaultObjects, ...data];
        } else {
          this.userObjects = this.defaultObjects;
        }
        this.removePathObjects();

        this.addThreeObjects(this.userObjects, false);
        this.takeDefaultScreenshot();
      });
  }

  restartDesign(): void {
    this.removeControlsFromObject();
    if (
      this.toolingService.toolingVisibility &&
      !this.toolingService.toolingVisibility.isPublished
    ) {
      // is not yet published
      this.modalManager
        .showModal(
          this.viewContainerRef,
          ModalManager.createConfiguration(
            'errors.warning',
            'user.project.restartDesign',
            'generic.yesBtn',
            'generic.noBtn'
          )
        )
        .subscribe((res) => {
          if (res) {
            this.deleteConfirmationCallBack();
          }
        });
    } else {
      // if it is published
      this.modalManager
        .showModal(
          this.viewContainerRef,
          ModalManager.createConfiguration(
            'errors.warning',
            'user.project.restartPublishedDesign',
            'generic.yesBtn',
            'generic.noBtn'
          )
        )
        .subscribe((res) => {
          if (res) {
            this.publishService
              .restartDesign(this.publishService.designProposal)
              .subscribe((data) => {
                this.publishService.designProposal = new DesignProposal();
                this.toolingService.toolingVisibility.isPublished = false;
                this.threeInstance.isPublished = false;
                this.deleteConfirmationCallBack();
              });
          }
        });
    }
  }

  deleteConfirmationCallBack(): void {
    const objectsInsideThreeJs = this.getObjectsInsideCanvas();
    objectsInsideThreeJs.forEach((obj) => {
      if (!obj.userData['default']) {
        this.removeObjectFromScene(obj);
      }
    });
    this.deleteObjects();
  }

  switchSafetyArea(): void {
    this.isSafetyEnabled = !this.isSafetyEnabled;
    this.navigationService.toggleSafetyArea(this.isSafetyEnabled);
    this.toolingService.toolingVisibility.isSafetyEnabled =
      this.isSafetyEnabled;
  }

  switchOpacity(): void {
    this.isTransparent = !this.isTransparent;
    this.navigationService.toggleTransparency(this.isTransparent);
    this.toolingService.toolingVisibility.isTransparencyEnabled =
      this.isTransparent;
  }

  showViewAndEditMode(): boolean {
    return (
      this.toolingService.toolingVisibility &&
      !this.toolingService.toolingVisibility.isPublished &&
      !this.project.ended &&
      this.state !== ThreeStateEnum.screenshot
    );
  }

  getTooltipTranslation(translationLabel: string): boolean {
    return FurbanUtil.isMobile()
      ? null
      : this.translateService.instant(translationLabel);
  }

  addCustom3DObjectForTour(): void {
    const project3DModel = this.shepherdTourService.customObjectForTour;
    if (!this.instanceIntersectedObject) {
      const meshPosition = this.getMeshPositionOnDragOrClick(false);
      const pathObject = ThreeUtils.convertProject3DModelToPathObject(
        project3DModel,
        meshPosition,
        this.getProjectAndPathId(),
        this.authService.hasAdministrativeRole(),
        this.designProposalId
      );
      this.addBoxToScene(pathObject);
    }
  }

  addBoxToScene(pathObject: PathObject): void {
    const createdObject = this.meshBuilder.createThreeMesh(
      pathObject.name,
      pathObject
    );
    ThreeUtils.addPropertiesToObject(pathObject, createdObject);
    this.threeInstance.objectsGround.add(createdObject);
    this.threeInstance.intersectedObject = createdObject;
    this.getIntersectedObjectType();
    createdObject.add(this.threeInstance.controlBtns);
    ThreeUtils.setMoveMode(this.threeInstance);
    this.threeInstance.transformControls.attach(createdObject);
  }

  public keyDownFunction = (e: KeyboardEvent) => {
    switch (e.key) {
      case KeyCode.shift: {
        this.isShiftPressed.next(true);
        e.preventDefault();
        break;
      }
      case KeyCode.alt: {
        this.isAltPressed.next(true);
        e.preventDefault();
        break;
      }
      case KeyCode.cKey: {
        if (e.ctrlKey || e.metaKey) {
          this.copyFromKeyboard();
          e.preventDefault();
        }
        break;
      }
      case KeyCode.vKey: {
        if (e.ctrlKey || e.metaKey) {
          this.pasteObject();
          e.preventDefault();
        }
        break;
      }
      case KeyCode.zKey: {
        if (e.ctrlKey || e.metaKey) {
          this.undo();
          e.preventDefault();
        }
        break;
      }
      case KeyCode.yKey: {
        if (e.ctrlKey || e.metaKey) {
          this.redo();
          e.preventDefault();
        }
        break;
      }
      case KeyCode.deleteKey: {
        this.deleteObject();
        e.preventDefault();
        break;
      }
      default:
        break;
    }
  };

  public keyUpFunction = (e: KeyboardEvent) => {
    switch (e.key) {
      case KeyCode.shift: {
        this.isShiftPressed.next(false);
        break;
      }
      case KeyCode.alt: {
        this.isAltPressed.next(false);
        break;
      }
    }
  };

  setObjectsToCopy(): void {
    if (this.instanceIntersectedObject) {
      this.threeInstance.objectsToCopy = [
        this.copyObjectInProximity(this.instanceIntersectedObject),
      ];
    } else if (
      this.threeInstance.selectionObjects &&
      this.threeInstance.selectionObjects.length > 0
    ) {
      this.threeInstance.selectionObjects.forEach((element) => {
        this.threeInstance.objectsToCopy.push(
          this.copyObjectInProximity(
            element,
            this.instanceMultiselectGroup.position
          )
        );
      });
    } else if (
      this.instanceMultiselectGroup &&
      this.instanceMultiselectGroup.userData &&
      this.instanceMultiselectGroup.userData['selected']
    ) {
      this.instanceMultiselectGroup.userData['selected'].forEach((element) => {
        this.threeInstance.objectsToCopy.push(
          this.copyObjectInProximity(
            element,
            this.instanceMultiselectGroup.position
          )
        );
      });
    }
  }

  pasteObject(): void {
    if (
      !this.threeInstance.objectsToCopy ||
      this.threeInstance.objectsToCopy.length === 0 ||
      this.isManagingCustomObject
    ) {
      return;
    }

    this.threeInstance.newGroup = [];
    const objsToCopyIds = this.threeInstance.objectsToCopy.map(
      (obj) => obj.objId
    );

    const objectsToAdd = this.createListOfCopiedObjectToAdd();

    if (!objectsToAdd || !objectsToAdd[0]) {
      this.showToaster(
        'warning',
        'info',
        'errors.pasteObjectsInsideArea',
        20000
      );
      return;
    }

    if (!this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.addObjects(objectsToAdd, true, true);
      return;
    }
    this.projectService
      .getObjectPriceOnArea(this.project.projectId, objsToCopyIds)
      .subscribe((objectsCost) => {
        this.addObjectsConsideringPriceRestriction(
          objectsCost,
          objectsToAdd,
          true,
          true
        );
      });
  }

  private updatePositionForPastedObject(
    centerOfScreen: Vector3,
    shift: Vector3
  ): Vector3 {
    return new Vector3(
      centerOfScreen.x + shift.x,
      shift.y,
      centerOfScreen.z + shift.z
    );
  }

  private addMultiselectionToPastedGroup = () => {
    if (!this.instanceMultiselectGroup) {
      return;
    }
    this.instanceIntersectedObject = null;
    this.disableMultiselect();

    this.threeGrouping = new ThreeMultiselect(
      this.threeInstance,
      this.skipDefaultObjects,
      this.menuService
    );
    this.threeGrouping.onGroupingStart(this.threeInstance.newGroup);
  };

  private createListOfCopiedObjectToAdd(): PathObject[] {
    const centerPosition = ThreeUtils.getIntersectionFromCenterOfScreen(
      this.threeInstance
    );
    const objectsToAdd = this.threeInstance.objectsToCopy.map((copiedObj) => {
      const newObj = { ...copiedObj };
      const relativPosition = JSON.parse(newObj.position);
      const newPosition = this.updatePositionForPastedObject(
        centerPosition,
        relativPosition
      );

      const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
        this.threeInstance.currentCoordinates
      );
      const pointToCheck = new Vector3(newPosition.x, 0, -newPosition.z);
      if (
        !ThreeUtils.isPointInsideThePolygon(pointToCheck, canvasCoordinates) &&
        !copiedObj.placeOutside
      ) {
        return;
      }

      if (this.threeInstance.customDesign) {
        const newPositionConsideringCustomObject =
          ThreeUtils.getPositionConsideringCustomDesign(
            newPosition,
            this.threeInstance.customDesign
          );
        newObj.position = JSON.stringify(newPositionConsideringCustomObject);
      } else {
        newObj.position = JSON.stringify(newPosition);
      }

      return newObj;
    });

    return objectsToAdd;
  }

  public copyObjectInProximity(
    objectToClone,
    centerOfSelection?: Vector3
  ): PathObject {
    const clonedObject: PathObject = JSON.parse(
      JSON.stringify(objectToClone.userData)
    );
    const position = new Vector3();
    position.x = centerOfSelection ? objectToClone.position.x : 0;
    position.z = centerOfSelection ? objectToClone.position.z : 0;

    clonedObject.position = JSON.stringify(position);
    clonedObject.userId = this.authService.userProfile.userId;
    clonedObject.pathObjsId = null;
    clonedObject.angle = ThreeUtils.convertFromQuaternionToDegrees(
      objectToClone.quaternion
    );

    if (objectToClone.userData.name === ObjectTypeEnum.custom) {
      const dimensions = {
        width: objectToClone.geometry.parameters.width * objectToClone.scale.x,
        height: objectToClone.geometry.parameters.depth * objectToClone.scale.z,
        depth: objectToClone.geometry.parameters.height * objectToClone.scale.y,
      };
      clonedObject.freeshapePoints = JSON.stringify(dimensions);
    } else if (FurbanUtil.isGroundMaterial(objectToClone.userData.objId)) {
      clonedObject.freeshapeGroundMaterial = objectToClone.userData.objId;

      if (objectToClone.userData.name === ObjectTypeEnum.freeshape) {
        clonedObject.freeshapePoints = objectToClone.userData.freeshapePoints;
      } else if (objectToClone.userData.name === ObjectTypeEnum.square) {
        clonedObject.freeshapePoints = JSON.stringify({
          x: Math.abs(
            objectToClone.geometry.parameters.width * objectToClone.scale.x
          ),
          y: Math.abs(
            objectToClone.geometry.parameters.height * objectToClone.scale.z
          ),
        });
      } else if (objectToClone.userData.name === ObjectTypeEnum.elipse) {
        clonedObject.freeshapePoints = JSON.stringify({
          x: Math.abs(
            objectToClone.geometry.parameters.shapes.curves[0].xRadius *
              objectToClone.scale.x
          ),
          y: Math.abs(
            objectToClone.geometry.parameters.shapes.curves[0].yRadius *
              objectToClone.scale.z
          ),
        });
      }
    }
    return clonedObject;
  }

  public override exportSceneToGLTF(): void {
    const exportHelper: ThreeGLTFExportHelper = new ThreeGLTFExportHelper();
    const projectName = this.project.name.replace(/ /g, '_');
    const username = this.authService.user.username;
    this.navigationService.toggleSafetyArea(false);
    this.navigationService.toggleTransparency(false);

    const fileName = this.getStringForFile(projectName, username);
    exportHelper.exportGLTF(
      this.threeInstance,
      fileName,
      this.modalManager,
      this.viewContainerRef
    );
  }

  public checkIfLockedInfoHidden(): boolean {
    return (
      !this.threeInstance.intersectedObject ||
      this.hasAccessToIntersectedObjOrMultiSelectionGroup()
    );
  }

  public checkIfControlBtnsHidden(): boolean {
    return (
      this.state != this.threeStateEnum.default ||
      !this.hasAccessToIntersectedObjOrMultiSelectionGroup()
    );
  }

  private get hasMultipleObjectsSelected(): boolean {
    return this.instanceMultiselectGroup?.userData['selected'].length > 0;
  }

  private get hasObjectSelected(): boolean {
    return !!this.threeInstance.intersectedObject;
  }

  private get isObjectDefault(): boolean {
    return this.threeInstance.intersectedObject.userData['default'];
  }

  private get isAdminOnDefaultDesign(): boolean {
    return (
      this.authService.hasAdministrativeRole() && this.isDefaultDesignRoute
    );
  }

  private get isCollaborativeDesignRoute(): boolean {
    return this.router.url.indexOf('collaborative-design') > -1;
  }

  private hasAccessToIntersectedObjOrMultiSelectionGroup(): boolean {
    return (
      this.hasMultipleObjectsSelected ||
      this.isCollaborativeDesignRoute ||
      (this.hasObjectSelected && !this.isObjectDefault) ||
      this.isAdminOnDefaultDesign
    );
  }

  private isAdminDesigning(): boolean {
    return this.router.url.indexOf('/(project:create-design/') > -1;
  }

  private get isDefaultDesignRoute(): boolean {
    return this.router.url.indexOf('(project:objects)') > -1;
  }

  public getButtonsClass(): string {
    if (this.checkIfIsLocked()) {
      return 'circle-container-1';
    }

    if (this.isManagingCustomObject) {
      return 'circle-container-4-items';
    }

    if (this.isSquareOrElipseOrCustom(this.threeInstance.intersectedType)) {
      return 'circle-container-8-items';
    }

    return 'circle-container-6-items';
  }

  private subscribeToEvents(): void {
    this.render = this.render.bind(this);
    this.threeFreeshapeUtil = new ThreeFreeshapeUtil(this.threeInstance);
    this.subscribeToModifiedShift();
    this.subscribeToSafetyAreaToggle();
    this.subscribeToTransparencyToggle();
    this.subscribeTourStartingEvents();
  }

  private getFileName(): string {
    const fileNameToCut =
      this.authService.userProfile.screenName +
      '_' +
      this.project.name +
      '.png';
    if (fileNameToCut.length > 124) {
      return FurbanUtil.reduceFileNameLength(fileNameToCut, 120);
    }
    return fileNameToCut;
  }

  private updateLineDistanceInfo(newPosition: Vector3): void {
    this.threeInstance.infoParagraph.element.textContent = this.getLineLength();
    this.threeInstance.infoParagraph.position.set(
      newPosition.x,
      newPosition.y,
      newPosition.z
    );
  }

  private configureDialogRef(): MatDialogRef<GenerateGreenComponent> {
    const dialogRef = this.dialog.open(GenerateGreenComponent, {
      width: '600px',
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.parentRef = this.viewContainerRef;
    dialogRef.componentInstance.freeshapePoints =
      this.threeInstance.freeShapeLineDetails.points;
    dialogRef.componentInstance.projectId = this.project.projectId;
    return dialogRef;
  }

  private triggerModalWithObjects(): void {
    const closeModalIfNoTriangles = (trianglesLength) => {
      if (trianglesLength === 0) {
        dialogRef.close();
        return;
      }
    };

    const dialogRef = this.configureDialogRef();

    const flattenPoints = CanvasUtils.flattenArrayOfPoints(
      dialogRef.componentInstance.freeshapePoints
    );
    const triangles = CanvasUtils.triangulate(flattenPoints, MyEarcut);

    closeModalIfNoTriangles(triangles.length);

    dialogRef.componentInstance.onModalClose.subscribe((data) => {
      if (!data) {
        return;
      }
      this.onGenerateGreenModalClose(triangles, data);
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      this.menuService.emitOnActionFinished();
    });
  }

  private onGenerateGreenModalClose(
    triangles: Point[],
    data: Map<Project3dModel, number>
  ): void {
    let priceOfGeneratedGreenObjects = 0;
    let showBudgetExceeded = false;
    const hashMapArr: Map<Project3dModel, number> = data;
    const pathObjectArray: PathObject[] = [];
    let objectSkipped = false;
    for (const [k, v] of hashMapArr) {
      for (let i = 0; i < v; i++) {
        if (
          this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
          this.availableBudget < priceOfGeneratedGreenObjects + k.price
        ) {
          showBudgetExceeded = true;
          if (this.project.isPriceRestrictionEnabled) {
            break;
          }
        }

        const pathObject = this.createObjectOnRandomPosition(triangles, k);
        if (!pathObject) {
          objectSkipped = true;
          continue;
        }
        priceOfGeneratedGreenObjects = priceOfGeneratedGreenObjects + k.price;
        pathObjectArray.push(pathObject);
      }
    }

    if (objectSkipped) {
      this.showToaster(
        'warning',
        'info',
        'errors.outsideObjectsSkipped',
        20000
      );
    }
    this.checkForPriceRestrictions(
      priceOfGeneratedGreenObjects,
      showBudgetExceeded,
      pathObjectArray
    );
  }

  private checkForPriceRestrictions(
    priceOfGeneratedGreenObjects: number,
    showBudgetExceeded: boolean,
    pathObjectArray: PathObject[]
  ): void {
    if (this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.updateCurrentAvailableBudget(
        priceOfGeneratedGreenObjects,
        showBudgetExceeded
      );

      if (this.getPriceRestriction(showBudgetExceeded)) {
        this.dispatchAddMultipleObjectsRequest(
          pathObjectArray,
          false,
          false,
          this.availableBudget
        );
      }
    } else {
      this.dispatchAddMultipleObjectsRequest(pathObjectArray, false, false);
    }
  }

  private getPriceRestriction(showBudgetExceeded): boolean {
    return (
      (this.project.isPriceRestrictionEnabled && !showBudgetExceeded) ||
      !this.project.isPriceRestrictionEnabled
    );
  }

  private attachDistanceInfoToLine(): void {
    if (
      this.threeInstance.freeShapeLineDetails.freeshapeType ===
        ObjectTypeEnum.row &&
      !FurbanUtil.isMobile()
    ) {
      this.threeInstance.infoParagraph = ThreeUtils.createParagraphElement(
        this.threeInstance.freeShapeLineDetails.lengthInMeters.toFixed(3),
        this.threeInstance.freeShapeLineDetails.points[0]
      );
      this.instanceIntersectedObject =
        this.threeInstance.freeShapeLineDetails.line;
      this.threeInstance.freeShapeLineDetails.line.add(
        this.threeInstance.infoParagraph
      );
    }
  }

  private addRowOfObjects(): void {
    if (this.threeInstance.freeShapeLineDetails.points.length < 2) {
      return;
    }

    const freeshapePoints = this.threeInstance.freeShapeLineDetails.points;
    this.triggerRowOfObjectsModal(freeshapePoints);
  }

  private publishProject(): void {
    this.project.projectStatus = this.projectService.getStatus(
      ProjectStatusNameEnum.published
    );
    this.projectDetailsService
      .updateProjectStatus(this.project)
      .subscribe((data) => {
        if (data.projectStatus.statusWeight === ProjectStatusEnum.published) {
          this.showToaster(
            'check_circle',
            'success',
            this.translateService.instant(
              'admin.projectActivate.projectStatusSuccess'
            ),
            2000
          );
        }
        this.changeProjectStatusHandler(data);
      });
  }

  private changeProjectStatusHandler(project: Project): void {
    this.project = project;
    this.stepperService.project = project;
    this.stepperService.modifyCurrentStepId(false);
  }

  private getLineLength(): string {
    return (
      `${this.threeInstance?.freeShapeLineDetails?.lengthInMeters.toFixed(3)}` +
      `${this.translateService.instant('user.project.meters')}`
    );
  }
  private updateCurrentAvailableBudget(
    price: number,
    showBudgetExceed: boolean
  ): void {
    if (showBudgetExceed) {
      this.showBudgetExcededToaster();
    }
    if (this.getPriceRestriction(showBudgetExceed)) {
      this.availableBudget = this.availableBudget - price;
    }
  }

  private canAddObjectConsideringThePrice(price: number): boolean {
    if (
      this.authService.isAdminDesignRouteOrNotAdministrativeRole() &&
      this.isBudgetExceded(price)
    ) {
      this.showBudgetExcededToaster();
      if (this.project.isPriceRestrictionEnabled) {
        return false;
      }
    }
    return true;
  }

  private hasPublishOptionWhenAdmin(): boolean {
    return this.project.isCitizenDesigns && this.authService.isAdmin();
  }

  private hasPublishOptionWhenPioneer(): boolean {
    return (
      this.project.projectType.projectTypeId ===
        ProjectTypeEnum.pioneerInitiativeProject && this.authService.isPioneer()
    );
  }

  private makeFreeshape(): void {
    if (this.threeInstance.freeShapeLineDetails.pointsLength <= 2) {
      return;
    }

    const freeshapePoints = ThreeUtils.convertPoints3DTo2D(
      this.threeInstance.freeShapeLineDetails.points
    );
    this.threeInstance.freeShapeLineDetails.freeshapeProject3DModel.freeshapePoints =
      JSON.stringify(freeshapePoints);
    const scaledPoints = this.threeInstance.freeShapeLineDetails.points;

    const freeshapePositionOnDefaultPlane =
      ThreeFreeshapeUtil.computeFreeshapePosition(scaledPoints);
    const freeshapePositionOnCustomDesign =
      ThreeUtils.getPositionConsideringCustomDesign(
        freeshapePositionOnDefaultPlane,
        this.threeInstance.customDesign
      );
    freeshapePositionOnCustomDesign.y =
      freeshapePositionOnCustomDesign.y + ThreeUtils.defaultZFightingOffset;
    const furbanFreeshapePosition = new FurbanMeshPosition(
      freeshapePositionOnCustomDesign,
      0
    );

    const freeshapePathObj = ThreeUtils.convertProject3DModelToPathObject(
      this.threeInstance.freeShapeLineDetails.freeshapeProject3DModel,
      furbanFreeshapePosition,
      this.getProjectAndPathId(),
      this.authService.hasAdministrativeRole(),
      this.designProposalId
    );
    this.dispatchAddObjectRequest(freeshapePathObj, true);
    this.finishedDrawing.emit();
  }

  private placeRowOfObjectsConsideringTotalPrice(
    selectedObject: Project3dModel
  ): void {
    const objectsToAddInRow = RowOfObjects.getPathObjectsPlacedInARow(
      this.threeInstance,
      selectedObject,
      this.getProjectAndPathId(),
      this.authService.hasAdministrativeRole(),
      this.designProposalId
    );

    const isInBudget = this.addObjectsConsideringPriceRestriction(
      objectsToAddInRow.totalPrice,
      objectsToAddInRow.piecesPathObjects
    );

    if (objectsToAddInRow.objectsSkipped && isInBudget) {
      this.showToaster(
        'warning',
        'info',
        'errors.outsideObjectsSkipped',
        20000
      );
    }
  }

  private addObjectsConsideringPriceRestriction(
    objectsPrice: number,
    objects: PathObject[],
    shouldBeSelectable: boolean = false,
    shouldBeGrouped: boolean = false
  ) {
    if (!this.canAddObjectConsideringThePrice(objectsPrice)) {
      return false;
    }
    this.updateCurrentAvailableBudget(objectsPrice, false);
    this.addObjects(objects, shouldBeSelectable, shouldBeGrouped);
    return true;
  }

  private addObjects(
    objects: PathObject[],
    shouldBeSelectable: boolean = false,
    shouldBeGrouped: boolean = false
  ): void {
    if (this.authService.isAdminDesignRouteOrNotAdministrativeRole()) {
      this.dispatchAddMultipleObjectsRequest(
        objects,
        shouldBeGrouped,
        shouldBeSelectable,
        this.availableBudget
      );
    } else {
      this.dispatchAddMultipleObjectsRequest(
        objects,
        shouldBeGrouped,
        shouldBeSelectable
      );
    }
  }

  private isReadyForPublishing(): boolean {
    return (
      this.project.projectStatus.statusWeight ===
        ProjectStatusEnum.unpublished ||
      this.project.projectStatus.statusWeight === ProjectStatusEnum.created
    );
  }

  private changeMultipleSelectedObjectsLockStatus(lock: boolean): void {
    this.instanceMultiselectGroup.userData['selected'].forEach((element) => {
      element.userData.isLocked = lock;
    });
    this.instanceMultiselectGroup.userData['isLocked'] = lock;
    this.threeInstance.transformControls.attach(this.instanceMultiselectGroup);
    this.setControlsMode(this.instanceMultiselectGroup);
    this.updateMultipleSelectedObjects();
  }

  private updateButtonsOnIntersectedObject(): void {
    if (this.hasAccessToIntersectedObjOrMultiSelectionGroup()) {
      this.threeInstance.intersectedObject.remove(
        this.threeInstance.lockedInfo
      );
      this.threeInstance.intersectedObject.add(this.threeInstance.controlBtns);
      this.threeInstance.transformControls.attach(
        this.threeInstance.intersectedObject
      );
    } else {
      this.discardTransformControls();
      this.threeInstance.intersectedObject.remove(
        this.threeInstance.controlBtns
      );
      this.threeInstance.intersectedObject.add(this.threeInstance.lockedInfo);
    }
  }

  private setControlsMode(
    newIntersectedObject: Object3D,
    showY?: boolean
  ): void {
    if (!newIntersectedObject.userData['isLocked']) {
      ThreeUtils.setMoveMode(this.threeInstance, !!showY);
    } else {
      ThreeUtils.setLockedMode(this.threeInstance);
    }
    newIntersectedObject.userData['previousPosition'] = new Vector3(
      newIntersectedObject.position.x,
      newIntersectedObject.position.y,
      newIntersectedObject.position.z
    );
  }

  private subscribeToToolingActionObservable(): void {
    this.toolingActionsSubscription =
      this.toolingService.toolingActionsObservable.subscribe((data) => {
        if (!data) {
          return;
        }
        switch (data) {
          case ToolingActionsEnum.exportSceneToGLTF:
            this.exportSceneToGLTF();
            break;
          case ToolingActionsEnum.publish:
            this.publish();
            break;
          case ToolingActionsEnum.restartDesign:
            this.restartDesign();
            break;
          case ToolingActionsEnum.switchFullScreenMode:
            this.switchFullScreenMode();
            break;
          case ToolingActionsEnum.switchSafetyArea:
            this.switchSafetyArea();
            break;
          case ToolingActionsEnum.toggleGridView:
            this.toggleGridView();
            break;
          case ToolingActionsEnum.changeCameraPerspective:
            this.changeCameraPerspective();
            break;
          case ToolingActionsEnum.viewFromTheTop:
            this.viewFromTop();
            break;
          case ToolingActionsEnum.takeScreenshot:
            this.takeScreenshot();
            break;
          case ToolingActionsEnum.takeDefaultDesignScreenshot:
            this.takeScreenshotForDefaultDesignProposal();
            break;
          case ToolingActionsEnum.toggleTransparency:
            this.switchOpacity();
            break;
          case ToolingActionsEnum.undo:
            this.undo();
            break;
          case ToolingActionsEnum.redo:
            this.redo();
            break;
          default:
            console.error('Unknown method');
            break;
        }
      });
  }

  private treatUploadObjectData(
    data: File | ObjAndTextureFiles,
    instance,
    type: UploadedObjectTypeEnum
  ): void {
    if (!data) {
      this.removeUploadedObject(instance);
      this.loaderSceneGround();
      return;
    }
    if (data instanceof File) {
      this.loadObjectFromFile(data, type);
    } else if (data instanceof ObjAndTextureFiles) {
      this.loadObjectFromMultipleFiles(data, type);
    }
  }

  private subscribeToObjectAdded(): void {
    this.fileUploadService.objectFileChangeSubjectObserable.subscribe(
      (data: UploadFile) => {
        if (!data) {
          return;
        }

        const instance = this.getInstance(data.type);
        this.treatUploadObjectData(data.file, instance, data.type);
      }
    );
  }

  private subscribeToUploadedObjectUpdate(): void {
    this.fileUploadService.uploadedObjectUpdateObserable.subscribe(
      (data: UploadedObjectNameEnum) => {
        this.isManagingCustomObject = true;
        this.selectUploadedObject(data);
      }
    );
  }

  private selectUploadedObject(type: UploadedObjectNameEnum): void {
    const uploadedObjectToEdit =
      this.threeInstance?.scene.getObjectByName(type);
    this.addControlsToCustomObject(uploadedObjectToEdit);
    if (type === UploadedObjectNameEnum.customObject) {
      this.isEditingCustomObject = true;
      this.loaderSceneGround();
      return;
    }
    this.isEditingUnderground = true;
  }

  private removeUploadedObject(object: Mesh): void {
    object?.remove(this.threeInstance.controlBtns);
    this.discardTransformControls();
    if (this.instanceIntersectedObject === object) {
      this.instanceIntersectedObject = null;
    }
    this.threeInstance?.uploadedObjectHelper?.remove(object);
    object = null;
    this.isManagingCustomObject = false;
  }

  private setMultipleSelectObjectPreviousPositionAndColor(
    objectToClone: Object3D
  ): void {
    objectToClone.position.x = this.threeInstance.multiselectGroup.position
      ? objectToClone.position.x +
        this.threeInstance.multiselectGroup.position.x
      : objectToClone.position.x;
    objectToClone.position.z = this.threeInstance.multiselectGroup.position
      ? objectToClone.position.z +
        this.threeInstance.multiselectGroup.position.z
      : objectToClone.position.z;

    ThreeUtils.setHexColorOnMaterial(objectToClone, TextureColorEnum.neutral0);
  }

  private onObjectScale(object: Mesh): void {
    if (object.userData['name'] === ObjectTypeEnum.custom) {
      ThreeUtils.checkIfExceedingRestrictions(object, this.threeInstance);
      ThreeUtils.bringCustomObjectToPlane(object, this.threeInstance);
    }
    if (!this.isAltPressedValue) {
      this.previousScaleX = ThreeUtils.checkProportionateScalling(
        object,
        this.previousScaleX,
        this.previousScaleZ,
        this.changingValue
      );
      if (this.previousScaleX !== this.changingValue) {
        this.changingValue = this.previousScaleX;
      }
      this.previousScaleZ = Math.abs(object.scale.z);
    }
  }

  private copyFromKeyboard(): void {
    if (
      this.checkIfIsLocked() ||
      !this.hasAccessToIntersectedObjOrMultiSelectionGroup() ||
      this.isManagingCustomObject
    ) {
      return;
    }

    this.threeInstance.objectsToCopy = [];
    this.setObjectsToCopy();
  }

  private loadObjectFromFile(file: File, uploadedObjectType: number): void {
    const reader = new FileReader();
    const extension = file.name?.split('.')?.pop().trim().toLowerCase();

    if (extension === FileExtensionEnum.glb) {
      reader.onload = (gltfText) => {
        this.parseGLBFile(
          gltfText.target.result,
          uploadedObjectType,
          this.onGlbParsed
        );
      };
      reader.readAsArrayBuffer(file);
    }
  }

  private loadObjectFromMultipleFiles(
    files: ObjAndTextureFiles,
    uploadedObjectType: number
  ): void {
    const reader = new FileReader();
    reader.onload = (objText) => {
      const secondReader = new FileReader();
      secondReader.onload = (textureText) => {
        this.parseObjFile(
          objText.target.result,
          textureText.target.result,
          uploadedObjectType,
          this.onObjParsed
        );
      };
      secondReader.readAsText(files.textureFile);
    };
    reader.readAsText(files.objFile);
  }

  private onGlbParsed(gltf, uploadedObjectType: number): void {
    if (uploadedObjectType === UploadedObjectTypeEnum.customObject) {
      this.instanceUploadedCustom = gltf.scene;
      this.instanceUploadedCustom.userData['extension'] = FileExtensionEnum.glb;
      this.loadCustomObject(
        this.instanceUploadedCustom,
        UploadedObjectNameEnum.customObject
      );
    } else if (uploadedObjectType === UploadedObjectTypeEnum.fixedDesign) {
      this.instanceFixedDesign = gltf.scene;
      this.instanceFixedDesign.userData['extension'] = FileExtensionEnum.glb;
      this.loadCustomObject(
        this.instanceFixedDesign,
        UploadedObjectNameEnum.fixedDesign
      );
    } else {
      this.instanceUploadedUnderground = gltf.scene;
      this.instanceUploadedUnderground.userData['extension'] =
        FileExtensionEnum.glb;
      this.loadCustomObject(
        this.instanceUploadedUnderground,
        UploadedObjectNameEnum.underground
      );
    }
    this.isManagingCustomObject = true;
  }

  private getTypeName(type: UploadedObjectTypeEnum): string {
    switch (type) {
      case UploadedObjectTypeEnum.fixedDesign:
        return UploadedObjectNameEnum.fixedDesign;
      case UploadedObjectTypeEnum.customObject:
        return UploadedObjectNameEnum.customObject;
      case UploadedObjectTypeEnum.underground:
        return UploadedObjectNameEnum.underground;
      default:
        return '';
    }
  }

  private onObjParsed(
    uploadedObjectType: number,
    loadedObject: Object3D
  ): void {
    const typeName = this.getTypeName(uploadedObjectType);

    if (uploadedObjectType === UploadedObjectTypeEnum.customObject) {
      this.instanceUploadedCustom = loadedObject as any;
      this.instanceUploadedCustom.userData['extension'] = FileExtensionEnum.obj;
      this.loadCustomObject(this.instanceUploadedCustom, typeName);
    } else if (uploadedObjectType === UploadedObjectTypeEnum.fixedDesign) {
      this.instanceFixedDesign = loadedObject as any;
      this.instanceFixedDesign.userData['extension'] = FileExtensionEnum.obj;
      this.loadCustomObject(this.instanceFixedDesign, typeName);
    } else {
      this.instanceUploadedUnderground = loadedObject as any;
      this.instanceUploadedUnderground.userData['extension'] =
        FileExtensionEnum.obj;
      this.loadCustomObject(this.instanceUploadedUnderground, typeName);
    }
    this.isManagingCustomObject = true;
  }

  private loadCustomObject(instance: Mesh, name: string): void {
    const isUnderground: boolean = name === UploadedObjectNameEnum.underground;

    const position = ThreeUtils.getIntersectionFromCenterOfScreen(
      this.threeInstance
    );
    const yPos = isUnderground ? -5 : position.y;
    instance.position.set(position.x, yPos, position.z);
    instance.receiveShadow = true;
    instance.renderOrder = 0;
    instance.name = name;

    if (!this.threeInstance.uploadedObjectHelper) {
      this.threeInstance.uploadedObjectHelper = ThreeGroupBuilder.createGroup(
        ThreeGroupEnum.custom_uploaded_object,
        this.threeInstance.helpersGroup.renderOrder
      );
      this.threeInstance.helpersGroup.add(
        this.threeInstance.uploadedObjectHelper
      );
    }

    this.threeInstance.uploadedObjectHelper.add(instance);

    if (isUnderground && !this.isTransparent) {
      this.navigationService.toggleTransparency(true);
    }

    this.checkIfShouldAddControlsToCustomObject(instance);
  }

  private checkIfShouldAddControlsToCustomObject(uploadedObject: any): void {
    if (uploadedObject && !uploadedObject.userData.loadedOnServer) {
      this.addControlsToCustomObject(uploadedObject);
    }
  }

  private addControlsToCustomObject(uploadedObject: Object3D): void {
    const isFixedObject =
      uploadedObject.name === UploadedObjectNameEnum.fixedDesign;
    this.instanceIntersectedObject = uploadedObject;
    this.removeMultiselectGroup();
    this.setControlsMode(uploadedObject, isFixedObject);
    this.open3DNotificationSnackbar('userSettings.manageCustomObject');
    this.getIntersectedObjectType();
    this.threeInstance.transformControls.attach(
      this.threeInstance.intersectedObject
    );
    this.threeInstance.intersectedObject.add(this.threeInstance.controlBtns);
  }

  public manageCustomUploadedObject(mouseCoordinates: Vector2) {
    const newIntersectedObject = ThreeUtils.getIntersectedCustomUploadedObject(
      this.threeInstance,
      mouseCoordinates
    );
    this.checkIfShouldAddControlsToCustomObject(newIntersectedObject);
  }

  private checkIfShouldDisplayCollisionMessage() {
    if (this.checkSafetyAreaCollision()) {
      this.showToaster(
        'warning',
        'info',
        'user.publish.safetyAreaWarning',
        20000
      );
    }
    this.isCollisionOnPipes(this.threeInstance.objectsRegular.children as any);
  }

  public resetVisibility = () => {
    this.navigationService.toggleFullScreen(false);
    this.navigationService.toggleSafetyArea(false);
    this.navigationService.toggleTransparency(false);
    this.toolingService.toolingVisibility.isFullScreen = false;
    this.toolingService.toolingVisibility.isSafetyEnabled = false;
    this.toolingService.toolingVisibility.isTransparencyEnabled = false;
  };

  private unsubscribeFromObservable = () => {
    this.toolingActionsSubscription?.unsubscribe();
    this.tourSubscription?.unsubscribe();
    this.safetyAreaSubscription?.unsubscribe();
    this.transparentSubscription?.unsubscribe();
  };

  private get skipDefaultObjects(): boolean {
    if (this.isCollaborativeDesignRoute) {
      return false;
    }

    return (
      (this.authService.hasAdministrativeRole() &&
        this.designProposalId != null) ||
      !this.authService.hasAdministrativeRole()
    );
  }

  private subscribeToModifiedShift() {
    this.getModifiedShift()
      .pipe(distinctUntilChanged())
      .subscribe((response) => {
        if (response) {
          this.removeControlsFromObject();
          this.addEventsForClickSelection();
          this.open3DNotificationSnackbar('userSettings.shiftMessage');
        } else {
          this.removeEventsForClickSelection();
          if (this.threeInstance.selectionObjects.length > 0) {
            this.threeGrouping = new ThreeMultiselect(
              this.threeInstance,
              this.skipDefaultObjects,
              this.menuService
            );
            this.threeGrouping.onGroupingStart(
              this.threeInstance.selectionObjects
            );
            this.open3DNotificationSnackbar('userSettings.deselectMessage');
          }
        }
      });
  }

  private subscribeToSafetyAreaToggle(): void {
    this.safetyAreaSubscription =
      this.navigationService.safetyAreaToggled.subscribe((data) => {
        this.isSafetyEnabled = data;
        if (this.isSafetyEnabled) {
          this.enableSafetyArea();
        } else {
          this.disableSafetyArea();
        }
      });
  }

  private subscribeToTransparencyToggle(): void {
    this.transparentSubscription =
      this.navigationService.transparentToggled.subscribe((data) => {
        this.isTransparent = data;
        this.toggleOpacity();
      });
  }

  private subscribeTourStartingEvents(): void {
    this.tourSubscription =
      this.shepherdTourService.tourStepChangedEmitter.subscribe((data) => {
        if (!data) {
          return;
        }

        if (data === TourEvent.objectPlacementEvent) {
          if (!this.instanceIntersectedObject) {
            this.disabledEventsOnTour = true;
            this.addCustom3DObjectForTour();
            this.threeInstance.controls.enabled = false;
            this.threeInstance.transformControls.enabled = false;
          }
        } else if (data === TourEvent.endEvent) {
          if (this.instanceIntersectedObject != null) {
            this.disabledEventsOnTour = false;
            this.removeIntersectedObjectFromCanvas();
            this.threeInstance.controls.enabled = true;
            this.threeInstance.transformControls.enabled = true;
          }
        }
      });
  }

  private subscribeToStore(): void {
    this.store
      .pipe(map((state) => selectActualState(state.store)))
      .subscribe((data) => {
        this.objectsToRemoveStore = [...data.objectsToRemove];
        this.objectsToAddStore = [...data.objectsToAdd];

        if (this.objectsToRemoveStore?.length > 0) {
          this.removeMultipleObjects(this.objectsToRemoveStore);
        }

        this.updatePriceFromStore(data.budget);

        if (!this.objectsToAddStore?.length) {
          return;
        }
        this.addThreeObjects(
          this.objectsToAddStore,
          data.shouldSelect,
          data.shouldGroup,
          data.shouldGroup ? this.addMultiselectionToPastedGroup : null
        );
      });
  }

  private updatePriceFromStore(price: number): void {
    if (!price && price !== 0) {
      return;
    }
    this.availableBudget = price;
    this.projectPriceChange.emit(this.availableBudget);
  }

  private removeMultipleObjects(pathObjects: PathObject[]) {
    pathObjects.forEach((element) => {
      this.removePathObjectFromScene(element);
    });
  }

  private removePathObjectFromScene(pathObject: PathObject) {
    const objectsRegular = this.threeInstance?.scene?.getObjectByName(
      'furban_objects_regular'
    );
    const objectsGround = this.threeInstance?.scene?.getObjectByName(
      'furban_objects_ground'
    );
    if (!objectsRegular && !objectsGround) {
      return;
    }

    const allObjects = [
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...objectsRegular?.children,
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...objectsGround?.children,
    ];

    allObjects.forEach((element) => {
      if (element.userData['pathObjsId'] === pathObject.pathObjsId) {
        objectsRegular.remove(element);
        objectsGround.remove(element);
        return;
      }
    });
  }

  public callForObjects(): void {
    this.store.dispatch(
      getDefaultObjects({ projectId: this.project.projectId })
    );
    if (this.authService.isAdmin() && this.designProposalId) {
      this.store.dispatch(
        getDefaultDesignObjects({
          projectId: this.project.projectId,
          designId: this.designProposalId,
        })
      );
      this.store.dispatch(
        setInitialAvailableBudget({ budget: this.availableBudget })
      );
    } else if (this.authService.isCitizenOrExpert) {
      this.store.dispatch(
        getUserObjects({
          projectId: this.project.projectId,
          userProfileId: this.authService.userProfile.userProfileId,
        })
      );
      this.store.dispatch(
        setInitialAvailableBudget({ budget: this.availableBudget })
      );
    } else if (this.authService.isCollaborator()) {
      this.store.dispatch(getLiveObjects({ dpId: this.designProposalId }));
    }
    // it is possible here to treat the care if it is on a live collaboration route  ->  must be teststed admin functionality
  }

  private undo() {
    this.removeControlsFromObject();
    const storeValue = this.currentAppState.getValue().store;
    if (!storeValue.previous.length || storeValue.previous.length < 1) {
      return;
    }
    const currentState = storeValue.actual;
    const previousState = storeValue.previous[storeValue.previous.length - 1];

    const objectsToSave = [
      ...previousState.defaultObjects,
      ...previousState.userObjects,
    ];

    const userObjsToRemove = currentState.userObjects.filter(
      (obj) =>
        undefined ===
        previousState.userObjects.find(
          (element) => element.pathObjsId === obj.pathObjsId
        )
    );
    const defaultObjsToRemove = currentState.defaultObjects.filter(
      (obj) =>
        undefined ===
        previousState.defaultObjects.find(
          (element) => element.pathObjsId === obj.pathObjsId
        )
    );

    this.store.dispatch(
      undoRequest({
        objectsToSave: objectsToSave,
        objectsToRemove: [...userObjsToRemove, ...defaultObjsToRemove],
      })
    );
  }

  private redo() {
    const storeValue = this.currentAppState.getValue().store;
    if (!storeValue.future.length || storeValue.future.length < 1) {
      return;
    }

    const currentState = storeValue.actual;
    const futureState = storeValue.future[storeValue.future.length - 1];

    const objectsToSave = [
      ...futureState.defaultObjects,
      ...futureState.userObjects,
    ];

    const userObjsToRemove = currentState.userObjects.filter(
      (obj) =>
        undefined ===
        futureState.userObjects.find(
          (element) => element.pathObjsId === obj.pathObjsId
        )
    );
    const defaultObjsToRemove = currentState.defaultObjects.filter(
      (obj) =>
        undefined ===
        futureState.defaultObjects.find(
          (element) => element.pathObjsId === obj.pathObjsId
        )
    );

    this.store.dispatch(
      redoRequest({
        objectsToSave: objectsToSave,
        objectsToRemove: [...userObjsToRemove, ...defaultObjsToRemove],
      })
    );
  }

  protected updateIntersectedObject(): void {
    const object = this.threeInstance.transformControls.object as Mesh;
    const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
      this.threeInstance.currentCoordinates
    );
    const pointToCheck = new Vector3(object.position.x, 0, -object.position.z);
    if (
      !ThreeUtils.isPointInsideThePolygon(pointToCheck, canvasCoordinates) &&
      !object.userData['placeOutside']
    ) {
      object.position.x = object.userData['previousPosition'].x;
      object.position.z = object.userData['previousPosition'].z;
      this.showToaster(
        'warning',
        'info',
        'errors.objectNotMovedOutside',
        20000
      );
    } else {
      object.userData['previousPosition'] = new Vector3(
        object.position.x,
        object.position.y,
        object.position.z
      );
      const objToSave = ThreeUtils.convert3DObjectToPathObject(object);
      this.dispatchUpdateObjectRequest(objToSave);
    }
  }

  private updateMultipleSelectedObjects(): void {
    const pathObjectsToUpdate = [];
    const canvasCoordinates = ThreeUtils.convertFromPointXYTo3D(
      this.threeInstance.currentCoordinates
    );
    this.instanceMultiselectGroup.userData['selected'].forEach((element) => {
      const worldPosition = new Vector3(0, 0, 0);
      element?.getWorldPosition(worldPosition);

      const pointToCheck = new Vector3(worldPosition.x, 0, -worldPosition.z);

      if (
        !ThreeUtils.isPointInsideThePolygon(pointToCheck, canvasCoordinates) &&
        !element.userData?.placeOutside
      ) {
        this.restoreAllObjectsPreviousPositionAndRotation();
        return;
      }

      const objToSave = ThreeUtils.convert3DObjectToPathObject(element);

      objToSave.position = JSON.stringify(worldPosition);

      const worldQuaternion = new Quaternion();
      element?.getWorldQuaternion(worldQuaternion);
      objToSave.angle =
        ThreeUtils.convertFromQuaternionToDegrees(worldQuaternion);

      pathObjectsToUpdate.push(objToSave);
    });
    this.instanceMultiselectGroup.userData['previousPosition'] = new Vector3(
      this.instanceMultiselectGroup.position.x,
      this.instanceMultiselectGroup.position.y,
      this.instanceMultiselectGroup.position.z
    );

    this.instanceMultiselectGroup.userData['previousQuaternion'] =
      new Quaternion(
        this.instanceMultiselectGroup.quaternion.x,
        this.instanceMultiselectGroup.quaternion.y,
        this.instanceMultiselectGroup.quaternion.z,
        this.instanceMultiselectGroup.quaternion.w
      );

    this.dispatchUpdateMultipleObjectsRequest(pathObjectsToUpdate);
  }

  private restoreAllObjectsPreviousPositionAndRotation(): void {
    this.resetPosition();
    this.resetQuaternion();

    this.showToaster(
      'warning',
      'info',
      'errors.multiselectGroupOutside',
      20000
    );
  }

  private resetQuaternion(): void {
    this.instanceMultiselectGroup.quaternion.x =
      this.instanceMultiselectGroup.userData['previousQuaternion'].x;
    this.instanceMultiselectGroup.quaternion.y =
      this.instanceMultiselectGroup.userData['previousQuaternion'].y;
    this.instanceMultiselectGroup.quaternion.z =
      this.instanceMultiselectGroup.userData['previousQuaternion'].z;
    this.instanceMultiselectGroup.quaternion.w =
      this.instanceMultiselectGroup.userData['previousQuaternion'].w;
  }

  private resetPosition(): void {
    this.instanceMultiselectGroup.position.x =
      this.instanceMultiselectGroup.userData['previousPosition'].x;
    this.instanceMultiselectGroup.position.z =
      this.instanceMultiselectGroup.userData['previousPosition'].z;
  }

  private removeIntersectedObjectFromCanvas(): void {
    this.removeObjectFromScene(this.instanceIntersectedObject);
    this.removeControlsFromObject();
    this.instanceIntersectedObject = null;
  }

  protected dispatchAddObjectRequest(
    pathObject: PathObject,
    shouldSelect: boolean,
    budget?: number
  ): void {
    this.store.dispatch(
      addObjectRequest({
        object: pathObject,
        shouldSelect: shouldSelect,
        budget: budget,
      })
    );
  }

  protected dispatchMultipleDeleteObjectsRequest(
    pathObjectsToDelete: PathObject[],
    budget?: number
  ): void {
    this.store.dispatch(
      deleteMultipleObjectsRequest({
        objects: pathObjectsToDelete,
        budget: budget,
      })
    );
  }

  protected dispatchDeleteObjectsRequest(budget?: number): void {
    this.store.dispatch(
      deleteObjectRequest({
        object: this.instanceIntersectedObject.userData as any,
        budget: budget,
      })
    );
  }

  protected dispatchAddMultipleObjectsRequest(
    pathOjects: PathObject[],
    shouldGroup: boolean,
    shouldSelect: boolean,
    budget?: number
  ) {
    this.store.dispatch(
      addMultipleObjectsRequest({
        objects: pathOjects,
        shouldGroup: shouldGroup,
        shouldSelect: shouldSelect,
        budget: budget,
      })
    );
  }

  protected dispatchUpdateObjectRequest(pathObject: PathObject) {
    this.store.dispatch(updateObjectRequest({ object: pathObject }));
  }

  protected dispatchUpdateMultipleObjectsRequest(
    pathObjectsToUpdate: PathObject[]
  ) {
    this.store.dispatch(
      updateMultipleObjectsRequest({ objects: pathObjectsToUpdate })
    );
  }
}
