import { BoxGeometry, Path, PlaneGeometry, Shape, ShapeGeometry } from 'three';
import { ThreeUtils } from './three.utils';

export class ThreeGeometryBuilder {
  public static createInflatedGeometryFromArrayOfPoints(
    currentCoordinates: any,
    hole?: Path
  ): ShapeGeometry {
    const shape = new Shape();
    const inflatedShape = ThreeUtils.inflatePolygon(currentCoordinates, 15);
    shape.moveTo(inflatedShape[0].x, inflatedShape[0].y);

    inflatedShape.forEach((coordinate) => {
      shape.lineTo(coordinate.x, coordinate.y);
    });

    if (hole) {
      shape.holes.push(hole);
    }

    return new ShapeGeometry(shape);
  }

  public static createGeometryFromArrayOfPoints(
    curentCoordinates: any,
    hole?: Path
  ): ShapeGeometry {
    const shape = new Shape();
    shape.moveTo(curentCoordinates[0].X, curentCoordinates[0].Y);

    curentCoordinates.forEach((coordinate) => {
      shape.lineTo(coordinate.X, coordinate.Y);
    });

    if (hole) {
      shape.holes.push(hole);
    }

    return new ShapeGeometry(shape);
  }

  public static createPlaneGeometry(
    width: number,
    height: number
  ): PlaneGeometry {
    return new PlaneGeometry(width, height);
  }

  public static createElipseGeometry(rx: number, ry: number): ShapeGeometry {
    const curve = new Shape();
    curve.absellipse(0, 0, rx, ry, 0, Math.PI * 2, false, 0);
    return new ShapeGeometry(curve, 100);
  }

  public static createFreeshapeGeometry(parsedDimensions: any): ShapeGeometry {
    for (let i = 0; i < parsedDimensions.length; i++) {
      parsedDimensions[i]['x'] = parseFloat(
        parsedDimensions[i]['x'].toFixed(3)
      );
      parsedDimensions[i]['y'] = parseFloat(
        parsedDimensions[i]['y'].toFixed(3)
      );
    }

    const shape = new Shape();
    shape.moveTo(parsedDimensions[0].x, parsedDimensions[0].y);

    parsedDimensions.forEach((coordinate) => {
      shape.lineTo(coordinate['x'], coordinate['y']);
    });

    return new ShapeGeometry(shape);
  }

  public static createCustomShapeGeometry(parsedDimensions: any): BoxGeometry {
    return new BoxGeometry(
      parsedDimensions.width,
      parsedDimensions.depth,
      parsedDimensions.height
    );
  }
}
