import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  Injector,
  ViewContainerRef,
} from '@angular/core';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { NavigationService } from '../navigation/navigation.service';

import {
  AuthService,
  BackgroundModel,
  Comment,
  CommentsService,
  Project3DUpload,
  CustomToasterService,
  DesignProposal,
  FileUploadService,
  FurbanMeshPosition,
  FurbanUtil,
  Loaded3DObject,
  MenuService,
  ModalManager,
  ObjectTypeEnum,
  ObjectUtil,
  Opened3DSections,
  PathObject,
  Project,
  ProjectAndPathIds,
  ProjectService,
  SvgImageEnum,
  TextureColorEnum,
  ThreeAnimationBuilder,
  ThreeCameraBuilder,
  ThreeCommentPopupComponent,
  ThreeControlBuilder,
  ThreeGLTFExportHelper,
  ThreeGroupEnum,
  ThreeLightsBuilder,
  ThreeMeshBuilder,
  ThreeObjectControlsEnum,
  ThreeRendererBuilder,
  ThreeStateEnum,
  ThreeUtils,
  urlConstants,
  UploadedObjectTypeEnum,
  UploadedObjectNameEnum,
  FileExtensionEnum,
} from '@furban/utilities';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { KeyCode } from '@furban/utilities';

import {
  FurbanLoaderService,
  ThreeGridHelperBuilder,
  ThreeGroupBuilder,
  ThreeInstance,
} from '@furban/utilities';
import { TranslateService } from '@ngx-translate/core';
import TWEEN from '@tweenjs/tween.js';
import { ProjectDetailsService } from '../../project-shared/project-details/project-details.service';
import { PublishService } from '../../project-shared/publish-popup/publish.service';
import { BackgroundMediaService } from '../../municipality/admin/background-media/background-media.service';
import { NotificationToasterComponent } from '../notification-toaster/notification-toaster.component';
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from 'three-mesh-bvh/build/index.module';
import { forkJoin, Subscription } from 'rxjs';
import { ToolingService } from '../tooling/tooling.service';
import {
  AmbientLight,
  AnimationClip,
  Box3,
  BufferGeometry,
  Clock,
  DirectionalLight,
  Group,
  HemisphereLight,
  LoadingManager,
  Mesh,
  MeshLambertMaterial,
  MOUSE,
  Object3D,
  Path,
  RingGeometry,
  Scene,
  Sphere,
  Vector3,
} from 'three';

Mesh.prototype.raycast = acceleratedRaycast;
BufferGeometry['prototype']['computeBoundsTree'] = computeBoundsTree;
BufferGeometry['prototype']['disposeBoundsTree'] = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

@Component({
  selector: 'furban-public-three-js-editor',
  templateUrl: './public-three-js-editor.component.html',
  styleUrls: ['./public-three-js-editor.component.scss'],
})
export class PublicThreeJsEditorComponent implements AfterViewInit, OnDestroy {
  @Input() public userObjects: PathObject[];
  @Input() public state?;
  @Input() project?: Project;
  @Input() showButtons?: boolean;
  @Input() userProfileId?: string;
  @Input() designProposal?: DesignProposal;
  @Output() rightMenuVisibiltyChanged: EventEmitter<boolean> =
    new EventEmitter();
  @Output() threeJSCommentSave: EventEmitter<Comment> = new EventEmitter();

  @ViewChild('canvas3d')
  public canvasRef: ElementRef;
  @ViewChild('commentPopup')
  threeCommentPopupComponent: ThreeCommentPopupComponent;

  public isApp = FurbanUtil.isApp;

  public clock = new Clock();

  public threeInstance = new ThreeInstance();
  public meshBuilder = new ThreeMeshBuilder();
  public manager: LoadingManager;
  public loader: GLTFLoader;
  public editMode = true;
  public canExportDesign = false;
  public fileName: string;
  public isRightMenuVisible = !FurbanUtil.isMobile();
  public isMobile = FurbanUtil.isMobile();
  public bindedClickEvent = this.onMouseClick.bind(this);
  public bindedSelectPinEvent = this.onPinSelectClick.bind(this);
  public addingCommentsEnabled = false;
  public pinPosition: string;
  public isThreeLoaded = false;
  public currentPinComment: Comment;
  public mtlLoader = new MTLLoader();
  public objLoader = new OBJLoader();
  public gltfLoader = new GLTFLoader();

  public authService: AuthService;
  public projectService: ProjectService;
  public dialog: MatDialog;
  public publishService: PublishService;
  public navigationService: NavigationService;
  public backgroundMediaService: BackgroundMediaService;
  public loaderService: FurbanLoaderService;
  public modalManager: ModalManager;
  public translateService: TranslateService;
  public snackbar: MatSnackBar;
  public menuService: MenuService;
  public projectDetailsService: ProjectDetailsService;
  public customToasterService: CustomToasterService;
  public commentService: CommentsService;
  public fileUploadService: FileUploadService;
  public toolingService: ToolingService;
  public commentHighlightedSubscription: Subscription;
  public commentDeletedSubscription: Subscription;
  public animationFrameId: number;
  public isIOS = FurbanUtil.isIOS();
  public viewContainerRef: ViewContainerRef;
  public hemiLight: HemisphereLight;
  public ambientLight: AmbientLight;
  public dirLight: DirectionalLight;
  public tilesRuntime = null;

  private lastAddedPin: Group;
  private lastSelectedPin: Object3D;
  private uploadedCustomObject: Project3DUpload;
  private uploadedUnderground: Project3DUpload;
  private fixedDesign: Project3DUpload;

  constructor(protected injector: Injector) {
    this.authService = injector.get<AuthService>(AuthService);
    this.projectService = injector.get<ProjectService>(ProjectService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.publishService = injector.get<PublishService>(PublishService);
    this.navigationService = injector.get<NavigationService>(NavigationService);
    this.backgroundMediaService = injector.get<BackgroundMediaService>(
      BackgroundMediaService
    );
    this.loaderService = injector.get<FurbanLoaderService>(FurbanLoaderService);
    this.modalManager = injector.get<ModalManager>(ModalManager);
    this.translateService = injector.get<TranslateService>(TranslateService);
    this.snackbar = injector.get<MatSnackBar>(MatSnackBar);
    this.menuService = injector.get<MenuService>(MenuService);
    this.projectDetailsService = injector.get<ProjectDetailsService>(
      ProjectDetailsService
    );
    this.customToasterService =
      injector.get<CustomToasterService>(CustomToasterService);
    this.commentService = injector.get<CommentsService>(CommentsService);
    this.fileUploadService = injector.get<FileUploadService>(FileUploadService);
    this.toolingService = injector.get<ToolingService>(ToolingService);
    this.viewContainerRef = injector.get<ViewContainerRef>(ViewContainerRef);

    this.render = this.render.bind(this);
    this.navigationService.leftNavToggled.subscribe(() => {
      this.onResize();
    });

    this.navigationService.fullScreenToggled.subscribe(() => {
      this.onResize();
    });

    this.menuService.menuToggled.subscribe(() => {
      this.onResize();
    });
  }

  public get instanceUploadedCustom(): Mesh {
    return this.threeInstance.uploadedCustomObject;
  }

  public set instanceUploadedCustom(object: Mesh) {
    this.threeInstance.uploadedCustomObject = object;
  }

  public get instanceUploadedUnderground(): Mesh {
    return this.threeInstance.uploadedUnderground;
  }

  public set instanceUploadedUnderground(group: Mesh) {
    this.threeInstance.uploadedUnderground = group;
  }

  public get instanceFixedDesign(): Mesh {
    return this.threeInstance.uploadedFixedbject;
  }

  public set instanceFixedDesign(group: Mesh) {
    this.threeInstance.uploadedFixedbject = group;
  }

  public get threeStateEnum() {
    return ThreeStateEnum;
  }

  public get threeObjectControlsEnum() {
    return ThreeObjectControlsEnum;
  }

  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public get svgImageType(): typeof SvgImageEnum {
    return SvgImageEnum;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    const openedSections = new Opened3DSections(
      this.menuService.menuOpened,
      this.navigationService.leftNavOpened,
      !this.editMode,
      this.navigationService.isFullScreen
    );
    ThreeUtils.setCanvasDimensions(
      this.canvas,
      this.threeInstance.camera,
      openedSections
    );
    if (this.threeInstance.renderer) {
      this.threeInstance.renderer.setSize(
        this.canvas.width,
        this.canvas.height
      );
      if (this.threeInstance.htmlRenderer) {
        this.threeInstance.htmlRenderer.setSize(
          this.threeInstance.renderer.domElement.width,
          this.threeInstance.renderer.domElement.height
        );
      }
    }
  }

  ngOnDestroy(): void {
    cancelAnimationFrame(this.animationFrameId);

    this.ambientLight.dispose();
    this.hemiLight.dispose();
    this.dirLight.dispose();
    this.threeInstance.renderer.dispose();
    ThreeUtils.disposeThreeElement(this.threeInstance.scene);
    delete this.threeInstance.scene;
    delete this.threeInstance.camera;
    delete this.threeInstance.renderer;
    delete this.threeInstance.htmlRenderer;
    ThreeUtils.removeNoScroll();
    this.unsubscribeToPinEvents();
    this.removeEventListenersForPan();
  }

  ngAfterViewInit(): void {
    this.getAccessLevelForExport();
    this.checkIfObjectsAreAvailableAndLoadThreeJs();
  }

  public checkIfObjectsAreAvailableAndLoadThreeJs(): void {
    if (
      !this.menuService.availableObjects ||
      this.menuService.availableObjects.length === 0
    ) {
      this.menuService
        .getObjectsOrdered(this.project.projectId)
        .subscribe(() => {
          this.initializeThreeJS();
        });
    } else {
      this.initializeThreeJS();
    }
  }

  public initializeThreeJS(): void {
    this.createScene();
    this.createLight();
    this.createCamera();
    this.startRendering();
    this.addControls();
    this.addCommentsFunctionalities();
  }

  public get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  public checkIfAlreadyLoaded(object: PathObject): Loaded3DObject {
    let loadedObject: Loaded3DObject;
    this.threeInstance.loadedMeshes.forEach(
      (value: AnimationClip, key: Group) => {
        if (key.userData['objId'] === object.objId) {
          loadedObject = new Loaded3DObject(key, value);
        }
      }
    );
    return loadedObject;
  }

  public loadAndCloneObjects(
    loader: GLTFLoader,
    pathObjArray: any,
    shouldBeSelectableObjects: boolean,
    shouldBeGrouped?: boolean,
    groupedCallack?: any
  ): void {
    const length = Object.keys(pathObjArray).length;
    if (pathObjArray && length) {
      const firstIndex = ThreeUtils.getKeyOfObject(pathObjArray, 0);
      const loadedObjectEntry: Loaded3DObject = this.checkIfAlreadyLoaded(
        pathObjArray[firstIndex]
      );

      /** If the object is already loaded, clone it and add it to canvas */
      if (loadedObjectEntry) {
        this.cloneLoadedObject(
          loadedObjectEntry.scene,
          pathObjArray,
          loadedObjectEntry.animation,
          shouldBeSelectableObjects,
          shouldBeGrouped,
          groupedCallack
        );
      } else {
        /** Load the object if it isn't already loaded */
        const loadingUrl = ThreeUtils.getObjectPath(
          pathObjArray[firstIndex].objId,
          this.threeInstance.mobileObjectsIds
        );

        /** Loading the resorce with GLTF Loader */
        loader.load(loadingUrl, (gltf) => {
          gltf.scene.userData['objId'] = pathObjArray[firstIndex].objId;
          const animation = gltf.animations[0];
          ThreeUtils.traverseObjectAndAddProperties(gltf.scene);
          this.threeInstance.loadedMeshes.set(gltf.scene, animation);
          this.cloneLoadedObject(
            gltf.scene,
            pathObjArray,
            animation,
            shouldBeSelectableObjects,
            shouldBeGrouped
          );
        });
      }
    }
  }

  public cloneLoadedObject(
    gltfScene: Group,
    pathObjArray: PathObject[],
    animation: AnimationClip,
    shouldBeSelectableObject: boolean,
    shouldBeGrouped?: boolean,
    groupedCallack?: any
  ): void {
    const length = Object.keys(pathObjArray).length;
    for (let i = 0; i < length; i++) {
      const clonedObject = gltfScene.clone();

      ThreeUtils.cloneMaterial(clonedObject);
      clonedObject.renderOrder = this.threeInstance.groundGroup.children.length;
      const nextIndex = ThreeUtils.getKeyOfObject(pathObjArray, i);

      /** If the 3D Object has animation that will be stored in order to render it */
      if (animation) {
        const mixer = ThreeAnimationBuilder.createAnimationMixer(
          clonedObject,
          animation
        );
        this.threeInstance.animationMixers.push(mixer);
      }

      const pathObjectPosition = JSON.parse(pathObjArray[nextIndex].position);

      const meshPosition = new FurbanMeshPosition(
        pathObjectPosition,
        pathObjArray[nextIndex].angle
      );

      ThreeUtils.addPropertiesToObject(pathObjArray[nextIndex], clonedObject);
      ThreeUtils.setPositionAndRotationOnMesh(
        clonedObject,
        meshPosition,
        false
      );
      this.attachSafetyHelperOnObject(clonedObject);
      this.threeInstance.objectsRegular.add(clonedObject);

      if (shouldBeSelectableObject) {
        this.attachButtonsAndControlsToObject(clonedObject);
      }

      if (shouldBeGrouped) {
        this.threeInstance.newGroup.push(clonedObject);
      }
    }
    if (shouldBeGrouped) {
      groupedCallack();
    }
  }

  public attachSafetyHelperOnObject(object: Group): void {
    const sphere = this.createSphereBasedBox(object);
    object.userData['obb'] = sphere.radius;
    if (!object.userData['safetyArea']) {
      return;
    }
    const material = new MeshLambertMaterial({
      color: TextureColorEnum.warning,
    });
    const mesh = new Mesh(
      new RingGeometry(sphere.radius - 0.4, sphere.radius, 100),
      material
    );
    this.addPropertiesToMesh(mesh);
    object.add(mesh);
  }

  public createSphereBasedBox(object: Group): Sphere {
    const box = new Box3().setFromObject(object);
    const sphere = new Sphere();
    box.getBoundingSphere(sphere);
    const increaseByUnits = object.userData['safetyArea'] / 100; // Convert cm to units used by THREE.js (m)
    sphere.radius += increaseByUnits;
    return sphere;
  }

  public addPropertiesToMesh(mesh: Mesh): void {
    mesh.rotation.x = -Math.PI / 2;
    mesh.updateMatrix();
    mesh.geometry.applyMatrix4(mesh.matrix);
    mesh.rotation.set(0, 0, 0);
    mesh.position.y = ThreeUtils.linesZFightingOffset;
    mesh.name = ThreeUtils.sphereHelper;
    mesh.visible = false;
  }

  public getIntersectedObjectType(): void {
    if (this.threeInstance.intersectedObject.userData['name']) {
      this.threeInstance.intersectedType =
        this.threeInstance.intersectedObject.userData['name'];
    } else {
      this.threeInstance.intersectedType = ObjectTypeEnum.regular;
    }
  }

  public getProjectAndPathId(): ProjectAndPathIds {
    return {
      projectId: this.project.projectId,
      pathId: this.projectService.curentDrawing.pathId,
    };
  }

  public showLoader(): void {
    this.loaderService.show(false);
    this.loaderService.permanent = true;
  }

  public hideLoader(): void {
    setTimeout(() => {
      this.loaderService.permanent = false;
      this.loaderService.hide();
    }, 1000);
  }

  public onLoadManager(): void {
    this.manager.onProgress = (item: any, loaded: number, total: number) => {
      this.showLoader();
      if (loaded === total) {
        this.isThreeLoaded = true;
        this.hideLoader();
      }
    };
  }

  public createManagerAndLoader(): void {
    /** Adding manager to know when all the models are loaded */
    this.manager = new LoadingManager();
    this.manager.addHandler(/\.dds$/i, new DDSLoader());
    this.loader = new GLTFLoader(this.manager);
    this.onLoadManager();
  }

  public addThreeObjects(
    pathObjects: PathObject[],
    shouldBeSelectableObjects: boolean,
    shouldBeGrouped?: boolean,
    groupedCallack?: any
  ): void {
    if (!pathObjects) {
      return;
    }

    this.menuService.getObjectsIds(this.project.projectId).subscribe((data) => {
      const objectIds = data;
      const pathObjectArray = ThreeUtils.transformArrayIntoObject(objectIds);
      this.organizePathObjectsBasedOnIds(
        pathObjectArray,
        pathObjects,
        shouldBeSelectableObjects
      );

      if (
        pathObjects.length === 1 &&
        pathObjects[0].name === ObjectTypeEnum.custom
      ) {
        return;
      }

      this.traverseArrayOfObjectsAndClone(
        objectIds,
        pathObjectArray,
        shouldBeSelectableObjects,
        shouldBeGrouped,
        groupedCallack
      );
    });
  }

  public toggleAddingCommentsOnClick(): void {
    this.addingCommentsEnabled = !this.addingCommentsEnabled;
    if (this.addingCommentsEnabled) {
      this.enable3DComments();
    } else {
      this.disable3DComment();
    }
  }

  private disable3DComment(): void {
    this.removeEventsForComments();
    this.addEventsForSelectingComment();
    if (this.threeCommentPopupComponent) {
      this.threeCommentPopupComponent.closeDialog();
    }
    this.commentService.highlightPinSubject.next(false);
  }

  private enable3DComments(): void {
    this.removeEventsForSelectingComment();
    this.addEventsForComments();
    this.commentService.highlightPinSubject.next(true);
  }

  private organizePathObjectsBasedOnIds(
    pathObjectArray: any,
    pathObjects: PathObject[],
    shouldBeSelectableObjects: boolean
  ): void {
    for (const pathObject of pathObjects) {
      if (ObjectUtil.isGroundOrCustomObject(pathObject.name)) {
        this.attachGroundObjectToScene(pathObject, shouldBeSelectableObjects);
      } else {
        pathObjectArray[pathObject.objId].push(pathObject);
      }
    }
  }

  private traverseArrayOfObjectsAndClone(
    objectIds: number[],
    pathObjectArray: any,
    shouldBeSelectableObjects: boolean,
    shouldBeGrouped?: boolean,
    groupedCallack?: any
  ): void {
    for (let i = 0; i < objectIds.length; i++) {
      if (pathObjectArray[objectIds[i]].length > 0) {
        this.loadAndCloneObjects(
          this.loader,
          pathObjectArray[objectIds[i]],
          shouldBeSelectableObjects,
          shouldBeGrouped,
          groupedCallack
        );
      }
    }
  }

  public attachGroundObjectToScene(
    pathObject: PathObject,
    shouldBeSelectableObjects: boolean,
    freeze?: boolean
  ): void {
    const createdObject = this.meshBuilder.createThreeMesh(
      pathObject.name,
      pathObject
    );

    ThreeUtils.addPropertiesToObject(pathObject, createdObject);
    if (pathObject.name !== ObjectTypeEnum.custom) {
      createdObject.renderOrder =
        this.threeInstance.objectsGround.children.length;
    }
    this.threeInstance.objectsGround.add(createdObject);

    if (shouldBeSelectableObjects) {
      this.attachButtonsAndControlsToObject(createdObject);
    }

    if (freeze) {
      createdObject.userData['freeze'] = true;
      ThreeUtils.setHexColorOnMaterial(createdObject, TextureColorEnum.yellow);
    }
  }

  public addingBackground(backgroundMedia: BackgroundModel): void {
    this.threeInstance.backgroundGroup = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.background,
      1
    );
    this.threeInstance.scene.add(this.threeInstance.backgroundGroup);

    this.backgroundMediaService
      .downloadBackgroundMedia(backgroundMedia.backgroundMediaId)
      .subscribe((data) => {
        if (data) {
          this.loader.parse(
            data,
            '',
            (gltf) => {
              const coordinates = JSON.parse(backgroundMedia.coordinates);
              gltf.scene.position.x = coordinates.x;
              gltf.scene.position.z = coordinates.y;
              gltf.scene.traverse((obj) => {
                obj.receiveShadow = true;
              });
              this.threeInstance.backgroundGroup.add(gltf.scene);
            },
            (err) => {
              console.error(err);
            }
          );
        }
      });
  }

  public loaderSceneGround = (customObjectHolePath?: Path) => {
    this.threeInstance.groundGroup.remove(
      ...this.threeInstance.groundGroup.children
    );

    const areaSelectionHolePath = ThreeUtils.generateHoleFromPoints(
      this.threeInstance.currentCoordinates
    );

    const defaultPlane = this.meshBuilder.createDefaultPlane(
      areaSelectionHolePath
    );
    this.threeInstance.groundGroup.add(defaultPlane);

    const hiddenPlane = this.meshBuilder.createInvisiblePlane();
    this.threeInstance.groundGroup.add(hiddenPlane);

    const plane = this.meshBuilder.createPlaneFromGroundAreaSelection(
      this.threeInstance.currentCoordinates,
      this.projectService.curentDrawing.defaultMaterial,
      customObjectHolePath
    );
    this.threeInstance.groundGroup.add(plane);
    const bbox = new Box3().setFromObject(plane);
    this.setUpGrid(bbox);
    this.shouldEnableTransparency();
  };

  public shouldEnableTransparency(): void {
    if (this.instanceUploadedUnderground) {
      this.navigationService.toggleTransparency(true);
      this.toolingService.toolingVisibility.isTransparencyEnabled = true;
    }
  }

  public getUploadObjectsOvrr(): void {
    this.getUploadedObjects();
  }

  public setupSceneGround = () => {
    this.threeInstance.groundGroup = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.ground,
      0
    );
    this.threeInstance.scene.add(this.threeInstance.groundGroup);
    ThreeUtils.parseCoordinaresForGround(
      this.threeInstance,
      this.projectService.curentDrawing.xYCoordinates
    );
    this.getUploadObjectsOvrr();
  };

  public parseGLBFile(
    text: string | ArrayBuffer,
    uploadedObjectType: number,
    callbackFunction: any
  ): void {
    this.gltfLoader.parse(
      text,
      '',
      (gltf) => {
        callbackFunction.apply(this, [gltf, uploadedObjectType]);
      },
      (errormsg) => {
        console.error(errormsg);
      }
    );
  }

  public parseObjFile(
    objText: string | ArrayBuffer,
    textureText: string | ArrayBuffer,
    uploadedObjectType: number,
    callbackFunction: any
  ): void {
    const loadedTexture = this.mtlLoader.parse(textureText as string, '/');
    this.objLoader.setMaterials(loadedTexture);
    const loadedObject = this.objLoader.parse(objText as string);
    callbackFunction.apply(this, [uploadedObjectType, loadedObject]);
  }

  private loadFromGLBFile(
    file: File,
    uploadedObjectType: UploadedObjectTypeEnum
  ): void {
    const reader = new FileReader();
    reader.onload = (gltfText) => {
      this.parseGLBFile(
        gltfText.target.result,
        uploadedObjectType,
        this.onGlbFileParsed
      );
    };
    reader.readAsArrayBuffer(file);
  }

  private onGlbFileParsed(
    gltf,
    uploadedObjectType: UploadedObjectTypeEnum
  ): void {
    if (uploadedObjectType === UploadedObjectTypeEnum.customObject) {
      this.instanceUploadedCustom = gltf.scene;
      this.addCustomObjectToScene(
        this.instanceUploadedCustom,
        this.uploadedCustomObject,
        UploadedObjectNameEnum.customObject
      );
    } else if (uploadedObjectType === UploadedObjectTypeEnum.fixedDesign) {
      this.instanceFixedDesign = gltf.scene;
      this.addCustomObjectToScene(
        this.instanceFixedDesign,
        this.fixedDesign,
        UploadedObjectNameEnum.fixedDesign
      );
    } else {
      this.instanceUploadedUnderground = gltf.scene;
      this.addCustomObjectToScene(
        this.instanceUploadedUnderground,
        this.uploadedUnderground,
        UploadedObjectNameEnum.underground
      );
    }
  }

  private loadFromOBJFile(
    file: File,
    textureFile: File,
    uploadedObjectType: UploadedObjectTypeEnum
  ): void {
    const reader = new FileReader();
    reader.onload = (objText) => {
      const secondReader = new FileReader();
      secondReader.onload = (textureText) => {
        this.parseObjFile(
          objText.target.result,
          textureText.target.result,
          uploadedObjectType,
          this.onObjFileParsed
        );
      };
      secondReader.readAsText(textureFile);
    };
    reader.readAsText(file);
  }

  private onObjFileParsed(
    uploadedObjectType: number,
    loadedObject: Object3D
  ): void {
    if (uploadedObjectType === UploadedObjectTypeEnum.customObject) {
      this.instanceUploadedCustom = loadedObject as Mesh;
      this.addCustomObjectToScene(
        this.instanceUploadedCustom,
        this.uploadedCustomObject,
        UploadedObjectNameEnum.customObject
      );
    } else if (uploadedObjectType === UploadedObjectTypeEnum.fixedDesign) {
      this.instanceFixedDesign = loadedObject as Mesh;
      this.addCustomObjectToScene(
        this.instanceFixedDesign,
        this.fixedDesign,
        UploadedObjectNameEnum.fixedDesign
      );
    } else {
      this.instanceUploadedUnderground = loadedObject as Mesh;
      this.addCustomObjectToScene(
        this.instanceUploadedUnderground,
        this.uploadedUnderground,
        UploadedObjectNameEnum.underground
      );
    }
  }

  public addCustomObjectToScene(
    uploadedObject: Mesh,
    project3DUpload: Project3DUpload,
    objectName: string
  ): void {
    const position: Vector3 = JSON.parse(project3DUpload.position);
    const rotation = JSON.parse(project3DUpload.rotation);
    ThreeUtils.traverseObjectAndAddProperties(uploadedObject);

    uploadedObject.position.set(position.x, position.y, position.z);
    uploadedObject.rotation.copy(rotation);
    uploadedObject.receiveShadow = true;
    uploadedObject.renderOrder = 0;
    uploadedObject.name = objectName;
    uploadedObject.userData['loadedOnServer'] = true;

    if (!this.threeInstance.uploadedObjectHelper) {
      this.threeInstance.uploadedObjectHelper = ThreeGroupBuilder.createGroup(
        ThreeGroupEnum.custom_uploaded_object,
        this.threeInstance.helpersGroup.renderOrder
      );
      this.threeInstance.helpersGroup.add(
        this.threeInstance.uploadedObjectHelper
      );
    }
    this.threeInstance.uploadedObjectHelper.add(uploadedObject);
    if (objectName === UploadedObjectNameEnum.customObject) {
      const hole = ThreeUtils.generateHoleForCustomLayer(uploadedObject);
      this.loaderSceneGround(hole);
    }
  }

  public setupSceneBackground(): void {
    const projectId = this.projectService.getProjectIdFromService();

    this.backgroundMediaService
      .getBackgroundMedia(projectId)
      .subscribe((data) => {
        if (data && data.isVisible) {
          this.addingBackground(data);
        }
      });
  }

  public addChildrenGroupsToParent(allObjectsGroup: Group): void {
    this.threeInstance.objectsGround = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.objectsGround,
      1
    );
    allObjectsGroup.add(this.threeInstance.objectsGround);

    this.threeInstance.objectsRegular = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.objectsRegular,
      2
    );
    allObjectsGroup.add(this.threeInstance.objectsRegular);
  }

  public setupGroups(): void {
    this.threeInstance.allObjectsGroup = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.objects,
      2
    );
    this.threeInstance.scene.add(this.threeInstance.allObjectsGroup);
    this.addChildrenGroupsToParent(this.threeInstance.allObjectsGroup);

    this.threeInstance.helpersGroup = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.helpers,
      3
    );
    this.threeInstance.scene.add(this.threeInstance.helpersGroup);

    this.threeInstance.transformGroup = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.transform,
      5
    );
    this.threeInstance.scene.add(this.threeInstance.transformGroup);
  }

  public createScene(): void {
    this.createManagerAndLoader();
    this.threeInstance.scene = new Scene();
    this.setupSceneGround();
    this.setupSceneBackground();
    this.setupGroups();

    if (this.userObjects) {
      this.addThreeObjects(this.userObjects, false);
    }
  }

  public createLight(): void {
    // Adding Ambient Light //
    this.ambientLight = ThreeLightsBuilder.createAmbientLight();
    this.threeInstance.scene.add(this.ambientLight);

    // // Adding Hemisphere Light //
    this.hemiLight = ThreeLightsBuilder.createHemisphereLight(
      this.threeInstance.maxXPointFromArray / 2,
      this.threeInstance.minYPointFromArray / 2
    );
    this.threeInstance.scene.add(this.hemiLight);

    // Adding Directional Light //
    this.dirLight = ThreeLightsBuilder.createDirectionalLight(
      this.threeInstance.maxXPointFromArray,
      this.threeInstance.minYPointFromArray
    );
    this.threeInstance.scene.add(this.dirLight);
  }

  public createCamera(): void {
    const aspectRatio = ThreeUtils.getAspectRatio(this.canvas);
    const position = new Vector3(
      this.threeInstance.maxXPointFromArray,
      20,
      this.threeInstance.minYPointFromArray
    );
    this.threeInstance.camera = ThreeCameraBuilder.createPerspectiveCamera(
      aspectRatio,
      position
    );
  }

  public createNewCameraTransition(
    cameraPosition: any,
    newPosition: any,
    focussedPoint: Vector3
  ): void {
    new TWEEN.Tween(cameraPosition)
      .to(newPosition, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        this.threeInstance.controls.update();
      })
      .onStart(() => {
        this.threeInstance.controls.target = new Vector3(
          focussedPoint.x,
          focussedPoint.y,
          focussedPoint.z
        );
      })
      .start(TWEEN.now());
  }

  public startRendering(): void {
    const openedSections = new Opened3DSections(
      this.menuService.menuOpened,
      this.navigationService.leftNavOpened,
      !this.editMode,
      this.navigationService.isFullScreen
    );

    ThreeUtils.setCanvasDimensions(
      this.canvas,
      this.threeInstance.camera,
      openedSections
    );

    this.threeInstance.renderer = ThreeRendererBuilder.createRenderer(
      this.canvas
    );

    if (this.authService.isLoggedIn()) {
      this.setupPinPopupOnRenderer();
    }

    this.recursiveRender();
  }

  public recursiveRender = () => {
    this.animationFrameId = requestAnimationFrame(this.recursiveRender);
    TWEEN.update(TWEEN.now());

    const dt = this.clock.getDelta();
    if (this.tilesRuntime) {
      this.tilesRuntime.update(
        dt,
        window.innerHeight,
        this.threeInstance.camera
      );
    }

    this.render();
  };

  public updateAnimationMixers(viewMode: boolean): void {
    if (viewMode) {
      const delta = this.threeInstance.clock.getDelta();
      this.threeInstance.animationMixers.forEach((mixer) => {
        mixer.update(delta);
      });
    }
  }

  public render(): void {
    this.updateAnimationMixers(true);

    if (
      this.threeInstance.scene &&
      this.threeInstance.camera &&
      this.threeInstance.renderer
    ) {
      this.threeInstance.renderer.render(
        this.threeInstance.scene,
        this.threeInstance.camera
      );
      if (this.threeInstance.htmlRenderer) {
        this.threeInstance.htmlRenderer.render(
          this.threeInstance.scene,
          this.threeInstance.camera
        );
      }
    }
  }

  public addControls(): void {
    const position = new Vector3(
      this.threeInstance.maxXPointFromArray / 2,
      0,
      this.threeInstance.minYPointFromArray / 2
    );
    this.threeInstance.controls = ThreeControlBuilder.createOrbitControls(
      this.threeInstance.camera,
      this.threeInstance.renderer.domElement,
      position
    );

    this.addEventListenersForCustomPan();
  }

  public redrawObjects(objects: any[]): void {
    if (!this.threeInstance?.scene) {
      return;
    }

    this.userObjects = objects;
    this.removePathObjects();
    this.addThreeObjects(this.userObjects, false);
    this.onPinPopupClose(false);
    this.loadPin();
  }

  public removePathObjects(): void {
    const objectsRegular = this.threeInstance?.scene?.getObjectByName(
      'furban_objects_regular'
    );
    objectsRegular?.remove(...objectsRegular.children);

    const objectsGround = this.threeInstance?.scene?.getObjectByName(
      'furban_objects_ground'
    );
    objectsGround?.remove(...objectsGround.children);

    this.removePins();
  }

  public changeCameraPerspective(): void {
    const center = new Vector3(
      this.threeInstance.maxXPointFromArray / 2,
      0,
      this.threeInstance.minYPointFromArray / 2
    );

    const currentState = this.threeInstance.perspectiveCubeState;
    const nextState = (currentState + 1) % 4;

    const newState = this.threeInstance.states[nextState];
    this.createNewCameraTransition(
      this.threeInstance.camera.position,
      newState,
      center
    );

    this.threeInstance.perspectiveCubeState = nextState;
  }

  public viewFromTop(): void {
    this.createNewCameraTransition(
      this.threeInstance.camera.position,
      this.computeCenterOfObject(220),
      this.computeCenterOfObject(0)
    );
  }

  public getStringForFile(projectName: string, username: string): string {
    if (username.indexOf('@') > -1) {
      return (projectName + '_ADMIN') as string;
    } else {
      return (projectName + '_' + username) as string;
    }
  }

  public exportSceneToGLTF(): void {
    const exportHelper: ThreeGLTFExportHelper = new ThreeGLTFExportHelper();
    const projectName = this.project.name.replace(/ /g, '_');
    this.navigationService.toggleSafetyArea(false);
    this.navigationService.toggleTransparency(false);
    const fileName = this.getStringForFile(
      projectName,
      this.authService.user.username
    );
    exportHelper.exportGLTF(
      this.threeInstance,
      fileName,
      this.modalManager,
      this.viewContainerRef
    );
  }

  public toggleRightMenuVisibiltiy(): void {
    this.isRightMenuVisible = !this.isRightMenuVisible;
    this.rightMenuVisibiltyChanged.emit(this.isRightMenuVisible);
    ThreeUtils.updateCanvasOnDesignProposalDetails(this.canvas);
  }

  public focusCameraOnObject(object: Object3D): void {
    //Compute camera position near the object by adding random offset values(40, 30, 20)
    const offset = new Vector3(
      object.position.x - 40,
      object.position.y + 30,
      object.position.z + 20
    );
    this.createNewCameraTransition(
      this.threeInstance.camera.position,
      offset,
      object.position
    );
  }

  public getAccessLevelForExport(): void {
    if (this.authService.hasAdministrativeRole()) {
      this.projectDetailsService
        .hasAccessOnProject(this.project.projectId)
        .subscribe((data) => {
          this.canExportDesign = data.valueOf();
        });
    } else {
      this.canExportDesign = false;
    }
  }

  public onMouseClick(event: MouseEvent): void {
    const groupsToCheck = [
      this.threeInstance.objectsRegular,
      this.threeInstance.objectsGround,
      this.threeInstance.uploadedCustomObject,
      this.threeInstance.groundGroup,
    ];

    const intersectionCoordinates = ThreeUtils.getIntersectionPointForComment(
      event,
      this.threeInstance,
      groupsToCheck
    );
    const point = intersectionCoordinates[0]?.point;

    if (!point) {
      return;
    }

    const newPinToAdd = this.threeInstance.loadedPin.clone();
    ThreeUtils.cloneMaterial(newPinToAdd);
    newPinToAdd.position.set(point.x, point.y, point.z);
    newPinToAdd.userData = new Comment();
    this.threeInstance.pinHelper.add(newPinToAdd);
    this.initializeNewCommentForPopup(newPinToAdd);
    newPinToAdd.add(this.threeInstance.pinCommentPopup);

    if (!this.lastAddedPin?.userData?.['commentId']) {
      this.threeInstance.pinHelper.remove(this.lastAddedPin);
    }

    this.lastAddedPin = newPinToAdd;
  }

  public onPinSelectClick(event: MouseEvent): void {
    const container =
      this.threeInstance.renderer.domElement.getBoundingClientRect();
    const mouseCoordinates = ThreeUtils.getMouseCoordinatesInContainer(
      event,
      container
    );

    const newIntersectedObject = ThreeUtils.getIntersectedPinComment(
      this.threeInstance,
      mouseCoordinates
    );

    if (!newIntersectedObject) {
      return;
    }

    this.currentPinComment = newIntersectedObject.userData as Comment;
    this.threeCommentPopupComponent.setComment(this.currentPinComment);
    this.highlightComment(this.currentPinComment);
  }

  public onPinPopupClose(pinWasModified: boolean): void {
    if (pinWasModified) {
      return;
    }
    this.lastAddedPin?.remove(this.threeInstance.pinCommentPopup);
    this.lastSelectedPin?.remove(this.threeInstance.pinCommentPopup);
    this.highlightComment(null);
    if (!this.lastAddedPin?.userData?.['commentId']) {
      this.threeInstance.pinHelper.remove(this.lastAddedPin);
    }
    if (this.addingCommentsEnabled) {
      this.toggleAddingCommentsOnClick();
    }
  }

  public onPinCommentSaved(comment: Comment): void {
    this.lastAddedPin?.remove(this.threeInstance.pinCommentPopup);
    this.lastSelectedPin?.remove(this.threeInstance.pinCommentPopup);
    this.highlightComment(null);
    this.lastAddedPin.userData = comment;
    this.threeJSCommentSave.emit(comment);
    this.toggleAddingCommentsOnClick();
  }

  private addEventListenersForCustomPan(): void {
    document.addEventListener('keyup', this.keyUpFunctionForPan, {
      passive: false,
    });

    document.addEventListener('keydown', this.keyDownFunctionForPan, {
      passive: false,
    });
  }

  private removeEventListenersForPan(): void {
    document.removeEventListener('keydown', this.keyDownFunctionForPan);
    document.removeEventListener('keyup', this.keyUpFunctionForPan);
  }

  private keyUpFunctionForPan = (e: KeyboardEvent) => {
    if (e.code === KeyCode.space) {
      this.threeInstance.controls.mouseButtons.LEFT = MOUSE.ROTATE;
    }
  };

  public keyDownFunctionForPan = (e: KeyboardEvent) => {
    if (e.code === KeyCode.space) {
      this.threeInstance.controls.mouseButtons.LEFT = MOUSE.PAN;
    }
  };

  private removePins(): void {
    const pinGroup = this.threeInstance.scene.getObjectByName(
      ThreeGroupEnum.pin_helpers
    );
    if (!pinGroup) {
      return;
    }
    pinGroup.remove(...pinGroup.children);
  }

  private initializeNewCommentForPopup(newPinToAdd: Object3D): void {
    this.currentPinComment = new Comment(
      this.designProposal.designProposalId,
      this.authService.userProfile,
      JSON.stringify(newPinToAdd.position)
    );
    this.threeCommentPopupComponent.setComment(this.currentPinComment);
  }

  private highlightComment(comment: Comment): void {
    this.commentService.highlightedComment = comment;
    this.commentService.highlightedCommentEmitter.emit(comment);
  }

  private addEventsForComments(): void {
    if (!this.authService.isLoggedIn()) {
      return;
    }
    this.instantiatePinOnThreeInstance();
    this.threeInstance.renderer.domElement.addEventListener(
      'pointerdown',
      this.bindedClickEvent,
      { passive: false }
    );
  }

  private removeEventsForComments(): void {
    if (this.threeInstance.renderer) {
      this.threeInstance.renderer.domElement.removeEventListener(
        'pointerdown',
        this.bindedClickEvent
      );
    }
  }

  private addEventsForSelectingComment(): void {
    this.threeInstance.renderer.domElement.addEventListener(
      'pointerdown',
      this.bindedSelectPinEvent,
      { passive: false }
    );
  }

  private removeEventsForSelectingComment(): void {
    if (this.threeInstance.renderer) {
      this.threeInstance.renderer.domElement.removeEventListener(
        'pointerdown',
        this.bindedSelectPinEvent
      );
    }
  }

  private setUpGrid(bbox: Box3): void {
    const centroid = FurbanUtil.getPolygonCentroid(
      this.threeInstance.currentCoordinates
    );
    const gridHelper = ThreeGridHelperBuilder.createGridHelper(
      bbox.max.x * 2,
      (bbox.max.x / 3) * 2
    );
    gridHelper.position.set(centroid.x, 0.1, -centroid.y);
    gridHelper.visible = false;
    this.threeInstance.groundGroup.add(gridHelper);
  }

  public getUploadedObjects(ids: string[] = [this.project.projectId]): void {
    let isSceneGroundLoaded = false;
    this.fileUploadService
      .getProject3DUploads(ids)
      .subscribe((data: Project3DUpload[]) => {
        data.forEach((uploadedObject) => {
          if (
            uploadedObject.objectType === UploadedObjectTypeEnum.underground
          ) {
            this.uploadedUnderground = uploadedObject;
            this.fileUploadService.changeProject3DUpload(
              this.uploadedUnderground,
              UploadedObjectTypeEnum.underground
            );
          } else if (
            uploadedObject.objectType === UploadedObjectTypeEnum.fixedDesign
          ) {
            this.fixedDesign = uploadedObject;
            this.fileUploadService.changeProject3DUpload(
              this.fixedDesign,
              UploadedObjectTypeEnum.fixedDesign
            );
          } else {
            this.uploadedCustomObject = uploadedObject;
            this.fileUploadService.changeProject3DUpload(
              this.uploadedCustomObject,
              UploadedObjectTypeEnum.customObject
            );
            isSceneGroundLoaded = true;
          }

          if (uploadedObject.extension === FileExtensionEnum.obj) {
            this.getUploadedOBJFiles(uploadedObject);
          } else if (uploadedObject.extension === FileExtensionEnum.glb) {
            this.getUploadedFileGLB(uploadedObject);
          }
        });
        if (!isSceneGroundLoaded) {
          this.loaderSceneGround();
        }
      });
  }

  private getUploadedFileGLB(uploadedObject: Project3DUpload): void {
    this.fileUploadService
      .getProject3DUploadFile(uploadedObject.objectS3Key)
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/glb' });
        const file = new File([blob], uploadedObject.objectS3Key);
        this.loadFromGLBFile(file, uploadedObject.objectType);
      });
  }

  private getUploadedOBJFiles(uploadedObject: Project3DUpload): void {
    const requests = [
      this.fileUploadService.getProject3DUploadFile(uploadedObject.objectS3Key),
      this.fileUploadService.getProject3DUploadFile(
        uploadedObject.textureS3Key
      ),
    ];
    forkJoin(requests).subscribe((data) => {
      const blobObj = new Blob([data[0]], { type: 'application/obj' });
      const fileObj = new File([blobObj], uploadedObject.objectS3Key);
      const blobMtl = new Blob([data[1]], { type: 'application/obj' });
      const fileMtl = new File([blobMtl], uploadedObject.textureS3Key);
      this.loadFromOBJFile(fileObj, fileMtl, uploadedObject.objectType);
    });
  }

  private attachButtonsAndControlsToObject(addedObject: Mesh | Group): void {
    this.threeInstance.intersectedObject = addedObject;
    this.getIntersectedObjectType();
    addedObject.add(this.threeInstance.controlBtns);
    ThreeUtils.setMoveMode(this.threeInstance);
    this.threeInstance.transformControls.attach(addedObject);
    this.threeInstance.transformControls.object.userData['previousPosition'] =
      new Vector3(
        this.threeInstance.transformControls.object.position.x,
        this.threeInstance.transformControls.object.position.y,
        this.threeInstance.transformControls.object.position.z
      );
    if (!this.isIOS) {
      this.open3DNotificationSnackbar('userSettings.deselectMessage');
    }
  }

  protected open3DNotificationSnackbar(message: string): void {
    if (this.authService.getUserSettings().show3DNotifications) {
      this.customToasterService.openCustomToaster(
        NotificationToasterComponent,
        '3d_rotation',
        'notification',
        message,
        0
      );
    }
  }

  private loadPin(): void {
    if (this.threeInstance.loadedPin) {
      this.clonePinForEveryPositionedComment();
      return;
    }

    const loadingUrl = urlConstants.pin;
    this.loader.load(loadingUrl, (gltf) => {
      this.threeInstance.loadedPin = gltf.scene;
      this.clonePinForEveryPositionedComment();
    });
  }

  private addCommentsFunctionalities(): void {
    if (this.authService.isLoggedIn() && this.designProposal) {
      this.instantiatePinOnThreeInstance();
      this.addHighlightPinCommentFunctionality();
      this.initializeRemovePinCommentFunctionality();
    }
  }

  private instantiatePinOnThreeInstance(): void {
    if (this.threeInstance.pinHelper) {
      return;
    }

    this.threeInstance.pinHelper = ThreeGroupBuilder.createGroup(
      ThreeGroupEnum.pin_helpers,
      6
    );
    this.threeInstance.helpersGroup.add(this.threeInstance.pinHelper);
    this.loadPin();
  }

  private clonePinForEveryPositionedComment(): void {
    if (!this.designProposal) {
      return;
    }
    this.designProposal.comments?.forEach((pin) => {
      if (pin.position) {
        const pinPosition = JSON.parse(pin.position);
        const newPinToAdd = this.threeInstance.loadedPin.clone();
        ThreeUtils.cloneMaterial(newPinToAdd);
        newPinToAdd.position.set(pinPosition.x, pinPosition.y, pinPosition.z);
        newPinToAdd.userData = pin;
        this.threeInstance.pinHelper.add(newPinToAdd);
      }
    });
  }

  private highlightPinComment(pinComment: Comment): void {
    if (!pinComment) {
      ThreeUtils.setHexColorOnMaterial(
        this.lastSelectedPin,
        TextureColorEnum.neutral0
      );
      this.lastSelectedPin?.remove(this.threeInstance.pinCommentPopup);
      return;
    }

    this.threeInstance.pinHelper.children.forEach((element) => {
      if (element.userData?.['commentId'] === pinComment?.commentId) {
        this.lastSelectedPin = element;
        ThreeUtils.setHexColorOnMaterial(element, TextureColorEnum.mildBlue);

        this.threeCommentPopupComponent.setComment(element.userData as Comment);
        this.lastSelectedPin.add(this.threeInstance.pinCommentPopup);
        this.focusCameraOnObject(this.lastSelectedPin);
      } else {
        ThreeUtils.setHexColorOnMaterial(element, TextureColorEnum.neutral0);
      }
    });
  }

  private setupPinPopupOnRenderer(): void {
    this.threeInstance.htmlRenderer = ThreeRendererBuilder.createHTMLRenderer(
      this.threeInstance.renderer.domElement
    );
    this.threeInstance.pinCommentPopup =
      ThreeUtils.getCommentPopupHTMLElement();
  }

  private addHighlightPinCommentFunctionality(): void {
    this.addEventsForSelectingComment();
    this.commentHighlightedSubscription =
      this.commentService.highlightedCommentEmitter.subscribe((data) => {
        this.highlightPinComment(data as Comment);

        if (data && this.addingCommentsEnabled) {
          this.addingCommentsEnabled = false;
          this.removeEventsForComments();
          this.addEventsForSelectingComment();
        }
      });
  }

  private initializeRemovePinCommentFunctionality(): void {
    this.commentDeletedSubscription =
      this.commentService.removeCommentEmitter.subscribe((data) => {
        if (!data) {
          return;
        }
        this.removePinComment(data);
      });
  }

  private removePinComment(comment: Comment): void {
    const pinToDelete = this.threeInstance?.pinHelper?.children?.find(
      (element) => element.userData?.['commentId'] === comment?.commentId
    );
    if (!pinToDelete) {
      return;
    }
    this.threeInstance.pinHelper.remove(pinToDelete);
  }

  private unsubscribeToPinEvents(): void {
    if (this.authService.isLoggedIn() && this.designProposal) {
      this.commentDeletedSubscription.unsubscribe();
      this.commentHighlightedSubscription.unsubscribe();
    }
  }

  private computeCenterOfObject(middlePoint: number): Vector3 {
    const center = new Vector3(
      this.threeInstance.maxXPointFromArray / 2,

      middlePoint,

      this.threeInstance.minYPointFromArray / 2
    );

    return center;
  }
}
