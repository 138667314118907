import { ThreeTextureBuilder } from './three-texture-builder';
import { ThreeGeometryBuilder } from './three-geometry-builder';
import { TextureColorEnum } from '../_enum/texture-color.enum';
import { PathObject } from '../_models/path-object';
import { ObjectTypeEnum } from '../_enum/object-type-enum';
import { MathUtil } from '../helpers/math.util';
import { PointXY } from '../_models/point-xy';
import { ThreeObjectsEnum } from '../_enum/three-objects.enum';
import { ThreeGroupEnum } from '../_enum/three-group.enum';
import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  Path,
  PlaneGeometry,
  ShapeGeometry,
  Vector3,
} from 'three';

export class ThreeMeshBuilder {
  private readonly defaultPoints = [
    new PointXY(-10000, 10000),
    new PointXY(-10000, -10000),
    new PointXY(10000, -10000),
    new PointXY(10000, 10000),
  ];

  public createZoneForBuildings(currentCoordinates: any, hole?: Path): Mesh {
    const geometry =
      ThreeGeometryBuilder.createInflatedGeometryFromArrayOfPoints(
        currentCoordinates,
        hole
      );
    const material = ThreeTextureBuilder.createMaterialWithDefaultColor(
      TextureColorEnum.warm2
    );
    const plane = new Mesh(geometry, material);
    plane.name = ThreeObjectsEnum.zonePlane;
    this.setupRotationAndRenderOrder(plane, 0);
    return plane;
  }

  public createPlaneFromGroundAreaSelection(
    coordinates: any,
    groundTextureId: number,
    hole?: Path
  ): Mesh {
    const geometry = ThreeGeometryBuilder.createGeometryFromArrayOfPoints(
      coordinates,
      hole
    );
    const material =
      ThreeTextureBuilder.createTextureForGeometry(groundTextureId);
    const plane = new Mesh(geometry, material);
    plane.name = ThreeObjectsEnum.areaPlane;
    this.setupRotationAndRenderOrder(plane, 0);
    return plane;
  }

  public createDefaultPlane(hole?: Path): Mesh {
    const geometry = ThreeGeometryBuilder.createGeometryFromArrayOfPoints(
      this.defaultPoints,
      hole
    );
    const material = ThreeTextureBuilder.createMaterialWithDefaultColor(
      TextureColorEnum.neutral2
    );
    const largePlane = new Mesh(geometry, material);
    largePlane.name = ThreeObjectsEnum.defaultPlane;
    this.setupRotationAndRenderOrder(largePlane, 0);
    return largePlane;
  }

  public createInvisiblePlane(): Mesh {
    const geometry = new PlaneGeometry(10000, 10000);
    const invisiblePlane = new Mesh(geometry);
    invisiblePlane.visible = false;
    this.setupRotationAndRenderOrder(invisiblePlane, 3);
    invisiblePlane.name = ThreeGroupEnum.invisiblePlane;
    return invisiblePlane;
  }

  public createMeshFromGeometryAndMaterial(
    geometry: PlaneGeometry | ShapeGeometry | BoxGeometry,
    material: MeshLambertMaterial | MeshBasicMaterial
  ): Mesh {
    return new Mesh(geometry, material);
  }

  public createThreeMesh(type: string, pathObject: PathObject): Mesh {
    const parsedDimensions = JSON.parse(pathObject.freeshapePoints);
    const pathObjectPosition: Vector3 = JSON.parse(pathObject.position);
    const position = new Vector3(
      pathObjectPosition.x,
      pathObjectPosition.y,
      pathObjectPosition.z
    );

    switch (type) {
      case ObjectTypeEnum.square: {
        const geometry = ThreeGeometryBuilder.createPlaneGeometry(
          parsedDimensions.x,
          parsedDimensions.y
        );
        const texture = ThreeTextureBuilder.createTextureForGeometry(
          pathObject.objId,
          parsedDimensions
        );
        const squareMesh = this.createMeshFromGeometryAndMaterial(
          geometry,
          texture
        );
        this.setRotationAndPosition(squareMesh, position, pathObject.angle);
        return squareMesh;
      }

      case ObjectTypeEnum.elipse: {
        const geometry = ThreeGeometryBuilder.createElipseGeometry(
          parsedDimensions.x,
          parsedDimensions.y
        );
        const material = ThreeTextureBuilder.createTextureForGeometry(
          pathObject.objId
        );
        const elipseMesh = this.createMeshFromGeometryAndMaterial(
          geometry,
          material
        );
        this.setRotationAndPosition(elipseMesh, position, pathObject.angle);
        return elipseMesh;
      }

      case ObjectTypeEnum.freeshape: {
        const geometry =
          ThreeGeometryBuilder.createFreeshapeGeometry(parsedDimensions);
        const material = ThreeTextureBuilder.createTextureForGeometry(
          pathObject.objId
        );
        const freeshapeMesh = this.createMeshFromGeometryAndMaterial(
          geometry,
          material
        );
        this.setRotationAndPosition(freeshapeMesh, position, pathObject.angle);
        freeshapeMesh.scale.z = -1;
        return freeshapeMesh;
      }

      case ObjectTypeEnum.custom: {
        const geometry =
          ThreeGeometryBuilder.createCustomShapeGeometry(parsedDimensions);
        const material = ThreeTextureBuilder.createCustomShapeTexture(
          parsedDimensions,
          pathObject
        );
        const customShapeMesh = this.createMeshFromGeometryAndMaterial(
          geometry,
          material
        );

        const position: Vector3 = JSON.parse(pathObject.position);
        customShapeMesh.position.set(
          position.x,
          parsedDimensions.depth / 2,
          position.z
        );
        customShapeMesh.rotateY(-MathUtil.deg2rad(pathObject.angle));
        return customShapeMesh;
      }
      default: {
        console.error('Invalid object type');
      }
    }
  }

  private setRotationAndPosition(mesh: Mesh, position: Vector3, angle: number) {
    mesh.receiveShadow = true;
    mesh.rotation.x = -Math.PI / 2;
    mesh.updateMatrix();
    mesh.geometry.applyMatrix4(mesh.matrix);
    mesh.rotation.set(0, 0, 0);
    mesh.position.set(position.x, position.y, position.z);
    mesh.geometry.center();
    mesh.rotateY(-MathUtil.deg2rad(angle));
  }

  private setupRotationAndRenderOrder = (
    mesh: Mesh,
    renderOrder: number
  ): void => {
    mesh.receiveShadow = true;
    mesh.renderOrder = renderOrder;
    mesh.lookAt(new Vector3(0, 1, 0));
    mesh.updateMatrix();
    mesh.updateMatrixWorld();
  };
}
