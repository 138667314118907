import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
    Project,
    ObjectCategoryStatistics,
    AuthService,
    StepperService,
} from '@furban/utilities';
import { StatisticsService } from '../../municipality/user/statistics/statistics.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'furban-object-statistics',
    templateUrl: './object-statistics.component.html',
    styleUrls: ['./object-statistics.component.scss'],
})
export class ObjectStatisticsComponent implements OnInit, OnDestroy {
    public curentProject: Project;
    public objectsPerCategoryCount: ObjectCategoryStatistics[];
    public objectsTotalCount: number;
    public curentProjectId: string;

    public get isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    public get isPioneer(): boolean {
        return this.authService.isPioneer();
    }

    private routeSub: Subscription;

    constructor(
        private statisticsService: StatisticsService,
        private authService: AuthService,
        private stepperService: StepperService,
        private location: Location,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.setCurrentProject();
        this.getDataForAdminCharts();
    }

    public ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    public displayCategoryStatistics(): boolean {
        return (
            (this.isAdmin || this.isPioneer) &&
            this.objectsTotalCount &&
            this.objectsPerCategoryCount?.length > 0
        );
    }

    public goBack(): void {
        this.location.back();
    }

    private setCurrentProject(): void {
        this.curentProject = this.stepperService.project as Project;
        if (!this.curentProject) {
            this.routeSub = this.route.params.subscribe((params) => {
                this.curentProjectId = params['id'];
            });
        }
    }

    private getDataForAdminCharts(): void {
        this.statisticsService
            .getDataForAdminCharts(this.curentProjectId)
            .subscribe((responseList) => {
                this.objectsPerCategoryCount = responseList[0];
                this.objectsTotalCount = responseList[1];
            });
    }
}
